/**
 * @file
 * configuration object based on ENV variables
 */
const ENV = process.env.NODE_ENV;

export const CONFIG = {
  ENV,
  APP_NAME: 'dataflow',
  GOJS_KEY: process.env.REACT_APP_GOJS_KEY,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  AUTH_TOKEN: ENV !== 'production' ? process.env.REACT_APP_LOCAL_AUTH_TOKEN : null,
  LOCAL_PLATFORM_URL: process.env.REACT_APP_LOCAL_PLATFORM_URI,
  CDN_BASEURL: process.env.REACT_APP_CDN_BASEURL || 'https://cdn.integrtr.com',
  DOWNLOAD_MANAGER_SW_PROXY_URL:
    process.env.REACT_APP_DOWNLOAD_MANAGER_SW_PROXY_URL ||
    'https://integrtr-downloadmanager.netlify.app/index.html',
  SUPPORT_EMAIL: 'support@integrtr.com',
  DATA_FETCH_LIMIT: process.env.REACT_APP_DATA_FETCH_LIMIT || 100000,

  // Sentry stuff
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  RELEASE: process.env.REACT_APP_RELEASE || 'dev',
};
