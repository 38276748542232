/**
 * @file
 * This file contains the component that renders the data lookup dialog
 */

import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import DialogWrapper from 'components/DialogWrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { entries, isEmpty, omit, values } from 'lodash-es';
import { MdSearch } from 'react-icons/md';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import axios from 'axios';

import { dataflowApiBase, getServiceInstance } from 'service';
import { DebouncedTextField } from 'components/FormComponents/DebouncedTextField';
import { LOOKUP_TYPES, LOOKUP_TYPE_TABLE_COLUMNS } from './constants';
import { NotFoundImage } from 'components/Illustrations/NotFound';
import GenericErrorImage from 'components/Illustrations/GenericErrorImage';
import DataTableLoader from './DataTableLoader';

const ITEMS_PER_PAGE = 50;

export default function DataLookupDialog({ isOpen, closeDialog, rule, onSelect, fieldData }) {
  const [lookupEntries, setLookupEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [lookupType, setLookupType] = useState();
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const { connectionId, tenantId } = useParams();

  // We need to understand what kind of a lookup we are dealing with. Is it a picklist or a foundation object
  useEffect(() => {
    setLoading(true);
    if (!rule.property) {
      return;
    }

    if (rule.property.picklist) {
      setLookupType(LOOKUP_TYPES.PICKLIST);
      setCode(rule.property.picklist);
      return;
    }

    if (rule.property.related_foundation_object) {
      setLookupType(LOOKUP_TYPES.FOUNDATION_OBJECT);
      setCode(rule.property.related_foundation_object);
      return;
    }
  }, [rule.property]);

  // The values present in the textfield should be added to the selected array, so that they are checked in the table
  useEffect(() => {
    if (!rule.param) {
      setSelected([]);
      return;
    }

    if (rule.param.includes(';')) {
      setSelected(rule.param.split(';'));
      return;
    }

    setSelected([rule.param]);
  }, [rule.param]);

  useEffect(() => {
    if (!lookupType || !code) {
      return;
    }

    const tokenSource = axios.CancelToken.source();

    async function fetchLookupMap() {
      try {
        const pagination =
          lookupType === LOOKUP_TYPES.FOUNDATION_OBJECT
            ? `?top=${ITEMS_PER_PAGE}&skip=${page * ITEMS_PER_PAGE}`
            : '';

        const searchTermQuery = searchTerm ? `&search=${searchTerm}` : '';
        const lookupData = await getServiceInstance(tenantId).get(
          `${dataflowApiBase}/connection/${connectionId}/${lookupType}/${code}${pagination}${searchTermQuery}`,
          { cancelToken: tokenSource.token }
        );

        if (isEmpty(lookupData) || !lookupData) {
          throw new Error(
            'No Data Found. Kindly pull the latest system configurations and try again'
          );
        }

        if (typeof lookupData !== 'object') {
          throw new Error('Something went wrong while fetching the data');
        }

        let _lookupEntries = [];

        // The first entry is an empty string. This is a buffer added for the checkbo
        if (lookupType === LOOKUP_TYPES.PICKLIST) {
          _lookupEntries = entries(lookupData).map(([optionId, { externalCode, label }]) => ({
            _type: LOOKUP_TYPES.PICKLIST,
            optionId,
            externalCode,
            label: label.defaultValue,
          }));
        }

        if (lookupType === LOOKUP_TYPES.FOUNDATION_OBJECT) {
          setTotalPages(Math.ceil(lookupData.count / ITEMS_PER_PAGE));

          _lookupEntries = lookupData.results.map(({ _int_code, _int_name }) => ({
            _type: LOOKUP_TYPES.FOUNDATION_OBJECT,
            externalCode: _int_code,
            name: _int_name,
          }));
        }

        setLookupEntries(_lookupEntries);
        setFilteredEntries(_lookupEntries);
      } catch (err) {
        setError(err.msg || err.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }

      return () => {
        setLookupEntries([]);
        setFilteredEntries([]);
        setError(null);
        setLoading(false);
        tokenSource.cancel();
      };
    }

    fetchLookupMap();
  }, [connectionId, tenantId, lookupType, code, page, searchTerm]);

  const handleSearch = useCallback(
    async (searchTerm) => {
      setSearching(true);

      // If the search term is less than 2 characters, we don't want to make an API call
      // This is because redis has a MINPREFIX of 2 characters set for performance reasons
      if (searchTerm.length === 1) {
        return;
      }

      let _filteredEntries = [];

      // For picklist we have not implemented the redis cache so we fetch all the data together
      if (lookupType === LOOKUP_TYPES.PICKLIST) {
        _filteredEntries = lookupEntries.filter((eachEntry) => {
          const _sanitizedEntry = omit(eachEntry, ['_type']);

          return values(_sanitizedEntry).some((eachValue) =>
            eachValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
      }

      if (lookupType === LOOKUP_TYPES.FOUNDATION_OBJECT) {
        setSearchTerm(searchTerm);
        setPage(0);
        const lookupData = await getServiceInstance(tenantId).get(
          `${dataflowApiBase}/connection/${connectionId}/${lookupType}/${code}?search=${searchTerm}`
        );

        if (!lookupData) {
          setError('No Data Found. Kindly pull the latest system configurations and try again');
          return;
        }

        if (typeof lookupData !== 'object') {
          setError('Something went wrong while fetching the data');
          return;
        }

        if (lookupData.length === 0 && searchTerm.length > 1) {
          return;
        }

        _filteredEntries = lookupData.results.map(({ _int_code, _int_name }) => ({
          _type: LOOKUP_TYPES.FOUNDATION_OBJECT,
          externalCode: _int_code,
          name: _int_name,
        }));

        setTotalPages(Math.ceil(lookupData.count / ITEMS_PER_PAGE));
      }

      setFilteredEntries(_filteredEntries);

      setSearching(false);
    },
    [code, connectionId, lookupEntries, lookupType, tenantId]
  );

  const handleContinue = useCallback(() => {
    onSelect(selected);
    closeDialog();
  }, [closeDialog, onSelect, selected]);

  const handleNext = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const handlePrevious = useCallback(() => {
    setPage((prevPage) => prevPage - 1);
  }, []);

  const showPagination =
    lookupType === LOOKUP_TYPES.FOUNDATION_OBJECT &&
    totalPages > 1 &&
    !error &&
    !loading &&
    !searching &&
    filteredEntries.length > 0;

  return (
    <DialogWrapper
      isOpen={isOpen}
      closeDialog={closeDialog}
      primaryBtnAction={handleContinue}
      size={lookupType === LOOKUP_TYPES.PICKLIST ? 'md' : 'sm'}
      title={
        <DialogHeader code={code} handleSearch={handleSearch} loading={loading} error={error} />
      }
      secondaryBtnAction={closeDialog}
      {...(showPagination && {
        footerComponent: (
          <DialogFooter
            page={page}
            totalPages={totalPages}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        ),
      })}
    >
      <LookupTable
        rows={filteredEntries}
        error={error}
        loading={loading}
        searching={searching}
        selected={selected}
        setSelected={setSelected}
        lookupType={lookupType}
        fieldData={fieldData}
      />
    </DialogWrapper>
  );
}

function LookupTable({
  rows,
  error,
  loading,
  searching,
  selected,
  setSelected,
  lookupType,
  fieldData,
}) {
  const handleClick = useCallback(
    (_, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    },
    [selected, setSelected]
  );

  const isSelected = useCallback((id) => selected.indexOf(id) !== -1, [selected]);

  const columns = LOOKUP_TYPE_TABLE_COLUMNS[lookupType];

  const shouldApplyExtCode = !fieldData.is_external_code_translation;

  if (loading || searching) {
    const columnCount = isNaN(columns?.length) ? 2 : columns?.length - 1;

    return (
      <Stack height={500}>
        <DataTableLoader columnCount={columnCount} rowCount={8} />
      </Stack>
    );
  }

  if (error || !lookupType) {
    return (
      <Stack height={500} alignItems="center" justifyContent="center">
        <GenericErrorImage height="250px" width="250px" />
        <Typography mt={2} variant="h6" textAlign="center" color="#ff005a" sx={{ opacity: 0.7 }}>
          {typeof error === 'string'
            ? error
            : 'An error occured while fetching data. Please try again.'}
        </Typography>
      </Stack>
    );
  }

  if (rows.length === 0) {
    return (
      <Stack height={500} alignItems="center" justifyContent="center">
        <NotFoundImage height="200px" width="200px" />
        <Typography mt={2} variant="h6" textAlign="center" color="slategray" sx={{ opacity: 0.7 }}>
          {typeof error === 'string' ? error : 'No data to display'}
        </Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '500px' }}>
      <TableContainer
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          height: '100%',
          maxHeight: 500,
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            borderRadius: 2,
          },
          '&::-webkit-scrollbar-thumb': {
            border: 0,
          },
        }}
      >
        <Table sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}>
          <TableHeaderContent columns={columns} />
          <TableBody sx={{ height: '100%' }}>
            {rows.map((row, index) => (
              <TableRowContent
                key={index}
                index={index}
                row={row}
                columns={columns}
                isSelected={isSelected}
                handleClick={handleClick}
                shouldApplyExtCode={shouldApplyExtCode}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

// Table Related Components
function TableHeaderContent({ columns }) {
  return (
    <TableRow sx={{ backgroundColor: 'white' }}>
      {columns.map((eachColumn) => (
        <TableCell
          key={eachColumn.key}
          variant="head"
          align={eachColumn.align ?? 'left'}
          padding="normal"
          {...(eachColumn.key === 'checkbox' && { style: { width: 50 } })}
          sx={{
            borderRight: 1,
            borderColor: 'divider',
          }}
        >
          {eachColumn.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function TableRowContent({ index, row, columns, isSelected, handleClick, shouldApplyExtCode }) {
  const { _type } = row;

  let code = row.externalCode;
  if (_type === LOOKUP_TYPES.PICKLIST) {
    code = shouldApplyExtCode ? row.externalCode : row.optionId;
  }

  const isItemSelected = isSelected(code);

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, code)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
    >
      {columns.map((eachColumn) => {
        if (eachColumn.key === 'checkbox') {
          return (
            <TableCell
              key={`${eachColumn.key}-${index}`}
              padding="checkbox"
              sx={{
                borderRight: 1,
                borderColor: 'divider',
              }}
            >
              <Checkbox color="primary" checked={isItemSelected} />
            </TableCell>
          );
        }

        return (
          <TableCell
            key={eachColumn.key}
            sx={{
              maxWidth: '80px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              borderRight: 1,
              borderColor: 'divider',
            }}
            align="left"
          >
            {row[eachColumn.key]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

// Dialog Related Components
function DialogHeader({ code, handleSearch, loading, error }) {
  return (
    <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h6">Data Lookup</Typography>
        <Tooltip title={code} arrow PopperProps={{ sx: { zIndex: 100002 } }}>
          <Chip
            size="small"
            color="info"
            variant="outlined"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 100,
            }}
            label={code ?? 'N/A'}
          />
        </Tooltip>
      </Stack>
      {!loading && !error && (
        <DebouncedTextField
          size="small"
          isMUI5={true}
          InputProps={{
            startAdornment: (
              <MdSearch size={28} style={{ marginLeft: -5, marginRight: 2 }} color="lightGray" />
            ),
          }}
          placeholder="Search by label, external code or option id"
          helperText="Search by label, external code or option id"
          sx={{ width: 300 }}
          onChange={handleSearch}
          disabled={loading || error}
          debounceTime={1000}
        />
      )}
    </Stack>
  );
}

function DialogFooter({ page, totalPages, handleNext, handlePrevious }) {
  return (
    <Stack direction="row" spacing={1}>
      <IconButton
        size="small"
        color="primary"
        sx={{ borderRadius: 1, border: 1 }}
        onClick={handlePrevious}
        disabled={page === 0}
      >
        <BiChevronLeft />
      </IconButton>
      <Box alignItems="center">
        <Typography variant="body1" color="textPrimary" alignItems="center" mt={0.5}>
          {page + 1} / {totalPages}
        </Typography>
      </Box>
      <IconButton
        size="small"
        color="primary"
        sx={{ borderRadius: 1, border: 1 }}
        onClick={handleNext}
        disabled={page + 1 === totalPages}
      >
        <BiChevronRight />
      </IconButton>
    </Stack>
  );
}
