import { ClickAwayListener, Popper, Stack } from '@mui/material';
import React from 'react';

import { borders } from '../../mui5Theme';

export default function PopoverMenu({ anchorEl, isOpen, close, children, sx, zIndex = 1 }) {
  return (
    <Popper
      open={isOpen}
      keepMounted={false}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
      sx={(theme) => ({
        p: 2,
        top: '10px !important',
        border: borders[0],
        borderRadius: 0.5,
        maxHeight: theme.spacing(50),
        overflow: 'hidden',
        backgroundColor: theme.palette.common.white,
        zIndex: zIndex,
        ...sx,
      })}
    >
      <ClickAwayListener onClickAway={close}>
        <Stack width="100%" height="100%">
          {children}
        </Stack>
      </ClickAwayListener>
    </Popper>
  );
}
