/**
 * @file
 *
 * this file contains a custom DatePicker component that extends the material-ui picker to provide first-class keyboard accessibility
 */
import { makeStyles, TextField } from '@material-ui/core';
import { TextField as MUI5TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import {
  DatePicker as MuiDatePicker,
  DateTimePicker as MuiDateTimePicker,
} from '@material-ui/pickers';
import { AutocompleteVirtualizedListbox } from 'components/AutocompleteVirtualizedListbox';
import { castToDayjsDate, checkIsDateValid, customFormats } from 'date';
import { useDateTimePicker } from 'hooks/useDateTimePicker';
import { dateTimeParamOptions, hintTexts } from 'odata/queryBuilder';
import { useCallback, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { commonTextFieldProps } from 'theme';
import { AutocompleteOption } from './AutocompleteOption';
import { autoCompleteFilterOptions } from './AutocompleteOption';
import { DateTimePickerToolbar } from './DateTimePickerToolbar';
import { VIEWS } from './DateTimePickerToolbar';
import { HelperTextAdornment, MUI5HelperTextAdornment } from './HelperTextAdornment';

const useStyles = makeStyles((theme) => ({
  endAdornment: {
    top: `calc(50 % -${theme.spacing(2)}px)`,
    right: theme.spacing(1.5),
    position: 'absolute',
  },
}));

export function DatePicker({
  label,
  disabled,
  clearable,
  value,
  maxDate,
  minDate,
  onChange,
  placeholder,
  helperText,
  resetCounter,
  getOptionDisabled = () => {},
  isMUI5 = false,
}) {
  const classes = useStyles();

  const { dateTimePicker, textfield, autocomplete } = useDateTimePicker({
    dateFormat: customFormats.date,
    value,
    onChange,
    clearable,
    resetCounter,
  });

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        classes={{ endAdornment: classes.endAdornment }}
        freeSolo
        autoHighlight
        autoComplete
        disabled={disabled}
        openOnFocus
        disableListWrap
        ListboxComponent={AutocompleteVirtualizedListbox}
        getOptionDisabled={getOptionDisabled}
        options={dateTimeParamOptions}
        filterOptions={(option, state) => {
          // THIS IS A HACK! To make the autocomplete list visible even when a option is selected in the field. This is required because, we aren't using
          // autocomplete in it's intended way but have customised it to handle our use case. What we wanted was a textfield which also provides us the list of
          // dynamic variables to select from. The main input field here is the textfield, while Autocomplete here only plays the role of providing us with the
          // variables to select. The normal behaviour of the textfield inside the autocomplete is just of typing and searching for an option present in the autocomplete
          // list and the Autocomplete component is what handles the input change.

          // For the autocomplete list to open up it is required that the inputValue in the autocomplete state is an empty string ('').
          // But in our case, the inputValue is what we are using to display the selected value in the textfield and display to the users and hence we can't
          // set the inputValue to an empty string.
          // But what we can do is, tweak the filterOptions property in a way that, if the textfield is not empty, we explicitly get access to the autocomplete,
          // state and set the inputValue to empty string and pass it to the autoCompleteFilterOptions. By doing this, the function will return us the list of
          // all the options and not just the one selected in the field.
          if (autocomplete.inputValue !== '') {
            const _state = { ...state, inputValue: '' };
            return autoCompleteFilterOptions(option, _state);
          }
          return autoCompleteFilterOptions(option, state);
        }}
        getOptionSelected={(option, value) => option.key === value.key}
        getOptionLabel={({ key }) => key ?? ''}
        renderInput={(params) =>
          isMUI5 ? (
            <MUI5TextField
              {...params}
              label={label}
              {...commonTextFieldProps}
              {...textfield}
              placeholder={placeholder}
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: params.InputProps.endAdornment,
                startAdornment: (
                  <MUI5HelperTextAdornment
                    error={textfield.error}
                    text={
                      params.inputProps.value?.startsWith('$')
                        ? hintTexts.datetimeVariable.helperText
                        : helperText
                    }
                  />
                ),
              }}
            />
          ) : (
            <TextField
              {...params}
              label={label}
              {...commonTextFieldProps}
              {...textfield}
              placeholder={placeholder}
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: params.InputProps.endAdornment,
                startAdornment: (
                  <HelperTextAdornment
                    error={textfield.error}
                    text={
                      params.inputProps.value?.startsWith('$')
                        ? hintTexts.datetimeVariable.helperText
                        : helperText
                    }
                  />
                ),
              }}
            />
          )
        }
        renderOption={(option, { inputValue }) => (
          <AutocompleteOption label={option.label} name={option.key} inputValue={inputValue} />
        )}
        {...autocomplete}
        onChange={autocomplete.onChange}
      />
      <MuiDatePicker
        {...dateTimePicker}
        autoOk
        TextFieldComponent={() => <TextField style={{ display: 'none' }} />}
        clearable={clearable}
        maxDate={maxDate}
        minDate={minDate}
      />
    </>
  );
}

export const useDateAndTimePicker = ({
  dateFormat,
  clearable,
  onChange,
  value,
  disabled,
  resetCounter,
}) => {
  const [isOpen, updateOpen] = useState(false);
  const [inputDate, updateInputDate] = useState(() => {
    const date = castToDayjsDate(value);

    if (checkIsDateValid(date)) {
      return date.format(dateFormat);
    }
    return '';
  });
  const [isDateValid, updateIsDateValid] = useState(() =>
    value ? checkIsDateValid(value) : clearable
  );

  // We are using ref to track the focus state of the textfield, button and dialog-box. We don't want the useUpdateEffect to run when these three
  // components are in focus. We aren't using useState to create a state as we will have to add the state as dependecies in useUpdateEffect hook
  // which will cause unnecessary trigerring of the hook.
  const focusStateRef = useRef({
    textField: false,
    button: false,
    dialog: false,
  });

  useUpdateEffect(() => {
    if (
      !focusStateRef.current.textField &&
      !focusStateRef.current.button &&
      !focusStateRef.current.dialog
    ) {
      updateInputDate(value?.format(dateFormat) ?? '');
    }
  }, [resetCounter, value, dateFormat]);

  // This function handles the date input by user. The user can input date either by typing it inside the textfield
  // or by selecting the date from the date picker and the handleChange function handles both scenario.
  const handleChange = useCallback(
    (eventOrDate) => {
      // If event.target exists, it means the user typed the date and we update the "inputDate" state with the value inside the TextField
      if (eventOrDate?.target) {
        const value = eventOrDate.target.value;
        const date = castToDayjsDate(value, dateFormat);
        const isValid = checkIsDateValid(date);

        updateIsDateValid(isValid);
        updateInputDate((prevDate) => {
          const wasPrevDateValid = checkIsDateValid(prevDate);

          setTimeout(() => {
            if (isValid) {
              onChange(date);
              // We don't want to set the value to null everytime the date is invalid as it is an expensive process. So what we are doing is check
              // if the previous date was a valid date and the current input is an invalid date, we trigger the
              // onChange function and pass it null
            } else if (wasPrevDateValid && !isValid) {
              onChange(null);
            }
          });

          return value;
        });
      }
      // If event.target doesn't exists, it means that the date was selected from the date picker.
      // The arguement we recieve in the handleChange function in this case is a date object and we have to convert that date to the valid format before updating
      // the "inputDate" state as we are using the state to track the controlled TextField and we want to show the value of the Textfield in our desired format
      else {
        const date = eventOrDate?.format(dateFormat);
        // If date is selected from the DatePicker, we are already moulding it in the required format in the above line of code
        // so we can directly set the isDateValid to true
        updateIsDateValid(true);
        updateInputDate(date ?? '');
        onChange(eventOrDate);
      }
    },
    [dateFormat, onChange]
  );

  const openDialog = useCallback(() => {
    if (!disabled) {
      updateOpen(true);
      focusStateRef.current.dialog = true;
    }
  }, [disabled]);
  const closeDialog = useCallback(() => {
    updateOpen(false);
    focusStateRef.current.dialog = false;
  }, []);

  const handleTextFieldFocus = useCallback(() => {
    focusStateRef.current.textField = true;
  }, []);
  const handleTextFieldBlur = useCallback(() => {
    focusStateRef.current.textField = false;
  }, []);

  const handleButtonFocus = useCallback(() => {
    focusStateRef.current.button = true;
  }, []);
  const handleButtonBlur = useCallback(() => {
    focusStateRef.current.button = false;
  }, []);

  const isError = clearable
    ? (!inputDate && typeof inputDate !== 'number' ? null : inputDate) !== null && !isDateValid
    : !isDateValid;

  return {
    dialog: {
      value: isError ? value ?? null : inputDate,
      open: isOpen,
      onOpen: openDialog,
      onClose: closeDialog,
      onChange: handleChange,
    },
    textField: {
      value: inputDate,
      error: isError,
      onFocus: handleTextFieldFocus,
      onBlur: handleTextFieldBlur,
      onChange: handleChange,
    },
    button: {
      onFocus: handleButtonFocus,
      onBlur: handleButtonBlur,
    },
  };
};

const useDateTimePickerStyles = makeStyles((theme) => ({
  endAdornment: {
    top: `calc(50 % -${theme.spacing(2)}px)`,
    right: theme.spacing(1.5),
    position: 'absolute',
  },
}));

export function DateTimePicker({
  label,
  value,
  clearable,
  onChange,
  dateFormat,
  placeholder,
  hintTexts,
  isMUI5 = false,
}) {
  const classes = useDateTimePickerStyles();

  const { dateTimePicker, textfield, autocomplete } = useDateTimePicker({
    dateFormat,
    value,
    onChange,
    clearable,
  });

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        classes={{ endAdornment: classes.endAdornment }}
        freeSolo
        autoHighlight
        autoComplete
        openOnFocus
        disableListWrap
        ListboxComponent={AutocompleteVirtualizedListbox}
        options={dateTimeParamOptions}
        filterOptions={(option, state) => {
          // THIS IS A HACK! To make the autocomplete list visible even when a option is selected in the field. This is required because, we aren't using
          // autocomplete in it's intended way but have customised it to handle our use case. What we wanted was a textfield which also provides us the list of
          // dynamic variables to select from. The main input field here is the textfield, while Autocomplete here only plays the role of providing us with the
          // variables to select. The normal behaviour of the textfield inside the autocomplete is just of typing and searching for an option present in the autocomplete
          // list and the Autocomplete component is what handles the input change.

          // For the autocomplete list to open up it is required that the inputValue in the autocomplete state is an empty string ('').
          // But in our case, the inputValue is what we are using to display the selected value in the textfield and display to the users and hence we can't
          // set the inputValue to an empty string.
          // But what we can do is, tweak the filterOptions property in a way that, if the textfield is not empty, we explicitly get access to the autocomplete,
          // state and set the inputValue to empty string and pass it to the autoCompleteFilterOptions. By doing this, the function will return us the list of
          // all the options and not just the one selected in the field.
          if (autocomplete.inputValue !== '') {
            const _state = { ...state, inputValue: '' };
            return autoCompleteFilterOptions(option, _state);
          }
          return autoCompleteFilterOptions(option, state);
        }}
        getOptionSelected={(option, value) => option.key === value.key}
        getOptionLabel={({ key }) => key ?? ''}
        renderInput={(params) =>
          isMUI5 ? (
            <MUI5TextField
              {...params}
              label={label}
              {...commonTextFieldProps}
              {...textfield}
              placeholder={placeholder}
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: params.InputProps.endAdornment,
                startAdornment: (
                  <MUI5HelperTextAdornment
                    error={textfield.error}
                    text={
                      params.inputProps.value?.startsWith('$')
                        ? hintTexts.datetimeVariable.helperText
                        : hintTexts.datetime.helperText
                    }
                  />
                ),
              }}
            />
          ) : (
            <TextField
              {...params}
              label={label}
              {...commonTextFieldProps}
              {...textfield}
              placeholder={placeholder}
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: params.InputProps.endAdornment,
                startAdornment: (
                  <HelperTextAdornment
                    error={textfield.error}
                    text={
                      params.inputProps.value?.startsWith('$')
                        ? hintTexts.datetimeVariable.helperText
                        : hintTexts.datetime.helperText
                    }
                  />
                ),
              }}
            />
          )
        }
        renderOption={(option, { inputValue }) => (
          <AutocompleteOption label={option.label} name={option.key} inputValue={inputValue} />
        )}
        {...autocomplete}
        onChange={autocomplete.onChange}
      />
      <MuiDateTimePicker
        {...dateTimePicker}
        autoOk
        ampm={false}
        TextFieldComponent={() => <TextField style={{ display: 'none' }} />}
        views={[VIEWS.DATE, VIEWS.YEAR, VIEWS.MONTH, VIEWS.HOURS, VIEWS.MINUTES, VIEWS.SECONDS]}
        ToolbarComponent={(props) => <DateTimePickerToolbar {...props} />}
        clearable={clearable}
      />
    </>
  );
}
