import { noop } from 'lodash-es';
import toast from 'react-hot-toast';
import Papa from 'papaparse';

// eslint-disable-next-line import/no-webpack-loader-syntax
import ExporterWorker from 'comlink-loader!./worker';

import streamSaver from '../streamSaver';
import { bulkExportStateActions, useBulkExportState } from '../components/BulkExportDialog';
import { dataflowApiBase, getServiceInstance } from 'service';

const BATCH_SIZE = 1000;
const worker = new ExporterWorker();

export async function exportAllData({ queryUrl, connectionId, tenantId, flattenedEntity }) {
  const { recordsCount } = useBulkExportState.get();

  const noOfBatches = Math.ceil(recordsCount / BATCH_SIZE);

  const fileStream = streamSaver.createWriteStream(`${flattenedEntity.label}.csv` || 'sample.csv', {
    onDownloadStart: () => {
      // NOTE: some artificial delay to account for browser responding to events late
      setTimeout(() => {
        bulkExportStateActions.downloadStarted(noOfBatches);
      }, 2000);
    },
    onDownloadAbort: () => {
      const { isActive, isMinimized, exportStage } = useBulkExportState.get();

      if (isActive && exportStage) {
        bulkExportStateActions.downloadComplete(false);

        if (isMinimized) {
          toast.error('Failed to export the file. Try again shortly!');
        }
      }
    },
  });
  const writer = fileStream.getWriter();
  bulkExportStateActions.attachAbortController(async () => {
    await writer.abort().catch(noop);
    await writer.close().catch(noop);
  });

  const textEncoder = new TextEncoder();

  const writeToFile = async (data, isFirstJob) => {
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    const blobText = await blob.text();
    // ufeff is the unicode for byte order mark (BOM). This helps excel with reading the data properly
    const blobTextWithBOM = `${isFirstJob ? '\ufeff' : ''}` + blobText;
    const encodedData = textEncoder.encode(blobTextWithBOM);

    await writer.write(encodedData);
  };

  const closeFileWriter = () => writer.close().catch(noop);

  let batchIndex = 0;
  const startSnapshotFetch = async (batchUrl, index) => {
    try {
      bulkExportStateActions.updateDownloadProgress(index);

      const response = await getServiceInstance(tenantId, { isWorker: true }).post(
        `${dataflowApiBase}/connection/${connectionId}/proxy`,
        {
          skipLog: true,
          url: batchUrl.toString(),
        }
      );

      if (!response.d) {
        return;
      }

      let rawRecords = [];
      if (Array.isArray(response.d)) {
        rawRecords = response.d;
      }

      if (Array.isArray(response.d?.results)) {
        rawRecords = response.d?.results;
      }

      if (!rawRecords.length) {
        return;
      }

      const isFirstJob = index === 0;

      const records = await worker.flattenComplexTypesInRecords(rawRecords);

      const sheetData = await worker.transformSheetData(records, flattenedEntity);

      const csv = await convertJsonToCsv(sheetData, isFirstJob);
      await writeToFile(csv + '\n', isFirstJob);

      if (response.d.hasOwnProperty('__next')) {
        batchIndex += 1;
        startSnapshotFetch(response.d.__next, batchIndex);
        return;
      }

      const { isActive, exportStage, isMinimized } = useBulkExportState.get();

      if (isActive && exportStage) {
        bulkExportStateActions.downloadComplete(true);

        if (isMinimized) {
          toast.success('Export of records completed!');
        }
      }

      closeFileWriter();
    } catch (err) {
      const { isActive, exportStage, isMinimized } = useBulkExportState.get();

      if (isActive && exportStage) {
        bulkExportStateActions.downloadComplete(false);
        console.error(err);

        if (isMinimized) {
          toast.error('Failed to export the file. Try again shortly!');
        }
      }

      closeFileWriter();
    }
  };

  const url = createNewUrlInstance(queryUrl);
  url.searchParams.set('paging', 'snapshot');
  url.searchParams.set('$format', 'json');
  startSnapshotFetch(url, batchIndex);
}

function convertJsonToCsv(json, isFirstJob) {
  return new Promise((resolve, reject) => {
    try {
      let csv = Papa.unparse(json, {
        header: isFirstJob ? true : false,
      });
      resolve(csv);
    } catch (error) {
      reject(error);
    }
  });
}

export const createNewUrlInstance = (_url) => {
  const url = new URL(_url);
  url.searchParams.delete('$top');
  url.searchParams.delete('$skip');
  url.searchParams.delete('$format');
  url.searchParams.delete('$expand');
  url.searchParams.delete('$inlinecount');

  return url;
};
