import { useCallback } from 'react';

import { reportViewStateHandlers } from 'state/reportView';
import { ROOT_LEVEL_GROUP_NAME } from '../constants';

export default function usePin({ columnKey }) {
  const dotSplits = columnKey.split('.');
  const underscoreSplits = columnKey.split('_');

  let parent = ROOT_LEVEL_GROUP_NAME;
  let propertyName = columnKey;

  const { handlePinColumn } = reportViewStateHandlers;

  // Depending on the nested levels of the key define the parent and the property
  // name itself.
  if (dotSplits.length === 3) {
    // An N-multiplicity field.
    parent = dotSplits[0];
    propertyName = dotSplits[2];
  } else if (underscoreSplits.length === 2) {
    // A 1-multiplicity field.
    parent = underscoreSplits[0];
    propertyName = underscoreSplits[1];
  }

  const onPin = useCallback(() => {
    handlePinColumn(parent, propertyName, true);
  }, [handlePinColumn, parent, propertyName]);

  const onUnpin = useCallback(() => {
    handlePinColumn(parent, propertyName, false);
  }, [handlePinColumn, parent, propertyName]);

  return [onPin, onUnpin];
}
