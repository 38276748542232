/**
 * @file
 *
 * this file contains the component and hooks needsed for the "Where" section of the query builder form
 */
import React, { createContext, useContext } from 'react';
import { TextField, Grid, Typography, IconButton, makeStyles, Button } from '@material-ui/core';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { MdAdd, MdDelete, MdVpnKey } from 'react-icons/md';
import clsx from 'clsx';

import { typeBasedFilterOptions, canQueryDataType } from '../odata/queryBuilder';
import { ODATA_DATA_TYPES } from '../odata/utils';
import { commonTextFieldProps } from '../theme';
import { autoCompleteFilterOptions, AutocompleteOption } from './FormComponents/AutocompleteOption';
import {
  useWhereActions,
  useQueryBuilderState,
  OPERATORS,
  WHERE_CONDITION,
} from '../state/queryBuilder';
import { tourSteps } from './Onboarding/Tour';
import { AutocompleteVirtualizedListbox } from './AutocompleteVirtualizedListbox';
import { ParamValueField } from './FormComponents/ParamValueField';

const IsSFSystemContext = createContext(false);

const useGroupStyles = makeStyles((theme) => ({
  addFirstWhereButton: {
    padding: theme.spacing(0.5, 2),
  },
  toggleButtonGroup: {
    width: theme.spacing(10),
    height: theme.spacing(3.5),
  },
  toggleButton: {
    width: '100%',
    fontSize: 10,
    fontWeight: 600,

    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  group: {
    borderLeft: theme.borders[2],
    borderLeftWidth: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1),
  },
}));

const Group = ({ group, path }) => {
  const classes = useGroupStyles();

  const { toggleGroupOperator, addNewRule, addNewGroup, deleteRuleOrGroup } = useWhereActions(
    path,
    WHERE_CONDITION.TYPE.GROUP
  );

  return (
    <Grid
      container
      item
      spacing={1}
      direction="column"
      className={clsx(path !== WHERE_CONDITION.BASE_PATH && classes.group)}
    >
      <Grid container item spacing={1} alignItems="center">
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={group.operator}
            aria-label="group operator"
            size="medium"
            onChange={toggleGroupOperator}
            className={classes.toggleButtonGroup}
            data-tour-step={tourSteps['and-or-operators'].id}
          >
            <ToggleButton
              value={OPERATORS.AND}
              aria-label="AND operator"
              className={classes.toggleButton}
            >
              And
            </ToggleButton>
            <ToggleButton
              value={OPERATORS.OR}
              aria-label="AND operator"
              className={classes.toggleButton}
            >
              Or
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <Button
            size="small"
            startIcon={<MdAdd />}
            variant="outlined"
            onClick={addNewGroup}
            data-tour-step={tourSteps['add-group'].id}
          >
            Group
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            startIcon={<MdAdd />}
            variant="outlined"
            onClick={addNewRule}
            data-tour-step={tourSteps['add-condition'].id}
          >
            Condition
          </Button>
        </Grid>
        {path !== WHERE_CONDITION.BASE_PATH && (
          <Grid item>
            <IconButton size="small" onClick={deleteRuleOrGroup}>
              <MdDelete />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={1} direction="column">
        {group.rules.length ? (
          group.rules.map((rule, index) => {
            const nextPath = `${path}.rules[${index}]`;

            if (rule.rules) {
              return <Group key={rule.id} path={nextPath} group={rule} />;
            }

            return (
              <Rule
                key={rule.id}
                path={nextPath}
                rule={rule}
                isLast={index + 1 === group.rules.length}
              />
            );
          })
        ) : (
          <Grid item container alignItems="center">
            <Grid item>
              <Button
                className={classes.addFirstWhereButton}
                size="small"
                onClick={addNewRule}
                startIcon={<MdAdd />}
                variant="contained"
                color="primary"
                disableElevation
              >
                Add First Condition
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const wherePropertiesSelector = (state) => state.where.options;
const Rule = ({ rule, path, isLast }) => {
  const whereOptions = useQueryBuilderState(wherePropertiesSelector);

  const { updateRuleProperty, updateRuleFilter, updateRuleParam, deleteRuleOrGroup, addNewRule } =
    useWhereActions(path, WHERE_CONDITION.TYPE.RULE);

  const isSfSystem = useContext(IsSFSystemContext);

  return (
    <Grid container item spacing={1} alignItems="center">
      <Grid item xs>
        <Autocomplete
          fullWidth
          size="small"
          disableClearable
          autoHighlight
          autoComplete
          openOnFocus
          disableListWrap
          filterOptions={autoCompleteFilterOptions}
          ListboxComponent={AutocompleteVirtualizedListbox}
          options={whereOptions}
          getOptionDisabled={({ type, filterable }) => !canQueryDataType(type) || !filterable}
          getOptionLabel={({ name }) => name}
          renderInput={(params) => (
            <TextField {...params} label="Property" {...commonTextFieldProps} />
          )}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption
              label={option.filterable ? option.label : `${option.label} (Not Filterable)`}
              name={option.name}
              inputValue={inputValue}
              adornment={option.isKey && !option.name.includes('/') ? <MdVpnKey /> : null}
            />
          )}
          value={rule.property}
          onChange={(event, value) => {
            updateRuleProperty(value);
          }}
        />
      </Grid>
      <Grid item xs>
        <Autocomplete
          fullWidth
          size="small"
          disableClearable
          autoHighlight
          autoComplete
          openOnFocus
          options={typeBasedFilterOptions[rule.property.type]}
          getOptionSelected={({ key }, value) => key === value.key}
          getOptionDisabled={(options) => options.disabled?.({ isSfSystem })}
          getOptionLabel={({ label }) => label}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption name={option.label} inputValue={inputValue} />
          )}
          renderInput={(params) => (
            <TextField {...params} label="Filter Operator" {...commonTextFieldProps} />
          )}
          value={rule.filter}
          onChange={(event, value) => {
            updateRuleFilter(value);
          }}
        />
      </Grid>
      {rule.property.type !== ODATA_DATA_TYPES['Edm.Boolean'] && (
        <Grid item xs>
          <ParamValueField key={rule.property.name} rule={rule} updateRuleParam={updateRuleParam} />
        </Grid>
      )}
      <Grid container item justifyContent="space-between" style={{ width: 60 }}>
        <Grid item>
          <IconButton size="small" onClick={deleteRuleOrGroup}>
            <MdDelete />
          </IconButton>
        </Grid>
        {isLast && (
          <Grid item>
            <IconButton size="small" onClick={addNewRule}>
              <MdAdd />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(0.5),
  },
  noData: {
    margin: theme.spacing(1),
    color: theme.palette.grey[800],
  },
}));

const whereSliceSelector = (state) => state.where;
export function WhereFilterOptions({ isSfSystem }) {
  const classes = useStyles();

  const { options, value } = useQueryBuilderState(whereSliceSelector);

  return (
    <IsSFSystemContext.Provider value={isSfSystem}>
      <Grid item container spacing={2}>
        <Grid item container justifyContent="flex-end" alignItems="flex-start" xs={2}>
          <Grid item>
            <Typography color="textPrimary" variant="subtitle1" className={classes.topMargin}>
              Where
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={10}>
          <Grid item container direction="column" xs={9} spacing={1}>
            {options.length ? (
              <Group key={value.id} group={value} path={WHERE_CONDITION.BASE_PATH} />
            ) : (
              <Typography variant="overline" className={classes.noData}>
                No filterable properties on this entity
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </IsSFSystemContext.Provider>
  );
}
