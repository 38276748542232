/**
 * @file
 *
 * this file contains the dialog box which handles the mass update operation
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  TextField,
  Alert,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { commonTextFieldProps } from 'mui5Theme';

import { useCallback, useState, useEffect } from 'react';

export default function MassUpdateDialog({
  isOpen,
  handleClose,
  rowsCount,
  massUpdateDialogInformation,
  setMassUpdateValue,
  picklistValues,
  totalRecordsCount,
  picklistFetcher,
}) {
  const [showConfirmationDialogActions, setShowConfirmationDialogActions] = useState(true);
  const [showDialogField, setShowDialogField] = useState(false);
  const [dialogFieldvalue, setDialogFieldValue] = useState('');
  const [isPicklistField, setIsPicklistField] = useState(false);
  const [isPicklistDataLoading, setIsPicklistDataLoading] = useState(false);

  const handleCloseDialog = useCallback(() => {
    handleClose();
    setShowDialogField(false);
    setShowConfirmationDialogActions(true);
    setIsPicklistField(false);
  }, [handleClose]);

  const handleOpen = useCallback(async () => {
    if (picklistValues && picklistFetcher.length) {
      setIsPicklistDataLoading(false);
      return;
    }

    const picklistId = massUpdateDialogInformation.picklist;

    setIsPicklistDataLoading(true);
    await picklistFetcher(picklistId);
    setIsPicklistDataLoading(false);
  }, [massUpdateDialogInformation.picklist, picklistFetcher, picklistValues]);

  const showDialogInputfield = useCallback(() => {
    setShowDialogField(true);
    setShowConfirmationDialogActions(false);
  }, []);

  const handleTextFieldChange = useCallback((event) => {
    setDialogFieldValue(event.target.value);
  }, []);

  const handlePicklistFieldChange = useCallback((event, value) => {
    setDialogFieldValue(value.value);
  }, []);

  const triggerMassUpdate = useCallback(() => {
    if (!dialogFieldvalue) {
      return;
    }

    setMassUpdateValue(dialogFieldvalue);
    handleClose();
  }, [handleClose, setMassUpdateValue, dialogFieldvalue]);

  useEffect(() => {
    if (massUpdateDialogInformation.picklist !== '') {
      setIsPicklistField(true);
    }
  }, [massUpdateDialogInformation, isOpen]);

  useEffect(() => {
    if (picklistValues) {
      setIsPicklistDataLoading(false);
    }
  }, [picklistValues]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 10001 }}
      >
        <DialogTitle>Mass updating ({massUpdateDialogInformation.name})</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to update
            <strong> {rowsCount}</strong> fields of
            <strong> {massUpdateDialogInformation.name}</strong>, are you sure you want to continue?
          </DialogContentText>
          <Alert severity="info" sx={{ marginTop: '8px' }}>
            Only the <strong>{rowsCount}</strong> out of the total{' '}
            <strong>{totalRecordsCount}</strong> records will be updated with this action. Kindly
            scroll the table if you are looking to update additional records.
          </Alert>
        </DialogContent>
        <Collapse in={showConfirmationDialogActions} timeout="auto" unmountOnExit>
          <DialogActions sx={{ marginRight: '16px' }}>
            <Button variant="outlined" size="small" onClick={handleCloseDialog} color="primary">
              Disagree
            </Button>
            <Button
              disableElevation
              size="small"
              onClick={showDialogInputfield}
              variant="contained"
            >
              Agree
            </Button>
          </DialogActions>
        </Collapse>
        <Collapse in={showDialogField} timeout="auto" unmountOnExit>
          <DialogContent>
            {isPicklistField ? (
              <Autocomplete
                disabled={isPicklistDataLoading}
                fullWidth
                size="small"
                getOptionLabel={(option) => option.value}
                loading={isPicklistDataLoading}
                options={picklistValues}
                onChange={handlePicklistFieldChange}
                onOpen={handleOpen}
                noOptionsText={'no available options'}
                renderInput={(params) => (
                  <TextField
                    {...commonTextFieldProps}
                    {...params}
                    label="picklist values"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {isPicklistDataLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <TextField
                {...commonTextFieldProps}
                id="massUpadate"
                label="NEW DATA"
                fullWidth
                size="small"
                value={dialogFieldvalue}
                onChange={handleTextFieldChange}
                helperText={!dialogFieldvalue ? 'Please input updated value' : ''}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ marginRight: '16px' }}>
            <Button variant="outlined" size="small" onClick={handleCloseDialog}>
              Close
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="small"
              autoFocus
              disabled={!dialogFieldvalue}
              onClick={triggerMassUpdate}
            >
              Mass Update
            </Button>
          </DialogActions>
        </Collapse>
      </Dialog>
    </>
  );
}
