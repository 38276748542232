/**
 * @file
 *
 * this file contains the component and hooks needsed for the "Select" section of the query builder form
 */
import React, { useCallback, useState, useMemo } from 'react';
import { Grid, Typography, TextField, makeStyles, Chip } from '@material-ui/core';
import { Autocomplete, ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { MdVpnKey } from 'react-icons/md';

import { commonTextFieldProps } from '../theme';
import { getOriginalEntityType, ENUMS } from '../odata/utils';
import { AutocompleteOption, autoCompleteFilterOptions } from './FormComponents/AutocompleteOption';
import { columnSelectActions, useQueryBuilderState } from '../state/queryBuilder';
import { tourSteps } from './Onboarding/Tour';
import { AutocompleteVirtualizedListbox } from './AutocompleteVirtualizedListbox';

const getOriginalColumns = (schema, selectedEntity) => {
  const originalEntity = getOriginalEntityType(selectedEntity, schema.entityTypesMap);

  return originalEntity?.property ?? [];
};
export function useColumnSelect(schema, selectedEntity) {
  const columnOptions = useMemo(
    () => getOriginalColumns(schema, selectedEntity),
    [schema, selectedEntity]
  );

  // state to track exclude or include flag
  const [selectFlag, updateSelectFlag] = useState(ENUMS.COLUMN_SELECT_FLAG.EXCLUDE);
  // state to track selected columns
  const [selectedColumns, updateSelectedColumns] = useState([]);

  const toggleSelectFlag = useCallback(() => {
    updateSelectFlag((flag) =>
      flag === ENUMS.COLUMN_SELECT_FLAG.INCLUDE
        ? ENUMS.COLUMN_SELECT_FLAG.EXCLUDE
        : ENUMS.COLUMN_SELECT_FLAG.INCLUDE
    );
  }, [updateSelectFlag]);

  const resetSelectedColumns = useCallback(() => {
    updateSelectFlag(ENUMS.COLUMN_SELECT_FLAG.EXCLUDE);
    updateSelectedColumns([]);
  }, [updateSelectedColumns]);

  const handleSelectedColumnsChange = useCallback(
    (event, value) => {
      updateSelectedColumns([...value]);
    },
    [updateSelectedColumns]
  );

  return {
    toggleSelectFlag,
    resetSelectedColumns,
    columnOptions,
    columnSelectFlag: selectFlag,
    selectedColumns,
    handleSelectedColumnsChange,
  };
}

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(1),
  },
  toggleButtonGroup: {
    width: theme.spacing(10),
    height: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toggleButton: {
    width: '100%',
    fontSize: 10,
    fontWeight: 600,

    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const preventEventPropagation = (event) => {
  event.stopPropagation();
};

export const columnSelectStateSelector = (state) => state.columnSelect;
export function ColumnSelectionOptions() {
  const classes = useStyles();

  const { options, value, flag } = useQueryBuilderState(columnSelectStateSelector);

  return (
    <Grid item container spacing={2} data-tour-step={tourSteps['include-exclude-columns'].id}>
      <Grid item container justifyContent="flex-end" alignItems="flex-start" xs={2}>
        <Grid item>
          <Typography color="textPrimary" className={classes.topMargin} variant="subtitle1">
            Columns
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={10} alignItems="center" spacing={1}>
        <Grid item xs={9}>
          <Autocomplete
            multiple
            size="small"
            limitTags={3}
            fullWidth
            autoHighlight
            autoComplete
            openOnFocus
            disableListWrap
            ListboxComponent={AutocompleteVirtualizedListbox}
            filterOptions={autoCompleteFilterOptions}
            options={options}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(columnOption) => columnOption.name}
            value={value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option.name}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <ToggleButtonGroup
                        className={classes.toggleButtonGroup}
                        value={flag}
                        onChange={columnSelectActions.toggleSelectFlag}
                        exclusive
                        aria-label="column select flag"
                        size="small"
                      >
                        <ToggleButton
                          value={ENUMS.COLUMN_SELECT_FLAG.EXCLUDE}
                          aria-label="column select exclude"
                          className={classes.toggleButton}
                          onClick={preventEventPropagation}
                        >
                          Exc
                        </ToggleButton>

                        <ToggleButton
                          value={ENUMS.COLUMN_SELECT_FLAG.INCLUDE}
                          aria-label="column select include"
                          className={classes.toggleButton}
                          onClick={preventEventPropagation}
                        >
                          Inc
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                label={
                  flag === ENUMS.COLUMN_SELECT_FLAG.EXCLUDE ? 'Unselect Columns' : 'Select Columns'
                }
                {...commonTextFieldProps}
              />
            )}
            renderOption={(option, { inputValue }) => (
              <AutocompleteOption
                label={option.label}
                name={option.name}
                inputValue={inputValue}
                adornment={option.isKey && !option.name.includes('/') ? <MdVpnKey /> : null}
              />
            )}
            onChange={columnSelectActions.onColumnSelectChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
