/**
 * @file
 *
 * This file contains the create bookmark dialog component
 */
import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { commonTextFieldProps } from '../theme';
import { tourSteps } from './Onboarding/Tour';
import { strip$formatAnd$inlinecountFromUrl } from '../utils';
import { useCreateBookmarkMutation } from '../data/queriesAndBookmarks';
import { useTenantState } from '../data/user';

export const useCreateBookmarkDialog = () => {
  const [bookmarkDialog, updateBookmarkDialogState] = useState({
    isOpen: false,
    queryUrl: '',
  });

  const openCreateBookmarkDialog = useCallback((url) => {
    updateBookmarkDialogState({
      isOpen: true,
      queryUrl: url,
    });
  }, []);

  const closeCreateBookmarkDialog = useCallback(() => {
    updateBookmarkDialogState({
      isOpen: false,
      queryUrl: '',
    });
  }, []);

  return { bookmarkDialog, openCreateBookmarkDialog, closeCreateBookmarkDialog };
};

export const validationSchema = yup.object({
  name: yup.string().trim().required('Bookmark title is required'),
  description: yup.string().trim(),
});

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2, 3),
  },
}));

export const dialogTitleId = 'add-bookmark-title';
export const dialogDescriptionId = 'add-bookmark-description';
export function CreateBookmarkDialog({ isOpen, handleClose, queryUrl, connectionId }) {
  const classes = useStyles();

  const { createBookmarkMutation } = useCreateBookmarkMutation();
  const tenant = useTenantState();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { url: '', name: '', description: '' },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await createBookmarkMutation.mutateAsync({
          formData: { ...data },
          tenant,
          connectionId,
        });

        handleClose();
        tourSteps['add-bookmark-btn'].next();
      } finally {
      }
    },
    [handleClose, createBookmarkMutation, tenant, connectionId]
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id={dialogTitleId}>Add Bookmark</DialogTitle>
        <DialogContent id={dialogDescriptionId}>
          <TextField
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            label="Bookmark URL"
            name="url"
            helperText={' '}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            value={strip$formatAnd$inlinecountFromUrl(queryUrl)}
            data-tour-step={tourSteps['bookmark-url'].id}
          />
          <TextField
            label="Bookmark Title"
            placeholder="Favourite Query"
            name="name"
            helperText={errors.name ? errors.name.message : ' '}
            error={Boolean(errors.name)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={createBookmarkMutation.isLoading}
            data-tour-step={tourSteps['bookmark-title'].id}
          />
          <TextField
            label="Bookmark Description"
            placeholder="This is my favorite query"
            name="description"
            helperText={errors.description ? errors.description.message : ' '}
            error={Boolean(errors.description)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={createBookmarkMutation.isLoading}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            disabled={createBookmarkMutation.isLoading}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={!isValid || createBookmarkMutation.isLoading}
            type="submit"
            startIcon={createBookmarkMutation.isLoading ? <CircularProgress size={15} /> : null}
            data-tour-step={tourSteps['add-bookmark-btn'].id}
          >
            {createBookmarkMutation.isLoading ? 'Adding' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
