/**
 * @file
 *
 * this file renders the connection archive confirmation dialog
 */

import { useCallback, useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { useConnectionHelper } from '../data/connectionsAndSystems';
import { useDisclosure } from '../hooks/useDisclosure';
import DialogWrapper from './DialogWrapper';
import GenericArchiveImage from './Illustrations/GenericArchiveImage';

export default function ArchiveConfirmationDialog({ isDialogOpen, closeDialog, connectionId }) {
  const { updateArchiveState, isArchiving } = useConnectionHelper(connectionId);

  const handleClick = useCallback(async () => {
    await updateArchiveState(true);
    closeDialog();
  }, [closeDialog, updateArchiveState]);

  return (
    <DialogWrapper
      isOpen={isDialogOpen}
      closeDialog={closeDialog}
      title=""
      primaryBtnText="Yes, Continue"
      primaryBtnProps={{
        disabled: isArchiving,
        startIcon: isArchiving ? <CircularProgress size={16} /> : <BiCheck />,
      }}
      primaryBtnAction={handleClick}
      secondaryBtnText="No"
      secondaryBtnAction={closeDialog}
    >
      <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
        <Box
          width="60%"
          bgcolor="rgba(255, 184, 112, 0.1)"
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <GenericArchiveImage width="200px" height="200px" />
        </Box>
        <Typography mt={2} variant="h6">
          Are you sure you want to archive the connection?
        </Typography>
        <Typography width="90%" align="center" color="textSecondary" variant="body1">
          Archiving would prevent anyone from using this connection and allow deletion by admins. Do
          you want to proceed?{' '}
        </Typography>
      </Stack>
    </DialogWrapper>
  );
}

export const useArchiveConnectionConfirmation = () => {
  const [connectionToBeArchived, setConnectionToBeArchived] = useState(null);
  const { isOpen, open, close } = useDisclosure();

  const openArchiveConfirmationDialog = useCallback(
    (connectionId) => {
      setConnectionToBeArchived(connectionId);
      open();
    },
    [open]
  );

  const closeArchiveConfirmationDialog = useCallback(() => {
    setConnectionToBeArchived(null);
    close();
  }, [close]);

  return {
    isArchiveConfirmationDialogOpen: isOpen,
    closeArchiveConfirmationDialog,
    openArchiveConfirmationDialog,
    connectionToBeArchived,
  };
};
