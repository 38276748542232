/**
 * @file
 *
 * root file to mount the React application and hot module reloading setup
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as go from 'gojs';

import App from './App';
import { CONFIG } from './config';

go.Diagram.licenseKey = CONFIG.GOJS_KEY;

const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = (RootComponent) => {
  root.render(<RootComponent />);
};

renderApp(App);
