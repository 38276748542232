import { get } from 'lodash-es';

/**
 *
 * @param {Array} visibleFieldsValue
 * @param {object} visibleFieldsOptionsMap
 *
 * @returns {Array}
 */
export const getColumns = (visibleFieldsValue, visibleFieldsOptionsMap) => {
  let fields = [];

  visibleFieldsValue.forEach((field) => {
    const _field = { ...get(visibleFieldsOptionsMap, field) };
    _field.visible = true;
    fields.push(_field);
  });

  return fields;
};

/**
 * Flattens the nested n-multiplicity object into a series of objects. Also, serializes
 * the data types into string (if they are not already).
 */
export function flattenNMutiplicity({ obj, nestedFields }) {
  if (nestedFields.length === 0) {
    return [obj];
  }

  // Flatten each nested field individually
  let flattenedObjects = [obj];
  nestedFields.forEach((nestedField) => {
    flattenedObjects = flattenedObjects.flatMap((obj) => {
      let nested = obj[nestedField] ?? [];
      const midpoint = Math.floor(nested.length / 2);

      if (nested.length === 0) {
        nested = [{}];
      }

      return nested.map((it, index) => ({
        ...(midpoint === index ? obj : {}),
        lf_dataflow_keyHash: obj['lf_dataflow_keyHash'] + index.toString(),
        lf_dataflow_merge_is_first: index === 0,
        ...Object.keys(it).reduce((acc, key) => {
          const fieldName = `${nestedField}.index.${key}`;
          acc[fieldName] = it[key];
          return acc;
        }, {}),
      }));
    });
  });

  return flattenedObjects;
}
