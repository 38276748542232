/**
 * @file
 *
 * This file contains the component for the bookmark panel
 */
import {
  Collapse,
  DialogContentText,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Link,
  List,
  ListSubheader,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useCallback, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { AiFillThunderbolt } from 'react-icons/ai';
import {
  MdChevronRight,
  MdContentCopy,
  MdDelete,
  MdEdit,
  MdExpandMore,
  MdShare,
} from 'react-icons/md';
import { useConfirmationDialog } from 'material-ui-confirmation';

import { useTenantState } from '../data/user';
import { useCopy } from '../hooks/useCopy';
import { useShareableUrl } from '../state/queryBuilder';
import { strip$formatAnd$inlinecountFromUrl, getBillingExpiryStatus } from '../utils';
import { useBookmarkAndLogsStyles, SkeletonLoader } from './LogPanel';
import { NoBookmarksImage } from '../components/Illustrations/NoBookmarks';
import { EditBookmarkDialog, useEditBookmark } from '../components/EditBookmarkDialog';
import { ErrorImage } from '../components/Illustrations/Error';
import { drawerWidth } from './SidePanel';
import { useConnection } from '../data/connectionsAndSystems';
import { SYSTEMS } from '../constants';
import { useDeleteBookmarkMutations } from '../data/queriesAndBookmarks';
import { useBillingUsage } from '../data/billingUsage';
import { theme } from 'theme';
import { translateVariablesToDateTimeString } from 'odata/queryBuilder';
import { ERROR_TYPES } from './ReportViewComponents/constants';

const BOOKMARK_TYPE = {
  INTEGRTR_BOOKMARKS: 'Predefined Queries',
  MY_BOOKMARKS: 'My Bookmarks',
  REPORT_VIEW: 'REPORT_VIEW',
};

const useBookmarkStyles = makeStyles((theme) => ({
  bookmarkUrl: {
    padding: theme.spacing(1, 3.3, 1, 1.5),
    fontSize: theme.spacing(1.5),
    cursor: 'pointer',
  },
  bookmarkName: {
    paddingLeft: theme.spacing(1.5),
  },
  bookmarkNameWrap: {
    width: '92%',
  },
  bookmarkDesc: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  deleteOption: {
    color: theme.palette.error.main,
  },
  groupName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  tooltipRoot: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    top: -theme.spacing(1),
    width: theme.spacing(drawerWidth - 4),
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  bookmarkSection: {
    padding: 0,
    margin: 0,
  },
  bookmarkSubHeader: {
    padding: 0,
    margin: 0,
    height: theme.spacing(5.25),
    backgroundColor: theme.palette.common.white,
  },
  expandIcon: {
    padding: theme.spacing(0, 0.5, 0, 1.5),
    height: '100%',
  },
}));

export const useDeleteBookmarkDialog = () => {
  const { getConfirmation } = useConfirmationDialog();
  const tenant = useTenantState();
  const params = useParams();
  const { deleteBookmarkMutation } = useDeleteBookmarkMutations();

  const deleteBookmark = useCallback(
    (bookmarkId) => {
      getConfirmation({
        title: 'Delete Bookmark',
        body: <DialogContentText>Are you sure you want to delete the bookmark?</DialogContentText>,

        onAccept: () => {
          deleteBookmarkMutation.mutate({ tenant, connectionId: params.connectionId, bookmarkId });
        },

        acceptButtonProps: {
          disableElevation: true,
          variant: 'contained',
        },
      });
    },
    [getConfirmation, params.connectionId, tenant, deleteBookmarkMutation]
  );

  return deleteBookmark;
};

function Bookmark({ bookmark, populateQueryBuilder, bookmarkType, openEditDialog, baseEndpoint }) {
  const classes = useBookmarkAndLogsStyles();
  const bookmarkClasses = useBookmarkStyles();

  const { getShareableUrl } = useShareableUrl();
  const copyToClipboard = useCopy();

  const deleteBookmark = useDeleteBookmarkDialog();

  const formattedQueryString =
    bookmarkType === BOOKMARK_TYPE.INTEGRTR_BOOKMARKS && baseEndpoint
      ? `${baseEndpoint}${bookmark.query_string}`
      : strip$formatAnd$inlinecountFromUrl(bookmark.query_string);

  const handleLinkClick = useCallback(() => {
    if (bookmarkType === BOOKMARK_TYPE.INTEGRTR_BOOKMARKS) {
      populateQueryBuilder(formattedQueryString);
    } else {
      populateQueryBuilder(bookmark.query_string);
    }
  }, [populateQueryBuilder, bookmark.query_string, formattedQueryString, bookmarkType]);

  const translatedQueryString = translateVariablesToDateTimeString(formattedQueryString);

  // Code to check if bookmark is a dynamic bookmark
  const regexForDynamicVariables = RegExp('[$][A-Z]+[$]', 'g');
  const isDynamicBookmark = regexForDynamicVariables.test(formattedQueryString);

  if (bookmark.type === BOOKMARK_TYPE.REPORT_VIEW) {
    return null;
  }

  return (
    <Grid
      item
      container
      direction="column"
      className={clsx(classes.container, classes.queryContent)}
    >
      <Tooltip
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        disableFocusListener
        disableTouchListener
        placement="bottom"
        classes={{
          tooltip: bookmarkClasses.tooltipRoot,
          arrow: bookmarkClasses.tooltipArrow,
        }}
        title={
          bookmark.description ? (
            <Typography align="center" className={bookmarkClasses.bookmarkDesc}>
              {bookmark.description}
            </Typography>
          ) : (
            ''
          )
        }
      >
        <Grid item container className={classes.addPadding}>
          <Grid item className={classes.textWrapper}>
            <Link
              component={Typography}
              className={bookmarkClasses.bookmarkUrl}
              color="textSecondary"
              onClick={handleLinkClick}
            >
              {formattedQueryString}
            </Link>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            className={classes.expandClickArea}
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item className={classes.textWrapper}>
              <Typography className={bookmarkClasses.bookmarkName} color="textPrimary">
                {bookmark.name ?? bookmark.title}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={2}
              justifyContent="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              {isDynamicBookmark && (
                <AiFillThunderbolt size={14} color={theme.palette.primary.main} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
      <Grid item>
        <Grid item>
          <PopupState
            variant="popover"
            popupId={`bookmark-action-${bookmark.query_bookmark_id}-menu`}
          >
            {(popupState) => (
              <>
                <Tooltip title="Bookmark Menu">
                  <IconButton
                    data-icon="bookmark-menu"
                    size="small"
                    disableRipple
                    className={classes.floatingBtns}
                    {...bindTrigger(popupState)}
                  >
                    <FiMoreVertical />
                  </IconButton>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      copyToClipboard(translatedQueryString);
                    }}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <MdContentCopy fontSize="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Copy Query URL" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      copyToClipboard(getShareableUrl(formattedQueryString));
                    }}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <MdShare fontSize="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Copy Shareable URL" />
                  </MenuItem>
                  {bookmarkType === BOOKMARK_TYPE.MY_BOOKMARKS && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        openEditDialog(bookmark);
                      }}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <MdEdit fontSize="18px" />
                      </ListItemIcon>
                      <ListItemText primary="Edit Bookmark" />
                    </MenuItem>
                  )}
                  {bookmarkType === BOOKMARK_TYPE.MY_BOOKMARKS && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        deleteBookmark(bookmark.query_bookmark_id);
                      }}
                    >
                      <ListItemIcon
                        className={clsx(bookmarkClasses.deleteOption, classes.menuItemIcon)}
                      >
                        <MdDelete fontSize="18px" />
                      </ListItemIcon>
                      <ListItemText
                        className={bookmarkClasses.deleteOption}
                        primary="Delete Bookmark"
                      />
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </PopupState>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const getBookmarkErrorText = (
  error,
  hasPlanExpired,
  bookmarkType = BOOKMARK_TYPE.MY_BOOKMARKS
) => {
  if (bookmarkType === BOOKMARK_TYPE.MY_BOOKMARKS) {
    if (error?.msg) {
      return error.msg;
    } else if (hasPlanExpired) {
      return 'Plan has expired for the tenant.';
    }

    return 'Failed to fetch Bookmarks';
  } else {
    if (error?.msg) {
      return error.msg;
    }

    return 'Failed to fetch Predefined Queries';
  }
};

const BookmarkContent = ({
  isLoading,
  error,
  data,
  populateQueryBuilder,
  bookmarkType,
  openEditDialog,
  baseEndpoint,
}) => {
  const classes = useBookmarkAndLogsStyles();

  const { data: billingUsage } = useBillingUsage();
  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

  if (isLoading && !hasPlanExpired) {
    return <SkeletonLoader />;
  }

  // if error exists then display error, if error doesn't exists then check if the plan has expired. If the plan has expired and the bookmarkType
  // is not INTEGRTR_BOOKMARKS, then show the plan expiry message. (We check the bookmarkType because we don't want to restrict our users from accessing
  // the suggested bookmarks when the plan has expired.)
  if (error || (hasPlanExpired && bookmarkType !== BOOKMARK_TYPE.INTEGRTR_BOOKMARKS)) {
    return (
      <Grid container direction="column">
        <Grid item container justifyContent="center">
          <ErrorImage width="180px" height="200px" />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography
            className={clsx(classes.noDataText, {
              [classes.errorText]: Boolean(error || hasPlanExpired),
            })}
          >
            {getBookmarkErrorText(error, hasPlanExpired, bookmarkType)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Grid container direction="column">
        <Grid item container justifyContent="center">
          <NoBookmarksImage width="180px" height="200px" />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography className={classes.noDataText}>
            {bookmarkType === BOOKMARK_TYPE.INTEGRTR_BOOKMARKS
              ? 'No Predefined Queries Available'
              : 'No Bookmarks'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return data.map((bookmark) => (
    <Bookmark
      key={bookmark.query_code ?? bookmark.query_bookmark_id}
      bookmark={bookmark}
      populateQueryBuilder={populateQueryBuilder}
      bookmarkType={bookmarkType}
      openEditDialog={openEditDialog}
      baseEndpoint={baseEndpoint}
    />
  ));
};

export function BookmarkPanel({
  bookmarkData,
  predefinedBookmarkData,
  populateQueryBuilder,
  isBookmarkLoading,
  isPredefinedBookmarkLoading,
  bookmarkError,
  predefinedBookmarkError,
  baseEndpoint,
}) {
  const classes = useBookmarkAndLogsStyles();
  const bookmarkClasses = useBookmarkStyles();

  const [isExpanded, setIsExpanded] = useState({
    [BOOKMARK_TYPE.INTEGRTR_BOOKMARKS]: (predefinedBookmarkData?.length ?? 0) > 0 ? true : false,
    [BOOKMARK_TYPE.MY_BOOKMARKS]: (bookmarkData?.length ?? 0) > 0 ? true : false,
  });

  const toggleBookmarkGroupExpand = (bookmarkType) => {
    setIsExpanded((state) => ({
      ...state,
      [bookmarkType]: !state[bookmarkType],
    }));
  };

  const params = useParams();
  const { selectedConnection } = useConnection(params.connectionId);

  const { isEditDialogOpen, openEditDialog, closeEditDialog, selectedBookmark } = useEditBookmark();

  if (bookmarkError?.type === ERROR_TYPES.AUTHENTICATION_ERROR) {
    return (
      <Grid container direction="column">
        <Grid item container justifyContent="center">
          <ErrorImage width="180px" height="200px" />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography
            className={clsx(classes.noDataText, {
              [classes.errorText]: true,
            })}
          >
            UNAUTHORIZED
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {selectedConnection?.user_system?.system.includes(SYSTEMS.SF_EC.KEY) ? (
        <>
          <List className={bookmarkClasses.bookmarkSection}>
            <ListSubheader
              className={clsx(bookmarkClasses.bookmarkSubHeader, {
                [classes.container]: !isExpanded[BOOKMARK_TYPE.MY_BOOKMARKS],
              })}
            >
              <Grid
                id={BOOKMARK_TYPE.MY_BOOKMARKS}
                component={Link}
                onClick={() => toggleBookmarkGroupExpand(BOOKMARK_TYPE.MY_BOOKMARKS)}
                item
                container
                alignItems="center"
                className={classes.expandClickArea}
                style={{ height: '100%' }}
              >
                <Grid item className={bookmarkClasses.expandIcon}>
                  {isExpanded[BOOKMARK_TYPE.MY_BOOKMARKS] ? (
                    <MdExpandMore fontSize={18} />
                  ) : (
                    <MdChevronRight fontSize={18} />
                  )}
                </Grid>
                <Grid item className={classes.textWrapper}>
                  <Typography className={bookmarkClasses.groupName}>
                    {BOOKMARK_TYPE.MY_BOOKMARKS}
                  </Typography>
                </Grid>
              </Grid>
            </ListSubheader>
            <Collapse in={isExpanded[BOOKMARK_TYPE.MY_BOOKMARKS]} style={{ width: '100%' }}>
              <BookmarkContent
                isLoading={isBookmarkLoading}
                error={bookmarkError}
                data={bookmarkData}
                populateQueryBuilder={populateQueryBuilder}
                bookmarkType={BOOKMARK_TYPE.MY_BOOKMARKS}
                openEditDialog={openEditDialog}
              />
            </Collapse>
          </List>
          <List className={bookmarkClasses.bookmarkSection}>
            <ListSubheader
              className={clsx(bookmarkClasses.bookmarkSubHeader, {
                [classes.container]: !isExpanded[BOOKMARK_TYPE.INTEGRTR_BOOKMARKS],
              })}
            >
              <Grid
                id={BOOKMARK_TYPE.INTEGRTR_BOOKMARKS}
                component={Link}
                onClick={() => toggleBookmarkGroupExpand(BOOKMARK_TYPE.INTEGRTR_BOOKMARKS)}
                item
                container
                alignItems="center"
                className={classes.expandClickArea}
                style={{ height: '100%' }}
              >
                <Grid item className={bookmarkClasses.expandIcon}>
                  {isExpanded[BOOKMARK_TYPE.INTEGRTR_BOOKMARKS] ? (
                    <MdExpandMore fontSize={18} />
                  ) : (
                    <MdChevronRight fontSize={18} />
                  )}
                </Grid>
                <Grid item className={classes.textWrapper}>
                  <Typography className={bookmarkClasses.groupName}>
                    {BOOKMARK_TYPE.INTEGRTR_BOOKMARKS}
                  </Typography>
                </Grid>
              </Grid>
            </ListSubheader>
            <Collapse in={isExpanded[BOOKMARK_TYPE.INTEGRTR_BOOKMARKS]} style={{ width: '100%' }}>
              <BookmarkContent
                isLoading={isPredefinedBookmarkLoading}
                error={predefinedBookmarkError}
                data={predefinedBookmarkData}
                populateQueryBuilder={populateQueryBuilder}
                bookmarkType={BOOKMARK_TYPE.INTEGRTR_BOOKMARKS}
                baseEndpoint={baseEndpoint}
              />
            </Collapse>
          </List>
        </>
      ) : (
        <BookmarkContent
          isLoading={isBookmarkLoading}
          error={bookmarkError}
          data={bookmarkData}
          populateQueryBuilder={populateQueryBuilder}
          bookmarkType={BOOKMARK_TYPE.MY_BOOKMARKS}
          openEditDialog={openEditDialog}
        />
      )}
      {selectedBookmark && (
        <EditBookmarkDialog
          isOpen={isEditDialogOpen}
          selectedBookmark={selectedBookmark}
          handleClose={closeEditDialog}
        />
      )}
    </>
  );
}
