import React, { createContext, useEffect } from 'react';
import { Typography, Link, Grid } from '@material-ui/core';
import { createStore } from '@halka/state';
import { noop } from 'lodash-es';

import { Portal } from './Portal';
import { HighlightDot } from './HighlightDot';
import { StepLayout } from './StepLayout';
import { CopyButton } from './CopyButton';
import { theme } from '../../theme';
import { CONNECTION_TYPES, CREATE_FLOW_TYPE, SYSTEMS } from '../../constants';
import { getServiceInstance } from '../../service';
import { useLoggedInUser, useTenantState } from '../../data/user';
import { CONFIG } from '../../config';
import { queryClient } from '../../queryClient';

const initialTourState = {
  onboardedOnce: null,

  currentStepId: null,
  isActive: false,

  // connection creation flow metadata
  createFlowType: null,

  // system metadata
  systemType: null,

  connectionType: CONNECTION_TYPES.BASIC.KEY,

  // connection metadata
  connectionId: null,
  isConnectionLive: null,
};

export const useTourState = createStore(initialTourState);

export const TourStepContext = createContext();

export const getTourStepQuerySelector = (tourStepId) => `[data-tour-step="${tourStepId}"]`;

export const tourSteps = {
  'create-connection-card': {
    id: 'create-connection-card',
    title: 'Getting Started',
    content: (
      <StepLayout>
        <Typography variant="body1">Let's start by creating a new connection.</Typography>
        <Typography variant="body1">
          Click on the <strong>+</strong> icon to get started.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height / 2,
    }),
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['new-system-flow'].id, delay: 0 });
    },
    style: { zIndex: theme.zIndex.appBar - 1 },
  },
  'new-system-flow': {
    id: 'new-system-flow',
    title: 'Create New System',
    content: (
      <StepLayout
        showNextStepButton
        references={
          <Link
            href="https://help.integrtr.com/dataflow--9fb8f7c2-d762-4f71-854a-03015dae2bfb/creating-connection-from-a-new-system--d9ea4128-5f66-4de2-b6a5-4f892aa220a2"
            target="_blank"
          >
            Creating Connection from a New System
          </Link>
        }
      >
        <Typography variant="body1">You can choose to create a new system</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */ getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height - 5,
    }),
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['existing-system-flow'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'existing-system-flow': {
    id: 'existing-system-flow',
    title: 'Use Existing System',
    content: () => (
      <StepLayout
        showPrevStepButton
        showNextStepButton
        references={
          <Link
            href="https://help.integrtr.com/dataflow--9fb8f7c2-d762-4f71-854a-03015dae2bfb/creating-connection-from-an-existing-system--194cde07-ecf6-4910-b8cd-f2e86794480c"
            target="_blank"
          >
            Creating Connection from an Existing System
          </Link>
        }
      >
        <Typography variant="body1">
          Or if you have any existing system (say, from one of our other applications) use one of
          them.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */ getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height - 5,
    }),
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['select-system'].id });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['new-system-flow'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'select-system': {
    id: 'select-system',
    title: ({ createFlowType }) =>
      createFlowType === CREATE_FLOW_TYPE.EXISTING_SYSTEM ? 'Select System' : 'Select System Type',
    content: ({ createFlowType }) => {
      const isExistingSystemFlow = createFlowType === CREATE_FLOW_TYPE.EXISTING_SYSTEM;

      return (
        <StepLayout
          showNextStepButton
          showPrevStepButton
          references={
            isExistingSystemFlow ? null : (
              <Typography>
                <strong>Why the distinction?</strong> Because we have some specialized features for
                <strong> {SYSTEMS.SF_EC.LABEL}</strong>.
              </Typography>
            )
          }
        >
          {isExistingSystemFlow ? (
            <>
              <Typography variant="body1">Choose from one of the existing systems.</Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                We support two type of systems as of now.
              </Typography>
              <br />
              <Typography variant="body1">
                You can choose between a <strong>{SYSTEMS.SF_EC.LABEL}</strong> or an
                <strong> {SYSTEMS.ODATA_SERVICE.LABEL}</strong>.
              </Typography>
            </>
          )}
        </StepLayout>
      );
    },
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { createFlowType } = useTourState.get();
      if (createFlowType === CREATE_FLOW_TYPE.EXISTING_SYSTEM) {
        tourStateActions.goToStep({ stepId: tourSteps['connection-name'].id });
      } else {
        tourStateActions.goToStep({ stepId: tourSteps['select-environment'].id });
      }
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['existing-system-flow'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'select-environment': {
    id: 'select-environment',
    title: 'Select environment',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">Select an environment for the connection.</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { systemType } = useTourState.get();
      if (systemType === SYSTEMS.ODATA_SERVICE.KEY) {
        tourStateActions.goToStep({ stepId: tourSteps['connection-name'].id });
      } else {
        tourStateActions.goToStep({ stepId: tourSteps['company-Id'].id });
      }
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['select-system'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'company-Id': {
    id: 'company-Id',
    title: 'Enter Company ID',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">Enter the SuccessFactors Company ID</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['connection-name'].id });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['select-environment'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'connection-name': {
    id: 'connection-name',
    title: 'Connection Name',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          Add a <strong>name</strong> (and optionally a <strong>description</strong>) for the
          connection.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['create-connection-button'].id });
    },
    prev: () => {
      const { createFlowType, systemType } = useTourState.get();
      if (createFlowType === CREATE_FLOW_TYPE.EXISTING_SYSTEM) {
        tourStateActions.goToStep({ stepId: tourSteps['select-system'].id });
      } else if (systemType === SYSTEMS.ODATA_SERVICE.KEY) {
        tourStateActions.goToStep({ stepId: tourSteps['select-environment'].id });
      } else {
        tourStateActions.goToStep({ stepId: tourSteps['company-Id'].id });
      }
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'create-connection-button': {
    id: 'create-connection-button',
    title: 'Create the Connection',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">
          Finally, create the connection by clicking the <strong>Create</strong> button.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: (connectionId, systemType) => {
      const { isActive } = useTourState.get();
      tourStateActions.goToStep({
        stepId: tourSteps['systems-secrets'].id,
        delay: 0,
        ...(isActive && { otherUpdates: { connectionId, systemType } }),
      });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['connection-name'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'systems-secrets': {
    id: 'systems-secrets',
    title: 'Configuring the connection',
    content: () => (
      <StepLayout showNextStepButton>
        <Typography variant="body1">
          Now, We need to configure some details about the connection before we can use this
          connection.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 20,
      y: rect.y - 5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { systemType } = useTourState.get();

      const stepId =
        systemType === SYSTEMS.SF_EC.KEY
          ? tourSteps['connection-type'].id
          : tourSteps['metadata-endpoint'].id;

      tourStateActions.goToStep({ stepId });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'connection-type': {
    id: 'connection-type',
    title: 'Connection Type',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          We need the <strong>Connection type</strong> to set the authentication mechanism to be
          used to connect to the system.
        </Typography>
        <Typography variant="body1">
          You can either select <strong>Basic Auth</strong> or <strong>OAuth</strong>{' '}
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { connectionType } = useTourState.get();

      const stepId =
        connectionType === CONNECTION_TYPES.BASIC.KEY
          ? tourSteps['metadata-endpoint'].id
          : tourSteps['oauth-endpoint'].id;

      tourStateActions.goToStep({
        stepId,
      });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['systems-secrets'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'metadata-endpoint': {
    id: 'metadata-endpoint',
    title: 'Metadata Endpoint',
    content: ({ systemType }) => (
      <StepLayout
        showNextStepButton
        showPrevStepButton
        {...(systemType === SYSTEMS.SF_EC.KEY && {
          references: (
            <Link
              href="https://apps.support.sap.com/sap/support/knowledge/en/2215682"
              target="_blank"
              rel="noreferrer noopener"
            >
              Successfactors API URLs List
            </Link>
          ),
        })}
      >
        <Typography variant="body1">
          We need the <strong>OData Service Metadata Endpoint</strong> to connect to the system.
        </Typography>
        {systemType === SYSTEMS.SF_EC.KEY ? (
          <Typography variant="body1">
            You can select your <strong>SuccessFactor Instance</strong> from the dropdown.
          </Typography>
        ) : (
          <>
            <Typography variant="body1">
              You can use any valid OData Service. Just put in the <code>$metadata</code> endpoint
              for the service
            </Typography>

            <Typography variant="body1">
              Below, we have example of a <strong>public OData Service Metadata Endpoint.</strong>
            </Typography>
            <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
              <Grid item>
                <CopyButton
                  size="small"
                  title="Metadata Endpoint"
                  textToCopy="https://services.odata.org/V2/Northwind/Northwind.svc/$metadata"
                />
              </Grid>
              <Grid item>
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://services.odata.org/V2/Northwind/Northwind.svc/$metadata"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  <strong>https://services.odata.org/V2/Northwind/Northwind.svc/$metadata</strong>
                </Link>
              </Grid>
            </Grid>
          </>
        )}
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { systemType } = useTourState.get();

      tourStateActions.goToStep({
        stepId:
          systemType === SYSTEMS.SF_EC.KEY
            ? tourSteps['sf-company-id'].id
            : tourSteps['odata-username'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['systems-secrets'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'oauth-endpoint': {
    id: 'oauth-endpoint',
    title: 'OAuth Endpoint',
    content: ({ systemType }) => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          We need the <strong>OAuth Endpoint</strong> to connect to the OAuth Application in
          SuccessFactors.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['sf-company-id'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['connection-type'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'sf-company-id': {
    id: 'sf-company-id',
    title: 'SuccessFactors Company ID',
    content: () => (
      <StepLayout
        showNextStepButton
        showPrevStepButton
        references={
          <Link
            href="https://apps.support.sap.com/sap/support/knowledge/en/2655655"
            target="_blank"
            rel="noreferrer noopener"
          >
            How to get your <strong>SuccessFactors Company ID</strong>?
          </Link>
        }
      >
        <Typography variant="body1">
          Add your <strong>SuccessFactors Company ID</strong> which will be used for authentication
          purposes.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { connectionType } = useTourState.get();

      const stepId =
        connectionType === CONNECTION_TYPES.BASIC.KEY
          ? tourSteps['odata-username'].id
          : tourSteps['api-key'].id;

      tourStateActions.goToStep({ stepId });
    },
    prev: () => {
      const { connectionType } = useTourState.get();

      const stepId =
        connectionType === CONNECTION_TYPES.BASIC.KEY
          ? tourSteps['metadata-endpoint'].id
          : tourSteps['oauth-endpoint'].id;

      tourStateActions.goToStep({
        stepId,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'api-key': {
    id: 'api-key',
    title: 'API Key',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography>
          We use <strong>API Key</strong> for accessing the OAuth application in SuccessFactors.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['oauth-email-key'].id });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['sf-company-id'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'oauth-email-key': {
    id: 'oauth-email-key',
    title: 'OAuth Email Key',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography>
          The value provided here will be used as the key to store the email of the user in the
          database.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['oauth-certificate'].id });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['api-key'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'oauth-certificate': {
    id: 'oauth-certificate',
    title: 'X.509 Certificate',
    content: ({ systemType }) => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography>
          Upload the <strong>X.509 certificate</strong> here. It is used for the SAML signing{' '}
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    next: () => {
      const { createFlowType } = useTourState.get();
      tourStateActions.goToStep({
        stepId:
          createFlowType === CREATE_FLOW_TYPE.NEW_SYSTEM
            ? tourSteps['save-secrets'].id
            : tourSteps['update-secrets'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['oauth-email-key'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'odata-username': {
    id: 'odata-username',
    title: 'Username',
    content: ({ systemType }) => (
      <StepLayout
        showNextStepButton
        showPrevStepButton
        references={
          <>
            <Typography>
              We use <strong>HTTP Basic Authentication</strong> for protected endpoint access.
            </Typography>
            {systemType === SYSTEMS.ODATA_SERVICE.KEY && (
              <Typography>Skip if the endpoint is public.</Typography>
            )}
          </>
        }
      >
        <Typography>
          Add your <strong>Username</strong> which will be used to access the endpoint.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['odata-password'].id });
    },
    prev: () => {
      const { systemType } = useTourState.get();
      tourStateActions.goToStep({
        stepId:
          systemType === SYSTEMS.SF_EC.KEY
            ? tourSteps['sf-company-id'].id
            : tourSteps['metadata-endpoint'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'odata-password': {
    id: 'odata-password',
    title: 'Password',
    content: ({ systemType }) => (
      <StepLayout
        showNextStepButton
        showPrevStepButton
        references={
          <>
            <Typography>
              We use <strong>HTTP Basic Authentication</strong> for protected endpoint access.
            </Typography>
            {systemType === SYSTEMS.ODATA_SERVICE.KEY && (
              <Typography>Skip if the endpoint is public.</Typography>
            )}
          </>
        }
      >
        <Typography variant="body1">
          Add your <strong>Password</strong> which will be used to access the endpoint.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    next: () => {
      const { createFlowType } = useTourState.get();
      tourStateActions.goToStep({
        stepId:
          createFlowType === CREATE_FLOW_TYPE.NEW_SYSTEM
            ? tourSteps['save-secrets'].id
            : tourSteps['update-secrets'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['odata-username'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'save-secrets': {
    id: 'save-secrets',
    title: 'Save Secrets',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">
          <strong>Save</strong> the credentials / configuration for the connection.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['ping-test'].id });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['odata-password'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'update-secrets': {
    id: 'update-secrets',
    title: 'Update Secrets',
    content: () => (
      <StepLayout
        showPrevStepButton
        showNextStepButton
        references={<Typography>Skip, if the required credentials are already setup.</Typography>}
      >
        <Typography variant="body1">
          <strong>Save</strong> the credentials / configuration for the connection.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['ping-test'].id });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['odata-password'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'ping-test': {
    id: 'ping-test',
    title: 'Ping Test',
    content: () => (
      <StepLayout>
        <Typography variant="body1">Now that we have configured the connection.</Typography>
        <Typography variant="body1">
          Let's <strong>ping the endpoint</strong> to check if it is working fine.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    next: ({ isConnectionLive }) => {
      const { isActive } = useTourState.get();
      tourStateActions.goToStep({
        stepId: tourSteps['close-dialog'].id,
        ...(isActive && { otherUpdates: { isConnectionLive } }),
      });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['save-secrets'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'close-dialog': {
    id: 'close-dialog',
    title: 'Close Dialog',
    content: () => (
      <StepLayout>
        <Typography variant="body1">
          Since, the ping test has been run we can go ahead and close this dialog.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height * 0.8,
    }),
    popoverAnchor: {
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['connection-card'].id });
    },
    prev: () => {
      tourStateActions.goToStep({ stepId: tourSteps['ping-test'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'connection-menu': {
    id: 'connection-menu',
    title: 'Connection Menu',
    content: () => (
      <StepLayout>
        <Typography variant="body1">Click on this button to open Connection Menu </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 20,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['edit-secrets'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'edit-secrets': {
    id: 'edit-secrets',
    title: 'Edit Secrets Option',
    content: () => (
      <StepLayout>
        <Typography variant="body1">Click on this button to edit secrets </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 20,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({ stepId: tourSteps['metadata-endpoint'].id });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'connection-card': {
    id: 'connection-card',
    title: 'Connection Card',
    content: () => (
      <StepLayout>
        <Typography variant="body1">
          The created <strong>Connection</strong> is ready to be used
        </Typography>
        <Typography variant="body1">Click on the connection to get started</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['query-builder'].id,
        otherUpdates: { isActive: false },
      });
    },
    style: { zIndex: theme.zIndex.appBar - 1 },
  },
  'query-builder': {
    id: 'query-builder',
    title: 'Query Builder',
    content: () => (
      <StepLayout showNextStepButton>
        <Typography variant="body1">
          The queries are built using a flexible yet powerful Visual <strong>Query Builder</strong>{' '}
          User Interface
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['select-entity'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'select-entity': {
    id: 'select-entity',
    title: 'Select Entity',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">
          Select an <strong>Entity</strong> from the list below
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['rows-to-fetch'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['query-builder'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'rows-to-fetch': {
    id: 'rows-to-fetch',
    title: 'Rows to Fetch',
    content: () => (
      <StepLayout
        showNextStepButton
        showPrevStepButton
        references={
          <>
            <Typography>Maximum possible is 1000 and minimum is 1.</Typography>
            <Typography>
              <i>
                You can see such validation helper messages for all fields by{' '}
                <strong>hovering on the help icon beside each field</strong>
              </i>
            </Typography>
          </>
        }
      >
        <Typography variant="body1">
          Add the <strong>numbers of records</strong> you want to fetch
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['rows-to-skip'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['select-entity'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'rows-to-skip': {
    id: 'rows-to-skip',
    title: 'Rows to Skip',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">Add the numbers of records you want to skip</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { systemType } = useTourState.get();
      tourStateActions.goToStep({
        stepId:
          systemType === SYSTEMS.SF_EC.KEY
            ? tourSteps['effective-range'].id
            : tourSteps['and-or-operators'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['rows-to-fetch'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'effective-range': {
    id: 'effective-range',
    title: 'Effective Range',
    content: () => (
      <StepLayout
        showNextStepButton
        showPrevStepButton
        references={
          <Link
            href="https://apps.support.sap.com/sap/support/knowledge/en/2470903"
            target="_blank"
            rel="noreferrer noopener"
          >
            What is Effective Dating?
          </Link>
        }
      >
        <Typography variant="body1">
          Put in <i>optional</i> date values for <strong>Effective Dated</strong> queries here
        </Typography>
        <Typography variant="body1">
          You can either use the <strong>As of Date field</strong> or the <strong>From Date</strong>{' '}
          and <strong>To Date</strong> fields
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height / 2 + 10,
    }),
    popoverAnchor: {
      horizontal: 'center',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['and-or-operators'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['rows-to-skip'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'and-or-operators': {
    id: 'and-or-operators',
    title: 'AND/OR Operators',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          This is where you choose which operator (<strong>AND / OR</strong>) you want to use when
          using multiple filter conditions
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['add-group'].id,
      });
    },
    prev: () => {
      const { systemType } = useTourState.get();
      tourStateActions.goToStep({
        stepId:
          systemType === SYSTEMS.SF_EC.KEY
            ? tourSteps['effective-range'].id
            : tourSteps['rows-to-skip'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'add-group': {
    id: 'add-group',
    title: 'Group',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          Use this to <strong>group</strong> your conditions correctly when mixing{' '}
          <strong>AND / OR</strong> operators
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['add-condition'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['and-or-operators'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'add-condition': {
    id: 'add-condition',
    title: 'Condition',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">This is where you add your conditions</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      vertical: 'bottom',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['expand-columns'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['add-group'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'expand-columns': {
    id: 'expand-columns',
    title: 'Expand',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          This is where you can add <strong>relations</strong> (navigation properties) to expand on
          in your query
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: (rect.x + rect.width) / 4 + 10,
      y: rect.y + rect.height / 3,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['include-exclude-columns'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['add-condition'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'include-exclude-columns': {
    id: 'include-exclude-columns',
    title: 'Columns',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          This is where you can add <strong>columns to be included and excluded</strong> from the
          results
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: (rect.x + rect.width) / 4 + 10,
      y: rect.y + rect.height / 3,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['order-by'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['expand-columns'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'order-by': {
    id: 'order-by',
    title: 'Columns',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          This is where you can add your <strong>ordering rules</strong> for your data
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: (rect.x + rect.width) / 4 + 10,
      y: rect.y + rect.height / 3,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['query-display'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['include-exclude-columns'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'query-display': {
    id: 'query-display',
    title: 'Query Display',
    content: () => (
      <StepLayout showNextStepButton showPrevStepButton>
        <Typography variant="body1">
          This is where you can see the built out <strong>query</strong> that will be made to the
          OData System Endpoint
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['get-data'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['order-by'].id,
      });
    },
    style: { zIndex: theme.zIndex.appBar - 1 },
  },
  'get-data': {
    id: 'get-data',
    title: 'Get Data',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">
          Click on this <strong>button</strong> to fetch your data
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['data-table'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['query-display'].id,
      });
    },
    style: { zIndex: theme.zIndex.appBar - 1 },
  },
  'data-table': {
    id: 'data-table',
    title: 'Data Table',
    content: () => (
      <StepLayout showNextStepButton>
        <Typography variant="body1">
          This is where your queried data is displayed in a tabular form
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-icon'].id,
      });
    },
    style: { zIndex: theme.zIndex.appBar - 1 },
  },
  'bookmark-icon': {
    id: 'bookmark-icon',
    title: 'Add Bookmark',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">
          Click on this bookmark icon to <strong>bookmark this query</strong>
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-url'].id,
        delay: 0,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['data-table'].id,
      });
    },
    style: { zIndex: theme.zIndex.appBar - 1 },
  },
  'bookmark-url': {
    id: 'bookmark-url',
    title: 'Bookmark URL',
    content: () => (
      <StepLayout showNextStepButton>
        <Typography variant="body1">
          This is the <strong>Query URL</strong> you want to bookmark
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-title'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'bookmark-title': {
    id: 'bookmark-title',
    title: 'Bookmark Title',
    content: () => (
      <StepLayout showPrevStepButton showNextStepButton>
        <Typography variant="body1">
          Add the <strong>name</strong> for your bookmark and optionally a{' '}
          <strong>description</strong>
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['add-bookmark-btn'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-url'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'add-bookmark-btn': {
    id: 'add-bookmark-btn',
    title: 'Add Bookmark',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">Click on this button to save the bookmark</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['side-panel-btn'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-title'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'side-panel-btn': {
    id: 'side-panel-btn',
    title: 'Side Panel Toggle',
    content: () => (
      <StepLayout>
        <Typography variant="body1">
          Clicking on this button will open the Side Panel where you can see your{' '}
          <strong>historical query logs</strong> and <strong>bookmarks</strong>
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-panel-btn'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'bookmark-panel-btn': {
    id: 'bookmark-panel-btn',
    title: 'Bookmark Panel Button',
    content: () => (
      <StepLayout showPrevStepButton>
        <Typography variant="body1">Click on this tab to switch to Bookmarks Panel</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + rect.height * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-panel'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['side-panel-btn'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'bookmark-panel': {
    id: 'bookmark-panel',
    title: 'Bookmark Panel',
    content: () => (
      <StepLayout showPrevStepButton showNextStepButton>
        <Typography variant="body1">This is where all your bookmarks are saved</Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x + rect.width * 0.9,
      y: rect.y + 130 * 0.5,
    }),
    next: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['help-button'].id,
      });
    },
    prev: () => {
      tourStateActions.goToStep({
        stepId: tourSteps['bookmark-panel-btn'].id,
      });
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
  'help-button': {
    id: 'help-button',
    title: 'Help',
    content: () => (
      <StepLayout
        isFinalStep
        showNextStepButton
        references={
          <Link
            href="https://help.integrtr.com/dataflow--9fb8f7c2-d762-4f71-854a-03015dae2bfb"
            target="_blank"
          >
            Check out INTEGRTR Help for in-depth documentation.
          </Link>
        }
      >
        <Typography variant="body1">
          This concludes our tour of <strong>Dataflow</strong>. Feel free to explore on it's own.
          There is plenty more it can do and offer.
        </Typography>
      </StepLayout>
    ),
    /**
     * @param {DOMRect} rect
     */
    getDotCoords: (rect) => ({
      x: rect.x - 10,
      y: rect.y + rect.height * 0.5,
    }),
    popoverAnchor: {
      horizontal: 'right',
    },
    next: () => {
      const { onboardedOnce } = useTourState.get();
      tourStateActions.reset();
      if (!onboardedOnce) {
        updateAppOnboardedStatus();
      }
    },
    style: { zIndex: theme.zIndex.modal + 1 },
  },
};

const conditionallyDelayed = (callback, delay) => {
  if (typeof delay === 'number') {
    setTimeout(callback, delay);
  } else {
    callback();
  }
};

export const updateAppOnboardedStatus = async () => {
  const tenant = useTenantState.get();

  await getServiceInstance(tenant?.tenant_id)
    .put(`/profile/app_onboarded`, {
      app_code: CONFIG.APP_NAME,
    })
    .then(() => queryClient.invalidateQueries('/whoAmI'))
    .catch(noop);
};

export const tourStateActions = {
  goToStep: ({ stepId, delay, otherUpdates }) => {
    conditionallyDelayed(() => {
      if (stepId && tourSteps[stepId]) {
        const { currentStepId, isActive } = useTourState.get();
        if (isActive) {
          if (currentStepId !== stepId) {
            useTourState.set((state) => ({
              ...state,
              ...otherUpdates,
              currentStepId: stepId,
            }));
          } else if (otherUpdates) {
            useTourState.set((state) => ({
              ...state,
              ...otherUpdates,
            }));
          }
        }
      }
    }, delay);
  },
  reset: () => {
    useTourState.set((state) => ({ ...initialTourState, onboardedOnce: state.onboardedOnce }));
  },
  start: () => {
    useTourState.set((state) => ({
      ...initialTourState,
      currentStepId: 'create-connection-card',
      isActive: true,
      onboardedOnce: state.onboardedOnce,
    }));
  },
  pause: () => {
    useTourState.set((state) => ({ ...state, isActive: false }));
  },
  resume: () => {
    useTourState.set((state) => ({ ...state, isActive: true }));
  },
};

const useInitializeTour = () => {
  const { user } = useLoggedInUser();

  useEffect(() => {
    useTourState.set((state) => ({
      ...state,
      onboardedOnce: user?.dataflowOnboarded,
    }));

    const { currentStepId } = useTourState.get();

    if (!user?.dataflowOnboarded) {
      // Check if there is an active current step, if yes then don't run the start() method
      // This is done because when we transition from ConnectionsListing page to ConnectionPage, there is a whoAmI call made, which run this
      // useEffect again causing the tourStateActions.start() to run again hence the tour will get restarted and won't move to the ConnectionPage
      if (!currentStepId) {
        tourStateActions.start();
      }
    } else {
      tourStateActions.reset();
    }
  }, [user]);
};

export function Tour() {
  useInitializeTour();

  return (
    <Portal>
      <HighlightDot />
    </Portal>
  );
}
