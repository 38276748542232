/**
 * @file
 *
 * this file contains the expiry banner component
 */

import { Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useLocalStorage } from 'react-use';
import { getBillingExpiryStatus } from 'utils';

import { billingPlanDisplayActions, useBillingUsage } from '../data/billingUsage';

export const START_EXPIRY_ALERT_DAY = 10; //days
const EXPIRY_BANNER_DISPLAY_BUFFER = 24; // hrs

export const useBannerStyles = makeStyles((theme) => ({
  warning: {
    top: theme.spacing(11.5),
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
  checkUsagePlanBtn: {
    textTransform: 'none',
  },
  detials: {
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    color: theme.palette.warning.contrastText,
  },
  bannerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: theme.spacing(4),
    padding: theme.spacing(0, 1),
  },
  bannerMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bannerIcon: {
    padding: theme.spacing(0.5, 0),
    marginRight: theme.spacing(1),
  },
  bannerCloseBtn: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.5),
    padding: 0,
  },
}));

const bannerCloseDateToken = 'integrtr_expiry_banner_close_date';
const useExpiryBanner = (noOfDaysLeftTillExpiry) => {
  const [isExpiryBannerOpen, setIsExpiryBannerOpen] = useState(false);

  const [localStorageValue, setLocalStorageValue] = useLocalStorage(bannerCloseDateToken, null);

  const previousBannerCloseDate = new Date(localStorageValue);
  const currentDate = new Date();

  const timeSinceBannerWasClosed = Math.ceil(
    (currentDate - previousBannerCloseDate) / (1000 * 60 * 60)
  );

  useEffect(() => {
    if (noOfDaysLeftTillExpiry && noOfDaysLeftTillExpiry <= START_EXPIRY_ALERT_DAY) {
      if (timeSinceBannerWasClosed < EXPIRY_BANNER_DISPLAY_BUFFER) {
        setIsExpiryBannerOpen(false);
      } else {
        setIsExpiryBannerOpen(true);
      }
    } else {
      setIsExpiryBannerOpen(false);
    }
  }, [noOfDaysLeftTillExpiry, timeSinceBannerWasClosed]);

  const closeBanner = useCallback(() => {
    setIsExpiryBannerOpen(false);
    const currentDate = new Date();

    setLocalStorageValue(currentDate);
  }, [setLocalStorageValue]);

  return { isExpiryBannerOpen, closeBanner };
};

export function ExpiryBanner() {
  const expiryBannerClasses = useBannerStyles();

  const { data: billingUsage } = useBillingUsage();
  const { noOfDaysLeft, hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);
  const { isExpiryBannerOpen, closeBanner } = useExpiryBanner(noOfDaysLeft);

  if (!isExpiryBannerOpen) {
    return null;
  }

  return (
    <Grid container className={expiryBannerClasses.warning}>
      <Alert
        severity="warning"
        classes={{
          root: expiryBannerClasses.bannerRoot,
          message: expiryBannerClasses.bannerMessage,
          icon: expiryBannerClasses.bannerIcon,
        }}
      >
        {hasPlanExpired ? (
          <Typography variant="body1">
            Your plan has <strong>expired</strong>
          </Typography>
        ) : (
          <Typography variant="body1">
            Your plan is about to expire in <strong>{noOfDaysLeft} days!</strong>
          </Typography>
        )}
        <Button
          size="small"
          className={expiryBannerClasses.detials}
          classes={{ root: expiryBannerClasses.checkUsagePlanBtn }}
          onClick={billingPlanDisplayActions.openDialog}
        >
          <Typography variant="body1">(see details)</Typography>{' '}
        </Button>

        <Grid item className={expiryBannerClasses.bannerCloseBtn}>
          <IconButton size="small" onClick={closeBanner}>
            <MdClose fontSize={18} />
          </IconButton>
        </Grid>
      </Alert>
    </Grid>
  );
}
