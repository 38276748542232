import { useLayoutEffect, useRef } from 'react';

export function useRunAfterPaint() {
  const afterPaintRef = useRef(null);
  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });

  const runAfterPaint = (fn) => (afterPaintRef.current = fn);
  return runAfterPaint;
}
