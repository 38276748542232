/**
 * @file
 *
 * this file contains the component that renders the quota usage display modal
 */

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { MdEmail } from 'react-icons/md';
import clsx from 'clsx';
import sortArray from 'sort-array';

import { CONFIG } from '../config';
import { useBillingUsage } from '../data/billingUsage';
import { useLoggedInUser, useTenantState } from '../data/user';
import { EmptyImage } from './Illustrations/Empty';
import { START_EXPIRY_ALERT_DAY } from '../components/ExpiryBanner';
import { getBillingExpiryStatus, getMailToString } from 'utils';
import { EMAIL_FORMATS } from '../constants';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: theme.typography.fontWeightBold,
    border: 0,
  },
  body: {
    fontWeight: theme.typography.fontWeightRegular,
    border: 0,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  activeChip: {
    backgroundColor: theme.palette.secondary.main,
  },
  inactiveChip: {
    backgroundColor: theme.palette.error.main,
  },
  quotaContainer: {
    width: theme.spacing(10),
  },
  tableContainer: {
    maxHeight: theme.spacing(50),
  },
  actionsButtons: {
    padding: theme.spacing(0, 3, 1.5),
  },
  noPlanDialogText: {
    margin: theme.spacing(1, 3, 2),
  },
  usageDisplayPositive: {
    backgroundColor: '#1a90ff',
  },
  usageDisplayNegative: {
    backgroundColor: theme.palette.error.main,
  },
  positiveChip: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    backgroundColor: '#1a90ff',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
  negativeChip: {
    backgroundColor: theme.palette.error.main,
  },
  planUpgradeInfo: {
    marginTop: theme.spacing(2),
  },
  booleanTypeData: {
    color: theme.palette.grey[400],
  },
}));

const getUsageValue = (feature) => {
  if (feature.usage_details) {
    if (feature.usage_details.active_count > feature.quota) {
      return 100;
    }

    return (feature.usage_details.active_count / feature.quota) * 100;
  }

  return 0 / feature.quota;
};

const UsageData = ({ feature }) => {
  const classes = useStyles();

  if (!feature.is_active || feature.feature_details.quota_type === 'BOOLEAN') {
    return (
      <Grid item container justifyContent="flex-start">
        <Typography variant="body2" className={classes.booleanTypeData}>
          N/A
        </Typography>
      </Grid>
    );
  }

  if (feature.feature_details.quota_type === 'STATIC') {
    return (
      <Grid item container justifyContent="flex-start">
        <Typography variant="body2" color="textSecondary">
          {feature.reference_count ?? 0}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item container direction="column">
      <BorderLinearProgress
        variant="determinate"
        value={getUsageValue(feature)}
        classes={{
          bar:
            (feature.usage_details?.active_count ?? 0) > feature.quota
              ? classes.usageDisplayNegative
              : classes.usageDisplayPositive,
        }}
      />
      <Typography variant="body2" color="textSecondary">
        {feature.usage_details
          ? `${feature.usage_details.active_count} / ${feature.quota}`
          : `0 / ${feature.quota}`}
      </Typography>
    </Grid>
  );
};

export function QuotaUsageDisplayDialog({ isOpen, close }) {
  const classes = useStyles();

  const { data: billingUsage } = useBillingUsage();
  const { noOfDaysLeft, hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

  const { user } = useLoggedInUser();
  const tenant = useTenantState();

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle>Plan Details</DialogTitle>
      <DialogContent>
        {billingUsage ? (
          <>
            <Chip
              className={clsx(classes.positiveChip, {
                [classes.negativeChip]: noOfDaysLeft <= START_EXPIRY_ALERT_DAY,
              })}
              label={hasPlanExpired ? 'Expired' : `${noOfDaysLeft} days remaining`}
            />
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="quote-usage-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Feature</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Quota</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FeatureRows billingUsage={billingUsage} />
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item container className={classes.planUpgradeInfo}>
              <PlanUpgradeInfo
                daysRemainingTillExpiry={noOfDaysLeft}
                hasPlanExpired={hasPlanExpired}
                user={user}
                tenant={tenant}
              />
            </Grid>
          </>
        ) : (
          <Grid item container justifyContent="center">
            <EmptyImage width="200px" height="200px" />
            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
              gutterBottom
              className={classes.noPlanDialogText}
            >
              You are not assigned any plan as of now and this may prevent you from using some of
              the features.
            </Typography>{' '}
            <Alert severity="info">
              <Typography variant="body1">
                Please reach out to us at{' '}
                <Link
                  href={getMailToString({
                    emailSubject: EMAIL_FORMATS.INQUIRY.subject,
                    emailBody: EMAIL_FORMATS.INQUIRY.getBody(user, tenant),
                  })}
                >
                  <strong>{CONFIG.SUPPORT_EMAIL}</strong>
                </Link>
                ​ to get a plan assigned
              </Typography>
            </Alert>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid item container justifyContent="space-between" className={classes.actionsButtons}>
          <Button
            startIcon={<MdEmail />}
            size="small"
            color="primary"
            variant="outlined"
            disableElevation
            href={getMailToString({
              emailSubject: EMAIL_FORMATS.UPGRADE.subject,
              emailBody: EMAIL_FORMATS.UPGRADE.getBody(user, tenant),
            })}
          >
            Request Upgrade
          </Button>
          <Button onClick={close} size="small" color="primary" variant="contained" disableElevation>
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

function FeatureRows({ billingUsage }) {
  const classes = useStyles();
  const features = billingUsage?.features ? [...billingUsage.features] : [];

  if (features.length > 0) {
    sortArray(features, {
      by: ['is_active', 'quota_type'],
      order: ['is_active', 'quota_type'],
      customOrders: {
        is_active: [true, false],
        quota_type: ['DYNAMIC', 'STATIC', 'BOOLEAN'],
      },
      computed: {
        quota_type: (feature) => feature.feature_details.quota_type,
      },
    });
  }

  return features.map((feature) => (
    <TableRow key={feature.feature_code}>
      <StyledTableCell>{feature.feature_details.description}</StyledTableCell>
      <StyledTableCell>
        {feature.is_active ? (
          <Chip className={classes.activeChip} size="small" color="primary" label="active" />
        ) : (
          <Chip className={classes.inactiveChip} size="small" color="secondary" label="inactive" />
        )}
      </StyledTableCell>
      <StyledTableCell className={classes.quotaContainer}>
        <UsageData feature={feature} />
      </StyledTableCell>
    </TableRow>
  ));
}

function PlanUpgradeInfo({ daysRemainingTillExpiry, hasPlanExpired, user, tenant }) {
  if (daysRemainingTillExpiry <= START_EXPIRY_ALERT_DAY) {
    return (
      <Alert severity="error">
        {hasPlanExpired ? (
          <Typography variant="body1">
            Your Plan has <strong>expired</strong>. Click on <strong>Request Upgrade</strong> to
            upgrade your existing plan.
          </Typography>
        ) : (
          <Typography variant="body1">
            Your plan is about to expire in <strong>{daysRemainingTillExpiry} days!</strong> Click
            on <strong>Request Upgrade</strong> to upgrade your existing plan.
          </Typography>
        )}
      </Alert>
    );
  }

  return (
    <Alert severity="info">
      <Typography variant="body1">
        If you want to increase the quota reach out to us at{' '}
        <Link
          href={getMailToString({
            emailSubject: EMAIL_FORMATS.INQUIRY.subject,
            emailBody: EMAIL_FORMATS.INQUIRY.getBody(user, tenant),
          })}
        >
          <strong>{CONFIG.SUPPORT_EMAIL}</strong>
        </Link>
      </Typography>
    </Alert>
  );
}
