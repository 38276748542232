/**
 * @file
 *
 * Component that houses pagination controls
 */

import { Box, Typography } from '@material-ui/core';
import { IconButton, Stack } from '@mui/material';

import { useCallback } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { reportViewStateHandlers, useReportViewState } from 'state/reportView';

export default function PaginationControls({
  batchState,
  viewPicklistValues,
  triggerDataFetch,
  pageNumber,
  setPageNumber,
}) {
  const { fork, current } = useReportViewState.get();

  const { entity, skip } = fork;
  const { isCustomPagination } = current;

  const { noOfBatches } = batchState;

  const isLastPage = pageNumber === noOfBatches;
  const isFirstPage = pageNumber === 1;

  const handleNextPageClick = useCallback(() => {
    setPageNumber((pageNumber) => pageNumber + 1);

    reportViewStateHandlers.handleUpdateSkipValue(skip + 1000);
    reportViewStateHandlers.handleUpdateIsCustomPagination(false);

    triggerDataFetch(false, viewPicklistValues, true);
  }, [setPageNumber, skip, triggerDataFetch, viewPicklistValues]);

  const handlePrevPageClick = useCallback(() => {
    setPageNumber((pageNumber) => pageNumber - 1);

    reportViewStateHandlers.handleUpdateSkipValue(skip - 1000);
    reportViewStateHandlers.handleUpdateIsCustomPagination(false);

    triggerDataFetch(false, viewPicklistValues, true);
  }, [setPageNumber, skip, triggerDataFetch, viewPicklistValues]);

  const isPrevButtonDisabled = isCustomPagination || !entity?.value || isFirstPage;
  const isNextButtonDisabled = isCustomPagination || !entity?.value || isLastPage;

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box fontSize="0.7rem" color="#ababab" paddingRight={1}>
          Displaying 1,000 Records per Page
        </Box>

        <IconButton
          variant="outlined"
          size="small"
          onClick={handlePrevPageClick}
          disabled={isPrevButtonDisabled}
          color="primary"
          sx={{
            border: isPrevButtonDisabled ? '1px solid #E6E6E6' : '1px solid #6d87a1',
            borderRadius: 1,
          }}
        >
          <MdChevronLeft />
        </IconButton>

        <Typography variant="body2">
          {isCustomPagination ? '1 / 1' : ` ${pageNumber} / ${noOfBatches}`}
        </Typography>

        <IconButton
          variant="outlined"
          size="small"
          disabled={isNextButtonDisabled}
          onClick={handleNextPageClick}
          color="primary"
          sx={{
            border:
              isNextButtonDisabled || !entity.value || isLastPage
                ? '1px solid #E6E6E6'
                : '1px solid #6d87a1',
            borderRadius: 1,
          }}
        >
          <MdChevronRight />
        </IconButton>
      </Stack>
    </Stack>
  );
}
