/**
 * @file
 *
 * This file includes the all GoJS setup templates and configuration
 */

import * as go from 'gojs';
import { blueGrey, blue, cyan, teal, common } from '@material-ui/core/colors';
import { replace } from 'lodash-es';

import {
  useSchemaVisualizerState,
  toggleVisibilityOfProperty,
  updateSchemaVisualizerState,
  removeFromCanvas,
} from '../state/schemaVisualizer';
import { watermarkImage } from './watermarkImage';

const $ = go.GraphObject.make;

const COLORS = {
  ENTITY_TYPE_TITLE: blueGrey[700],
  ENTITY_TYPE_BACKGROUND: blueGrey[50],
  PRIMARY: teal[100],
  SECONDARY: blue[400],
  PRIMARY_TEXT: blueGrey[700],
  SECONDARY_TEXT: '#FFFFFF',
  PROPERTY: blueGrey[600],
  TYPE: blueGrey[300],
  ENTITY_TYPE_BORDER: blueGrey[100],
  ENTITY_RELATION: blueGrey[400],
  LABELS: blueGrey[700],
  COMPLEX_TYPE_TITLE: cyan[600],
  COMPLEX_TYPE_BACKGROUND: cyan[50],
  COMPLEX_TYPE_RELATION: cyan[400],
  COMPLEX_TYPE_BORDER: cyan[100],
  PROPERTY_TO_PROPERTY_RELATION: blueGrey[300],
  PROPERTY_EXPAND: blueGrey[100],
};

const DRAG_SELECT = {
  FILL: blueGrey[50],
  STROKE: blueGrey[900],
  STROKE_WIDTH: 2,
};

const bindings = {
  get formatDataType() {
    return new go.Binding('text', 'type', (type) => type.split('.').pop());
  },
  get location() {
    return new go.Binding('location', 'location', go.Point.parse).makeTwoWay(go.Point.stringify);
  },
};

export function initDiagram() {
  const diagram = $(go.Diagram, {
    LayoutCompleted: (event) => {
      const { nodeDataArray, diagramZoomScale } = useSchemaVisualizerState.get();

      if (diagramZoomScale !== null && nodeDataArray.length) {
        event.diagram.scale = diagramZoomScale;
      }
    },
    ViewportBoundsChanged: (event) => {
      const scale = event.subject.scale;

      updateSchemaVisualizerState((state) => {
        state.diagramZoomScale = scale;
      });
    },
    scrollMode: go.Diagram.InfiniteScroll,
    allowCopy: false,
    'toolManager.hoverDelay': 0,
    'undoManager.isEnabled': true,
    'animationManager.isEnabled': false,
    model: $(go.GraphLinksModel, {
      linkKeyProperty: 'key',
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
    }),
  });

  diagram.toolManager.dragSelectingTool.delay = 0;
  diagram.toolManager.dragSelectingTool.box = $(
    go.Part,
    { layerName: 'Tool' },
    $(go.Shape, 'Rectangle', {
      name: 'SHAPE',
      fill: DRAG_SELECT.FILL,
      stroke: DRAG_SELECT.STROKE,
      strokeWidth: DRAG_SELECT.STROKE_WIDTH,
      strokeDashArray: [4, 4],
      opacity: 0.3,
    })
  );

  diagram.commandHandler.doKeyDown = function () {
    const event = diagram.lastInput;

    const isDelete = event.key === 'Del' || event.key === 'Backspace';

    const selectedNodes = diagram.selection
      .toArray()
      .filter((node) => node.data.category === 'entityTypeNode');

    if (isDelete && selectedNodes.length) {
      const schemaVisualizerState = useSchemaVisualizerState.get();

      let nodeDataArray = [...schemaVisualizerState.nodeDataArray];
      const nodes = { ...schemaVisualizerState.keyMap.nodes };

      let propertyLinksToBeRemoved = new Set();
      const nodesToBeRemoved = [];
      const complexTypeLinkCounts = new Map();

      for (let selectedNode of selectedNodes) {
        const toBeRemoved = removeFromCanvas({
          diagram,
          entityName: selectedNode.data.key,
          linkDataArray: schemaVisualizerState.linkDataArray,
          propertyLinks: schemaVisualizerState.propertyLinks,
          nodeDataArray,
          nodes,
          complexTypeLinkCounts,
        });

        if (!toBeRemoved) {
          continue;
        }

        propertyLinksToBeRemoved = new Set([
          ...propertyLinksToBeRemoved,
          ...toBeRemoved.propertyLinksToBeRemoved,
        ]);

        nodesToBeRemoved.push(...toBeRemoved.nodesToBeRemoved);

        nodeDataArray = nodeDataArray.map((n) => {
          if (n && toBeRemoved.nodesToBeRemoved.has(n.key)) {
            delete nodes[n.key];

            return undefined;
          }

          return n;
        });
      }

      if (propertyLinksToBeRemoved.size) {
        updateSchemaVisualizerState((prevState) => {
          prevState.propertyLinks = prevState.propertyLinks.filter(
            ({ key }) => !propertyLinksToBeRemoved.has(key)
          );
        });
      }

      diagram.commit((d) => {
        d.removeParts(nodesToBeRemoved.map((key) => d.findNodeForKey(key)));
      }, 'remove nodes');
    } else {
      go.CommandHandler.prototype.doKeyDown.call(diagram.commandHandler);
    }
  };

  const fieldTemplate = $(
    go.Panel,
    'TableRow',
    new go.Binding('portId', 'name'),
    new go.Binding('background', 'isKey', (isKey) => (isKey ? COLORS.PRIMARY : null)),
    $(
      go.TextBlock,
      {
        margin: new go.Margin(6),
        column: 0,
        alignment: go.Spot.Left,
      },
      new go.Binding('text', 'name'),
      new go.Binding('stroke', 'isKey', (isKey) => (isKey ? COLORS.PRIMARY_TEXT : COLORS.PROPERTY)),
      new go.Binding('font', 'isKey', (isKey) =>
        isKey ? 'bold 13px sans-serif' : '13px sans-serif'
      )
    ),
    $(
      go.TextBlock,
      {
        margin: new go.Margin(6),
        column: 1,
        alignment: go.Spot.Right,
      },
      bindings.formatDataType,
      new go.Binding('stroke', 'isKey', (isKey) => (isKey ? COLORS.PRIMARY_TEXT : COLORS.TYPE)),
      new go.Binding('font', 'isKey', (isKey) =>
        isKey ? 'bold 13px sans-serif' : '13px sans-serif'
      )
    )
  );

  const navPropFieldTemplate = $(
    go.Panel,
    'TableRow',
    {
      cursor: 'pointer',
      doubleClick: function (event, node) {
        const port = node?.portId;
        const nodeKey = node?.panel?.part?.key;

        const { linkDataArray } = useSchemaVisualizerState.get();

        const link = linkDataArray.find(
          (link) =>
            (link.to === nodeKey && link.toPort === port) ||
            (link.from === nodeKey && link.fromPort === port)
        );

        if (link) {
          const jumpToNodeKey = link.to === nodeKey ? link.from : link.to;

          const diagram = event.diagram;
          const part = diagram.findNodeForKey(jumpToNodeKey);

          if (part) {
            diagram.select(part);
            diagram.commandHandler.resetZoom(1);
            const offsetPoint = new go.Point(diagram.viewSize.width / 2, 0);
            diagram.position = part
              .getDocumentPoint(go.Spot.TopCenter)
              .add(offsetPoint)
              .subtract(new go.Point(0, 25));
          }
        }
      },
    },
    new go.Binding('portId', 'name'),
    $(
      go.Panel,
      'Auto',
      {
        stretch: go.GraphObject.Horizontal,
      },
      $(go.Shape, 'RoundedRectangle', {
        margin: new go.Margin(1, 0),
        minSize: new go.Size(200, NaN),
        stroke: null,
        fill: COLORS.SECONDARY,
      }),
      $(
        go.Panel,
        'Table',
        {
          minSize: new go.Size(200, NaN),
          stretch: go.GraphObject.Horizontal,
        },
        $(
          go.TextBlock,
          {
            column: 0,
            margin: new go.Margin(6),
            alignment: go.Spot.Left,
            stroke: COLORS.SECONDARY_TEXT,
          },
          new go.Binding('text', 'name')
        ),
        $(
          'Button',
          {
            column: 1,
            cursor: 'pointer',
            'ButtonBorder.name': 'NavPropertyToggle',
            'NavPropertyToggle.figure': 'Circle',
            'NavPropertyToggle.fill': 'transparent',
            'NavPropertyToggle.stroke': 'transparent',
            _buttonFillOver: null,
            _buttonStrokeOver: null,
            _buttonFillPressed: null,
            _buttonStrokePressed: null,
            margin: new go.Margin(6),
            alignment: go.Spot.Right,
          },
          $(
            go.Shape,
            'PlusLine',
            new go.Binding('figure', 'isAssociatedEntityVisible', (value, graphObject) => {
              return value ? 'MinusLine' : 'PlusLine';
            }),
            {
              cursor: 'pointer',
              fill: 'transparent',
              strokeWidth: 2.5,
              stroke: blue[800],
              name: 'NavPropertyToggle',
              width: 15,
              height: 15,
              alignment: go.Spot.Center,
            }
          )
        )
      )
    )
  );

  const complexTypeFieldTemplate = $(
    go.Panel,
    'TableRow',
    new go.Binding('portId', 'name'),
    {
      background: 'transparent',
    },
    $(
      go.TextBlock,
      { margin: new go.Margin(6), column: 0, alignment: go.Spot.Left, stroke: COLORS.PROPERTY },
      new go.Binding('text', 'name')
    ),
    $(
      go.TextBlock,
      {
        margin: new go.Margin(6),
        column: 1,
        font: '13px sans-serif',
        alignment: go.Spot.Right,
        stroke: COLORS.TYPE,
      },
      bindings.formatDataType
    )
  );

  const complexTypeNodeTemplate = $(
    go.Node,
    'Auto',
    {
      visible: false,
    },
    bindings.location,
    new go.Binding('visible', 'complexTypeLinkCountMap', (complexTypeLinkCountMap, target) =>
      Boolean(complexTypeLinkCountMap[target.part.data.key])
    ).ofModel(),
    {
      deletable: false,
    },
    $(go.Shape, 'RoundedRectangle', {
      fill: COLORS.COMPLEX_TYPE_BACKGROUND,
      stroke: COLORS.COMPLEX_TYPE_BORDER,
      minSize: new go.Size(200, 40),
    }),
    $(
      go.Panel,
      'Vertical',
      $(
        go.Panel,
        'Auto',
        { stretch: go.GraphObject.Horizontal },
        $(go.Shape, 'RoundedRectangle', {
          fill: COLORS.COMPLEX_TYPE_TITLE,
          stroke: null,
          minSize: new go.Size(200, 40),
        }),
        $(
          go.TextBlock,
          {
            stroke: 'white',
            font: 'bold 11pt sans-serif',
            textAlign: 'center',
            margin: new go.Margin(10),
            maxSize: new go.Size(300, 40),
          },
          new go.Binding('text', 'name')
        )
      ),
      $(
        go.Panel,
        'Table',
        {
          minSize: new go.Size(200, NaN),
          stretch: go.GraphObject.Horizontal,
          itemTemplate: complexTypeFieldTemplate,
        },
        new go.Binding('itemArray', 'properties')
      )
    )
  );

  const closeButtonAdornment = $(
    go.Adornment,
    'Spot',
    {
      background: 'transparent',
      mouseLeave: (event, object) => {
        const node = object.part;
        node.adornedPart.removeAdornment('mouseHover');
      },
    },
    $(
      go.Panel,
      'Auto',
      // this Adornment has a rectangular blue border around the selected node
      $(go.Shape, { fill: null, stroke: null, strokeWidth: 0 }),
      $(go.Placeholder)
    ),
    // and this Adornment has a Button to the right of the selected node
    $(
      'Button',
      {
        alignment: go.Spot.TopRight,
        click: (e, obj) => {
          const node = obj.part.adornedPart;

          const {
            nodeDataArray,
            linkDataArray,
            propertyLinks,
            keyMap: { nodes },
          } = useSchemaVisualizerState.get();

          const toBeRemoved = removeFromCanvas({
            diagram: obj.diagram,
            entityName: node.data.key,
            nodeDataArray,
            nodes,
            linkDataArray,
            propertyLinks,
          });

          if (toBeRemoved) {
            if (toBeRemoved.propertyLinksToBeRemoved.size) {
              updateSchemaVisualizerState((prevState) => {
                prevState.propertyLinks = prevState.propertyLinks.filter(
                  ({ key }) => !toBeRemoved.propertyLinksToBeRemoved.has(key)
                );
              });
            }

            diagram.commit((d) => {
              d.removeParts([...toBeRemoved.nodesToBeRemoved].map((key) => d.findNodeForKey(key)));
            }, 'remove nodes');
          }
        },
        cursor: 'pointer',
        'ButtonBorder.fill': 'white',
        'ButtonBorder.stroke': '#354a5f',
        _buttonFillOver: 'white',
        _buttonStrokeOver: '#354a5f',
        _buttonFillPressed: null,
        _buttonStrokePressed: null,
      },
      $(go.Shape, 'XLine', {
        fill: 'transparent',
        strokeWidth: 3,
        stroke: '#354a5f',
        width: 12,
        height: 12,
      })
    )
  );

  const togglePropertiesPanelExpand = (event, obj) => {
    const node = obj.part;
    const diagram = obj.diagram;

    // check what is the next expanded/collapsed state
    const newState = !node.data.propertiesVisible;

    diagram.commit(({ model }) => {
      const complexTypeLinkCountMap = { ...model.modelData.complexTypeLinkCountMap };

      toggleVisibilityOfProperty({
        complexTypeLinkCountMap,
        properties: node.data.properties,
        isExpanded: newState,
        getProperties: (key) => diagram.findNodeForKey(key)?.data?.properties ?? [],
      });

      model.set(model.modelData, 'complexTypeLinkCountMap', complexTypeLinkCountMap);

      model.setDataProperty(node.data, 'propertiesVisible', newState);
    }, 'toggle expand/collapse of properties');
  };

  const entityTypeNodeTemplate = $(
    go.Node,
    'Auto',
    bindings.location,
    {
      deletable: true,
      mouseHover: (event, object) => {
        const node = object.part;
        closeButtonAdornment.adornedObject = node;
        node.addAdornment('mouseHover', closeButtonAdornment);
      },
    },
    $(go.Shape, 'RoundedRectangle', {
      fill: COLORS.ENTITY_TYPE_BACKGROUND,
      stroke: COLORS.ENTITY_TYPE_BORDER,
      minSize: new go.Size(200, 40),
    }),
    $(
      go.Panel,
      'Vertical',
      $(
        go.Panel,
        'Auto',
        { stretch: go.GraphObject.Horizontal },
        $(go.Shape, 'RoundedRectangle', {
          fill: COLORS.ENTITY_TYPE_TITLE,
          stroke: null,
          minSize: new go.Size(200, 40),
        }),
        $(
          go.TextBlock,
          {
            stroke: 'white',
            font: 'bold 11pt sans-serif',
            textAlign: 'center',
            margin: new go.Margin(10),
            maxSize: new go.Size(300, 40),
          },
          new go.Binding('text', 'name')
        )
      ),
      $(
        go.Panel,
        'Table',
        {
          minSize: new go.Size(200, NaN),
          stretch: go.GraphObject.Horizontal,
          itemTemplate: fieldTemplate,
          margin: new go.Margin(3, 0),
        },
        $(
          go.Panel,
          'Auto',
          {
            stretch: go.GraphObject.Horizontal,
          },
          $(go.Shape, 'RoundedRectangle', {
            fill: COLORS.PROPERTY_EXPAND,
            stroke: null,
            cursor: 'pointer',
            minSize: new go.Size(200, 10),
            click: togglePropertiesPanelExpand,
          }),
          $(go.TextBlock, 'Properties', {
            alignment: go.Spot.Left,
            column: 0,
            row: 0,
            cursor: 'pointer',
            margin: 3,
          }),
          $('PanelExpanderButton', 'PropertyFields', {
            row: 0,
            column: 1,
            alignment: go.Spot.Right,
            cursor: 'pointer',
            margin: 3,
            click: togglePropertiesPanelExpand,
          }),
          new go.Binding('visible', 'properties', (properties) => Boolean(properties.length))
        )
      ),
      $(
        go.Panel,
        'Table',
        {
          name: 'PropertyFields',
          minSize: new go.Size(200, NaN),
          stretch: go.GraphObject.Horizontal,
          itemTemplate: fieldTemplate,
          margin: new go.Margin(3, 0),
        },
        new go.Binding('itemArray', 'properties'),
        new go.Binding('visible', 'propertiesVisible').makeTwoWay()
      ),
      $(
        go.Panel,
        'Table',
        {
          minSize: new go.Size(200, NaN),
          stretch: go.GraphObject.Horizontal,
          itemTemplate: fieldTemplate,
          margin: new go.Margin(3, 0),
        },
        new go.Binding('itemArray', 'primaryKeys')
      ),
      $(
        go.Panel,
        'Table',
        {
          name: 'NavPropertiesContainer',
          stretch: go.GraphObject.Horizontal,
          minSize: new go.Size(200, NaN),
          padding: 3,
          itemTemplate: navPropFieldTemplate,
        },
        new go.Binding('itemArray', 'navProperties')
      )
    )
  );

  const watermarkTemplate = $(
    go.Node,
    'Auto',
    bindings.location,
    $(
      go.Panel,
      'Table',
      $(go.TextBlock, {
        editable: false,
        text: 'Powered by',
        font: 'bold 12px Roboto, sans-serif',
        row: 0,
        column: 0,
      }),
      $(go.Picture, watermarkImage, {
        background: common.white,
        height: 35,
        width: 135,
        row: 1,
        column: 0,
      })
    )
  );

  const nodeTemplMap = new go.Map();

  nodeTemplMap.add('entityTypeNode', entityTypeNodeTemplate);
  nodeTemplMap.add('complexTypeNode', complexTypeNodeTemplate);
  nodeTemplMap.add('watermarkNode', watermarkTemplate);

  diagram.nodeTemplateMap = nodeTemplMap;

  function clearPropertytoPropertyRelations() {
    const tempLinks = diagram.findLinksByExample({ category: 'propertyToPropertyLink' });

    if (tempLinks) {
      const oldSkipSettings = diagram.skipsUndoManager;
      diagram.skipsUndoManager = true;

      const txnIdentifier = 'clear property to property links';
      diagram.startTransaction(txnIdentifier);

      diagram.commitTransaction(txnIdentifier);

      diagram.skipsUndoManager = oldSkipSettings;
      diagram.removeParts(tempLinks);
    }
  }

  function showPropertytoPropertyRelations(association) {
    const { propertyLinks } = useSchemaVisualizerState.get();

    const activeLinks = propertyLinks.filter((link) => link.name === association.name);

    if (activeLinks.length) {
      const oldSkipSettings = diagram.skipsUndoManager;
      diagram.skipsUndoManager = true;

      const txnIdentifier = 'display property to property links';
      diagram.startTransaction(txnIdentifier);

      diagram.model.addLinkDataCollection(activeLinks);

      const allPropertyLinks = diagram.findLinksByExample({
        category: 'propertyToPropertyLink',
        name: association.name,
      });

      allPropertyLinks.each((link) => {
        if (!link.toPort.data.isKey) {
          if (!link.toNode.data.propertiesVisible) {
            if (link.fromNode.actualBounds.centerX > link.toNode.actualBounds.centerX) {
              link.fromSpot = new go.Spot(0, 0.5, 0, 0);
              link.toSpot = new go.Spot(1, 0, 0, 65);
            } else {
              link.fromSpot = new go.Spot(1, 0.5, 0, 0);
              link.toSpot = new go.Spot(0, 0, 0, 65);
            }
          }
        }
      });

      diagram.commitTransaction(txnIdentifier);

      diagram.skipsUndoManager = oldSkipSettings;
    }
  }

  const entityTypeLinkTemplate = $(
    go.Link,
    {
      reshapable: true,
      routing: go.Link.AvoidsNodes,
      fromSpot: go.Spot.LeftRightSides,
      toSpot: go.Spot.LeftRightSides,
      corner: 5,
      curve: go.Link.JumpGap,
      deletable: false,
      layerName: 'Foreground',
    },
    {
      mouseEnter: (e, obj) => {
        obj.isHighlighted = true;
        showPropertytoPropertyRelations(obj.data);
      },
      mouseLeave: (e, node) => {
        node.isHighlighted = false;
        clearPropertytoPropertyRelations();
      },
    },
    $(go.Shape, { isPanelMain: true, stroke: 'transparent', strokeWidth: 10 }),
    $(go.Shape, { isPanelMain: true, stroke: COLORS.ENTITY_RELATION }),
    $(go.Shape, {
      fromArrow: 'Circle',
      stroke: COLORS.ENTITY_RELATION,
      fill: COLORS.ENTITY_RELATION,
    }),
    $(go.Shape, {
      toArrow: 'Standard',
      stroke: COLORS.ENTITY_RELATION,
      fill: COLORS.ENTITY_RELATION,
    }),

    $(
      go.TextBlock,
      new go.Binding('text', 'fromMultiplicity', (fromMultiplicity) =>
        replace(fromMultiplicity, '*', 'N')
      ),
      new go.Binding('visible', 'isHighlighted').ofObject(),
      {
        segmentIndex: 1,
        segmentOffset: new go.Point(NaN, NaN),
        segmentOrientation: go.Link.OrientUpright,
        font: 'bold 10pt sans-serif',
        stroke: COLORS.LABELS,
      }
    ),
    $(
      go.TextBlock,
      new go.Binding('text', 'name', (name) => name.split('.').pop()),
      new go.Binding('visible', 'isHighlighted').ofObject(),
      {
        segmentOffset: new go.Point(4, NaN),
        segmentOrientation: go.Link.OrientUpright45,
        font: 'bold 10pt sans-serif',
        stroke: COLORS.LABELS,
      }
    ),
    $(
      go.TextBlock,
      new go.Binding('text', 'toMultiplicity', (toMultiplicity) =>
        replace(toMultiplicity, '*', 'N')
      ),
      new go.Binding('visible', 'isHighlighted').ofObject(),
      {
        segmentIndex: -2,
        segmentOffset: new go.Point(NaN, NaN),
        segmentOrientation: go.Link.OrientUpright,
        font: 'bold 10pt sans-serif',
        stroke: COLORS.LABELS,
      }
    )
  );

  const complexTypeLinkTemplate = $(
    go.Link,
    {
      reshapable: true,
      routing: go.Link.AvoidsNodes,
      fromSpot: go.Spot.LeftRightSides,
      toSpot: go.Spot.LeftRightSides,
      corner: 5,
      curve: go.Link.JumpGap,
      deletable: false,
    },
    $(go.Shape, { stroke: COLORS.COMPLEX_TYPE_RELATION, strokeDashArray: '2 3' }),
    $(go.Shape, {
      fromArrow: 'Circle',
      stroke: COLORS.COMPLEX_TYPE_RELATION,
      fill: COLORS.COMPLEX_TYPE_RELATION,
    }),
    $(go.Shape, {
      toArrow: 'Standard',
      stroke: COLORS.COMPLEX_TYPE_RELATION,
      fill: COLORS.COMPLEX_TYPE_RELATION,
    })
  );

  const propertyToPropertyLinkTemplate = $(
    go.Link,
    {
      fromSpot: go.Spot.LeftRightSides,
      toSpot: go.Spot.LeftRightSides,
      corner: 5,
      curve: go.Link.JumpGap,
      deletable: false,
      selectable: false,
    },
    $(go.Shape, { stroke: COLORS.PROPERTY_TO_PROPERTY_RELATION, strokeDashArray: '1 2' }),
    $(go.Shape, {
      fromArrow: 'Circle',
      stroke: COLORS.PROPERTY_TO_PROPERTY_RELATION,
      fill: COLORS.PROPERTY_TO_PROPERTY_RELATION,
    }),
    $(go.Shape, {
      toArrow: 'Standard',
      stroke: COLORS.PROPERTY_TO_PROPERTY_RELATION,
      fill: COLORS.PROPERTY_TO_PROPERTY_RELATION,
    })
  );

  const linkTemplMap = new go.Map();

  linkTemplMap.add('entityTypeLink', entityTypeLinkTemplate);
  linkTemplMap.add('complexTypeLink', complexTypeLinkTemplate);
  linkTemplMap.add('propertyToPropertyLink', propertyToPropertyLinkTemplate);

  diagram.linkTemplateMap = linkTemplMap;

  return diagram;
}

export const initOverview = () => {
  const overview = $(go.Overview, {
    box: $(
      go.Node,
      'Auto',
      $(go.Shape, 'Rectangle', {
        stroke: teal[900],
        fill: teal[100],
        opacity: 0.4,
      })
    ),
  });

  return overview;
};
