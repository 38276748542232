/**
 * @file
 *
 * this file contains the component and hooks that helps implement virtualization in the autocomplete fields
 */
import React, {
  cloneElement,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  Children,
} from 'react';
import { VariableSizeList } from 'react-window';

const VIRTUAL_LIST_VALUES = {
  LISTBOX_PADDING: 8,
  EXTRA_HEIGHT: 2,
  DEFAULT_ITEM_SIZE: 48,
  MIN_ITEM_SIZE: 36,
  MIN_ITEM_COUNT: 8,
};

function renderChild({ data, index, style }) {
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + VIRTUAL_LIST_VALUES.LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

const InnerElementType = forwardRef(({ style, ...props }, ref) => {
  return <ul ref={ref} {...props} style={{ ...style, margin: 0 }} />;
});

const useVirtualizedList = (children) => {
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;

  const getItemSize = useCallback((child) => {
    const childName = child?.props?.children?.props?.name;
    const childLabel = child?.props?.children?.props?.label;

    if (childName === childLabel) {
      return VIRTUAL_LIST_VALUES.MIN_ITEM_SIZE;
    }

    return VIRTUAL_LIST_VALUES.DEFAULT_ITEM_SIZE;
  }, []);

  const getListHeight = useCallback(() => {
    if (itemCount > VIRTUAL_LIST_VALUES.MIN_ITEM_COUNT) {
      return VIRTUAL_LIST_VALUES.MIN_ITEM_COUNT * VIRTUAL_LIST_VALUES.DEFAULT_ITEM_SIZE;
    }

    return itemData.map(getItemSize).reduce((a, b) => a + b, 0);
  }, [getItemSize, itemCount, itemData]);

  return {
    itemData,
    itemCount,
    getItemSize,
    getListHeight,
  };
};

const useResetVariableSizeListCache = (itemCount) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [itemCount]);
  return ref;
};

export const AutocompleteVirtualizedListbox = forwardRef(function AutocompleteVirtualizedListbox(
  props,
  ref
) {
  const { children, ...other } = props;

  const { itemData, itemCount, getItemSize, getListHeight } = useVirtualizedList(children);
  const gridRef = useResetVariableSizeListCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={
            getListHeight() + VIRTUAL_LIST_VALUES.EXTRA_HEIGHT * VIRTUAL_LIST_VALUES.LISTBOX_PADDING
          }
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType={InnerElementType}
          itemSize={(index) => getItemSize(itemData[index])}
          itemCount={itemCount}
        >
          {renderChild}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
