import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Paper, useTheme, withStyles } from '@material-ui/core';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import { useRafLoop } from 'react-use';

import { useTourState, tourSteps, getTourStepQuerySelector, TourStepContext } from './Tour';

const variants = {
  pulse: {
    scale: [1.5, 1, 1.2, 1, 1.5],
    transition: {
      duration: 2,
      ease: 'easeInOut',
      repeat: Infinity,
    },
  },
  idle: {
    scale: 1.5,
  },
};

const StepTitle = withStyles((theme) => ({
  anchorOriginTopRightRectangular: {
    transform: 'translate(-10%, -50%)',
  },
  root: {
    display: 'flex',
  },
}))(Badge);

const stateSliceSelector = (state) => {
  if (state.isActive) {
    return {
      step: tourSteps[state.currentStepId] ?? null,
      createFlowType: state.createFlowType,
      systemType: state.systemType,
      currentStepId: state.currentStepId,
    };
  }

  return {
    step: null,
    createFlowType: null,
    systemType: null,
  };
};

const invokeIfFunction = (arg, ...args) => (typeof arg === 'function' ? arg(...args) : arg);

export function HighlightDot() {
  const theme = useTheme();
  const { step, currentStepId, ...tourMetadata } = useTourState(stateSliceSelector);

  const [isOpen, setIsOpen] = useState(true);
  const handleDotClick = useCallback((event) => {
    setIsOpen((prevState) => !prevState);
  }, []);
  const handleClose = useCallback((event) => {
    setIsOpen(false);
  }, []);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnterOnDot = useCallback(() => {
    setIsHovering(true);
  }, []);
  const handleMouseLeaveOnDot = useCallback(() => {
    setIsHovering(false);
  }, []);

  const [inView, updateInViewState] = useState(false);

  const rectX = useMotionValue(0);
  const rectY = useMotionValue(0);
  const popoverX = useMotionValue(0);
  const popoverY = useMotionValue(0);

  const rx = useSpring(rectX);
  const ry = useSpring(rectY);
  const px = useSpring(popoverX);
  const py = useSpring(popoverY);

  const [stopLoop, startLoop, checkIfActive] = useRafLoop(() => {
    if (step) {
      const element = document.querySelector(getTourStepQuerySelector(step.id));

      if (!element) {
        updateInViewState(false);
        return;
      }

      updateInViewState(true);

      const rect = element.getBoundingClientRect();

      const { x, y } = step.getDotCoords ? step.getDotCoords(rect) : rect;

      if (rectX.get() !== x) {
        rectX.set(x);
      }
      if (rectY.get() !== y) {
        rectY.set(y);
      }

      const anchorConfig = { horizontal: 'left', vertical: 'top', ...step.popoverAnchor };
      const padding = theme.spacing(1);
      const newCoords = { x: x + padding, y: y + padding };
      if (anchorConfig.horizontal === 'right') {
        newCoords.x = x - theme.spacing(40) + padding;
      }
      // if (anchorConfig.vertical === 'bottom') {
      //   const popover = document.querySelector('#tour-step-popover');
      //   if (popover) {
      //     const { height: popoverHeight } = popover.getBoundingClientRect();
      //     newCoords.y = y - popoverHeight + padding;
      //   }
      // }

      if (popoverX.get() !== newCoords.x) {
        popoverX.set(newCoords.x);
      }
      if (popoverY.get() !== newCoords.y) {
        popoverY.set(newCoords.y);
      }
    }
  }, false);
  useEffect(() => {
    const isActive = checkIfActive();
    if (!step) {
      if (isActive) {
        stopLoop();
      }
    } else {
      if (!isActive) {
        startLoop();
      }
    }
  }, [checkIfActive, startLoop, step, stopLoop]);

  if (!step || !inView) {
    return null;
  }

  return (
    <>
      <motion.div
        layout
        initial={false}
        style={{
          ...step.style,
          x: rx,
          y: ry,
          left: 0,
          top: 0,
          position: 'absolute',
          cursor: 'pointer',
          ...(!inView && { pointerEvents: 'none', opacity: 0 }),
        }}
        onClick={handleDotClick}
        onMouseEnter={handleMouseEnterOnDot}
        onMouseLeave={handleMouseLeaveOnDot}
      >
        <motion.div
          style={{
            width: theme.spacing(2),
            height: theme.spacing(2),
            backgroundColor: theme.palette.integrtr.green,
            borderRadius: '50%',
          }}
          variants={variants}
          animate={isHovering || isOpen ? 'idle' : 'pulse'}
        />
      </motion.div>

      {isOpen && (
        <motion.div
          layout="position"
          style={{
            ...step.style,
            position: 'absolute',
            left: 0,
            top: 0,
            x: px,
            y: py,
          }}
          id="tour-step-popover"
        >
          <Paper elevation={10} style={{ width: theme.spacing(40), overflow: 'initial' }}>
            <StepTitle
              color="primary"
              badgeContent={invokeIfFunction(step.title, tourMetadata)}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <TourStepContext.Provider value={{ closeTooltip: handleClose, currentStepId }}>
                {invokeIfFunction(step.content, tourMetadata)}
              </TourStepContext.Provider>
            </StepTitle>
          </Paper>
        </motion.div>
      )}
    </>
  );
}
