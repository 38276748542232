/**
 * @file
 *
 * this file contains the component, state and custom hook to handle the bulk export
 */
import React, { forwardRef, useCallback, useMemo, useEffect, useState } from 'react';
import { createStore } from '@halka/state';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { clamp, noop } from 'lodash-es';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { Alert } from '@material-ui/lab';
import { AnimatePresence, motion } from 'framer-motion';

import { useTenantState } from '../data/user';
import { createNewUrlInstance, exportAllData } from '../exporter/main';
import { wrapErrorMessageWithFallback } from '../hooks/useNotifyError';
import { createQuery, translateVariablesToDateTimeString } from '../odata/queryBuilder';
import { formQueryURL } from '../odata/utils';
import { dataflowApiBase, getServiceInstance } from '../service';
import { pickQueryParamsFromQueryBuilderState, useQueryBuilderState } from '../state/queryBuilder';
import { CustomSlide } from './Animations/CustomSlide';
import { DownloadProgress } from './Animations/DownloadProgress';
import { Loading } from './Illustrations/Loading';
import { FileExportStateAnimation, VARIANTS } from './Animations/FileExportStateAnimation';

export const BULK_EXPORT_STAGES = {
  FETCHING_RECORDS: 'FETCHING_RECORDS',
  CONFIRMATION: 'CONFIRMATION',
  INITIALIZATION: 'INITIALIZATION',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  ERROR: 'ERROR',
};

const FAILURE_REASON = {
  USER_ABORTED: 'USER_ABORTED',
  UNKNOWN: 'UNKNOWN',
};

const initialState = {
  exportStage: null,
  recordsCount: null,
  isActive: null,
  isMinimized: null,
  totalBatches: null,
  activeBatch: null,
  errorDetails: null,
  failureReason: null,
  // TODO: this is bad practice, refactor later
  abortController: null,
};

export const useBulkExportState = createStore(initialState);

export const calculateDownloadProgress = (active, total, exportStage) =>
  exportStage === BULK_EXPORT_STAGES.SUCCESS
    ? 100
    : clamp(Math.floor((active / total) * 100), 0, 100);

export const bulkExportStateActions = {
  setFetchingRecords: () => {
    useBulkExportState.set((state) => ({
      ...state,
      exportStage: BULK_EXPORT_STAGES.FETCHING_RECORDS,
    }));
  },
  openDialog: (count) => {
    useBulkExportState.set((state) => ({
      ...state,
      exportStage: BULK_EXPORT_STAGES.CONFIRMATION,
      recordsCount: count,
      isActive: true,
      isMinimized: false,
    }));
  },
  reset: () => {
    useBulkExportState.set(initialState);
  },
  minimizeDialog: () => {
    useBulkExportState.set((state) => ({ ...state, isMinimized: true }));
  },
  maximizeDialog: () => {
    useBulkExportState.set((state) => ({ ...state, isMinimized: false }));
  },

  initiateDownloading: () => {
    useBulkExportState.set((state) => ({
      ...state,
      exportStage: BULK_EXPORT_STAGES.INITIALIZATION,
    }));
  },
  downloadStarted: (totalBatches) => {
    useBulkExportState.set((state) =>
      state.exportStage === BULK_EXPORT_STAGES.INITIALIZATION
        ? {
            ...state,
            totalBatches,
            exportStage: BULK_EXPORT_STAGES.IN_PROGRESS,
          }
        : state
    );
  },
  attachAbortController: (abortController) => {
    useBulkExportState.set((state) => ({
      ...state,
      abortController,
    }));
  },
  updateDownloadProgress: (activeBatch) => {
    useBulkExportState.set((state) => ({
      ...state,
      activeBatch,
    }));
  },
  downloadComplete: (flag, didUserAbort) => {
    useBulkExportState.set((state) =>
      state.isMinimized
        ? initialState
        : {
            ...state,

            exportStage: flag ? BULK_EXPORT_STAGES.SUCCESS : BULK_EXPORT_STAGES.FAILURE,
            ...(!flag && {
              failureReason: didUserAbort ? FAILURE_REASON.USER_ABORTED : FAILURE_REASON.UNKNOWN,
            }),
          }
    );
  },
  abortDownload: async () => {
    const { abortController } = useBulkExportState.get();

    if (abortController) {
      await abortController().catch(noop);
      useBulkExportState.set((state) => ({
        ...state,
        exportStage: BULK_EXPORT_STAGES.FAILURE,
        failureReason: FAILURE_REASON.USER_ABORTED,
      }));
    }
  },
  showError: (error) => {
    useBulkExportState.set({
      ...initialState,
      isActive: true,
      isMinimized: true,
      exportStage: BULK_EXPORT_STAGES.ERROR,
      failureReason: FAILURE_REASON.UNKNOWN,
      errorDetails: error?.errorDetails,
    });
  },
};

export const usePreventNavigationDuringExport = () => {
  useEffect(() => {
    // always reset bulk export state on loading a new connection page
    bulkExportStateActions.reset();

    const handleBeforeUnload = (event) => {
      const { exportStage } = useBulkExportState.get();

      const preventUnload =
        exportStage &&
        exportStage !== (BULK_EXPORT_STAGES.SUCCESS || exportStage !== BULK_EXPORT_STAGES.FAILURE);

      if (preventUnload) {
        event.preventDefault();
        event.returnValue = '';
      } else {
        delete event.returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export const useTriggerExport = ({ baseEndpoint, baseParams, systemType, schema }) => {
  const tenant = useTenantState();
  const params = useParams();

  const fetchCountAndOpenDialog = useCallback(() => {
    const queryBuilderState = useQueryBuilderState.get();

    const queryString = createQuery({
      schema,
      systemType,
      ...pickQueryParamsFromQueryBuilderState(queryBuilderState),
    });

    const translatedQueryString = translateVariablesToDateTimeString(queryString);

    const queryUrl = formQueryURL(baseEndpoint, baseParams, translatedQueryString);

    const $countUrl = createNewUrlInstance(queryUrl);
    $countUrl.pathname = $countUrl.pathname.concat('/$count');

    bulkExportStateActions.setFetchingRecords();
    setTimeout(async () => {
      await getServiceInstance(tenant?.tenant_id)
        .post(`${dataflowApiBase}/connection/${params.connectionId}/proxy`, {
          skipLog: true,
          url: $countUrl.toString(),
          type: 'xml',
        })
        .then((response) => {
          const count = Number(response);
          bulkExportStateActions.openDialog(count);
        })
        // discuss about if fetch fails what happens
        .catch((error) => {
          bulkExportStateActions.showError(error);
          toast.error(
            wrapErrorMessageWithFallback(error, 'Error: Failed to initiate bulk export!')
          );
        });
    });
  }, [baseEndpoint, baseParams, schema, systemType, tenant, params.connectionId]);

  const triggerExport = useCallback(async () => {
    const queryBuilderState = useQueryBuilderState.get();

    const flattenedEntity = queryBuilderState.entity.value;

    const queryString = createQuery({
      schema,
      systemType,
      ...pickQueryParamsFromQueryBuilderState(queryBuilderState),
    });

    const translatedQueryString = translateVariablesToDateTimeString(queryString);

    const queryUrl = formQueryURL(baseEndpoint, baseParams, translatedQueryString);

    await exportAllData({
      queryUrl,
      connectionId: params.connectionId,
      tenantId: tenant?.tenant_id,
      flattenedEntity,
    });
  }, [baseEndpoint, baseParams, schema, systemType, tenant, params.connectionId]);

  return {
    triggerExport,
    fetchCountAndOpenDialog,
  };
};

const useModalStyles = makeStyles((theme) => ({
  abortBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  dialogBox: {
    height: theme.spacing(19),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  dialogBoxAlignNormal: {
    alignItems: 'flex-start',
  },
  errorText: {
    fontSize: theme.spacing(2),
    position: 'relative',
    bottom: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    color: theme.palette.grey[500],
    textAlign: 'center',
  },
  errorDetailsContainer: {
    borderLeft: '8px solid rgb(243 86 77)',
    padding: theme.spacing(2),
    backgroundColor: 'rgb(255 235 236)',
    color: 'rgb(255 83 87)',
    height: theme.spacing(17.5),
    width: theme.spacing(67.5),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(13),
  },
  errorDetails: {
    width: theme.spacing(50),
    height: theme.spacing(13.75),
    wordBreak: 'break-all',
    overflowY: 'auto',
  },
  dialogPaper: {
    overflow: 'hidden',
  },
}));

const DiagonalTransition = forwardRef(function DiagonalTransition(props, ref) {
  return <CustomSlide direction="down-left" ref={ref} {...props} />;
});

const dialogTitleId = 'bulk-export-dialog-title';
const dialogDescriptionId = 'bulk-export-dialog-description';
export function BulkExportDialog({ selectedEntity, triggerExport }) {
  const modalClasses = useModalStyles();
  const theme = useTheme();

  const [isErrorDetailsExpanded, updateIsErrorDetailsExpanded] = useState(false);

  const {
    exportStage,
    recordsCount,
    isActive,
    isMinimized,
    totalBatches,
    activeBatch,
    failureReason,
    errorDetails,
  } = useBulkExportState();

  const { expand } = useQueryBuilderState();

  const handleExport = useCallback(() => {
    bulkExportStateActions.initiateDownloading();
    triggerExport();
  }, [triggerExport]);

  const selectedEntityLabel = selectedEntity?.label ?? '';

  const confirmationStage = useMemo(
    () => (
      <>
        <DialogTitle>Bulk Export of Records</DialogTitle>
        <DialogContent className={clsx(modalClasses.dialogBox, modalClasses.dialogBoxAlignNormal)}>
          <DialogContentText id={dialogDescriptionId}>
            {recordsCount > 0 ? (
              <>
                You are about to download a .CSV file which contains <strong>{recordsCount}</strong>{' '}
                number of <strong>{selectedEntityLabel}</strong> entity records. It may take a while
                to complete. Are you sure you want to proceed?
              </>
            ) : (
              <>
                No records available for <strong>{selectedEntityLabel}</strong> entity!
              </>
            )}
            {expand?.value?.length > 0 && recordsCount > 0 && (
              <Alert severity="warning" style={{ marginTop: theme.spacing(2) }}>
                The <strong>expanded properties</strong> will not be present in the exported files
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={bulkExportStateActions.reset}
            variant="outlined"
            color="primary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleExport}
            disabled={recordsCount < 1}
            variant="contained"
            color="primary"
            disableElevation
            size="small"
          >
            Start Export
          </Button>
        </DialogActions>
      </>
    ),
    [modalClasses, handleExport, selectedEntityLabel, recordsCount, expand, theme]
  );

  const initializationStage = useMemo(
    () => (
      <>
        <DialogTitle>Download Initializing</DialogTitle>
        <DialogContent className={modalClasses.dialogBox}>
          <Loading />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={bulkExportStateActions.minimizeDialog}
            variant="outlined"
            color="primary"
            size="small"
          >
            Minimize
          </Button>
          <Button
            onClick={bulkExportStateActions.abortDownload}
            variant="contained"
            className={modalClasses.abortBtn}
            disableElevation
            size="small"
          >
            Abort
          </Button>
        </DialogActions>
      </>
    ),
    [modalClasses]
  );

  const inProgressStage = useMemo(
    () => (
      <>
        <DialogTitle>
          Download in Progress {calculateDownloadProgress(activeBatch, totalBatches, exportStage)}%
        </DialogTitle>
        <DialogContent className={modalClasses.dialogBox}>
          <DownloadProgress
            totalNoOfBatches={totalBatches}
            activeBatch={activeBatch}
            recordsCount={recordsCount}
            selectedEntityLabel={selectedEntityLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={bulkExportStateActions.minimizeDialog}
            variant="outlined"
            color="primary"
            size="small"
          >
            Minimize
          </Button>
          <Button
            onClick={bulkExportStateActions.abortDownload}
            variant="contained"
            className={modalClasses.abortBtn}
            disableElevation
            size="small"
          >
            Abort
          </Button>
        </DialogActions>
      </>
    ),
    [activeBatch, totalBatches, exportStage, modalClasses, recordsCount, selectedEntityLabel]
  );

  const successStage = useMemo(
    () => (
      <>
        <DialogTitle>Download Successfully Completed</DialogTitle>
        <DialogContent className={modalClasses.dialogBox}>
          <Grid item container direction="column" alignItems="center">
            <FileExportStateAnimation variant={VARIANTS.SUCCESS} />
            <Typography className={modalClasses.errorText}>
              Successfully exported <strong>{recordsCount}</strong> records of{' '}
              <strong>{selectedEntityLabel}</strong>!
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={bulkExportStateActions.reset}
            variant="outlined"
            color="primary"
            size="small"
          >
            Done
          </Button>
        </DialogActions>
      </>
    ),
    [modalClasses, selectedEntityLabel, recordsCount]
  );

  const failureStage = useMemo(
    () => (
      <>
        <DialogTitle>Download Failed</DialogTitle>
        <DialogContent className={modalClasses.dialogBox}>
          <Grid item container direction="row" alignItems="center" justifyContent="center">
            <motion.div
              style={{
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
              {...(exportStage === BULK_EXPORT_STAGES.ERROR && {
                animate: {
                  right: isErrorDetailsExpanded ? [0, 210] : [210, 0],
                },
              })}
            >
              <FileExportStateAnimation variant={VARIANTS.FAILURE} />
              {exportStage === BULK_EXPORT_STAGES.FAILURE && (
                <Typography className={modalClasses.errorText}>
                  {failureReason === FAILURE_REASON.UNKNOWN
                    ? 'Something went wrong!'
                    : 'The export was aborted!'}
                </Typography>
              )}
              {exportStage === BULK_EXPORT_STAGES.ERROR && (
                <AnimatePresence>
                  {!isErrorDetailsExpanded && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: [0, 0, 10] }}
                      exit={{ opacity: 0 }}
                    >
                      <Typography className={modalClasses.errorText}>
                        Something went wrong!
                      </Typography>
                    </motion.div>
                  )}
                </AnimatePresence>
              )}
            </motion.div>
            <AnimatePresence>
              {exportStage === BULK_EXPORT_STAGES.ERROR && isErrorDetailsExpanded && (
                <motion.div
                  style={{ position: 'absolute' }}
                  initial={{ opacity: 0 }}
                  animate={{ left: [600, 30], opacity: 1 }}
                  exit={{ left: [30, 600] }}
                >
                  <Grid item container className={modalClasses.errorDetailsContainer}>
                    <Typography variant="body2" className={modalClasses.errorDetails}>
                      {errorDetails}
                    </Typography>
                  </Grid>
                </motion.div>
              )}
            </AnimatePresence>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container justifyContent={errorDetails ? 'space-between' : 'flex-end'}>
            {errorDetails && (
              <Button
                onClick={() => updateIsErrorDetailsExpanded((state) => !state)}
                className={modalClasses.errorExpandButton}
                size="small"
              >
                {isErrorDetailsExpanded ? 'Show less' : 'Show more'}
              </Button>
            )}
            <Button
              onClick={() => {
                bulkExportStateActions.reset();
                updateIsErrorDetailsExpanded(false);
              }}
              variant="outlined"
              color="primary"
              size="small"
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </>
    ),
    [modalClasses, failureReason, isErrorDetailsExpanded, errorDetails, exportStage]
  );

  const dummyStage = useMemo(
    () => (
      <>
        <DialogTitle>Cancelling Export...</DialogTitle>
        <DialogContent className={modalClasses.dialogBox}>
          <div style={{ height: 200, width: 200 }} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            disabled
            style={{ visibility: 'hidden' }}
            size="small"
          >
            Minimize
          </Button>
          <Button
            variant="contained"
            className={modalClasses.abortBtn}
            disableElevation
            disabled
            style={{ visibility: 'hidden' }}
            size="small"
          >
            Abort
          </Button>
        </DialogActions>
      </>
    ),
    [modalClasses]
  );

  const isDialogOpen = Boolean(isActive && !isMinimized);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isDialogOpen}
      TransitionComponent={DiagonalTransition}
      TransitionProps={{ timeout: 500 }}
      keepMounted
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
      classes={{ paper: modalClasses.dialogPaper }}
    >
      {!exportStage && dummyStage}
      {exportStage && (
        <>
          {exportStage === BULK_EXPORT_STAGES.CONFIRMATION && confirmationStage}
          {exportStage === BULK_EXPORT_STAGES.INITIALIZATION && initializationStage}
          {exportStage === BULK_EXPORT_STAGES.IN_PROGRESS && inProgressStage}
          {exportStage === BULK_EXPORT_STAGES.SUCCESS && successStage}
          {(exportStage === BULK_EXPORT_STAGES.FAILURE ||
            exportStage === BULK_EXPORT_STAGES.ERROR) &&
            failureStage}
        </>
      )}
    </Dialog>
  );
}
