/**
 * @file
 *
 * this file contains renders a custom time picker component
 */
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { TextField as Mui5TextField } from '@mui/material';
import { TimePicker as MuiTimePicker } from '@material-ui/pickers';
import { customFormats } from 'date';
import { MdEvent } from 'react-icons/md';

import { VIEWS } from './DateTimePickerToolbar';
import { HelperTextAdornment, MUI5HelperTextAdornment } from './HelperTextAdornment';
import { useDateAndTimePicker } from './DatePicker';

export function TimePicker({
  label,
  clearable,
  value,
  updateRuleParam,
  disabled,
  resetCounter,
  placeholder,
  helperText,
  isMUI5 = false,
}) {
  const { dialog, textField, button } = useDateAndTimePicker({
    dateFormat: customFormats.time,
    clearable,
    onChange: updateRuleParam,
    value,
    disabled,
    resetCounter,
  });

  return (
    <>
      {isMUI5 ? (
        <Mui5TextField
          size="small"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={placeholder}
          margin="dense"
          fullWidth
          label={label}
          {...textField}
          disabled={disabled}
          InputProps={{
            startAdornment: <MUI5HelperTextAdornment error={textField.error} text={helperText} />,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" {...button} disabled={disabled} onClick={dialog.onOpen}>
                  <MdEvent fontSize="23px" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={placeholder}
          margin="dense"
          fullWidth
          label={label}
          {...textField}
          disabled={disabled}
          InputProps={{
            startAdornment: <HelperTextAdornment error={textField.error} text={helperText} />,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" {...button} disabled={disabled} onClick={dialog.onOpen}>
                  <MdEvent fontSize="23px" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <MuiTimePicker
        {...dialog}
        autoOk
        ampm={false}
        TextFieldComponent={() => <TextField style={{ display: 'none' }} />}
        views={[VIEWS.HOURS, VIEWS.MINUTES, VIEWS.SECONDS]}
        clearable={clearable}
      />
    </>
  );
}
