import {
  CircularProgress,
  Stack,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Alert,
  Link,
} from '@mui/material';
import React, { forwardRef, useCallback, useState } from 'react';
import Lottie from 'react-lottie-player';
import { pick, startsWith, truncate, values } from 'lodash-es';
import { MdCheck, MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { useConnection } from 'data/connectionsAndSystems';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { EmptyImage } from 'components/Illustrations/Empty';
import { ErrorImage } from 'components/Illustrations/Error';
import loadingAnimationData from 'lotties/loader.json';
import TableView from './TableView';
import TableIllustration from 'components/Illustrations/TableIllustration';
import { COE_DOCUMENTATION, ERROR_TYPES, FETCH_STATE, QUERY_STATE } from '../constants';
import { useReportViewState } from 'state/reportView';
import { useLoggedInUser, useTenantState } from 'data/user';
import { useCreateRunMutation } from '../../../data/dataflowEdit';
import { dataflowApiBase, getServiceInstance } from 'service';
import { useDisclosure } from 'hooks/useDisclosure';
import DataEditProgressDialog from './DataEditProgressDialog';
import { CONNECTION_TYPES } from '../../../constants';
import { cancelAllPendingBatchedRequests } from 'reportView/main';
import { FETCH_CANCELLATION } from '../constants';
import { extractErrorDetails } from 'utils';
import PaginationControls from './PaginationControls';

const TableArea = forwardRef(
  (
    {
      viewPicklistValues,
      fetchPaginatedDataFromDatabase,
      batchState,
      queryData,
      setDisableToolbar,
      baseEndpoint,
      setQueryData,
      pageNumber,
      setPageNumber,
    },
    ref
  ) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const {
      isOpen: isEditProgressDialogOpen,
      open: openEditProgressDialog,
      close: closeEditProgressDialog,
    } = useDisclosure();

    const {
      dataToUpsert,
      isEditMode,
      isSavingData,
      handleSaveData,
      setDataToUpsert,
      updateColumnsToEdit,
      createdRunId,
      setCreatedRunId,
      error,
    } = useDataEdit(setDisableToolbar, setQueryData);

    const isFetchingData = queryData.fetchState === FETCH_STATE.FETCHING;
    const isQueryingData = queryData.queryState === QUERY_STATE.QUERYING;
    const isLoadingData = isFetchingData || isQueryingData;
    const hasDataLoaded = queryData.data.length > 0;

    const handleCancelRequest = useCallback(() => {
      cancelAllPendingBatchedRequests(FETCH_CANCELLATION.CANCEL_ALL_BATCHES);
      setQueryData((state) => ({ ...state, fetchState: FETCH_STATE.IDLE }));
    }, [setQueryData]);

    // If the data is being fetched or queried and no data was fetched yet, then we show the loader.
    // As soon as we fetch the initial batch of data, we stop showing the loader and paint the UI
    // with the table.
    if (isLoadingData && !hasDataLoaded) {
      return (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <Lottie
            loop
            play
            animationData={loadingAnimationData}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            style={{ width: 150, height: 150 }}
          />
        </Stack>
      );
    }

    const hasDataLoadFailed =
      queryData.fetchState === FETCH_STATE.FAILED && queryData.error && !hasDataLoaded;

    // if any of the error code starts with 'COE', show this common error screen
    if (hasDataLoadFailed && startsWith(queryData.error?.code, ERROR_TYPES.COMMON_ERRORS)) {
      return (
        <Stack alignItems="center" justifyContent="center" spacing={2} wrap="nowrap" height="100%">
          <ErrorImage width="250px" height="250px" />
          <Stack spacing={1} alignItems="center">
            <Typography color="textSecondary" variant="h5">
              Looks like an error occurred while querying the data
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Please check the below error message for more more details. To learn about the errors
              thrown by SuccessFactors, <Link href={COE_DOCUMENTATION}>click here</Link>
            </Typography>
          </Stack>
          <Alert severity="error">{queryData.error?.message?.value}</Alert>
        </Stack>
      );
    }

    // If no data was fetched and the fetch fails, then we show the error screen.
    // If there was some data fetched and some of the batches failed, then we don't show
    // the error screen but instead paint the UI with the table
    if (hasDataLoadFailed) {
      const error = queryData.error;
      const errorMsg = error?.errorDetails ?? error?.message?.value ?? error?.message ?? error;
      return (
        <Stack alignItems="center" justifyContent="center" spacing={2} wrap="nowrap" height="100%">
          <ErrorImage width="250px" height="250px" />
          <Typography color="textSecondary" variant="h5">
            Something went wrong!
          </Typography>
          <Alert severity="error">{errorMsg}</Alert>
        </Stack>
      );
    }

    const isNotFetchingData = queryData.fetchState === FETCH_STATE.IDLE;
    const isNotQueryingData = queryData.queryState === QUERY_STATE.IDLE;
    const wasFetchCancelled = queryData.fetchState === FETCH_STATE.CANCELED;

    if (!hasDataLoaded && ((isNotFetchingData && isNotQueryingData) || wasFetchCancelled)) {
      return (
        <Stack alignItems="center" justifyContent="center" height="100%" wrap="nowrap">
          <TableIllustration height="250px" />
          <Typography color="textSecondary" variant="h5">
            Create a query to continue
          </Typography>
        </Stack>
      );
    }

    const hasQueriedSuccessfully = queryData.queryState === QUERY_STATE.QUERY_SUCCESSFUL;
    const hasFetchedSuccessfully = queryData.fetchState === FETCH_STATE.FETCHED;
    const hasResolvedDataExtraction = hasQueriedSuccessfully || hasFetchedSuccessfully;

    // If the data is not being fetched/queried and if there is no data fetched and the fetched and
    // quering state has been resolved then that means there is not data to be shown
    if (!isLoadingData && !hasDataLoaded && hasResolvedDataExtraction) {
      return (
        <Stack alignItems="center" justifyContent="center" height="100%" wrap="nowrap">
          <EmptyImage width="250px" height="250px" />
          <Typography color="textSecondary" variant="h5">
            Looks like the table is empty!
          </Typography>
        </Stack>
      );
    }

    return (
      hasDataLoaded && (
        <Stack
          {...(isFullScreen && {
            sx: {
              position: 'absolute',
              top: 45,
              p: 0,
              pt: 2,
              height: '85vh',
              width: '100vw',
              background: 'white',
              zIndex: 10000,
            },
          })}
        >
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            px={2}
            mb={1.5}
            flex={2}
            spacing={1}
          >
            <Tooltip title="Fullscreen">
              <IconButton
                size="small"
                color="primary"
                onClick={() => setIsFullScreen((state) => !state)}
                sx={{
                  border: '1px solid #6d87a1',
                  borderRadius: 1,
                }}
              >
                {isFullScreen ? <MdFullscreenExit /> : <MdFullscreen />}
              </IconButton>
            </Tooltip>
            {/* <EditModeButton
              isEditMode={isEditMode}
              isSavingData={isSavingData}
              queryData={queryData}
              openDialog={openEditProgressDialog}
              handleEnterEditMode={handleEnterEditMode}
              disabled={queryData.data.length <= 0 || isSavingData || isFetchingData}
              isOAuthConnection={isOAuthConnection}
              dataToUpsert={dataToUpsert}
            /> */}
            <Stack
              flex={1}
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {isFetchingData && (
                <Button
                  variant="text"
                  color="error"
                  sx={{ textTransform: 'none' }}
                  onClick={handleCancelRequest}
                >
                  Cancel
                </Button>
              )}
            </Stack>
            <PaginationControls
              batchState={batchState}
              viewPicklistValues={viewPicklistValues}
              triggerDataFetch={ref.current}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </Stack>
          <TableView
            isEditMode={isEditMode}
            setDataToUpsert={setDataToUpsert}
            updateColumnsToEdit={updateColumnsToEdit}
            baseEndpoint={baseEndpoint}
            fetchPaginatedDataFromDatabase={fetchPaginatedDataFromDatabase}
            openEditProgressDialog={openEditProgressDialog}
            handleSaveData={handleSaveData}
            batchState={batchState}
            isFullScreen={isFullScreen}
          />
          <Stack
            direction="row"
            alignContent="center"
            spacing={2}
            justifyContent={'flex-end'}
            pr={2}
          >
            <Stack direction="row" alignContent="center" spacing={1} justifyContent={'flex-end'}>
              {queryData.fetchState === FETCH_STATE.FETCHING ? (
                <CircularProgress size={15} />
              ) : (
                <MdCheck fontSize={15} />
              )}
              <Typography
                sx={(theme) => ({
                  fontSize: theme.spacing(1.5),
                  fontWeight: theme.typography.fontWeightBold,
                })}
              >
                <strong>Data Loaded: </strong>
                {Number(batchState.recordsFetched).toLocaleString()}
              </Typography>
            </Stack>
            <Typography
              sx={(theme) => ({
                fontSize: theme.spacing(1.5),
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              <strong>Total Records: </strong> {Number(batchState.totalRecords).toLocaleString()}
            </Typography>
          </Stack>
          {isEditProgressDialogOpen && (
            <DataEditProgressDialog
              isOpen={isEditProgressDialogOpen}
              handleClose={closeEditProgressDialog}
              handleSaveData={handleSaveData}
              isSavingData={isSavingData}
              runId={createdRunId}
              setRunId={setCreatedRunId}
              triggerDataFetch={ref.current}
              editedRowsCount={dataToUpsert.length}
              error={error}
            />
          )}
        </Stack>
      )
    );
  }
);

export default TableArea;

// TODO: use the useConnection hook to fetch connection info and disable the Edit more button
// if the connection is not an OAuth connection
// TODO: if the feature is turned off in Billing, don't show the Edit Mode button
function useDataEdit(setDisableToolbar, setQueryData) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [dataToUpsert, setDataToUpsert] = useState({});
  const [columnsToEdit, setColumnsToEdit] = useState([]);
  const [createdRunId, setCreatedRunId] = useState(null);
  const [error, setError] = useState(null);

  const { connectionId } = useParams();
  const { user } = useLoggedInUser();
  const tenant = useTenantState();
  const { selectedConnection: connection } = useConnection(connectionId);

  const isOAuthConnection = connection?.user_system?.connection_type === CONNECTION_TYPES.OAUTH.KEY;

  const createRunMutation = useCreateRunMutation();

  const handleEnterEditMode = useCallback(
    (value) => {
      setIsEditMode(value);
      setDisableToolbar(value);

      if (!value) {
        setDataToUpsert({});
        setColumnsToEdit([]);
        setCreatedRunId(null);
      }
    },
    [setDisableToolbar]
  );

  const updateColumnsToEdit = useCallback((columnKey, replaceAll = false) => {
    setColumnsToEdit((state) => {
      if (replaceAll) {
        return Array.isArray(columnKey) ? columnKey : [columnKey];
      }

      if (!state.includes(columnKey)) {
        return [...state, columnKey];
      }

      return state;
    });
  }, []);

  const handleSaveData = useCallback(async () => {
    setIsSavingData(true);
    setError(null);

    // dataToUpser is in data-key:value format and we only want the values
    const valuesToUpsert = values(dataToUpsert);

    if (valuesToUpsert.length > 0) {
      // Generate template sequence based on the Report View state
      const { entity } = useReportViewState.get().current;
      const entityName = entity.value?.name ?? '';

      const templateSequence = [
        {
          entity: entityName,
          fields: [],
        },
      ];

      templateSequence[0].fields = columnsToEdit.map((name) => ({ code: name }));

      const templateBody = {
        name: truncate(`Update ${entityName} with Effective Fields - ${columnsToEdit.join(', ')}`, {
          length: 72,
          omission: 'and more',
        }),
        description: `Template created by INTEGRTR.Dataflow to upsert data into ${entityName} entity`,
        destination_user_system_id: connection?.user_system_id,
        approvers: [user?.user_id ?? ''],
        full_purge: false,
        sequence: templateSequence,
      };

      const newTemplate = await getServiceInstance(tenant?.tenant_id)
        .post(
          `${dataflowApiBase}/connection/${connection.connection_id}/edit/template`,
          templateBody
        )
        .then(({ template }) => template)
        .catch((error) => {
          toast.error('Failed to update data. Please try again!');
          return error;
        });

      if (
        newTemplate &&
        newTemplate.hasOwnProperty('type') &&
        newTemplate.type === ERROR_TYPES.INVALID_REQUEST_ERROR
      ) {
        const errorString = newTemplate.errorDetails;
        const errorDetails = extractErrorDetails(errorString);

        setError(errorDetails);
        setIsSavingData(false);
        return;
      }

      if (!newTemplate) {
        setIsSavingData(false);
        return;
      }

      const upsertableData = valuesToUpsert.map((row) => pick(row, columnsToEdit));

      const runBody = {
        name: `Run to update ${entityName}`,
        template_id: newTemplate.template_id,
        run_data: [
          {
            entity: entityName,
            rows: upsertableData,
          },
        ],
      };

      await createRunMutation.mutateAsync(
        {
          tenant,
          body: runBody,
          connectionId: connection.connection_id,
          userSystemId: connection.user_system_id,
        },
        {
          onSuccess: (data) => setCreatedRunId(data.run_id),
          onError: (error) => {
            const errorString = error.errorDetails;
            const errorDetails = extractErrorDetails(errorString);

            setError(errorDetails);
            setIsSavingData(false);
            return;
          },
        }
      );

      // Clear dataToUpset after changes are saved
      setDataToUpsert([]);
    }

    // Code to handle the JSON structuring. template creation and data posting
    setIsEditMode(false);
    setDisableToolbar(false);
    setIsSavingData(false);
  }, [
    columnsToEdit,
    connection,
    createRunMutation,
    dataToUpsert,
    setDisableToolbar,
    tenant,
    user?.user_id,
  ]);

  return {
    dataToUpsert,
    isEditMode,
    isSavingData,
    handleEnterEditMode,
    handleSaveData,
    setDataToUpsert,
    updateColumnsToEdit,
    createdRunId,
    setCreatedRunId,
    isOAuthConnection,
    error,
  };
}
