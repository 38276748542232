import React from 'react';

export default function GenericArchiveImage(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={861.675}
      height={449.488}
      data-name="Layer 1"
      viewBox="0 0 861.675 449.488"
      {...props}
    >
      <path
        fill="#2f2e41"
        d="M263.117 24.476c3.252-10.366 10.649-19.873 20.878-23.377 10.23-3.504 25.208 1.834 32.898 9.498 14.067 14.02 17.5 62.182 7.836 77.402-1.923-.102-8.556-.177-10.502-.25l-2.752-9.173v9.083c-15.186-.47-30.997-.176-46.815.128-1.418-10.776-4.796-52.946-1.543-63.311Z"
      />
      <path
        fill="#9e616a"
        d="M413.454 184.857a9.43 9.43 0 0 1-7.73-12.22L378.92 152.53l16.391-5.876 22.941 19.996a9.48 9.48 0 0 1-4.798 18.207Z"
      />
      <path
        fill="#e6e6e6"
        d="m306.974 79.463 2.564-9.074s6.395-2.085 17.308 5.83 78.277 78.564 78.277 78.564l-20.055 2.804-61.296-43.788Z"
      />
      <path
        fill="#f0f0f0"
        d="M726.639 426.012c41.024-20.949 73.685-62.952 72.784-109.006a212.23 212.23 0 0 1-136.96 49.735c-19.318-.11-40.793-2.245-55.807 9.912-9.342 7.565-14.24 19.547-15.689 31.48-1.45 11.933.178 24 1.802 35.911l-1.008 2.827c45.955 3.153 93.854.089 134.878-20.86Z"
      />
      <path
        fill="#fff"
        d="M798.463 316.895c-17.87 33.225-46.47 60.957-80.563 77.24-7.37 3.52-15.154 6.45-23.293 7.514a44.804 44.804 0 0 1-23.337-3.379c-6.901-2.837-13.632-6.538-21.103-7.648-7.874-1.17-15.402 1.41-21.507 6.386-7.47 6.09-12.307 14.5-16.752 22.888-4.934 9.314-9.912 19.066-18.628 25.43-1.056.77.202 2.364 1.257 1.594 15.164-11.07 18.815-31.02 31.23-44.411 5.794-6.248 13.506-10.789 22.271-10.088 7.666.614 14.61 4.424 21.573 7.357 7.311 3.08 14.725 4.784 22.695 4.154 8.153-.644 16.067-3.275 23.496-6.591A175.99 175.99 0 0 0 761.683 367a184.617 184.617 0 0 0 38.67-49.363c.617-1.148-1.277-1.883-1.89-.743Z"
      />
      <path
        fill="#fff"
        d="M729.261 389.427a27.216 27.216 0 0 0 33.87 9.739c1.188-.542.402-2.415-.788-1.872a25.201 25.201 0 0 1-31.487-9.124c-.718-1.091-2.308.171-1.595 1.257ZM674.58 400.17a52.456 52.456 0 0 1 20.399-32.332c1.053-.774-.204-2.368-1.256-1.594a54.562 54.562 0 0 0-21.159 33.686c-.243 1.286 1.774 1.519 2.016.24ZM774.843 351.759a15.405 15.405 0 0 1-4.288-13.976c.26-1.282-1.758-1.513-2.016-.239a17.263 17.263 0 0 0 4.71 15.47 1.05 1.05 0 0 0 1.425.17 1.02 1.02 0 0 0 .169-1.425Z"
      />
      <path
        fill="#f0f0f0"
        d="M677.318 219.98c-.185.797-.37 1.593-.57 2.396a202.88 202.88 0 0 1-10.18 31.296 89.34 89.34 0 0 1-.983 2.362 213.882 213.882 0 0 1-43.907 65.368 207.705 207.705 0 0 1-23.784 20.743c-11.66 8.702-24.995 17.209-32.868 28.91a35.63 35.63 0 0 0-2.273 3.779l25.638 70.588c.139.13.262.269.401.4l.895 2.866c.458-.295.927-.61 1.385-.905.266-.17.527-.355.793-.525.176-.118.352-.237.517-.334.059-.039.117-.079.16-.112.165-.097.309-.204.458-.294q3.938-2.634 7.853-5.332c.016-.006.016-.006.027-.027 19.85-13.755 38.727-29.288 54.646-47.193.479-.539.974-1.082 1.441-1.654a198.198 198.198 0 0 0 19.304-25.951 175.22 175.22 0 0 0 8.562-15.525 145.704 145.704 0 0 0 13.223-43.595c4.172-30.172-1.353-61.342-19.345-85.433-.46-.616-.916-1.217-1.393-1.827Z"
      />
      <path
        fill="#fff"
        d="M676.48 220.465c5.736 37.287-.403 76.648-17.821 110.176-3.766 7.248-8.217 14.274-14.074 20.024a44.804 44.804 0 0 1-20.668 11.352c-7.218 1.89-14.82 2.988-21.454 6.6-6.991 3.806-11.449 10.398-13.328 18.047-2.298 9.36-1.097 18.987.405 28.36 1.668 10.408 3.565 21.192.437 31.52-.38 1.25 1.585 1.766 1.963.516 5.442-17.968-3.653-36.095-1.802-54.262.864-8.478 4.287-16.746 11.708-21.463 6.49-4.126 14.329-5.264 21.654-7.115 7.692-1.943 14.637-5.045 20.622-10.347 6.122-5.423 10.857-12.289 14.792-19.41a175.99 175.99 0 0 0 18.367-51.847 184.617 184.617 0 0 0 1.155-62.696c-.198-1.288-2.153-.735-1.956.545Z"
      />
      <path
        fill="#fff"
        d="M664.895 320.042a27.216 27.216 0 0 0 32.907-12.616c.622-1.148-1.133-2.17-1.756-1.02a25.201 25.201 0 0 1-30.634 11.672c-1.23-.439-1.74 1.527-.517 1.964ZM627.703 361.541a52.456 52.456 0 0 1-3.178-38.096c.375-1.253-1.589-1.768-1.963-.517a54.562 54.562 0 0 0 3.388 39.636c.58 1.173 2.33.144 1.753-1.023ZM678.61 262.523a15.405 15.405 0 0 1-11.836-8.577c-.565-1.18-2.315-.15-1.754 1.022a17.263 17.263 0 0 0 13.074 9.518 1.05 1.05 0 0 0 1.24-.723 1.02 1.02 0 0 0-.723-1.24Z"
      />
      <path
        fill="#f0f0f0"
        d="M233.22 92.902c-35.143-29.78-86.49-43.728-128.638-25.145a212.23 212.23 0 0 1 98.682 107.207c7.345 17.868 13.653 38.507 30.657 47.675 10.581 5.704 23.525 5.605 35.095 2.343 11.57-3.262 22.078-9.415 32.442-15.505l2.996-.16c-14.803-43.619-36.093-86.635-71.235-116.415Z"
      />
      <path
        fill="#fff"
        d="M104.85 68.686c37.545 3.682 74.158 19.383 102.324 44.566 6.089 5.445 11.792 11.498 15.912 18.597a44.804 44.804 0 0 1 5.877 22.836c.042 7.461-.779 15.099 1.077 22.42 1.956 7.717 7.237 13.669 14.182 17.384 8.498 4.546 18.123 5.768 27.576 6.635 10.496.964 21.414 1.798 30.645 7.388 1.118.677 2.104-1.098.987-1.774-16.06-9.726-35.875-5.405-53.017-11.7-7.999-2.938-15.16-8.303-17.892-16.662-2.39-7.31-1.55-15.186-1.527-22.741.023-7.933-1.261-15.431-4.914-22.543-3.737-7.275-9.216-13.563-15.14-19.14a175.99 175.99 0 0 0-45.679-30.641 184.617 184.617 0 0 0-60.454-16.655c-1.297-.128-1.246 1.903.043 2.03Z"
      />
      <path
        fill="#fff"
        d="M198.45 104.583a27.216 27.216 0 0 0-4.068-35.006c-.958-.888-2.383.56-1.423 1.449a25.201 25.201 0 0 1 3.717 32.57c-.73 1.083 1.048 2.064 1.774.987ZM229.44 150.899a52.456 52.456 0 0 1-37.696-6.361c-1.12-.673-2.107 1.101-.987 1.774a54.562 54.562 0 0 0 39.239 6.54c1.28-.272.717-2.223-.556-1.953ZM146.124 77.043a15.405 15.405 0 0 1-11.243 9.343c-1.283.254-.719 2.205.556 1.952a17.263 17.263 0 0 0 12.46-10.308 1.05 1.05 0 0 0-.393-1.38 1.02 1.02 0 0 0-1.38.393Z"
      />
      <path
        fill="#f0f0f0"
        d="M62.118 217.825c.806-.137 1.612-.273 2.43-.397a202.88 202.88 0 0 1 32.802-2.67 89.28 89.28 0 0 1 2.559-.003 213.882 213.882 0 0 1 77.24 15.319 207.705 207.705 0 0 1 28.308 13.95c12.524 7.406 25.513 16.431 39.344 19.186a35.632 35.632 0 0 0 4.364.64l55.251-50.864c.068-.179.147-.345.216-.525l2.298-1.93c-.448-.309-.92-.62-1.368-.929-.26-.18-.531-.35-.79-.53-.177-.117-.355-.233-.508-.348a2.833 2.833 0 0 0-.165-.104c-.153-.116-.307-.207-.448-.31q-3.948-2.618-7.946-5.19c-.012-.013-.012-.013-.036-.015-20.343-13.015-41.952-24.446-64.61-32.234-.68-.234-1.374-.481-2.08-.692a198.199 198.199 0 0 0-31.388-7.809 175.215 175.215 0 0 0-17.625-1.917 145.704 145.704 0 0 0-45.323 4.602c-29.449 7.78-56.081 24.893-71.376 50.78-.39.663-.77 1.315-1.149 1.99Z"
      />
      <path
        fill="#fff"
        d="M62.888 218.412c32.195-19.665 70.881-29.172 108.532-26.023 8.14.681 16.338 2.08 23.902 5.268a44.804 44.804 0 0 1 18.441 14.695c4.526 5.933 8.47 12.525 14.358 17.253 6.208 4.984 14.008 6.557 21.79 5.342 9.523-1.487 17.943-6.306 26.014-11.305 8.96-5.55 18.18-11.457 28.915-12.552 1.3-.132 1.019-2.143-.28-2.01-18.678 1.904-31.898 17.283-49.375 22.578-8.155 2.47-17.104 2.498-24.318-2.531-6.308-4.398-10.38-11.193-14.91-17.239-4.758-6.348-10.298-11.561-17.496-15.04-7.364-3.559-15.525-5.282-23.612-6.168a175.99 175.99 0 0 0-54.92 3.037 184.617 184.617 0 0 0-58.297 23.1c-1.113.679.151 2.27 1.256 1.595Z"
      />
      <path
        fill="#fff"
        d="M159.236 190.72a27.216 27.216 0 0 0-24.324-25.501c-1.3-.132-1.566 1.881-.265 2.013A25.201 25.201 0 0 1 157.225 191c.07 1.304 2.08 1.017 2.011-.28ZM211.864 209.042a52.456 52.456 0 0 1-33.927 17.617c-1.3.137-1.02 2.148.28 2.01a54.562 54.562 0 0 0 35.267-18.403c.86-.987-.765-2.206-1.62-1.224ZM100.875 200.235a15.405 15.405 0 0 1-3.352 14.228c-.871.976.754 2.194 1.62 1.224a17.263 17.263 0 0 0 3.743-15.732 1.05 1.05 0 0 0-1.146-.866 1.02 1.02 0 0 0-.865 1.146ZM606.58 271.148a10.869 10.869 0 0 0-9.65-10.185L439.87 243.24a10.868 10.868 0 0 0-4.478.426l-36.03 11.28-20.672 6.48-38.15 11.941a10.903 10.903 0 0 0-7.62 10.948l6.439 153.075a10.95 10.95 0 0 0 9.15 10.202l160.366.35a10.585 10.585 0 0 0 2.279.121 10.874 10.874 0 0 0 3.248-.664l93.974-.649a10.85 10.85 0 0 0 7.08-10.848Z"
      />
      <path
        fill="#f9a826"
        d="m350.023 438.18 160.364.347a1.367 1.367 0 0 0 .699-.065l93.961-.645a1.346 1.346 0 0 0 .887-1.359L597.07 271.7a1.379 1.379 0 0 0-1.203-1.274l-1.058-.124-156.01-17.585a1.033 1.033 0 0 0-.215-.006 1.352 1.352 0 0 0-.347.055l-87.877 27.52-6.975 2.178a1.376 1.376 0 0 0-.948 1.366l6.443 153.07a1.367 1.367 0 0 0 1.143 1.28Z"
      />
      <path
        fill="#2f2e41"
        d="m350.36 280.286 87.877-27.52a1.352 1.352 0 0 1 .347-.055 1.033 1.033 0 0 1 .215.006l156.01 17.585-37.573 12.226-14.063 4.569-40.013 13.012a2.957 2.957 0 0 1-.36.072c-.077-.007-.155-.013-.247-.018Z"
      />
      <path
        fill="#3f3d56"
        d="m348.509 447.601 160.364.347a10.243 10.243 0 0 0 2.282.107 11.007 11.007 0 0 0 3.254-.648l93.962-.645a10.872 10.872 0 0 0 7.086-10.856l-8.88-164.757a10.956 10.956 0 0 0-1.799-5.408 9.492 9.492 0 0 0-.454-.622 10.652 10.652 0 0 0-4.228-3.305 10.877 10.877 0 0 0-3.164-.85l-.187-.025-156.867-17.701a13.646 13.646 0 0 0-1.924-.056 10.797 10.797 0 0 0-2.566.493l-36.026 11.267-20.668 6.49-38.158 11.94a10.806 10.806 0 0 0-4.184 2.442 2.025 2.025 0 0 0-.263.245 1.816 1.816 0 0 0-.163.188 10.892 10.892 0 0 0-3.005 8.058l6.43 153.087a10.962 10.962 0 0 0 9.158 10.21Zm-12.872-163.423a8.075 8.075 0 0 1 1.048-4.383 7.088 7.088 0 0 1 .798-1.196 8.102 8.102 0 0 1 3.121-2.35 6.182 6.182 0 0 1 .736-.269l64.248-20.109 30.618-9.606c.206-.07.401-.107.595-.16a7.628 7.628 0 0 1 1.435-.194 6.315 6.315 0 0 1 1.322.032l157.054 17.726c.125.017.234.035.359.051a7.951 7.951 0 0 1 3.91 1.746 8.014 8.014 0 0 1 2.983 5.825l8.866 164.773a8.15 8.15 0 0 1-5.297 8.137l-93.976.646a8.249 8.249 0 0 1-4.157.399l-160.362-.332a8.232 8.232 0 0 1-6.857-7.65Z"
      />
      <path
        fill="#3f3d56"
        d="M511.641 448.358a1.362 1.362 0 0 1-1.428-1.28l-8.838-148.184a1.362 1.362 0 0 1 1.279-1.44l.012-.001a1.362 1.362 0 0 1 1.428 1.279l8.838 148.185a1.362 1.362 0 0 1-1.278 1.44ZM567.083 438.334l-15.386-.104-14.6-152.023s18.482-7.559 19.292-6.273c.493.766 10.638 157.457 10.694 158.4ZM601.68 266.763l-1.11-.91a7.614 7.614 0 0 0-4.008-1.687L439.51 246.441a7.916 7.916 0 0 0-2.593.143l-.589.146-.118-2.35 26.045-28.813a14.989 14.989 0 0 1 12.383-4.857l106.173 9.394a14.854 14.854 0 0 1 13.264 11.69Z"
      />
      <path
        fill="#fff"
        d="M364.301 382.703a2.305 2.305 0 0 1 .507-.025l48.338 2.93a2.286 2.286 0 0 1-.276 4.563l-48.337-2.93a2.286 2.286 0 0 1-.231-4.538ZM365.112 399.684a2.305 2.305 0 0 1 .507-.026l48.337 2.93a2.286 2.286 0 0 1-.276 4.563l-48.337-2.93a2.286 2.286 0 0 1-.231-4.537ZM596.31 196.924l-93.24 50.766-38.308-70.359 93.24-50.765Z"
      />
      <path
        fill="#e6e6e6"
        d="m596.31 196.924-93.24 50.766-38.308-70.359 93.24-50.765Zm-92.459 48.117 89.81-48.898-36.44-66.928-89.81 48.898Z"
      />
      <path
        fill="#f9a826"
        d="m487.493 189.798 68.643-37.373 2.492 4.576-68.643 37.374z"
        data-name="Rectangle 342"
      />
      <path
        fill="#f9a826"
        d="m494.968 203.526 68.643-37.374 2.492 4.577-68.643 37.374z"
        data-name="Rectangle 343"
      />
      <path
        fill="#f9a826"
        d="m502.442 217.255 68.644-37.373 2.491 4.576-68.643 37.374z"
        data-name="Rectangle 344"
      />
      <path
        fill="#fff"
        d="M383.837 134.922c-.963-1.079-1.292-1.189-1.634-.953l-53.06 36.609c-.338.233-.358.578.299 1.847a374.43 374.43 0 0 0 6.174 9.216l68.268 98.947c6.4 9.274 11.959 16.576 12.392 16.277l53.06-36.609c.433-.298-4.42-8.088-10.819-17.363l-68.268-98.946a372.999 372.999 0 0 0-6.412-9.025Z"
      />
      <path
        fill="#e4e4e4"
        d="M390.906 144.184a232.08 232.08 0 0 0-3.383-4.626c-1.236-1.637-2.249-2.935-3.018-3.863a21.445 21.445 0 0 0-.668-.773c-.963-1.079-1.292-1.189-1.634-.953l-53.06 36.609c-.338.233-.358.578.299 1.847q.202.39.499.917c.591 1.045 1.445 2.452 2.539 4.193 1.831 2.898 4.24 6.519 6.78 10.2l64.829 93.962c2.944 4.267 5.798 8.225 8.036 11.151 3.726 4.872 4 4.683 4.413 4.397l53.06-36.609c.414-.285.688-.474-2.544-5.687-1.941-3.13-4.628-7.204-7.572-11.47l-64.83-93.962a368.912 368.912 0 0 0-3.746-5.333ZM340.02 187.21c-2.532-3.67-4.936-7.283-6.757-10.166a103.588 103.588 0 0 1-2.515-4.159 12.642 12.642 0 0 1-.86-1.7l52.62-36.305a12.637 12.637 0 0 1 1.284 1.407q.572.682 1.325 1.643.749.964 1.67 2.185c2.049 2.726 4.571 6.251 7.106 9.925l64.829 93.962c6.153 8.917 9.697 14.794 10.135 16.023l-52.626 36.31c-.993-.846-5.23-6.246-11.382-15.163Z"
      />
      <path
        fill="#f9a826"
        d="m372.493 165.356-10.046 6.931a2.309 2.309 0 0 0-.59 3.21l6.004 8.702a2.312 2.312 0 0 0 3.21.589l10.046-6.931a2.315 2.315 0 0 0 .59-3.211l-6.004-8.7a2.312 2.312 0 0 0-3.21-.59Z"
      />
      <path
        fill="#e4e4e4"
        d="m397.52 174.929-35.012 24.157a1.048 1.048 0 1 0 1.19 1.725l35.012-24.157a1.048 1.048 0 1 0-1.19-1.725ZM378.596 193.953l-13.299 9.176a1.048 1.048 0 0 0 1.19 1.725l13.299-9.176a1.048 1.048 0 0 0-1.19-1.725Z"
      />
      <path
        fill="#f9a826"
        d="m398.445 202.971-10.046 6.931a2.309 2.309 0 0 0-.589 3.21l6.004 8.702a2.312 2.312 0 0 0 3.21.589l10.046-6.932a2.315 2.315 0 0 0 .589-3.21l-6.003-8.7a2.312 2.312 0 0 0-3.21-.59Z"
      />
      <path
        fill="#e4e4e4"
        d="M423.473 212.544 388.46 236.7a1.048 1.048 0 0 0 1.19 1.725l35.012-24.157a1.048 1.048 0 0 0-1.19-1.725ZM404.548 231.568l-13.298 9.175a1.048 1.048 0 1 0 1.19 1.725l13.298-9.175a1.048 1.048 0 0 0-1.19-1.725Z"
      />
      <path
        fill="#f9a826"
        d="m424.398 240.586-10.046 6.931a2.309 2.309 0 0 0-.589 3.21l6.003 8.702a2.312 2.312 0 0 0 3.21.589l10.047-6.932a2.315 2.315 0 0 0 .589-3.21l-6.004-8.7a2.312 2.312 0 0 0-3.21-.59Z"
      />
      <path
        fill="#e4e4e4"
        d="m449.426 250.159-35.013 24.157a1.048 1.048 0 0 0 1.19 1.725l35.013-24.157a1.048 1.048 0 1 0-1.19-1.725ZM430.5 269.183l-13.298 9.175a1.048 1.048 0 1 0 1.19 1.725l13.299-9.175a1.048 1.048 0 0 0-1.19-1.725Z"
      />
      <path
        fill="#fff"
        d="M541.875 179.092c-.21-5.15-.53-8.47-.9-8.47h-135.4c-.37 0-.69 3.32-.9 8.47-.14 3.59-.23 8.07-.23 12.92v94.34l.55.07 22.26 2.9 23.2 3.04 10.6 1.38 22.67 2.96 18.83 2.46c.09.01.17.01.25.02.12-.02.24-.04.35-.07l7.41-2.41 8.55-2.78 22.44-7.3.55-.18v-94.43c0-4.85-.09-9.33-.23-12.92Z"
      />
      <path
        fill="#e4e4e4"
        d="M542.325 176.862c-.07-1.34-.14-2.42-.22-3.28a17.033 17.033 0 0 0-.38-2.67c-.22-.84-.47-.84-.75-.84h-135.4c-.28 0-.53 0-.75.84a19.679 19.679 0 0 0-.38 2.8c-.07.84-.14 1.88-.21 3.15-.22 4.05-.34 9.43-.34 15.15v94.26l.55.08.55.07v-94.41c0-4.98.09-9.2.22-12.53a58.31 58.31 0 0 1 .67-8.32h134.79a58.094 58.094 0 0 1 .67 8.31c.12 3.33.21 7.55.21 12.54v94.61l1.1-.36v-94.25c0-5.72-.12-11.1-.33-15.15Z"
      />
      <path
        fill="#f9a826"
        d="M447.185 199.382h-17.27a3.263 3.263 0 0 0-3.27 3.26v14.96a3.265 3.265 0 0 0 3.27 3.27h17.27a3.271 3.271 0 0 0 3.27-3.27v-14.96a3.27 3.27 0 0 0-3.27-3.26Z"
      />
      <path
        fill="#e4e4e4"
        d="M518.425 205.172h-60.2a1.48 1.48 0 0 0 0 2.96h60.2a1.48 1.48 0 0 0 0-2.96ZM481.085 212.122h-22.86a1.48 1.48 0 0 0 0 2.96h22.86a1.48 1.48 0 1 0 0-2.96Z"
      />
      <path
        fill="#f9a826"
        d="M447.185 243.662h-17.27a3.271 3.271 0 0 0-3.27 3.27v14.96a3.271 3.271 0 0 0 3.27 3.27h17.27a3.278 3.278 0 0 0 3.27-3.27v-14.96a3.278 3.278 0 0 0-3.27-3.27Z"
      />
      <path
        fill="#e4e4e4"
        d="M518.425 249.452h-60.2a1.485 1.485 0 0 0 0 2.97h60.2a1.485 1.485 0 0 0 0-2.97ZM481.085 256.402h-22.86a1.485 1.485 0 0 0 0 2.97h22.86a1.485 1.485 0 1 0 0-2.97Z"
      />
      <path
        fill="#f9a826"
        d="M449.985 289.542a3.251 3.251 0 0 0-2.8-1.59h-17.27a3.253 3.253 0 0 0-2.66 1.37l23.2 3.04v-1.15a3.182 3.182 0 0 0-.47-1.67Z"
      />
      <path
        fill="#e4e4e4"
        d="M518.425 293.742h-57.37l22.67 2.96h26.84l8.55-2.78a1.34 1.34 0 0 0-.69-.18Z"
      />
      <path
        fill="#3f3d56"
        d="M602.775 267.662a1.616 1.616 0 0 1-.35.17l-4.97 1.61-40.22 13.09-14.06 4.56-.52.17-1.1.36-22.44 7.3-8.55 2.78-7.41 2.41c-.11.03-.23.05-.35.07-.08-.01-.16-.01-.25-.02l-18.83-2.46-22.67-2.96-10.6-1.38-23.2-3.04-22.26-2.9-.55-.07-.55-.08-66.41-8.67-.56-.08a.988.988 0 0 1-.37-.11 1.373 1.373 0 0 1-.8-1.42 1.326 1.326 0 0 1 .27-.67c.06-.06.11-.12.17-.17.05-.03.11-.09.17-.12a1.313 1.313 0 0 1 .92-.22l3.32.44 63.29 8.27.55.07.55.07 32.79 4.29 12.2 1.59 32.11 4.2 20.51 2.68 8.25-2.68 26.24-8.53 4.46-1.45.55-.18.55-.18 13.73-4.46 40.84-13.29 3.66-1.19.71-.23a1.364 1.364 0 0 1 1.62.67 1.326 1.326 0 0 1 .09.21 1.375 1.375 0 0 1-.53 1.55Z"
      />
      <path
        fill="#3f3d56"
        d="M464.683 361.703a17.02 17.02 0 0 1-2.622-.203l-100.405-15.68a17.23 17.23 0 0 1-14.153-13.487l-11.206-54.39.69.084 167.848 20.29-24.542 53.399a17.234 17.234 0 0 1-15.61 9.987Z"
      />
      <path
        fill="#9e616a"
        d="m290.396 424.717 12.227-2.55-4.013-48.375-18.046 3.763 9.832 47.162z"
      />
      <path
        fill="#2f2e41"
        d="m284.994 419.547 3.655 29.698.008.06.285-.06 8.393-1.752-.157-8.513 5.67 7.37 5.43-1.128v-.008l10.964-2.286.888-.18 3.384-.707 2.347-.49a5.292 5.292 0 0 0 3.083-8.43 5.432 5.432 0 0 0-1.534-1.33c-.075-.038-.15-.083-.226-.121h-.007a5.023 5.023 0 0 0-.346-.166l-.203-.09-3.588-1.511-.015-.008-9.468-3.986-7.746-3.264-1.722-8.28-2.068.564-12.936 3.505Z"
      />
      <path
        fill="#9e616a"
        d="m206.364 385.222 9.496 8.115 35.816-32.764-14.014-11.976-31.298 36.625z"
      />
      <path
        fill="#2f2e41"
        d="m192.582 404.646 6.64-5.335-2.355 8.99 17.87 15.272a5.168 5.168 0 0 0 1.782 1.004 5.345 5.345 0 0 0 1.528.259 5.283 5.283 0 0 0 5.235-6.634l-5.85-22.051 5.498-6.43-1.711-1.289-10.688-8.089-3.38-2.556-21.303 21.096Z"
      />
      <path
        fill="#9e616a"
        d="m254.395 192.062 58.865-2.264 2.215-59.726-49.74 1.597-11.34 60.393z"
      />
      <circle cx={298.408} cy={36.413} r={22.417} fill="#9e616a" />
      <path
        fill="#2f2e41"
        d="M279.061 16.725 304.872 3.8a32.442 32.442 0 0 1 19.002 26.88l.643 7.706-10.808-2.752-1.184-9.854-1.836 9.086-4.988-1.27.047-15.298-4.99 15.288-17.816-4.093ZM314.392 176.214s19.612 34.088.678 85.808l-26.775 68.275-46.523 47.081-25.003-14.78 46.486-39.413-20.73-70.274a122.133 122.133 0 0 1-4.326-49.175c1.494-12.358 12.647-22 20.276-33.664 25.475 16.432 54.817 13.788 55.917 6.142Z"
      />
      <path
        fill="#2f2e41"
        d="m288.327 313.032 17.456 81.086-25.251 4.761-14.38-68.165 22.175-17.682z"
      />
      <path
        fill="#e6e6e6"
        d="m281.978 66.326 28.984.386 9.433 11.978a23.612 23.612 0 0 1 17.847 18.956l.89 5.142a46.064 46.064 0 0 1-5.523 30.931l-17.694 16.702.767 1.394a3.193 3.193 0 0 1-.963 4.153 6.524 6.524 0 0 1 .18 4.293l-.375 1.236s9.92 2.638-51.282-6.447a6.034 6.034 0 0 1-5.094-5.183 4.506 4.506 0 0 1-.835-5.191l.125-.25-.628-.74a4.846 4.846 0 0 1-.246-5.958l-.16-35.072a22.415 22.415 0 0 1 15.533-24.504Z"
      />
      <path
        fill="#9e616a"
        d="M364.422 216.262a9.43 9.43 0 0 0-11.184-9.164l-16.685-29.06-7.833 15.552 17.045 25.21a9.48 9.48 0 0 0 18.657-2.538Z"
      />
      <path
        fill="#e6e6e6"
        d="m272.817 97.71-9.319 1.437s-2.85 6.093 3.673 17.89 68.42 87.284 68.42 87.284l5.231-19.563-35.976-66.183Z"
      />
      <path
        fill="#ccc"
        d="M860.485 449.488H1.19a1.19 1.19 0 0 1 0-2.381h859.294a1.19 1.19 0 0 1 0 2.381Z"
      />
    </svg>
  );
}
