/**
 * @file
 *
 * To keep date utils in one place
 */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(duration);

export const customFormats = {
  date: 'YYYY-MM-DD',
  datetime: 'YYYY-MM-DD HH:mm:ss',
  time: 'HH:mm:ss',
  reversedDate: 'DD-MM-YYYY',
  reversedDateTime: 'DD-MM-YYYY HH:mm:ss',
};

/**
 * This function parses the dateString and returns the timestamp and offset (if present)
 *
 * @function
 * @param {string} dateString - the date-string that you want to parse (eg - /Date(253402214400000)/)
 * @returns
 */
function getTimestamp(dateString) {
  const match = String(dateString).match(/\/Date\((-?\d+)([-+]\d{4})?\)\//);

  if (!match || !Array.isArray(match) || !match.length) {
    return {};
  }

  const timestamp = match[1];
  const offset = match[2];

  return { timestamp, offset };
}

/**
 * This function returns the date in UTC format
 *
 * @function
 * @param {(string|Object)} dateTime - dateTime that you want to convert to UTC
 * @returns
 */
function getUTCDateTime(dateTime) {
  if (typeof dateTime !== 'string') {
    return dayjs(dateTime).utc();
  }

  const { timestamp, offset } = getTimestamp(dateTime);

  if (!timestamp) {
    return dayjs(dateTime).utc();
  }

  if (!offset) {
    return dayjs(Number(timestamp)).utc();
  }

  const offsetSign = Number(offset) >= 0 ? 1 : -1;
  const [offsetHours, offsetMinutes] = [Number(offset.slice(1, 3)), Number(offset.slice(3))];
  const offsetInMinutes = (offsetHours * 60 + offsetMinutes) * offsetSign;

  return dayjs(Number(timestamp)).utcOffset(offsetInMinutes).utc();
}

/**
 * @function
 * Formats the provided date/time string according to the specified options.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.dateTime - The date/time string to be formatted.
 * @param {boolean} [params.skipTime=false] - If true, skips the time component in the returned date string.
 * @param {boolean} [params.returnObj=false] - If true, returns the date as a Date object instead of a string.
 * @param {string} [params.format='DD MMM YYYY, HH:mm:ss'] - The format to use when returning the date as a string. Defaults to 'DD MMM YYYY, HH:mm:ss'.
 * @returns {(string|Date)} The formatted date string or Date object.
 * @throws Will throw an error if `dateTime` is not provided.
 * @example
 * formatEdmDateTime({ dateTime: '2023-06-08T14:30:45Z', skipTime: true, returnObj: false, format: 'DD MMM YYYY' });
 */
export function formatEdmDateTime({ dateTime, skipTime = false, returnObj = false, format }) {
  if (returnObj) {
    return getUTCDateTime(dateTime).toDate();
  }

  const _format = format ?? `DD MMM YYYY${skipTime ? '' : ', HH:mm:ss'}`;
  return getUTCDateTime(dateTime).format(_format);
}

/**
 * This function formats the given EDM time.
 *
 * @param {Object} options - The options for formatting the EDM time.
 * @param {string} options.time - The EDM time to be formatted.
 * @param {boolean} [options.returnObj=false] - If true, the function will return the parsed time object. If false, it will return the time formatted as a string.
 *
 * @returns {(Object|string)} The parsed EDM time object if returnObj is true, otherwise the time formatted as 'HH:mm:ss'.
 *
 * @example
 *
 * // returns Date object representing the time
 * formatEdmTime({ time: 'PT14H30M', returnObj: true });
 *
 * // returns '14:30:00'
 * formatEdmTime({ time: 'PT14H30M' });
 */
export function formatEdmTime({ time, returnObj = false }) {
  if (returnObj) {
    return parseEdmTime(time).toDate();
  }

  return parseEdmTime(time).format('HH:mm:ss');
}

/**
 * This function parses the time and returns a dayjs time
 *
 * @param {string} timeString - the timeString that you want to parse
 * @returns
 */
export function parseEdmTime(timeString) {
  const parsedDuration = dayjs.duration(timeString);

  return dayjs()
    .hour(+parsedDuration.hours() || 0)
    .minute(+parsedDuration.minutes() || 0)
    .second(+parsedDuration.seconds() || 0);
}

/**
 * This function converts the datt
 *
 * @param {(string|Object)} dateString - the dateString that you want to convert to UTC
 * @returns
 */
export function getUTCDateTimeObject(dateString) {
  const localDate = dayjs(dateString).toDate();
  const utcDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    )
  );

  return utcDate;
}

/**
 * This function returns a dayjs date
 *
 * @param {(string|Object)} dateTime
 * @param {string} dateFormat
 * @returns
 */
export const castToDayjsDate = (dateTime, dateFormat) => {
  if (!dateTime) {
    return null;
  }

  if (typeof dateTime === 'object') {
    const date = dayjs(dateTime, dateFormat);

    return date.isValid() ? date : null;
  }

  const date = String(dateTime).trim();

  if (dateFormat === customFormats.time) {
    return dayjs(date, customFormats.time);
  }

  let format = dateFormat === customFormats.date ? dateFormat : customFormats.datetime;

  if (dateFormat === customFormats.datetime && dateTime.split(' ').length === 1) {
    format = customFormats.date;
  }

  return dayjs(date, format);
};

/**
 * This function checks whether the provided date is a valid date or not
 *
 * @function
 * @name checkIsDateValid
 * @param {(string|Object)} date - date that you want to verify
 * @returns {Boolean}
 */
export const checkIsDateValid = (date) => dayjs(date).isValid();

/**
 * This function returns a date or time string along with padding (i.e. 9 will be returned as 09)
 *
 * @function
 * @param {number} dateOrTime - date or time to which padding is to be added
 * @returns {string}
 */
export function getPaddedDateOrTimeString(dateOrTime) {
  return dateOrTime?.toString().padStart(2, '0');
}

/**
 * This function returns the offset in OData format
 * @returns
 */
export function getUTCOffsetInODataFormat() {
  return `${encodeURIComponent('+')}00:00`;
}
