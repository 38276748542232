import { get, groupBy, head, mapValues, pick } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import FileSaver from 'file-saver';
import sortArray from 'sort-array';

// This is a workaround to fix the issue unsafe-eval in the production build of exceljs.
// Read more: https://github.com/exceljs/exceljs/issues/713#issuecomment-873997340
import 'regenerator-runtime/runtime';
import ExcelJS from 'exceljs/dist/exceljs.bare.min.js';

import { ODATA_DATA_TYPES } from 'odata/utils';
import { formatEdmDateTime, formatEdmTime } from 'date';

export default function useExportData({
  schema,
  selectedEntityType,
  records,
  columns,
  connectionName,
}) {
  const [isExporting, updateExportingState] = useState(false);

  sortArray(columns, {
    by: ['isKey', 'required', 'label'],
    order: ['isKey', 'required', 'asc'],
    customOrders: {
      isKey: [true, false],
      required: [true, false],
    },
  });

  const columnsInfo = useMemo(() => {
    const groupedValues = groupBy(columns ?? [], (prop) => prop.name);
    return mapValues(groupedValues, (obj) => head(obj)) ?? {};
  }, [columns]);

  const handleExportCSV = useCallback(async () => {
    const selectedEntity = schema?.entityTypesMap?.[selectedEntityType];
    const entityLabel = selectedEntity?.label;

    updateExportingState(true);

    const maxFileNameLength = 255;
    const fileName = truncate(`${connectionName} - ${entityLabel}`, maxFileNameLength);

    // main sheet
    const columnNames = columns.map(({ name }) => name);
    const sheetData = records.map((_record) => {
      const record = pick(_record, columnNames);
      const props = Object.keys(record);

      props.forEach((prop) => {
        if (typeof record[prop] === 'string' && record[prop].trim()) {
          const { type } = columnsInfo[prop];

          if (
            type === ODATA_DATA_TYPES['Edm.DateTime'] ||
            type === ODATA_DATA_TYPES['Edm.DateTimeOffset']
          ) {
            record[prop] = formatEdmDateTime({
              dateTime: record[prop],
              returnObj: true,
            });
          } else if (type === ODATA_DATA_TYPES['Edm.Time']) {
            record[prop] = formatEdmTime({ time: record[prop] });
          }
        }
      });

      return record;
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const headerRow = worksheet.addRow(columnNames);
    headerRow.eachCell((cell) => {
      const { value: columnName } = cell?._value?.model;
      const columnData = columnsInfo[columnName];

      if (columnData.isKey) {
        cell.style = {
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A3E780' } },
          font: { color: { argb: '000000' } },
        };

        return;
      }

      if (columnData.required) {
        cell.style = {
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '80D7E7' } },
          font: { color: { argb: '000000' } },
        };
      }
    });

    sheetData.forEach((item) => {
      const row = columnNames.map((column) => get(item, column));
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    const options = {
      parserOptions: {
        delimiter: ';',
        quote: false,
      },
    };

    workbook.xlsx.writeBuffer(options).then((buffer) => {
      const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = new Blob([buffer], { type: blobType });

      FileSaver.saveAs(blob, `${fileName}.xlsx`);
      updateExportingState(false);
    });
  }, [columns, columnsInfo, connectionName, records, schema?.entityTypesMap, selectedEntityType]);

  return { handleExportCSV, isExporting };
}

function truncate(name, maxLength) {
  if (name.length > maxLength) {
    return name.slice(0, maxLength - 3).concat('...');
  }

  return name;
}
