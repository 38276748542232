/**
 * @file
 *
 * this hook is used to control feedback components like Dialog, Popper, Popover, Modal etc
 */
import { useState, useCallback } from 'react';

export function useDisclosure(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback((callback) => {
    if (callback && callback instanceof Function) {
      callback();
    }

    setIsOpen(true);
  }, []);

  const close = useCallback((callback) => {
    if (callback && callback instanceof Function) {
      callback();
    }

    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  return {
    isOpen,
    close,
    open,
    toggle,
  };
}
