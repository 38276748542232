/**
 * @file
 *
 * this file contains the columnar view table which shows each record in the datatable in a columnar key-value form
 */
import { createStore } from '@halka/state';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdVpnKey } from 'react-icons/md';

import { FormattedFieldValue } from './DataTable';

const useColumnarViewStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: theme.spacing(50),
    margin: theme.spacing(0, 1),
    overflow: 'auto',
  },
  tableHeaderContainer: {
    position: 'sticky',
    top: 0,
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.borders[0],
    zIndex: 1,
    padding: theme.spacing(2, 0),
  },
  tableHeader: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightBold,
    width: '47%',
    margin: 0,
    display: 'inline-block',
  },
  keyIconHeader: {
    width: '6%',
  },
  row: {
    position: 'sticky',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.borders[0],
    padding: theme.spacing(2, 0),
  },
  columnName: {
    fontWeight: theme.typography.fontWeightBold,
    width: '47%',
    display: 'inline-block',
  },
  keyIcon: {
    width: '6%',
    display: 'inline-block',
  },
  value: {
    fontWeight: theme.typography.fontWeightMedium,
    width: '47%',
    display: 'inline-block',
  },
  keyColumn: {
    position: 'sticky',
    backgroundColor: theme.palette.background.paper,
    display: 'block',
  },
}));

const initialState = {
  isOpen: false,
  records: [],
  columns: [],
  currentIndex: null,
};
export const useColumnarViewState = createStore(initialState);

export const useColumnarView = (records, columns) => {
  const openColumnarViewDialog = useCallback(
    (index) => {
      useColumnarViewState.set({ isOpen: true, records, columns, currentIndex: index });
    },
    [records, columns]
  );

  const closeColumnarViewDialog = useCallback(() => {
    useColumnarViewState.set(initialState);
  }, []);

  // Pagination functions
  const goToNextPage = useCallback(() => {
    useColumnarViewState.set((state) => ({ ...state, currentIndex: state.currentIndex + 1 }));
  }, []);

  const goToPreviousPage = useCallback(() => {
    useColumnarViewState.set((state) => ({ ...state, currentIndex: state.currentIndex - 1 }));
  }, []);

  return { openColumnarViewDialog, closeColumnarViewDialog, goToNextPage, goToPreviousPage };
};

export function ColumnarView({ isOpen, close, goToNextPage, goToPreviousPage }) {
  const classes = useColumnarViewStyles();

  const { records, columns, currentIndex } = useColumnarViewState();
  const theme = useTheme();

  const selectedRecord = records[currentIndex];

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md" fullWidth>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid item container className={classes.tableContainer}>
          <Grid item container id="columnar-view-header" className={classes.tableHeaderContainer}>
            <Grid item className={classes.tableHeader}>
              Key
            </Grid>
            <Grid item className={clsx(classes.tableHeader, classes.keyIconHeader)} />
            <Grid item className={classes.tableHeader}>
              Value
            </Grid>
          </Grid>
          <Grid item container id="columnar-view-body">
            {columns.map((column, index) => (
              <Grid
                item
                key={`columnar-view-${column.name}`}
                container
                id={`columnar-view-row-${index}`}
                className={clsx(classes.row, {
                  [classes.keyColumn]: column.isKey,
                })}
                {...(column.isKey && {
                  style: { top: (index + 1) * theme.spacing(6.225), zIndex: 1 },
                })}
                alignItems="center"
              >
                <Grid item className={classes.columnName}>
                  {column.name}
                </Grid>
                <Grid item className={classes.keyIcon}>
                  {column.isKey && <MdVpnKey />}
                </Grid>
                <Grid item className={classes.value}>
                  <FormattedFieldValue value={selectedRecord[column.name] ?? ''} column={column} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={goToPreviousPage} disabled={currentIndex === 0}>
          <MdKeyboardArrowLeft />
        </IconButton>
        <Grid item>{`${currentIndex + 1} / ${records.length}`}</Grid>
        <IconButton onClick={goToNextPage} disabled={currentIndex === records.length - 1}>
          <MdKeyboardArrowRight />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
