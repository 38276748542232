/**
 * @file
 *
 * this file renders the fields for Parameter Value in the where section of the UI
 */

import { hintTexts } from 'odata/queryBuilder';
import { ODATA_DATA_TYPES } from 'odata/utils';
import { useCallback, useMemo } from 'react';
import { commonTextFieldProps } from 'theme';

import { DebouncedTextField } from './DebouncedTextField';
import { customFormats } from 'date';
import { TimePicker } from './TimePicker';
import { DateTimePicker } from './DatePicker';

export const ParamValueField = ({ rule, updateRuleParam }) => {
  const isDateOrTimeClearable = useMemo(
    () =>
      (rule.filter.key.endsWith('timeEq') || rule.filter.key.endsWith('timeNe')) &&
      rule.property.nullable === 'true',
    [rule]
  );

  const hints = useMemo(() => rule.filter.getHints?.(rule), [rule]);
  const validate = useCallback(
    (value) => rule.filter.validate?.({ ...rule, param: value }),
    [rule]
  );

  if (
    rule.property.type === ODATA_DATA_TYPES['Edm.DateTime'] ||
    rule.property.type === ODATA_DATA_TYPES['Edm.DateTimeOffset']
  ) {
    return (
      <DateTimePicker
        label="Param Value"
        clearable={isDateOrTimeClearable}
        value={rule.param}
        onChange={updateRuleParam}
        dateFormat={customFormats.datetime}
        placeholder={hintTexts.datetime.placeholder}
        hintTexts={hintTexts}
      />
    );
  }

  if (rule.property.type === ODATA_DATA_TYPES['Edm.Time']) {
    return (
      <TimePicker
        label="Parameter Value"
        clearable={isDateOrTimeClearable}
        value={rule.param || null}
        updateRuleParam={updateRuleParam}
        {...hintTexts.time}
      />
    );
  }

  return (
    <DebouncedTextField
      key={rule.property.name + rule.filter.key}
      fullWidth
      label="Parameter Value"
      {...commonTextFieldProps}
      {...hints}
      validate={rule.filter.validate && validate}
      value={rule.param}
      onChange={updateRuleParam}
    />
  );
};
