import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Toolbar,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Lottie from 'react-lottie-player';
import { motion } from 'framer-motion';

import metadataFetchingAnimationData from '../../lotties/metadata_downloading.json';
import metadataParsingAnimationData from '../../lotties/metadata_parsing.json';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
  },
  text: {
    position: 'relative',
    bottom: theme.spacing(13),
  },
}));

const fadeInOutVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

export function ConnectionLoader({
  isMetadataLoading,
  isMetadataParsing,
  isPingingSystem,
  isConnectionLoading,
  loaderText = '',
}) {
  if (isConnectionLoading) {
    return (
      <ConnectionLoaderWrapper text="Loading Connection Information">
        <motion.div initial="hidden" animate="visible" exit="exit" variants={fadeInOutVariants}>
          <Lottie
            loop
            play
            animationData={metadataFetchingAnimationData}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            style={{
              width: 500,
              height: 500,
            }}
          />
        </motion.div>
      </ConnectionLoaderWrapper>
    );
  }

  if (isPingingSystem) {
    return (
      <ConnectionLoaderWrapper text="Testing Connectivity to the System">
        <motion.div initial="hidden" animate="visible" exit="exit" variants={fadeInOutVariants}>
          <Lottie
            loop
            play
            animationData={metadataFetchingAnimationData}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            style={{
              width: 500,
              height: 500,
            }}
          />
        </motion.div>
      </ConnectionLoaderWrapper>
    );
  }

  if (isMetadataLoading) {
    return (
      <ConnectionLoaderWrapper text="Fetching OData Metadata">
        <motion.div initial="hidden" animate="visible" exit="exit" variants={fadeInOutVariants}>
          <Lottie
            loop
            play
            animationData={metadataFetchingAnimationData}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            style={{
              width: 500,
              height: 500,
            }}
          />
        </motion.div>
      </ConnectionLoaderWrapper>
    );
  }

  if (isMetadataParsing) {
    return (
      <ConnectionLoaderWrapper text="Parsing OData Metadata">
        <motion.div initial="hidden" animate="visible" exit="exit" variants={fadeInOutVariants}>
          <Lottie
            loop
            play
            animationData={metadataParsingAnimationData}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            style={{
              width: 500,
              height: 500,
            }}
          />
        </motion.div>
      </ConnectionLoaderWrapper>
    );
  }

  return (
    <ConnectionLoaderWrapper text={loaderText}>
      <CircularProgress />
    </ConnectionLoaderWrapper>
  );
}

function ConnectionLoaderWrapper({ children, text }) {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      {children}
      <Typography variant="h5" component="p" align="center" className={classes.text}>
        {text}
      </Typography>
    </Grid>
  );
}

const useDatatableStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    maxHeight: '90vh',
  },
  table: {
    width: '100vh',
    height: '100%',
  },
  tableToolbar: {
    background: theme.palette.background.navBar,
  },
  tableTitle: {
    width: theme.spacing(40),
    height: theme.spacing(5),
  },
  tableHeadCell: {
    border: 'none',
    background: (props) => (props.darkHeader ? theme.palette.background.navBar : 'initial'),
  },
  tablePagination: {
    width: '100vw',
    height: theme.spacing(7),
    padding: theme.spacing(2),
  },
  tableRow: {
    width: '100%',
  },
  paginationSkeleton: {
    width: theme.spacing(70),
    height: theme.spacing(3),
  },
}));

const getDummyData = (length) =>
  Array(length)
    .fill('-')
    .map((_, index) => index.toString());

export function DatatableLoader({
  size = 'medium',
  rowsCount = 10,
  columnsCount = 6,
  disablePagination,
  disableTitle,
  darkHeader,
}) {
  const classes = useDatatableStyles({ darkHeader });

  const columns = getDummyData(columnsCount);
  const rows = getDummyData(rowsCount);

  return (
    <Paper>
      {!disableTitle && (
        <Toolbar className={classes.tableToolbar}>
          <Typography variant="h6">
            <Skeleton variant="text" className={classes.tableTitle} />
          </Typography>
        </Toolbar>
      )}
      <TableContainer className={classes.container}>
        <Table size={size}>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell className={classes.tableHeadCell} key={col}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row} hover>
                {columns.map((col) => (
                  <TableCell key={col}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={classes.tablePagination}
        >
          <Grid item>
            <Skeleton variant="rect" className={classes.paginationSkeleton} />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
