/**
 * @file
 *
 * this file contains the base Home component
 */
import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import { Nav } from '../components/Nav';
import { ConnectionCardLoader } from '../components/Loaders/HomePageLoaders';
import { useInitialLoadNormal } from '../hooks/useInitialLoadChecks';

const useStyles = makeStyles((theme) => ({
  titleBar: {
    height: theme.spacing(6),
    top: theme.spacing(5.5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(0.5, 6, 0),
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    borderBottom: theme.borders[0],
  },
  container: {
    height: '100%',
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    margin: theme.spacing(10, 0, 0),
    padding: theme.spacing(2, 4, 0),
  },
}));

export function Home() {
  const classes = useStyles();

  useInitialLoadNormal();

  return (
    <>
      <Nav />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        className={classes.titleBar}
      >
        <Grid item container justifyContent="center">
          <Grid component={Typography} variant="h6" align="center">
            Dataflow
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classes.container}
        container
        direction="row"
        alignContent="flex-start"
        spacing={2}
      >
        <ConnectionCardLoader />
        <ConnectionCardLoader />
        <ConnectionCardLoader />
        <ConnectionCardLoader />
        <ConnectionCardLoader />
      </Grid>
    </>
  );
}
