import { createTheme } from '@material-ui/core/styles';
import { blueGrey, grey } from '@material-ui/core/colors';

const PALETTE = {
  SHARED: {
    navBar: 'rgba(53, 74, 95, 1)',
  },
  DARK: {
    50: 'rgba(41, 49, 58, 1)',
    100: 'rgba(28, 34, 40, 1)',
    200: 'rgba(20, 23, 28, 0.6)',
    300: 'rgba(20, 23, 28, 0.8)',
  },
  LIGHT: {
    50: '#fff',
    100: '#fff',
  },
};

/**
 * custom theme object for the material-ui components
 */
export const theme = createTheme({
  palette: {
    type: 'light',
    common: { black: '#000', white: '#fff' },
    primary: {
      light: 'rgba(77, 121, 166, 1)',
      main: 'rgba(53, 74, 95, 1)',
      dark: 'rgba(41, 52, 64, 1)',
      contrastText: 'rgba(231, 238, 246, 1)',
    },
    secondary: {
      light: 'rgba(122, 255, 225, 1)',
      main: 'rgba(30, 189, 152, 1)',
      dark: 'rgba(53, 138, 117, 1)',
      contrastText: 'rgba(224, 237, 233, 1)',
    },
    error: {
      light: 'rgba(249, 147, 150, 1)',
      main: 'rgba(255, 60, 65, 1)',
      dark: 'rgba(163, 7, 10, 1)',
      contrastText: 'rgba(252, 230, 232, 1)',
    },
    warning: {
      light: 'rgba(255, 152, 0, 1)',
      main: 'rgba(237, 108, 2, 1)',
      dark: 'rgba(230, 81, 0, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    integrtr: {
      green: '#08b44c',
    },
    background: {
      paper: PALETTE.LIGHT[50],
      default: PALETTE.LIGHT[100],
      navBar: PALETTE.SHARED.navBar,
    },
    action: {
      disabledBackground: grey[300],
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: ['Inter', 'sans-serif'],
    body2: {
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 'bold',
      color: blueGrey[600],
    },
  },
  borders: [
    '1px solid rgba(0, 0, 0, 0.12)',
    '1px solid rgba(224, 224, 224, 1)',
    `1px solid ${blueGrey[50]}`,
    '1px solid rgba(53, 74, 95, 1)',
  ],
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          height: '100%',
        },
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: 'darkgray lightgray',
        },
        '*::-webkit-scrollbar': {
          width: 12,
          height: 12,
        },
        '*::-webkit-scrollbar-track': {
          background: 'lightgray',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgray',
          borderRadius: 20,
          border: '3px solid lightgray',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
    },

    // TextField
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: blueGrey[50],
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
      },
      underline: {
        '&:hover:before': {
          borderBottom: blueGrey[50],
        },
        '&:before': {
          borderBottom: blueGrey[50],
        },
      },
    },
  },
});

// TODO: use this for dark theme

/*
export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      light: blue[400],
      main: blue[600],
      dark: blue[800],
      contrastText: 'rgba(231, 238, 246, 1)',
    },
    secondary: {
      light: 'rgba(122, 255, 225, 1)',
      main: 'rgba(30, 189, 152, 1)',
      dark: 'rgba(53, 138, 117, 1)',
      contrastText: 'rgba(224, 237, 233, 1)',
    },
    error: {
      light: 'rgba(249, 147, 150, 1)',
      main: 'rgba(255, 60, 65, 1)',
      dark: 'rgba(163, 7, 10, 1)',
      contrastText: 'rgba(252, 230, 232, 1)',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
    },
    integrtr: {
      green: '#08b44c',
    },
    background: {
      paper: PALETTE.DARK[50],
      default: PALETTE.DARK[100],
      navBar: PALETTE.SHARED.navBar,
    },
    action: {
      disabled: 'rgba(86, 96, 110,1)',
      disabledBackground: 'rgba(59, 67, 77, 1)',
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: ['Inter', 'sans-serif'],
    body2: {
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 'bold',
      color: blueGrey[600],
    },
  },
  borders: [
    '1px solid rgba(0, 0, 0, 0.12)',
    '1px solid rgba(0, 0, 0, 0.4)',
    `1px solid ${blueGrey[50]}`,
    '1px solid rgba(53, 74, 95, 1)',
  ],
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: `${PALETTE.DARK[50]} ${PALETTE.DARK[300]}`,
        },
        '*::-webkit-scrollbar': {
          width: 12,
          height: 12,
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: PALETTE.DARK[300],
          borderRadius: 20,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: PALETTE.DARK[50],
          borderRadius: 20,
          border: `3px solid ${PALETTE.DARK[300]}`,
        },
        '*::-webkit-scrollbar-corner': {
          display: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiButtonGroup: {
      groupedContainedHorizontal: {
        '&:not(&:last-child)': {
          borderRight: '1px solid rgba(0, 0, 0, 0.4)',
        },
      },
    },

    // TextField
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: PALETTE.DARK[200],
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        '&:hover, &.Mui-focused': {
          backgroundColor: PALETTE.DARK[300],
        },
      },
      underline: {
        '&:hover:before': {
          borderBottom: blueGrey[50],
        },
        '&:before': {
          borderBottom: blueGrey[50],
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${PALETTE.DARK[100]}`,
      },
    },
  },
});
*/

/**
 * common styling variant props for the TextField
 */
export const commonTextFieldProps = {
  margin: 'dense',
  InputLabelProps: {
    shrink: true,
  },
  variant: 'filled',
};

// TODO: use these for dark theme

/*
export const ThemeSwitcherContext = createContext();
function useThemeSwitch() {
  const [theme, setTheme] = useLocalStorage('__dataflow_theme', lightTheme.palette.type);
  const switchTheme = useCallback(() => {
    setTheme(theme === lightTheme.palette.type ? darkTheme.palette.type : lightTheme.palette.type);
  }, [theme, setTheme]);

  return {
    theme: theme === lightTheme.palette.type ? lightTheme : darkTheme,
    switchTheme,
  };
}

export function ThemeSwitcherProvider({ children }) {
  const value = useThemeSwitch();

  return <ThemeSwitcherContext.Provider value={value}>{children}</ThemeSwitcherContext.Provider>;
} */
