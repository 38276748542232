/**
 * @file
 *
 * This component renders the overview dialog
 */
import React, { useState, useCallback } from 'react';
import { Grid, Button, ButtonGroup, Tooltip, makeStyles, Collapse } from '@material-ui/core';
import { ReactOverview } from 'gojs-react';
import {
  MdZoomOutMap,
  MdCenterFocusStrong,
  MdVisibility,
  MdVisibilityOff,
  MdAdd,
  MdRemove,
} from 'react-icons/md';

import { initOverview } from '../models';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    zIndex: theme.zIndex.modal - 1,
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
  overviewContainer: ({ height, width }) => ({
    width: width / 7,
    height: height / 7,
    boxShadow: theme.shadows[0],
    border: theme.borders[1],
    backgroundColor: theme.palette.background.default,
  }),
  overview: {
    width: '100%',
    height: '100%',
  },
  zoomActions: {
    boxShadow: theme.shadows[0],
    marginTop: theme.spacing(0.8),
  },

  button: {
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: theme.shadows[0],
    height: theme.spacing(3.5),
    minWidth: theme.spacing(3.5),
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    color: theme.palette.text.primary,
  },
  keymapBtn: {
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: theme.shadows[0],
    height: theme.spacing(3.5),
    border: theme.borders[1],
    minWidth: theme.spacing(3.5),
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    color: theme.palette.text.primary,
  },
  buttonGroup: {
    border: theme.borders[1],
    boxShadow: theme.shadows[0],
  },
}));

const useOverviewDialog = () => {
  const [isOverviewDialogExpanded, setIsOverviewDialogExpanded] = useState(true);

  const toggleOverviewDialog = useCallback(() => {
    setIsOverviewDialogExpanded((prevState) => !prevState);
  }, [setIsOverviewDialogExpanded]);

  return { isOverviewDialogExpanded, toggleOverviewDialog };
};

export const OverviewDialog = (props) => {
  const classes = useStyles({ height: props.height, width: props.width });

  const { isOverviewDialogExpanded, toggleOverviewDialog } = useOverviewDialog();

  return (
    <Grid item className={classes.container}>
      <Collapse in={isOverviewDialogExpanded} timeout="auto">
        <Grid item className={classes.overviewContainer}>
          <ReactOverview
            initOverview={initOverview}
            divClassName={classes.overview}
            observedDiagram={props.diagram}
          />
        </Grid>
      </Collapse>
      <Grid
        container
        direction="row"
        className={classes.zoomActions}
        justifyContent="space-between"
      >
        <Grid item>
          <ButtonGroup className={classes.buttonGroup} variant="contained" size="small">
            <Tooltip title="Zoom In">
              <Button
                disableElevation
                className={classes.button}
                onClick={props.handleIncreaseZoom}
              >
                <MdAdd size="18px" />
              </Button>
            </Tooltip>
            <Tooltip title="Zoom to Fit">
              <Button disableElevation className={classes.button} onClick={props.handleZoomToFit}>
                <MdZoomOutMap size="18px" />
              </Button>
            </Tooltip>
            <Tooltip title="Reset Zoom">
              <Button disableElevation className={classes.button} onClick={props.handleResetZoom}>
                <MdCenterFocusStrong size="18px" />
              </Button>
            </Tooltip>
            <Tooltip title="Zoom Out">
              <Button
                disableElevation
                className={classes.button}
                onClick={props.handleDecreaseZoom}
              >
                <MdRemove size="18px" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Tooltip title="Overview" placement="bottom">
            <Button
              variant="contained"
              disableElevation
              color="default"
              size="small"
              className={classes.keymapBtn}
              onClick={toggleOverviewDialog}
            >
              {isOverviewDialogExpanded ? (
                <MdVisibility size="18px" />
              ) : (
                <MdVisibilityOff size="18px" />
              )}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
