import { CircularProgress, DialogContentText, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie-player';

import { EDIT_RUN_STATUS } from '../constants';
import { FileExportStateAnimation, VARIANTS } from 'components/Animations/FileExportStateAnimation';
import loadingAnimationData from 'lotties/loader.json';
import DialogWrapper from 'components/DialogWrapper';
import { useFetchRunInfo } from 'data/dataflowEdit';
import { useConnection } from 'data/connectionsAndSystems';
import { dataGridStore } from './TableView';

export default function DataEditProgressDialog({
  isOpen,
  handleSaveData,
  handleClose,
  runId,
  setRunId,
  isSavingData,
  triggerDataFetch,
  editedRowsCount,
  error,
}) {
  const [status, setStatus] = useState();

  const [dataUpdateProgress, setdataUpdateProgress] = useState(null);

  const { connectionId } = useParams();
  const { selectedConnection: connection } = useConnection(connectionId);

  const {
    isLoading,
    isFetching,
    refetch,
    error: runInfoError,
  } = useFetchRunInfo(connectionId, runId, connection.user_system_id);

  useEffect(() => {
    if (!runId) {
      return;
    }

    const pollRunInfo = async () => {
      const response = await refetch();

      if (!response.data) {
        return;
      }

      const runState = response.data.state;

      const progress = response.data.progress;

      const encounteredError = errorStatus.includes(runState);
      const runCompleted = runState === EDIT_RUN_STATUS.COMPLETED;

      setStatus(runState);
      setdataUpdateProgress(progress);

      if (runCompleted || encounteredError) {
        setRunId(null);
        return;
      } else {
        setTimeout(pollRunInfo, 5000);
      }
    };

    pollRunInfo();
  }, [refetch, runId, setRunId]);

  if (EDIT_RUN_STATUS.COMPLETED === status) {
    return (
      <DialogWrapper
        isOpen={isOpen}
        closeDialog={handleClose}
        disableBackdropClick={true}
        title="Data successfully updated"
        primaryBtnText="Done"
        primaryBtnAction={() => {
          triggerDataFetch(true);
          handleClose();
          dataGridStore.set([]);
        }}
      >
        <Stack justifyContent="center" width="100%" alignItems="center">
          <FileExportStateAnimation variant={VARIANTS.SUCCESS} />
          <DialogContentText>You have successfully updated the data</DialogContentText>
        </Stack>
      </DialogWrapper>
    );
  }

  if (errorStatus.includes(status) || error || runInfoError) {
    return (
      <DialogWrapper
        isOpen={isOpen}
        closeDialog={handleClose}
        disableBackdropClick={true}
        title="Failed to update data"
        secondaryBtnAction={handleClose}
      >
        <Stack justifyContent="center" width="100%" alignItems="center">
          <FileExportStateAnimation variant={VARIANTS.FAILURE} />
          <DialogContentText>{error?.msg ?? 'Looks like something went wrong'}</DialogContentText>
        </Stack>
      </DialogWrapper>
    );
  }

  if (!runId && !status) {
    return (
      <DialogWrapper
        isOpen={isOpen}
        closeDialog={handleClose}
        title="Trigger Data Updation"
        disableBackdropClick={true}
        secondaryBtnText="No, Cancel"
        secondaryBtnAction={handleClose}
        secondaryBtnProps={{ disabled: isSavingData }}
        primaryBtnText="Yes, Continue"
        primaryBtnAction={handleSaveData}
        primaryBtnProps={{
          disabled: isSavingData,
          ...(isSavingData && { startIcon: <CircularProgress size={12} /> }),
        }}
      >
        <DialogContentText>
          This action will update <strong>{editedRowsCount}</strong> fields in your SuccessFactors
          system. Are you sure you want to continue?
        </DialogContentText>
      </DialogWrapper>
    );
  }

  if (isLoading || isFetching) {
    <DialogWrapper
      isOpen={isOpen}
      closeDialog={handleClose}
      disableBackdropClick={true}
      title="Data updation in progress"
      progress={dataUpdateProgress}
    >
      <Stack justifyContent="center" width="100%" alignItems="center">
        <Lottie
          loop
          play
          animationData={loadingAnimationData}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          style={{ width: 100, height: 100 }}
        />
        <DialogContentText>Updating data, please wait...</DialogContentText>
      </Stack>
    </DialogWrapper>;
  }

  return (
    <DialogWrapper
      isOpen={isOpen}
      closeDialog={handleClose}
      disableBackdropClick={true}
      title="Data updation in progress"
      progress={dataUpdateProgress}
    >
      <Stack justifyContent="center" width="100%" alignItems="center">
        <Lottie
          loop
          play
          animationData={loadingAnimationData}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          style={{ width: 100, height: 100 }}
        />
        <DialogContentText>Updating data, please wait...</DialogContentText>
      </Stack>
    </DialogWrapper>
  );
}

const errorStatus = [
  EDIT_RUN_STATUS.DATA_FILE_ERROR,
  EDIT_RUN_STATUS.GENERATION_REQUEST_REJECTED,
  EDIT_RUN_STATUS.FAILED,
  EDIT_RUN_STATUS.ABORTED,
  EDIT_RUN_STATUS.COMPLETED_WITH_ERRORS,
];
