/**
 * @file
 *
 * This file hoists the schema visualizer state into a global scope so that changing between page views like visualizer and query builder doesn't wipe out the state
 *
 */

import { createStore } from '@halka/state';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { wrapWithImmer } from '../utils';
import { CANVAS } from '../constants';

/**
 * @typedef ModelData
 *
 * @property {{ [string]: number }} complexTypeLinkCountMap
 */

/**
 * @typedef KeyMap
 *
 * @property {{ [string]: number }} nodes
 * @property {{ [string]: number }} links
 */

/**
 * @typedef State
 *
 * @property {KeyMap} keyMap
 * @property {object[]} nodeDataArray
 * @property {object[]} linkDataArray
 * @property {object[]} propertyLinks
 * @property {boolean} skipsDiagramUpdate
 * @property {number | null} diagramZoomScale
 * @property {ModelData} modelData
 * @property {string} lastSelectedEntityName
 */

export const initialSchemaVisualizerState = {
  keyMap: {
    nodes: {},
    links: {},
  },
  nodeDataArray: [],
  linkDataArray: [],
  propertyLinks: [],
  skipsDiagramUpdate: false,
  diagramZoomScale: null,
  modelData: {
    complexTypeLinkCountMap: {},
  },
  lastSelectedEntityName: '',
};

export const useSchemaVisualizerState = createStore(initialSchemaVisualizerState);

/**
 * @type {function(function(State): void): void}
 */
export const updateSchemaVisualizerState = wrapWithImmer(useSchemaVisualizerState.set);

export const resetSchemaVisualizer = () => {
  useSchemaVisualizerState.set(initialSchemaVisualizerState);
};

export const useInitializeSchemaVisualizerState = (metadata) => {
  useEffect(() => {
    resetSchemaVisualizer();
  }, [metadata]);
};

export function addToCanvas({
  diagram,
  location,
  entityName,
  metadata,
  linkDataArray,
  keyMap,
  lazyLinkAddition = false,
}) {
  // We recieve the key of the selected entity which we use to grab the data of the entity from entityTypesMap
  const entity = metadata.entityTypesMap[entityName];

  const dummyIndex = -1;
  const linksMap = new Map(
    keyMap.links?.constructor.name === 'Map' ? keyMap.links : Object.entries(keyMap.links)
  );
  const nodesMap = new Map(
    keyMap.nodes?.constructor.name === 'Map' ? keyMap.nodes : Object.entries(keyMap.nodes)
  );

  if (nodesMap.size >= CANVAS.MAX_NODE_COUNT) {
    toast.error('Maximum node limit reached');
    return;
  }

  // Check if the node already exists in the canvas, if not then create an object consisting all the data required by GoJS
  // nodeDataArray is the array of node data that is passed to the canvas to generate the nodes, so wahtever data is present in
  // nodeDataArry is present in the canvas
  if (!entity || nodesMap.has(entityName)) {
    return;
  }

  const entityNodeInGoJSFormat = {
    key: entityName,
    name: metadata.entitySetMap[entityName].name,
    // we are bisecting the property array into two arrays -
    // primaryKeys to hold all the properties that make up the key for the entity
    // properties to hold rest of the properties
    ...entity.property?.reduce(
      (data, prop) => {
        if (prop.isKey) {
          data.primaryKeys = [
            ...data.primaryKeys,
            {
              name: prop.name,
              isKey: prop.isKey,
              type: prop.type,
            },
          ];
        } else {
          data.properties = [
            ...data.properties,
            {
              name: prop.name,
              isKey: prop.isKey,
              type: prop.type,
            },
          ];
        }

        return data;
      },
      { primaryKeys: [], properties: [] }
    ),
    navProperties:
      entity.navigationProperty?.map((prop) => ({
        name: prop.name,
        relationship: prop.relationship,
        toRole: prop.toRole,
        isAssociatedEntityVisible: false,
      })) ?? [],
    category: 'entityTypeNode',
    propertiesVisible: false,
    ...(location && {
      location,
    }),
  };

  nodesMap.set(entityName, dummyIndex);

  const allComplexTypesRelatedToSelectedEntity = new Map();
  /**
   * this is a recursive function to add all the related complex types
   *
   * @param {object} entityOrComplexType for the first run of the recursion this is an entity otherwise a complexType
   *
   * @param {string} complexTypeName complexTypeName to differentiate between the first run and subsequent ones
   */
  function flattenComplexTypeNodes(entityOrComplexType, complexTypeName) {
    let nodeToBeAdded;
    if (complexTypeName) {
      nodeToBeAdded = {
        key: complexTypeName,
        name: entityOrComplexType.name,
        category: 'complexTypeNode',
        properties: [],
      };
    }

    entityOrComplexType.property.forEach((prop) => {
      if (nodeToBeAdded) {
        nodeToBeAdded.properties.push({ name: prop.name, type: prop.type });
      }

      const complexType = metadata.complexTypesMap[prop.type];
      if (complexType) {
        flattenComplexTypeNodes(complexType, prop.type);
      }
    });

    if (nodeToBeAdded) {
      nodesMap.set(complexTypeName, dummyIndex);
      allComplexTypesRelatedToSelectedEntity.set(complexTypeName, nodeToBeAdded);
    }
  }
  flattenComplexTypeNodes(entity);

  const newComplexTypesRelatedToSelectedEntity = Array.from(
    allComplexTypesRelatedToSelectedEntity.values()
  );

  const linksToComplexTypes = new Map();
  /**
   * this is a recursive function to
   */
  const flattenComplexTypeRelations = (node) => {
    newComplexTypesRelatedToSelectedEntity.forEach((complexType) =>
      node.properties.forEach((prop) => {
        if (metadata.complexTypesMap[prop.type] && complexType.key === prop.type) {
          const name = metadata.complexTypesMap[complexType.key].name;
          const key = `${node.name}->${name}`;
          const complexTypeRelationData = {
            key,
            name,
            from: node.key,
            to: complexType.key,
            fromPort: prop.name,
            category: 'complexTypeLink',
          };

          linksMap.set(key, dummyIndex);
          linksToComplexTypes.set(key, complexTypeRelationData);
          flattenComplexTypeRelations(complexType);
        }
      })
    );
  };
  flattenComplexTypeRelations(entityNodeInGoJSFormat);

  const newRelationLinksToBeAdded = [];

  const navProperties = [...nodesMap.keys()].flatMap((key) => {
    const entity = metadata.entityTypesMap[key];

    return entity?.navigationProperty ?? [];
  });

  // list out all the relations associated to the selected entity
  // eslint-disable-next-line no-unused-expressions
  navProperties?.forEach((prop) => {
    const name = prop.relationship;

    if (linksMap.has(prop.relationship)) {
      return;
    }

    const association = metadata.associationsMap[name];
    const relation = {
      name,
      from: {
        // referentialConstraint contains the 'from' and 'to' data of the relation: principal being the 'from' and dependent being the 'to'
        role: association.referentialConstraint?.principal.role,
      },
      to: {
        role: association.referentialConstraint?.dependent.role,
      },
    };

    // Some metadatas don't have a referentialConstraint so this logic is used to hard code the 'from' and 'to' data for the relations
    const from = relation.from.role
      ? association.end.find((end) => end.role === relation.from.role)
      : association.end[0];
    const to = relation.to.role
      ? association.end.find((end) => end.role === relation.to.role)
      : association.end[1];
    relation.from = from;
    relation.to = to;

    if (
      lazyLinkAddition &&
      (!nodesMap.has(relation.from.type) || !nodesMap.has(relation.to.type))
    ) {
      return;
    }

    // GoJS takes 'from' property and 'to' property to create a relation between two different entities, this 'from' and 'to'
    // are are the key of the nodes for which we want to show a relation. Apart from that we can also show a relationship from a specific
    // field of one entity to another and for that we use the below logic. The below logic is used to get the data of the fields we want to create
    // a relationship from and to
    const fromProperty = metadata.entityTypesMap[from.type].navigationProperty?.find(
      (prop) => prop.relationship === relation.name && prop.fromRole === relation.from.role
    );
    const toProperty = metadata.entityTypesMap[to.type].navigationProperty?.find(
      (prop) => prop.relationship === relation.name && prop.fromRole === relation.to.role
    );

    const link = {
      key: relation.name,
      name: relation.name,
      from: relation.from.type,
      to: relation.to.type,
      ...(Boolean(fromProperty) && {
        fromPort: fromProperty.name,
      }),
      ...(Boolean(toProperty) && {
        toPort: toProperty.name,
      }),
      fromMultiplicity: relation.from.multiplicity,
      toMultiplicity: relation.to.multiplicity,
      category: 'entityTypeLink',
    };

    newRelationLinksToBeAdded.push(link);
    linksMap.set(relation.name, dummyIndex);
  });

  const propertyToPropertyRelation = [];
  newRelationLinksToBeAdded.forEach((relation) =>
    metadata.entityTypesMap[relation.from].key?.propertyRef?.forEach((property) => {
      const doesPropertyExistInBothEntity = Boolean(
        metadata.entityTypesMap[relation.to].property.find((prop) => prop.name === property.name) &&
          metadata.entityTypesMap[relation.from].property.find(
            (prop) => prop.name === property.name
          )
      );

      if (doesPropertyExistInBothEntity && relation.from !== relation.to) {
        propertyToPropertyRelation.push({
          name: relation.name,
          from: relation.from,
          to: relation.to,
          fromPort: property.name,
          toPort: property.name,
          category: 'propertyToPropertyLink',
          key: nanoid(),
        });
      }
    })
  );

  // Update the toggle icon for nav props for associated nodes
  if (diagram) {
    diagram.commit(({ model }) => {
      [...linkDataArray, ...newRelationLinksToBeAdded].forEach(({ from, fromPort, to, toPort }) => {
        if (from === entityName || to === entityName) {
          const isSourceFrom = from === entityName;
          const destinationKey = isSourceFrom ? to : from;
          const destinationNode =
            destinationKey === entityName
              ? entityNodeInGoJSFormat
              : model.nodeDataArray.find(({ key }) => key === destinationKey);

          if (destinationNode && entityNodeInGoJSFormat.navProperties) {
            entityNodeInGoJSFormat.navProperties = entityNodeInGoJSFormat.navProperties.map(
              (prop) => ({
                ...prop,
                ...(isSourceFrom &&
                  prop.name === fromPort && {
                    isAssociatedEntityVisible: true,
                  }),
                ...(!isSourceFrom &&
                  prop.name === toPort && {
                    isAssociatedEntityVisible: true,
                  }),
              })
            );
          }

          if (destinationNode) {
            if (destinationNode.navProperties) {
              const modifiedNavProperties = destinationNode.navProperties.map((prop) => ({
                ...prop,
                ...(isSourceFrom &&
                  prop.name === toPort && {
                    isAssociatedEntityVisible: true,
                  }),
                ...(!isSourceFrom &&
                  prop.name === fromPort && {
                    isAssociatedEntityVisible: true,
                  }),
              }));

              model.setDataProperty(destinationNode, 'navProperties', modifiedNavProperties);
            }
          }
        }
      });
    });
  }

  return {
    ...(Boolean(entityNodeInGoJSFormat) && {
      nodeToBeAdded: [entityNodeInGoJSFormat, ...newComplexTypesRelatedToSelectedEntity],
    }),
    linksToBeAdded: [...linksToComplexTypes.values(), ...newRelationLinksToBeAdded],
    propertyLinks: propertyToPropertyRelation,
    keyMap: {
      nodes: nodesMap,
      links: linksMap,
    },
  };
}

/**
 * This logic is used to remove the selected node and complexTypes related to the nodes
 */
export const removeFromCanvas = ({
  diagram,
  entityName,
  nodeDataArray,
  nodes,
  propertyLinks,
  linkDataArray,
  complexTypeLinkCounts = new Map(),
}) => {
  const nodeIndex = nodes[entityName];

  if (!isPositiveInteger(nodeIndex)) {
    return;
  }

  const entityNode = nodeDataArray[nodeIndex];

  if (!entityNode) {
    return;
  }

  const nodesToBeRemoved = new Set();
  nodesToBeRemoved.add(entityName);

  // This is a recursive function which digs into entity nodes and complexType nodes to
  // find all the complexTypes related to the selectedEntity
  const flattenComplexTypeNodesToBeRemoved = (node) =>
    node.properties.forEach((prop) => {
      if (prop.type.startsWith('Edm.')) {
        return;
      }

      const index = nodes[prop.type];

      if (!isPositiveInteger(index)) {
        return;
      }

      const complexType = nodeDataArray[index];

      if (!complexType) {
        return;
      }

      const complexTypeName = complexType.key;

      const totalNoOfLinksToComplexType = linkDataArray.filter(
        (link) => link.to === complexTypeName
      ).length;

      let newCount = totalNoOfLinksToComplexType - 1;
      if (complexTypeLinkCounts.has(complexTypeName)) {
        const lastCount = complexTypeLinkCounts.get(complexTypeName);

        newCount = lastCount - 1;
      }

      if (newCount === 0) {
        nodesToBeRemoved.add(complexTypeName);
      }

      complexTypeLinkCounts.set(complexTypeName, newCount);

      flattenComplexTypeNodesToBeRemoved(complexType);
    });
  flattenComplexTypeNodesToBeRemoved(entityNode);

  const allPropertyLinksToBeRemoved = new Set();
  propertyLinks.forEach((link) => {
    if (link.from === entityName || link.to === entityName) {
      allPropertyLinksToBeRemoved.add(link.key);
    }
  });

  // Update the nav property's toggle icon for the associated nodes
  if (diagram) {
    diagram.commit(({ model }) => {
      linkDataArray.forEach(({ from, fromPort, to, toPort }) => {
        if (from === entityName || to === entityName) {
          const isSourceFrom = from === entityName;
          const destinationKey = isSourceFrom ? to : from;

          const destinationNode = model.nodeDataArray.find(
            ({ key }) => key === destinationKey && key !== entityName
          );

          if (destinationNode) {
            if (destinationNode.navProperties) {
              const modifiedNavProperties = destinationNode.navProperties.map((prop) => ({
                ...prop,
                ...(isSourceFrom &&
                  prop.name === toPort && {
                    isAssociatedEntityVisible: false,
                  }),
                ...(!isSourceFrom &&
                  prop.name === fromPort && {
                    isAssociatedEntityVisible: false,
                  }),
              }));

              model.setDataProperty(destinationNode, 'navProperties', modifiedNavProperties);
            }
          }
        }
      });
    });
  }

  return {
    nodesToBeRemoved,
    propertyLinksToBeRemoved: allPropertyLinksToBeRemoved,
  };
};

/**
 * an impure function that mutates the key-values in the complexTypeLinkCountMap
 * depending on the state of the complex type properites expanded/collapsed
 *
 * @param {object} param
 * @param {{ [string]: number }} param.complexTypeLinkCountMap the state that tracks the no of links to each complexTypeNodes should be shown
 * @param {object[]} param.properties the properties that are being expanded or collapsed
 * @param {boolean} isExpanded collapsed / expanded state
 * @param {(string) => object[]} getProperties a function to get the properties of a node for a given node key
 */
export const toggleVisibilityOfProperty = ({
  complexTypeLinkCountMap,
  properties,
  isExpanded,
  getProperties,
}) => {
  // find all the properties that are of complex type
  const propertiesWithComplexType = properties.filter((prop) => !prop.type.startsWith('Edm.'));

  propertiesWithComplexType.forEach((prop) => {
    const complexTypeNodeKey = prop.type;

    const value = complexTypeLinkCountMap[complexTypeNodeKey];

    // if we are expanding
    if (isExpanded) {
      // increment the amount of links to a complex type node in the modelData
      complexTypeLinkCountMap[complexTypeNodeKey] = (value ?? 0) + 1;
    }
    // if we are collapsing
    else if (isPositiveInteger(value)) {
      // decrement the amount of links to a complex type node in the modelData
      complexTypeLinkCountMap[complexTypeNodeKey] = Math.max(value - 1, 0);
    }

    // find the complex node
    const nextProperties = getProperties(complexTypeNodeKey);

    // check recursively if there are any complex types in the properties in the complex node
    toggleVisibilityOfProperty({
      complexTypeLinkCountMap,
      properties: nextProperties,
      isExpanded,
      getProperties,
    });
  });
};

const isPositiveInteger = (num) => typeof num === 'number' && Number.isInteger(num) && num >= 0;
export const inKeyMap = (map, key) => isPositiveInteger(map[key]);

/**
 * @param {go.IncrementalData} modelChangeIncrementalData
 */
export const handleModelChange = (modelChangeIncrementalData) => {
  updateSchemaVisualizerState(
    /**
     * @param {State} state
     */
    (state) => {
      const {
        modifiedNodeData,
        removedNodeKeys,
        insertedNodeKeys,

        modifiedLinkData,
        removedLinkKeys,
        insertedLinkKeys,

        modelData,
      } = modelChangeIncrementalData;

      const { nodes, links } = state.keyMap;
      if (modifiedNodeData?.length) {
        modifiedNodeData.forEach((modifiedNode) => {
          const { key } = modifiedNode;
          const isNew = insertedNodeKeys?.includes(key) && !inKeyMap(nodes, key);

          if (isNew) {
            const newLength = state.nodeDataArray.push(modifiedNode);

            nodes[key] = newLength - 1;
          } else {
            const index = nodes[key];

            if (!isPositiveInteger(index)) {
              return;
            }

            state.nodeDataArray.splice(index, 1, modifiedNode);
          }
        });
      }

      if (removedNodeKeys?.length) {
        const removedNodeKeysSet = new Set(removedNodeKeys);

        const newNodeDataArray = [];
        const newNodesMap = {};

        const complexTypeLinkCountMap = { ...state.modelData.complexTypeLinkCountMap };

        state.nodeDataArray.forEach((node) => {
          if (removedNodeKeysSet.has(node.key)) {
            if (node.category === 'complexTypeNode') {
              delete state.modelData.complexTypeLinkCountMap[node.key];
            } else {
              toggleVisibilityOfProperty({
                complexTypeLinkCountMap,
                properties: node.properties,
                isExpanded: false,
                getProperties: (_key) => {
                  const _index = nodes[_key];

                  if (!isPositiveInteger(_index)) {
                    return [];
                  }

                  const _node = state.nodeDataArray[_index];

                  return _node?.properties ?? [];
                },
              });
            }
          } else {
            const len = newNodeDataArray.push(node);

            newNodesMap[node.key] = len - 1;
          }
        });

        state.nodeDataArray = newNodeDataArray;
        state.keyMap.nodes = newNodesMap;

        setTimeout(() => {
          updateSchemaVisualizerState((_state) => {
            _state.modelData.complexTypeLinkCountMap = complexTypeLinkCountMap;
            _state.skipsDiagramUpdate = false;
          });
        });
      }

      if (modifiedLinkData?.length) {
        modifiedLinkData.forEach((modifiedLink) => {
          const { key } = modifiedLink;
          const isNew = insertedLinkKeys?.includes(key) && !inKeyMap(links, key);

          if (isNew) {
            const newLength = state.linkDataArray.push(modifiedLink);

            links[key] = newLength - 1;
          } else {
            const index = links[key];

            if (!isPositiveInteger(index)) {
              return;
            }

            state.linkDataArray.splice(index, 1, modifiedLink);
          }
        });
      }

      if (removedLinkKeys?.length) {
        const removedLinkKeysSet = new Set(removedLinkKeys);

        const newLinkDataArray = [];
        const newLinksMap = {};
        state.linkDataArray.forEach((link) => {
          // Check if the removed link is present on the canvas
          const isPresentOnCanvas = state.nodeDataArray.find(
            ({ key }) => key === link.from || key === link.to
          );

          // Only add the link to state if it has not been removed or it is present on the canvas
          if (isPresentOnCanvas || !removedLinkKeysSet.has(link.key)) {
            const len = newLinkDataArray.push(link);

            newLinksMap[link.key] = len - 1;
          }
        });

        state.linkDataArray = newLinkDataArray;
        state.keyMap.links = newLinksMap;
      }

      if (modelData) {
        state.modelData = modelData;
      }

      state.skipsDiagramUpdate = true;
    }
  );
};

export const selectors = {
  nodesMapAndArray: (state) => ({
    nodes: state.keyMap.nodes,
    nodeDataArray: state.nodeDataArray,
  }),
  nodes: (state) => state.keyMap.nodes,
};
