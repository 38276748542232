/**
 * @file
 *
 * The file handles the queryClient configuration for react-query
 */
import { QueryClient } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

import { CONFIG } from './config';
import { createIdbPersistor } from './createIdbPersistor';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      cacheTime: 1000 * 60 * 60 * 1, // 1 hour
      staleTime: Infinity,
    },
  },
});

const idbPersistor = createIdbPersistor();

persistQueryClient({
  queryClient,
  buster: CONFIG.RELEASE,
  persistor: idbPersistor,
});
