import { Badge, Box, Button, Stack } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { MdCheck } from 'react-icons/md';
import shallowEqual from 'shallowequal';

import { useDisclosure } from '../../hooks/useDisclosure';
import { useReportViewState, reportViewStateHandlers } from '../../state/reportView';
import { hintTexts, relativeDateTimeOptions } from '../../odata/queryBuilder';
import { getMaxDate, getMinDate } from '../../components/EffectiveRangeOptions';
import { DatePicker } from '../../components/FormComponents/DatePicker';
import { checkIsVariableValid } from '../../hooks/useDateTimePicker';
import PopoverMenu from './PopoverMenu';

const entityStateSelector = (state) => state.fork.entity.value;

const effectiveRangeSelector = (state) => ({
  ...state.fork.effectiveRange,
  queryKey: state.fork.queryKey,
});
export function EffectiveRangeOption({ disabled }) {
  const ref = useRef();

  const { isOpen, open, close } = useDisclosure();

  const selectedEntity = useReportViewState(entityStateSelector);
  const { asOfDate, fromDate, toDate, queryKey, balanceAsOf } = useReportViewState(
    effectiveRangeSelector,
    shallowEqual
  );

  const isValueSelected = asOfDate || fromDate || toDate || balanceAsOf;

  return (
    <>
      <Badge
        badgeContent={<MdCheck />}
        color="primary"
        invisible={!isValueSelected}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBadge-badge': {
            height: '20px',
            width: '20px',
            p: 0,
            top: 5,
            left: 5,
          },
          ml: 1,
        }}
      >
        <Button
          ref={ref}
          onClick={open}
          size="small"
          variant="outlined"
          color="primary"
          disabled={!selectedEntity || disabled}
        >
          Effective Range
        </Button>
      </Badge>
      <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current}>
        <Box minWidth={680} maxHeight={300} pr={2}>
          <EffectiveRangeFields
            selectedEntity={selectedEntity}
            balanceAsOf={balanceAsOf}
            asOfDate={asOfDate}
            fromDate={fromDate}
            toDate={toDate}
            queryKey={queryKey}
          />
        </Box>
      </PopoverMenu>
    </>
  );
}

function EffectiveRangeFields({
  asOfDate,
  fromDate,
  toDate,
  queryKey,
  balanceAsOf,
  selectedEntity,
}) {
  const {
    handleAsOfDateChange,
    handleFromDateChange,
    handleToDateChange,
    handleBalanceAsOfChange,
  } = reportViewStateHandlers;

  const getOptionDisabled = useCallback(
    (field) => (option) => {
      if (option.key === relativeDateTimeOptions.CUSTOM.key) {
        return false;
      }

      if (field === 'fromDate') {
        const _fromDate = option.getDateObj();

        if (toDate) {
          // If the toDate is a dynamic varilable, we can access the getDateObj function to get the date object,
          if (checkIsVariableValid(toDate?.key)) {
            const _toDate = toDate.getDateObj();

            return _fromDate >= _toDate;
          } else {
            return _fromDate >= toDate;
          }
        }
      }

      if (field === 'toDate') {
        const _toDate = option.getDateObj();

        if (fromDate) {
          // If the fromDate is a dynamic varilable, we can access the getDateObj function to get the date object,
          if (checkIsVariableValid(fromDate?.key)) {
            const _fromDate = fromDate.getDateObj();

            return _toDate <= _fromDate;
          } else {
            return _toDate <= fromDate;
          }
        }
      }
    },
    [fromDate, toDate]
  );

  const disableAsOfDate = Boolean(toDate) || Boolean(fromDate);
  const disableFromToDate = Boolean(asOfDate);

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1}>
        <DatePicker
          isMUI5={true}
          label="As of Date"
          clearable
          {...hintTexts.date}
          disabled={disableAsOfDate}
          value={asOfDate}
          onChange={handleAsOfDateChange}
        />
        <DatePicker
          isMUI5={true}
          label="From Date"
          clearable
          {...hintTexts.date}
          disabled={disableFromToDate}
          maxDate={getMaxDate(toDate)}
          value={fromDate}
          onChange={handleFromDateChange}
          getOptionDisabled={getOptionDisabled('fromDate')}
        />
        <DatePicker
          isMUI5={true}
          label="To Date"
          clearable
          {...hintTexts.date}
          disabled={disableFromToDate}
          minDate={getMinDate(fromDate)}
          value={toDate}
          onChange={handleToDateChange}
          getOptionDisabled={getOptionDisabled('toDate')}
        />
      </Stack>
      <Stack width="240px">
        {selectedEntity.name === 'EmpTimeAccountBalance' && (
          <DatePicker
            isMUI5={true}
            label="Balance As Of Date"
            clearable
            {...hintTexts.date}
            value={balanceAsOf}
            onChange={handleBalanceAsOfChange}
          />
        )}
      </Stack>
    </Stack>
  );
}
