/**
 * @file
 *
 * this hooks can be used to maintain state for any side panel
 */

import { useState, useCallback } from 'react';

import { tourSteps, useTourState } from '../components/Onboarding/Tour';

export const useSidePanel = (defaultStatus, updateCanvasSizing) => {
  const [isDrawerOpen, updateDrawerState] = useState(() => (defaultStatus ? true : false));

  const toggleDrawer = useCallback(() => {
    const { currentStepId } = useTourState.get();

    if (isDrawerOpen) {
      if (
        currentStepId === tourSteps['bookmark-panel-btn'].id ||
        currentStepId === tourSteps['bookmark-panel'].id
      ) {
        tourSteps['add-bookmark-btn'].next();
      }
    } else {
      if (currentStepId === tourSteps['side-panel-btn'].id) {
        tourSteps['side-panel-btn'].next();
      }
    }

    updateDrawerState((state) => !state);
  }, [updateDrawerState, isDrawerOpen]);

  return { isDrawerOpen, toggleDrawer };
};
