import React, { useCallback, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { TiPin } from 'react-icons/ti';
import { MdVpnKey, MdEdit } from 'react-icons/md';

import usePin from './usePin';

export default function HeaderCell({
  column,
  sx,
  setMassUpdateDialogState,
  isEditable,
  setMassUpdateDialogInformation,
  picklistFetcher,
}) {
  const [showPin, setShowPin] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setShowPin(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowPin(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setMassUpdateDialogInformation(column);
    setMassUpdateDialogState(true);
    if (column.picklist && column.upsertable) {
      picklistFetcher(column.picklist);
    }
  }, [column, picklistFetcher, setMassUpdateDialogInformation, setMassUpdateDialogState]);

  return (
    <>
      <Stack
        height="100%"
        width="100%"
        justifyContent="space-between"
        textAlign={column.name === 'Expand' ? 'center' : null}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={sx}
      >
        {column.parent && (
          <Box p={1} height="50%" alignItems="center" borderBottom="1px solid #ddd">
            <Typography fontWeight={600}>{column.parent}</Typography>
          </Box>
        )}
        <Stack
          direction="row"
          height={column.parent ? '50%' : '100%'}
          alignItems="center"
          p={1}
          spacing={1}
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>{column.label}</Typography>
            {column.isKey ? <MdVpnKey /> : <PinButton showPin={showPin} column={column} />}
          </Stack>
          <Stack>
            {isEditable && (
              <IconButton size="small" onClick={handleEditButtonClick}>
                <MdEdit />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
const PinButton = ({ column, showPin }) => {
  const isPinned = column.frozen;
  const [onPin, onUnpin] = usePin({ columnKey: column.key });

  return (
    (isPinned || showPin) && (
      <IconButton size="small" onClick={isPinned ? onUnpin : onPin}>
        <TiPin />
      </IconButton>
    )
  );
};
