/**
 * @file
 *
 * This file contains the component for the log panel
 */
import {
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { entries, groupBy } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import {
  MdBookmark,
  MdBookmarkBorder,
  MdChevronRight,
  MdContentCopy,
  MdExpandMore,
  MdShare,
} from 'react-icons/md';
import { usePrevious } from 'react-use';

import { FEATURE_CODES, useBillingUsage } from '../data/billingUsage';
import { useCopy } from '../hooks/useCopy';
import { useShareableUrl } from '../state/queryBuilder';
import {
  checkBookmarkExists,
  getBillingExpiryStatus,
  strip$formatAnd$inlinecountFromUrl,
} from '../utils';
import { NoLogsImage } from './Illustrations/NoLogs';
import { ErrorImage } from './Illustrations/Error';
import { Skeleton } from '@material-ui/lab';
import { getKeyRefBasedQueryUrlRegex } from '../odata/queryStringParser';
import { useUser } from 'data/user';
import { LOG_TYPE } from './ReportViewComponents/constants';

export const useBookmarkAndLogsStyles = makeStyles((theme) => ({
  container: {
    borderBottom: theme.borders[0],
  },
  queryContent: {
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  floatingBtns: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(0.5),
  },
  noDataContainer: {
    marginTop: theme.spacing(4),
  },
  noDataText: {
    fontSize: theme.spacing(2),
    color: theme.palette.grey[400],
    paddingBottom: theme.spacing(1),
  },
  textWrapper: {
    maxWidth: '100%',
    overflowWrap: 'break-word',
  },
  addPadding: {
    padding: theme.spacing(1),
  },
  expandIcon: {
    margin: theme.spacing(0.5, 0.5, 0),
    color: theme.palette.text.secondary,
  },
  disabledIcons: {
    color: theme.palette.grey[400],
  },
  expandClickArea: {
    // TODO: refactor the !important away later
    textDecoration: 'none !important',
    cursor: 'pointer',
  },
  menuItemIcon: {
    minWidth: theme.spacing(3),
  },
  errorText: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
}));

const useLogStyles = makeStyles((theme) => ({
  groupName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  logUrl: {
    padding: theme.spacing(1, 3.3),
    fontSize: theme.spacing(1.5),
    cursor: 'pointer',
  },
  copyBtn: {
    display: 'none',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(0.5),
  },
  logChip: {
    margin: theme.spacing(-0.5, 3.3, 1.5),
    height: theme.spacing(1.9),
    width: theme.spacing(11.75),
    fontSize: theme.spacing(1),
    padding: 0,
  },
  logChipLabel: {
    padding: theme.spacing(0, 0.125),
  },
}));

function useLogsExpand(logData) {
  const dates = useMemo(
    // This logic groups all the logs according to the date of creation
    () => entries(groupBy(logData, (log) => dayjs(log.created_at).format('MMMM DD'))),
    [logData]
  );

  // if the dates array is not empty, grab the latest date.
  const firstDate = useMemo(() => dates?.[0]?.[0], [dates]);
  const prevFirstDate = usePrevious(firstDate);

  const [expanded, setExpanded] = useState(() => ({
    // this sets the first log-group to expanded by default
    ...(Boolean(firstDate) && { [firstDate]: true }),
  }));

  useEffect(() => {
    // checks if the firstDate and previousDate are same
    // if not set the first date to true so that the first log group is expanded by default when a new query is made
    if (firstDate && prevFirstDate !== firstDate) {
      setExpanded((prevState) => ({ ...prevState, [firstDate]: true }));
    }
  }, [firstDate, prevFirstDate]);

  const toggleExpand = useCallback(
    (id) => {
      setExpanded((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    },
    [setExpanded]
  );

  return {
    dates,
    expanded,
    toggleExpand,
  };
}

function Log({
  log,
  populateQueryBuilder,
  bookmarkData,
  isBookmarkLoading,
  bookmarkError,
  openCreateBookmarkDialog,
  baseEndpoint,
}) {
  const classes = useBookmarkAndLogsStyles();
  const logStyles = useLogStyles();

  const copyToClipboard = useCopy();
  const { getShareableUrl } = useShareableUrl();

  const { data: billingUsage, isFeatureQuotaExhausted } = useBillingUsage(
    FEATURE_CODES.BOOKMARK_CREATE_COUNT,
    bookmarkData
  );

  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

  const handleLinkClick = useCallback(() => {
    populateQueryBuilder(log.query_string);
  }, [populateQueryBuilder, log.query_string]);

  const strippedQueryString = strip$formatAnd$inlinecountFromUrl(log.query_string);

  const regexParsedResult = getKeyRefBasedQueryUrlRegex(strippedQueryString, baseEndpoint);

  return (
    <>
      <Grid item container direction="row" className={classes.queryContent}>
        <Grid item className={classes.textWrapper}>
          <Link
            component={Typography}
            className={logStyles.logUrl}
            color="textSecondary"
            onClick={() => {
              handleLinkClick(log.query_string);
            }}
          >
            {strippedQueryString}
          </Link>
          {regexParsedResult && (
            <Grid item container justifyContent="flex-start">
              <Chip
                component="p"
                size="small"
                variant="outlined"
                color="primary"
                label="includes navigations"
                classes={{ labelSmall: logStyles.logChipLabel }}
                className={logStyles.logChip}
              />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <PopupState variant="popover" popupId={`log-action-${log.query_log_id}-menu`}>
            {(popupState) => (
              <>
                <Tooltip title="Log Menu">
                  <IconButton
                    data-icon="log-menu"
                    size="small"
                    disableRipple
                    className={classes.floatingBtns}
                    {...bindTrigger(popupState)}
                  >
                    <FiMoreVertical />
                  </IconButton>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      copyToClipboard(strippedQueryString);
                    }}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <MdContentCopy fontSize="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Copy Query URL" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      copyToClipboard(getShareableUrl(strippedQueryString));
                    }}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <MdShare fontSize="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Copy Shareable URL" />
                  </MenuItem>
                  {!isBookmarkLoading &&
                    !bookmarkError &&
                    (checkBookmarkExists(bookmarkData, log.query_string) ? (
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          openCreateBookmarkDialog(log.query_string);
                        }}
                        disabled={true}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          <MdBookmark fontSize="18px" />
                        </ListItemIcon>
                        <ListItemText primary="Bookmarked" />
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          openCreateBookmarkDialog(log.query_string);
                        }}
                        disabled={isFeatureQuotaExhausted || hasPlanExpired}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          <MdBookmarkBorder fontSize="18px" />
                        </ListItemIcon>
                        <ListItemText primary="Add to Bookmarks" />
                      </MenuItem>
                    ))}
                </Menu>
              </>
            )}
          </PopupState>
        </Grid>
      </Grid>
    </>
  );
}

function LogGroup({
  date,
  logs,
  populateQueryBuilder,
  isExpanded,
  toggleExpand,
  bookmarkData,
  isBookmarkLoading,
  bookmarkError,
  openCreateBookmarkDialog,
  baseEndpoint,
}) {
  const classes = useBookmarkAndLogsStyles();
  const logStyles = useLogStyles();

  const handleExpand = useCallback(() => {
    toggleExpand(date);
  }, [toggleExpand, date]);

  return (
    <Grid item container direction="column" className={classes.container}>
      <Grid
        component={Link}
        onClick={handleExpand}
        item
        container
        alignItems="center"
        className={clsx(classes.addPadding, classes.expandClickArea)}
      >
        <Grid item className={classes.expandIcon}>
          {isExpanded ? <MdExpandMore fontSize={18} /> : <MdChevronRight fontSize={18} />}
        </Grid>
        <Grid item className={classes.textWrapper}>
          <Typography color="textSecondary" className={logStyles.groupName}>
            {date}
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={isExpanded} style={{ width: '100%' }}>
        {logs.map((log) => (
          <Log
            key={log.query_log_id}
            log={log}
            populateQueryBuilder={populateQueryBuilder}
            bookmarkData={bookmarkData}
            isBookmarkLoading={isBookmarkLoading}
            bookmarkError={bookmarkError}
            openCreateBookmarkDialog={openCreateBookmarkDialog}
            baseEndpoint={baseEndpoint}
          />
        ))}
      </Collapse>
    </Grid>
  );
}

const getLogErrorText = ({ error, hasPlanExpired }) => {
  if (error?.msg) {
    return error.msg;
  } else if (hasPlanExpired) {
    return 'Plan has expired for the tenant.';
  }

  return 'Failed to fetch Logs';
};

export function LogPanel({
  logData,
  isLogLoading,
  logError,
  isBookmarkLoading,
  bookmarkError,
  populateQueryBuilder,
  bookmarkData,
  openCreateBookmarkDialog,
  baseEndpoint,
}) {
  const [logsByCurrentUser, setLogsByCurrentUser] = useState([]);

  const classes = useBookmarkAndLogsStyles();

  const { data: billingUsage } = useBillingUsage();
  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);
  const user = useUser();

  useEffect(() => {
    if (!logData || !Array.isArray(logData) || !logData.length || !user) {
      return;
    }

    const filteredLogs = logData.filter(
      ({ created_by, type }) => type === LOG_TYPE.QUERY_VIEW && created_by === user.user_id
    );

    setLogsByCurrentUser(filteredLogs);
  }, [logData, user]);

  const { dates, expanded, toggleExpand } = useLogsExpand(logsByCurrentUser);

  if (isLogLoading && !hasPlanExpired) {
    return <SkeletonLoader />;
  } else if (logError || hasPlanExpired) {
    return (
      <Grid container direction="column">
        <Grid item container justifyContent="center">
          <ErrorImage width="180px" height="200px" />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography
            className={clsx(classes.noDataText, {
              [classes.errorText]: Boolean(logError || hasPlanExpired),
            })}
          >
            {getLogErrorText({ error: logError, hasPlanExpired })}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (!logsByCurrentUser || logsByCurrentUser.length === 0) {
    return (
      <Grid container direction="column" className={classes.noDataContainer}>
        <Grid item container justifyContent="center">
          <NoLogsImage width="180px" height="180px" />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography className={classes.noDataText}>No Logs</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      {dates.map(([date, logs]) => (
        <LogGroup
          key={date}
          date={date}
          logs={logs}
          isExpanded={expanded[date]}
          toggleExpand={toggleExpand}
          populateQueryBuilder={populateQueryBuilder}
          bookmarkData={bookmarkData}
          isBookmarkLoading={isBookmarkLoading}
          bookmarkError={bookmarkError}
          openCreateBookmarkDialog={openCreateBookmarkDialog}
          baseEndpoint={baseEndpoint}
        />
      ))}
    </Grid>
  );
}

const useSkeletonStyles = makeStyles((theme) => ({
  skeleton: {
    padding: theme.spacing(0, 3),
  },
}));

export function SkeletonLoader() {
  const classes = useSkeletonStyles();

  return (
    <Grid container direction="column" justifyContent="center">
      {Array(12)
        .fill()
        .map((_, index) => (
          <Grid item key={index} className={classes.skeleton}>
            <Skeleton width="100%" height={50} />
          </Grid>
        ))}
    </Grid>
  );
}
