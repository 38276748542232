/**
 * @fileoverview DataTableLoader component.
 *
 * Renders a skeleton loader for any table.
 */

import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export default function DataTableLoader({ rowCount, columnCount }) {
  return (
    <Stack height="100%" width="100%" justifyContent="flex-start" spacing={2} pt={2}>
      {Array(rowCount)
        .fill(0)
        .map((_, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={1.5}>
            {Array(columnCount)
              .fill(0)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={`calc(100% / ${columnCount})`}
                  height={45}
                  sx={{ borderRadius: 2 }}
                />
              ))}
          </Stack>
        ))}
    </Stack>
  );
}
