import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BiBookmark } from 'react-icons/bi';

import {
  dialogDescriptionId,
  dialogTitleId,
  validationSchema,
} from 'components/CreateBookmarkDialog';
import { useCreateBookmarkMutation } from 'data/queriesAndBookmarks';
import { useTenantState } from 'data/user';
import { commonTextFieldProps } from 'mui5Theme';
import { checkBookmarkExists } from 'utils';
import { useDisclosure } from 'hooks/useDisclosure';
import { BOOKMARK_TYPE } from './constants';
import { MoveBookmarkField } from './BookmarkPanel';

export default function BookmarkButton({
  reportViewBookmarks,
  isLoadingData,
  queryUrl,
  openCreateBookmarkDialog,
}) {
  const bookmarkExists = useMemo(
    () => checkBookmarkExists(reportViewBookmarks, queryUrl),
    [queryUrl, reportViewBookmarks]
  );

  return (
    <>
      <Tooltip title={bookmarkExists ? 'Bookmark already exists' : 'Add to Bookmarks'}>
        <span>
          <IconButton
            disabled={isLoadingData || bookmarkExists}
            size="small"
            onClick={() => openCreateBookmarkDialog(queryUrl)}
          >
            <BiBookmark fontSize="18px" />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}

export function useCreateBookmark() {
  const [bookmarkUrl, setBookmarkUrl] = useState('');

  const { isOpen, open, close } = useDisclosure();

  const handleOpen = useCallback(
    (url) => {
      open();
      setBookmarkUrl(url);
    },
    [open]
  );

  return { bookmarkUrl, isOpen, open: handleOpen, close };
}

export function BookmarkCreationDialog({
  isDialogOpen,
  closeDialog,
  bookmarkUrl,
  reportViewBookmarks,
}) {
  const params = useParams();
  const tenant = useTenantState();

  const { createBookmarkMutation } = useCreateBookmarkMutation();

  const { id: defaultCollectionId } = reportViewBookmarks.find(
    (collection) => collection.is_default
  );

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { name: '', description: '', collectionId: defaultCollectionId },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await createBookmarkMutation.mutateAsync({
          formData: {
            ...data,
            type: BOOKMARK_TYPE.REPORT_VIEW,
          },
          tenant,
          connectionId: params.connectionId,
        });
        closeDialog();
      } finally {
      }
    },
    [closeDialog, createBookmarkMutation, params.connectionId, tenant]
  );

  const decodedBookmarkUrl = decodeURIComponent(bookmarkUrl);

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id={dialogTitleId}>Add Bookmark</DialogTitle>
        <DialogContent id={dialogDescriptionId}>
          <TextField
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            label="Bookmark URL"
            name="url"
            helperText={' '}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            value={decodedBookmarkUrl}
          />
          <TextField
            label="Bookmark Title"
            placeholder="Favourite Query"
            name="name"
            helperText={errors.name ? errors.name.message : ' '}
            error={Boolean(errors.name)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={createBookmarkMutation.isLoading}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            label="Bookmark Description"
            placeholder="This is my favorite query"
            name="description"
            helperText={errors.description ? errors.description.message : ' '}
            error={Boolean(errors.description)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={createBookmarkMutation.isLoading}
            inputProps={{ maxLength: 255 }}
          />
          <MoveBookmarkField
            collectionsData={reportViewBookmarks}
            isCreateFlow={true}
            {...register('collectionId')}
            name="collectionId"
            setValue={setValue}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            disabled={createBookmarkMutation.isLoading}
            onClick={closeDialog}
          >
            Close
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={!isValid || createBookmarkMutation.isLoading}
            type="submit"
            startIcon={createBookmarkMutation.isLoading ? <CircularProgress size={15} /> : null}
          >
            {createBookmarkMutation.isLoading ? 'Adding' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
