/**
 * @file
 *
 * this file contains the table component responsible for displaying the records returned from the OData service
 */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Box,
  Typography,
  Toolbar,
  Tooltip,
  useTheme,
  CircularProgress,
  TableSortLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  MdContentCopy,
  MdCheck,
  MdExpandMore,
  MdCloudDownload,
  MdLabel,
  MdLabelOutline,
  MdVpnKey,
  MdVisibility,
  MdRefresh,
} from 'react-icons/md';
import { CgToggleSquare, CgToggleSquareOff } from 'react-icons/cg';
import { useCopyToClipboard } from 'react-use';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { keys, entries, isEmpty, isPlainObject } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { createStore } from '@halka/state';
import sortArray from 'sort-array';

import { formatEdmDateTime, formatEdmTime } from '../date';
import { ODATA_DATA_TYPES, flattenEntityType, flattenRecords } from '../odata/utils';
import { DatatableLoader } from './Loaders/ConnectionPageLoader';
import { dataflowApiBase, getServiceInstance } from '../service';
import { useTenantState } from '../data/user';
import { tourSteps } from './Onboarding/Tour';
import { ColumnarView, useColumnarView, useColumnarViewState } from './ColumnarView';
import { API_ERROR_TYPES } from '../constants';
import { AccessDeniedImage } from './Illustrations/AccessDenied';
import useExportData from 'hooks/useExportData';

const useEntityLabel = createStore(false);
const useShowNavProps = createStore(true);

export const FormattedFieldValue = ({ value, column }) => {
  const [, copyToClipboard] = useCopyToClipboard();

  const { type, format } = column;

  switch (type) {
    case ODATA_DATA_TYPES['Edm.Binary']:
      return (
        <>
          Binary data{' '}
          <IconButton
            size="small"
            onClick={() => {
              copyToClipboard(value);
            }}
          >
            <MdContentCopy />
          </IconButton>
        </>
      );

    case ODATA_DATA_TYPES['Edm.DateTimeOffset']:
    case ODATA_DATA_TYPES['Edm.DateTime']: {
      return value ? formatEdmDateTime({ dateTime: value, skipTime: format === 'Date' }) : '';
    }

    case ODATA_DATA_TYPES['Edm.Time']:
      return value ? formatEdmTime({ time: value }) : '';

    default:
      return String(value);
  }
};

function usePagination() {
  const [page, updatePage] = useState(0);
  const [rowsPerPage, updateRowsPerPage] = useState(10);

  const onPageChange = useCallback(
    (event, newPage) => {
      updatePage(newPage);
    },
    [updatePage]
  );

  const onRowsPerPageChange = useCallback(
    (event) => {
      updateRowsPerPage(parseInt(event.target.value, 10));
      updatePage(0);
    },
    [updatePage]
  );

  return { page, onPageChange, rowsPerPage, onRowsPerPageChange };
}

const useNestedTableStyles = makeStyles((theme) => ({
  table: {
    height: '100%',
    overflowX: 'auto',
  },
  tableHeadCell: {
    background: theme.palette.background.navBar,
    border: 'none',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
  tableBodyCell: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  primaryKeyIcon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(0.5),
  },
  primaryKeyName: {
    color: theme.palette.common.white,
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

function DataTableErrorMessage({ data }) {
  if (!data.loading) {
    if (data.error) {
      if (data.error?.error?.message?.value) {
        return <Typography>{data.error.error.message.value}</Typography>;
      } else {
        return <Typography>{data.error}</Typography>;
      }
    } else if (data.value && data.value.records.length === 0) {
      return <Typography>No data exists for this relation!</Typography>;
    }

    return null;
  }

  return null;
}

function NestedTable({ propertyName, data, schema }) {
  const classes = useNestedTableStyles();

  const showEntityLabel = useEntityLabel();
  const showNavProps = useShowNavProps();

  const { openColumnarViewDialog } = useColumnarView(data.value?.records, data.value?.columns);

  const { page, rowsPerPage, onPageChange, onRowsPerPageChange } = usePagination();

  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div">
        {propertyName}
      </Typography>
      {data.loading && (
        <DatatableLoader size="small" rowsCount={3} disablePagination disableTitle darkHeader />
      )}
      <DataTableErrorMessage data={data} />
      {!data.loading && data.value && data.value.records.length > 0 && (
        <Paper variant="outlined">
          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                  {data.value.columns.map(({ label, name, isKey }, index) => (
                    <TableCell className={classes.tableHeadCell} key={index}>
                      {isKey ? (
                        <TableSortLabel
                          active={true}
                          direction="desc"
                          IconComponent={() => (
                            <MdVpnKey fontSize={16} className={classes.primaryKeyIcon} />
                          )}
                        >
                          <span className={classes.primaryKeyName}>
                            {getColumnHeader(showEntityLabel, label, name)}
                          </span>
                        </TableSortLabel>
                      ) : (
                        getColumnHeader(showEntityLabel, label, name)
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.value.records
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((record, index) => (
                    <ExpandableTableRow
                      key={index}
                      index={index}
                      record={record}
                      columns={data.value.columns}
                      schema={schema}
                      openColumnarViewDialog={openColumnarViewDialog}
                      showNavProps={showNavProps}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {data.value.records.length > 5 && (
            <TablePagination
              className={classes.tablePagination}
              size="small"
              component="div"
              rowsPerPageOptions={[5, 10, 20]}
              count={data.value.records.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          )}
        </Paper>
      )}
    </Box>
  );
}

function flattenNavPropertyResponseValues(value, schema) {
  const defaultValue = {
    records: [],
    columns: [],
  };

  let baseTypeName;
  if (isEmpty(value) || !(baseTypeName = value[0].__metadata?.type)) {
    return defaultValue;
  }

  // the response from the OData service only has the schema namespace and type name for an entity
  // we need to grab the type description (with properties and relations aka navProperties) from our parsed metadata
  const baseType = schema.entityTypesMap[baseTypeName];
  // we are checking if it exists because not all endpoint strictly return only valid navProperties
  // we need to ignore the property if not a base entity type
  if (!baseType) {
    return defaultValue;
  }

  // flatten the complex types on the entity so that we can render the complex types in the table
  const entityType = flattenEntityType(baseType, schema.complexTypesMap);

  const { records } = flattenRecords(value, baseType);

  const columns = records?.[0]
    ? entityType.property.filter((property) => records?.[0]?.[property.name] !== undefined)
    : entityType.property;

  const columnsSnapshot = [...columns];
  sortArray(columnsSnapshot, {
    by: 'isKey',
    order: 'isKey',
    customOrders: {
      isKey: [true, false],
    },
  });

  return {
    // set the table headers based on the flattened entity type
    columns: columnsSnapshot,
    // set the table row values by flattening the response
    records,
  };
}

function useExpandableNavProperty({ record, schema, isTopLevel }) {
  const params = useParams();
  const queryClient = useQueryClient();

  const tenant = useTenantState();
  const expandableProperties = useMemo(() => keys(record.navProperties ?? {}), [record]);

  const getInitialState = useCallback(() => {
    return entries(record.navProperties).reduce((state, [propertyName, value]) => {
      if (isPlainObject(value) && value.__deferred) {
        if (value.error) {
          state[propertyName] = {
            expanded: true,
            loading: false,
            error: value.error,
            url: value.__deferred.uri,
          };
        } else {
          state[propertyName] = {
            expanded: false,
            loading: false,
            error: null,
            url: value.__deferred.uri,
          };
        }
      }

      if (Array.isArray(value)) {
        state[propertyName] = {
          expanded: isTopLevel,
          loading: false,
          error: null,
          value: flattenNavPropertyResponseValues(value, schema),
        };
      }

      return state;
    }, {});
  }, [isTopLevel, record.navProperties, schema]);

  const [expandablePropertiesState, updateExpandableProperties] = useState(getInitialState());

  useEffect(() => {
    updateExpandableProperties(getInitialState());
  }, [getInitialState]);

  const toggleExpand = useCallback(
    (propertyName) => {
      updateExpandableProperties((prevState) => {
        const propertyStateSlice = prevState[propertyName];
        if (propertyStateSlice.expanded) {
          return {
            ...prevState,
            [propertyName]: {
              ...propertyStateSlice,
              expanded: false,
            },
          };
        }

        if (propertyStateSlice?.value) {
          return {
            ...prevState,
            [propertyName]: {
              ...propertyStateSlice,
              expanded: true,
            },
          };
        } else if (propertyStateSlice?.url) {
          getServiceInstance(tenant?.tenant_id)
            .post(
              `${dataflowApiBase}/connection/${params.connectionId}/proxy`,
              { url: `${propertyStateSlice.url}?$format=json` },
              { responseType: 'json' }
            )
            .then((response) => {
              let value = response.d;
              if (!Array.isArray(value)) {
                if (value.results) {
                  value = value.results;
                } else {
                  value = [value];
                }
              }

              updateExpandableProperties((_prevState) => ({
                ..._prevState,
                [propertyName]: {
                  ..._prevState[propertyName],
                  loading: false,
                  error: null,
                  value: flattenNavPropertyResponseValues(value, schema),
                },
              }));
            })
            .catch((error) => {
              updateExpandableProperties((_prevState) => ({
                ..._prevState,
                [propertyName]: {
                  ...prevState[propertyName],
                  loading: false,
                  expanded: true,
                  error,
                },
              }));
            })
            .finally(() => {
              queryClient.invalidateQueries([
                `/connection/${params.connectionId}/log`,
                tenant?.tenant_id,
              ]);
            });

          return {
            ...prevState,
            [propertyName]: {
              ...propertyStateSlice,
              expanded: true,
              loading: true,
              error: null,
            },
          };
        }
      });
    },
    [schema, params, tenant, queryClient]
  );

  return { expandableProperties, expandablePropertiesState, toggleExpand };
}

const useExpandableTableRowStyles = makeStyles((theme) => ({
  tableBodyCell: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  expandedRow: {
    backgroundColor: theme.palette.background.default,
  },
  keyIcon: {
    marginLeft: theme.spacing(2),
  },
  actionColumn: {
    minWidth: theme.spacing(12.5),
  },
}));

function ExpandableTableRow({
  index,
  columns,
  record,
  schema,
  isTopLevel,
  openColumnarViewDialog,
  showNavProps,
}) {
  const classes = useExpandableTableRowStyles();

  const { expandableProperties, expandablePropertiesState, toggleExpand } =
    useExpandableNavProperty({ record, schema, isTopLevel });

  const handleColumnarViewOpen = (index) => () => {
    openColumnarViewDialog(index);
  };

  return (
    <>
      <TableRow hover>
        <TableCell key={`expand-menu-${index}`} className={classes.actionColumn}>
          <PopupState variant="popover" popupId={`expand-menu-${index}`}>
            {(popupState) => (
              <>
                <Tooltip title="Expand">
                  <span>
                    <IconButton
                      size="small"
                      disabled={!expandableProperties.length}
                      {...bindTrigger(popupState)}
                    >
                      <MdExpandMore />
                    </IconButton>
                  </span>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  {expandableProperties.map((propertyName) => (
                    <MenuItem
                      key={propertyName}
                      onClick={() => {
                        popupState.close();
                        toggleExpand(propertyName);
                      }}
                    >
                      {expandablePropertiesState[propertyName]?.expanded && <MdCheck />}{' '}
                      {propertyName}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </PopupState>
          <Tooltip title="Columnar View">
            <IconButton
              size="small"
              onClick={handleColumnarViewOpen(index)}
              className={classes.keyIcon}
            >
              <MdVisibility />
            </IconButton>
          </Tooltip>
        </TableCell>

        {columns.map((column) => {
          const value = record[column.name];

          return (
            <TableCell key={`${column.name}-index`} className={classes.tableBodyCell}>
              <FormattedFieldValue value={value ?? ''} column={column} />
            </TableCell>
          );
        })}
      </TableRow>
      {expandableProperties.map(
        (propertyName) =>
          expandablePropertiesState[propertyName].expanded &&
          showNavProps && (
            <TableRow key={propertyName} className={classes.expandedRow}>
              <TableCell colSpan={columns.length + 1}>
                <Collapse
                  in={expandablePropertiesState[propertyName].expanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <NestedTable
                    propertyName={propertyName}
                    data={expandablePropertiesState[propertyName]}
                    schema={schema}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
          )
      )}
    </>
  );
}

const getSuccessAlertContent = (queryData) => {
  if (queryData.value.inlineCount) {
    return `Successfully fetched ${queryData.value.records.length} out of total 
    ${queryData.value.inlineCount} records!`;
  } else {
    if (queryData.value.records.length === 1) {
      return `Successfully fetched ${queryData.value.records.length} record!`;
    }

    return `Successfully fetched ${queryData.value.records.length} records!`;
  }
};

const useRootTableStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    maxHeight: 'calc(100vh - 210px)',
  },
  table: {
    width: '100vw',
    height: '100%',
    overflowX: 'auto',
  },
  tableToolbar: {
    background: theme.palette.background.navBar,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    position: 'sticky',
    top: '92px',
    zIndex: 1,
  },
  tableTitle: {
    flex: '1 1 100%',
  },
  tableHeadRow: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  tableHeadCell: {
    color: theme.palette.text.primary,
    border: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
  primaryKeyIcon: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(0.5),
  },
  primaryKeyName: {
    color: theme.palette.text.primary,
  },
  error: {
    padding: theme.spacing(2),
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    overflowX: 'auto',
    boxSizing: 'content-box',
  },
  tablePagination: {
    width: '100vw',
  },
  successAlertMessage: {
    width: '100%',
    paddingRight: theme.spacing(1),
  },
}));

const getColumnHeader = (showEntityLabel, label, name) => {
  if (showEntityLabel) {
    return label;
  }
  return name;
};

export function DataTable({ queryData, schema, connectionName, fetchQueriedData }) {
  const classes = useRootTableStyles();
  const theme = useTheme();

  const showEntityLabel = useEntityLabel();
  const showNavProps = useShowNavProps();

  const selectedEntitySet = useMemo(
    () =>
      queryData?.value?.records?.length
        ? schema.entitySetMap[queryData.value.records[0].__metadata.type]
        : null,
    [schema, queryData]
  );

  const { openColumnarViewDialog, closeColumnarViewDialog, goToNextPage, goToPreviousPage } =
    useColumnarView(queryData.value?.records, queryData.value?.columns);
  const { isOpen: isColumnarViewOpen } = useColumnarViewState();

  const { page, rowsPerPage, onPageChange, onRowsPerPageChange } = usePagination();

  const { handleExportCSV, isExporting } = useExportData({
    schema,
    selectedEntityType: selectedEntitySet?.entityType,
    ...(queryData.value ?? { records: [], columns: [] }),
    connectionName,
  });

  let status = null;
  if (queryData.loading) {
    status = <Alert severity="info">Fetching data for the query</Alert>;
  } else if (queryData.error) {
    if (queryData.error.type === API_ERROR_TYPES.VALIDATION_ERROR) {
      status = (
        <>
          <Alert data-tour-step={tourSteps['data-table'].id} severity="error">
            Failed to query the selected entity
          </Alert>
          <Grid
            direction="column"
            item
            container
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: 16 }}
          >
            <Grid item>
              <AccessDeniedImage height={250} width={250} />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Your user does not have necessary permissions to make this query. Kindly reach out
                to your SAP SuccessFactors administrator
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    } else {
      status = (
        <>
          <Alert data-tour-step={tourSteps['data-table'].id} severity="error">
            Failed to fetch the data. Please check the query!
          </Alert>
          <Grid item container justifyContent="center">
            <Grid item>
              <pre className={classes.error}>
                {typeof queryData.error === 'string'
                  ? queryData.error
                  : JSON.stringify(queryData.error, null, 2)}
              </pre>
            </Grid>
          </Grid>
        </>
      );
    }
  } else if (queryData.value && queryData.value?.records) {
    status = (
      <Alert
        data-tour-step={tourSteps['data-table'].id}
        severity="success"
        classes={{ message: classes.successAlertMessage }}
      >
        <Grid item container>
          {getSuccessAlertContent(queryData)}
        </Grid>
      </Alert>
    );
  }

  return (
    <>
      <Grid item container spacing={1} direction="column">
        {status}
        {queryData.loading && <DatatableLoader />}
        {!queryData.loading && queryData.value?.records?.length > 0 && (
          <Paper variant="outlined">
            <Toolbar className={classes.tableToolbar}>
              <Typography variant="h6" className={classes.tableTitle}>
                {(showEntityLabel ? selectedEntitySet?.label : selectedEntitySet?.name) ?? ''}
              </Typography>
              <Tooltip title="Refresh Data">
                <IconButton onClick={(event) => fetchQueriedData(event, false)}>
                  <MdRefresh color={theme.palette.primary.contrastText} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  showNavProps ? 'Hide Navigational Properties' : 'Show Navigational Properties'
                }
              >
                <IconButton
                  aria-label="toggle nav props"
                  onClick={() => {
                    useShowNavProps.set((flag) => !flag);
                  }}
                >
                  {showNavProps ? (
                    <CgToggleSquareOff color={theme.palette.primary.contrastText} />
                  ) : (
                    <CgToggleSquare color={theme.palette.primary.contrastText} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Toggle Metadata Labels">
                <IconButton
                  aria-label="toggle label"
                  onClick={() => {
                    useEntityLabel.set((flag) => !flag);
                  }}
                >
                  {showEntityLabel ? (
                    <MdLabel color={theme.palette.primary.contrastText} />
                  ) : (
                    <MdLabelOutline color={theme.palette.primary.contrastText} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Export to Excel">
                <IconButton aria-label="export" disabled={isExporting} onClick={handleExportCSV}>
                  {isExporting ? (
                    <CircularProgress />
                  ) : (
                    <MdCloudDownload color={theme.palette.primary.contrastText} />
                  )}
                </IconButton>
              </Tooltip>
            </Toolbar>
            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                    {queryData.value.columns.map(({ label, name, isKey }, index) => (
                      <TableCell
                        sortDirection={false}
                        className={classes.tableHeadCell}
                        key={index}
                      >
                        {isKey ? (
                          <TableSortLabel
                            active={true}
                            direction="desc"
                            IconComponent={() => (
                              <MdVpnKey fontSize={16} className={classes.primaryKeyIcon} />
                            )}
                          >
                            <span className={classes.primaryKeyName}>
                              {getColumnHeader(showEntityLabel, label, name)}
                            </span>
                          </TableSortLabel>
                        ) : (
                          getColumnHeader(showEntityLabel, label, name)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {queryData.value.records
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((record, index) => (
                      <ExpandableTableRow
                        key={index}
                        index={index}
                        record={record}
                        columns={queryData.value.columns}
                        schema={schema}
                        openColumnarViewDialog={openColumnarViewDialog}
                        showNavProps={showNavProps}
                        isTopLevel
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {queryData.value.records.length > 5 && (
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]}
                count={queryData.value.records.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            )}
          </Paper>
        )}
      </Grid>
      {isColumnarViewOpen && (
        <ColumnarView
          isOpen={isColumnarViewOpen}
          close={closeColumnarViewDialog}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}
        />
      )}
    </>
  );
}
