/**
 * @file
 *
 * This file contains the component that displays the schema visualizer
 *
 * SchemaVisualizer component renders everything related to the Canvas
 */
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import {
  Grid,
  makeStyles,
  Drawer,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  withStyles,
  InputAdornment,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { ReactDiagram } from 'gojs-react';
import { FixedSizeList } from 'react-window';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import clsx from 'clsx';
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs';
import { MdSearch, MdClose, MdRefresh } from 'react-icons/md';
import { isHotkey } from 'is-hotkey';
import { matchSorter } from 'match-sorter';
import { useUpdate } from 'react-use';
import * as go from 'gojs';

import { initDiagram } from '../models';
import { CustomFab } from '../pages/Connection';
import { useSidePanel } from '../hooks/useSidePanel';
import { OverviewDialog } from './OverviewDialog';
import { CANVAS, FILTER_TOGGLES } from '../constants';
import {
  resetSchemaVisualizer,
  useSchemaVisualizerState,
  handleModelChange,
  updateSchemaVisualizerState,
  selectors,
  inKeyMap,
  addToCanvas,
  removeFromCanvas,
  initialSchemaVisualizerState,
} from '../state/schemaVisualizer';
import { useQueryBuilderState } from '../state/queryBuilder';
import { autoCompleteFilterOptions, AutocompleteOption } from './FormComponents/AutocompleteOption';
import { Drawing } from './Illustrations/Drawing';
import { getRandomCoordinateInViewport } from '../utils';

const isSearchEvent = isHotkey('mod+f');
const drawerWidth = 38;
const drawerContentWidth = 34;
const LIST_WINDOWING_CONFIG = {
  HEIGHT_OFFSET: 240,
  ITEM_SIZE: 35,
  WIDTH: 270,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    margin: theme.spacing(10, 0, 0),
    backgroundColor: theme.palette.grey[200],
  },
  loader: {
    overflow: 'hidden',
    height: `calc(100vh - ${theme.spacing(10)}px)`,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    zIndex: theme.zIndex.modal + 10,
    margin: 0,
  },
  container: {
    height: '100%',
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    margin: theme.spacing(10, 0, 0),
    paddingTop: theme.spacing(4),
  },
  diagramWrapper: {
    width: '100%',
    padding: theme.spacing(5, 4, 4, 4),
    position: 'relative',
  },
  diagramContainer: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  floatingActionButton: {
    position: 'fixed',
    zIndex: theme.zIndex.modal - 1,
    boxShadow: theme.shadows[0],
    left: theme.spacing(1),
    top: theme.spacing(12.5),
  },
  panelOpen: {
    marginLeft: theme.spacing(drawerWidth),
  },
  searchBarOpen: {
    marginRight: theme.spacing(-1),
  },
  searchBar: {
    position: 'fixed',
    top: theme.spacing(16),
    right: theme.spacing(6),
    zIndex: theme.zIndex.modal - 1,
  },
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.modal + 1,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  searchBox: {
    width: theme.spacing(25),
    display: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  showSearchBox: {
    display: 'block',
  },
  contentExpandedLeft: {
    paddingLeft: theme.spacing(drawerWidth + 2),
  },
}));

const CustomIconButton = withStyles((theme) => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(0.5),
  },
}))(IconButton);

/**
 * @param {go.Diagram} diagram
 */
const useCanvasActions = (diagram) => {
  const handleIncreaseZoom = useCallback(() => {
    diagram.commandHandler.increaseZoom();
  }, [diagram]);

  const handleDecreaseZoom = useCallback(() => {
    diagram.commandHandler.decreaseZoom();
  }, [diagram]);

  const handleZoomToFit = useCallback(() => {
    diagram.zoomToFit();
  }, [diagram]);

  const handleResetZoom = useCallback(() => {
    diagram.commandHandler.resetZoom();
  }, [diagram]);

  const handleFindNodeOnCanvas = useCallback(
    (event, value) => {
      const part = diagram.findNodeForKey(value?.key);

      if (part) {
        diagram.select(part);
        diagram.commandHandler.resetZoom(1);
        diagram.position = part
          .getDocumentPoint(go.Spot.TopCenter)
          .subtract(new go.Point(500, 250));
      }
    },
    [diagram]
  );

  const updateCanvasSizing = useCallback(() => {
    // run the side effects in the next tick
    setTimeout(() => {
      // imperitavely request the diagram canvas to update since the parent DOM has changed dimensions
      diagram.requestUpdate();

      // bring the diagram to focus for better UX
      diagram.focus();
    }, 0);
  }, [diagram]);

  return {
    handleIncreaseZoom,
    handleDecreaseZoom,
    handleZoomToFit,
    handleResetZoom,
    handleFindNodeOnCanvas,
    updateCanvasSizing,
  };
};

// Custom hook for updating the dimension as the window dimensions change
const useWindowDimenstions = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleDimensions = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleDimensions);
    return () => window.removeEventListener('resize', handleDimensions);
  }, []);

  return dimensions;
};

const useDiagram = () => {
  const diagramRef = useRef(null);

  const update = useUpdate();
  useEffect(() => {
    if (diagramRef.current) {
      /**
       * @type {go.Diagram}
       */
      const diagram = diagramRef.current.getDiagram();

      diagramRef.current = diagram;

      update();
    }
    // eslint-disable-next-line
  }, []);

  return diagramRef;
};

const checkIfNodeInCanvas = (nodes, entity) => inKeyMap(nodes, entity.entityType);

/**
 * Component to render the list of entities in the virtualized list
 *
 * @param {object} props
 * @param {object} props.metadata
 * @param {number} props.index
 * @param {object[]} props.data
 * @param {go.Diagram} props.diagram
 */
const EntityListItem = React.memo(({ metadata, index, style, data, nodes, diagram }) => {
  const isNodeInCanvas = useMemo(
    () => checkIfNodeInCanvas(nodes, data[index]),
    [data, index, nodes]
  );

  const totalNodes = useMemo(() => Object.keys(nodes)?.length, [nodes]);

  const updateCanvas = (event) => {
    const entityName = event.target.name;

    const { linkDataArray, propertyLinks, nodeDataArray, keyMap } = useSchemaVisualizerState.get();

    // we are re-calculating isNodeInCanvas based on prevState
    // to have idiomatic functional state updates
    const isNodeInCanvas = checkIfNodeInCanvas(keyMap.nodes, data[index]);

    if (!isNodeInCanvas) {
      const spawnLocation = getRandomCoordinateInViewport(diagram);
      const toBeAdded = addToCanvas({
        diagram,
        entityName,
        metadata,
        keyMap,
        linkDataArray,
        location: spawnLocation,
      });

      if (toBeAdded) {
        updateSchemaVisualizerState((prevState) => {
          prevState.propertyLinks.push(...toBeAdded.propertyLinks);
        });

        diagram.commit(({ model }) => {
          model.addNodeDataCollection(toBeAdded.nodeToBeAdded);
          model.addLinkDataCollection(toBeAdded.linksToBeAdded);
        }, 'add node and links');
      }
    } else {
      const toBeRemoved = removeFromCanvas({
        diagram,
        entityName,
        nodeDataArray,
        nodes: keyMap.nodes,
        linkDataArray,
        propertyLinks,
      });

      if (toBeRemoved) {
        if (toBeRemoved.propertyLinksToBeRemoved.size) {
          updateSchemaVisualizerState((prevState) => {
            prevState.propertyLinks = prevState.propertyLinks.filter(
              ({ key }) => !toBeRemoved.propertyLinksToBeRemoved.has(key)
            );
          });
        }

        diagram.commit((d) => {
          d.removeParts([...toBeRemoved.nodesToBeRemoved].map((key) => d.findNodeForKey(key)));
        }, 'remove nodes');
      }
    }
  };

  return (
    <div style={style}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={totalNodes >= CANVAS.MAX_NODE_COUNT && !isNodeInCanvas}
            disableRipple
            checked={isNodeInCanvas}
            onChange={updateCanvas}
            name={data[index].entityType}
          />
        }
        label={data[index].name}
      />
    </div>
  );
});

const getEntityKey = (entity) => (entity ? `${entity.namespace}.${entity.name}` : '');
/**
 *
 * @param {object} metadata
 * @param {go.Diagram} diagram
 */
const useInitializeSchemaVisualizer = (metadata, diagram, updateDiagramInstance) => {
  // If the canvas is empty then the root entity selected in the query builder will get added in
  // the canvas and all the relations aswell
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    updateDiagramInstance(diagram);
  }, [diagram, updateDiagramInstance]);

  useEffect(() => {
    const {
      entity: { value: selectedEntity },
    } = useQueryBuilderState.get();
    const selectedEntityKey = getEntityKey(selectedEntity);

    if (diagram) {
      const schemaVisualizerState = useSchemaVisualizerState.get();

      if (selectedEntity && selectedEntityKey !== schemaVisualizerState.lastSelectedEntityName) {
        const key = `${selectedEntity.namespace}.${selectedEntity.name}`;

        const fromData = addToCanvas({
          entityName: key,
          metadata,
          keyMap: initialSchemaVisualizerState.keyMap,
        });

        let { keyMap } = fromData;

        const linksOriginatingFromSelectedEntity = fromData.linksToBeAdded;

        // Initializing the node counter
        let nodeCount = 1;

        // We are getting the data of all the entities that are linked to the selected entity
        const toData = linksOriginatingFromSelectedEntity.reduce(
          (modelData, link) => {
            if (link.category === 'entityTypeLink') {
              if (nodeCount < CANVAS.MAX_NODE_COUNT) {
                if (link.to) {
                  const toBeAdded = addToCanvas({
                    entityName: link.to,
                    metadata,
                    keyMap,
                    lazyLinkAddition: true,
                  });

                  if (toBeAdded) {
                    // Increment the node counter
                    nodeCount++;

                    keyMap = toBeAdded.keyMap;

                    modelData.nodeToBeAdded.push(...toBeAdded.nodeToBeAdded);
                    modelData.linkToBeAdded.push(...toBeAdded.linksToBeAdded);
                    modelData.propertyLinks.push(...toBeAdded.propertyLinks);
                  }
                }

                if (link.from) {
                  const toBeAdded = addToCanvas({
                    entityName: link.from,
                    metadata,
                    keyMap,
                    lazyLinkAddition: true,
                  });

                  if (toBeAdded) {
                    // Increment the node counter
                    nodeCount++;

                    keyMap = toBeAdded.keyMap;

                    modelData.nodeToBeAdded.push(...toBeAdded.nodeToBeAdded);
                    modelData.linkToBeAdded.push(...toBeAdded.linksToBeAdded);
                    modelData.propertyLinks.push(...toBeAdded.propertyLinks);
                  }
                }
              }
            }

            return modelData;
          },
          { nodeToBeAdded: [], linkToBeAdded: [], propertyLinks: [] }
        );

        // Some links might be same in both fromData and toData and we don't want redudant links in the canvas so we
        const linksToBeAdded = [...fromData.linksToBeAdded, ...toData.linkToBeAdded];
        const nodesToBeAdded = [...fromData.nodeToBeAdded, ...toData.nodeToBeAdded];

        linksToBeAdded.forEach(({ from, fromPort, to, toPort }) => {
          const fromNode = nodesToBeAdded.find(({ key }) => key === from);
          const toNode = nodesToBeAdded.find(({ key }) => key === to);

          if (fromNode) {
            if (fromNode.navProperties) {
              fromNode.navProperties = fromNode.navProperties.map((prop) => ({
                ...prop,
                ...(prop.name === fromPort && {
                  isAssociatedEntityVisible: true,
                }),
              }));
            }
          }

          if (toNode) {
            if (toNode.navProperties) {
              toNode.navProperties = toNode.navProperties.map((prop) => ({
                ...prop,
                ...(prop.name === toPort && {
                  isAssociatedEntityVisible: true,
                }),
              }));
            }
          }
        });

        useSchemaVisualizerState.set({
          ...initialSchemaVisualizerState,
          propertyLinks: [...fromData.propertyLinks, ...toData.propertyLinks],
          lastSelectedEntityName: selectedEntityKey,
        });

        diagram.commit(({ model }) => {
          model.addNodeDataCollection(nodesToBeAdded);
          model.addLinkDataCollection(linksToBeAdded);
        }, 'added initial nodes and links');

        setTimeout(() => {
          const mainNode = diagram.findNodeForKey(selectedEntityKey);

          if (mainNode) {
            diagram.select(mainNode);
            diagram.zoomPoint = diagram.transformDocToView(
              mainNode.getDocumentPoint(go.Spot.TopCenter)
            );

            diagram.commandHandler.resetZoom(1);
          }

          setLoading(false);
        });
      } else if (schemaVisualizerState.nodeDataArray.length) {
        updateSchemaVisualizerState((state) => {
          state.skipsDiagramUpdate = false;
          if (selectedEntityKey) {
            state.lastSelectedEntityName = selectedEntityKey;
          }
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [metadata, diagram]);

  return loading;
};

function useDiagramSearch() {
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);

  const openSearchBox = useCallback(() => {
    setIsSearchBoxOpen((prevState) => !prevState);
  }, [setIsSearchBoxOpen]);

  const searchButtonRef = useRef(null);
  const searchBoxRef = useRef(null);
  useEffect(() => {
    const handleSearchShortcut = (event) => {
      if (isSearchEvent(event)) {
        event.preventDefault();

        const searchButton = searchButtonRef.current;
        const searchBox = searchBoxRef.current;

        if (searchButton && searchButton.getAttribute('disabled') !== true) {
          searchButton.click();
          if (searchBox) {
            searchBox.focus();
          }
        }
      }
    };

    window.addEventListener('keydown', handleSearchShortcut);
    return () => window.removeEventListener('keydown', handleSearchShortcut);
  }, []);

  return {
    isSearchBoxOpen,
    openSearchBox,
    searchButtonRef,
    searchBoxRef,
  };
}

function useDiagramEventListener(diagramRef, metadata) {
  useEffect(() => {
    if (diagramRef.current) {
      const diagram = diagramRef.current;

      diagram.addDiagramListener('ObjectSingleClicked', (event) => {
        const graphObject = event.subject;

        if (graphObject.name === 'NavPropertyToggle') {
          const spawnLocation = getRandomCoordinateInViewport(diagram);

          const navPropData = graphObject.panel?.panel?.panel?.panel?.data;
          const association = metadata.associationsMap[navPropData.relationship];
          const associatedEntity = association.end.find(({ role }) => role === navPropData.toRole);
          const associatedEntityName = associatedEntity.type;

          const { linkDataArray, nodeDataArray, keyMap, propertyLinks } =
            useSchemaVisualizerState.get();

          if (!navPropData.isAssociatedEntityVisible) {
            const toBeAdded = addToCanvas({
              diagram,
              entityName: associatedEntityName,
              metadata,
              nodeDataArray,
              linkDataArray,
              keyMap,
              location: spawnLocation,
            });

            if (toBeAdded) {
              // Update the diagram
              diagram.commit(({ model }) => {
                model.addNodeDataCollection(toBeAdded.nodeToBeAdded);
                model.addLinkDataCollection(toBeAdded.linksToBeAdded);
              });
            }
          } else {
            const toBeRemoved = removeFromCanvas({
              diagram,
              entityName: associatedEntityName,
              nodeDataArray,
              nodes: keyMap.nodes,
              linkDataArray,
              propertyLinks,
            });

            if (toBeRemoved) {
              if (toBeRemoved.propertyLinksToBeRemoved.size) {
                updateSchemaVisualizerState((prevState) => {
                  prevState.propertyLinks = prevState.propertyLinks.filter(
                    ({ key }) => !toBeRemoved.propertyLinksToBeRemoved.has(key)
                  );
                });
              }

              // Update the diagram
              diagram.commit((d) => {
                d.removeParts(
                  [...toBeRemoved.nodesToBeRemoved].map((key) => d.findNodeForKey(key))
                );
              }, 'remove nodes');
            }
          }
        }
      });
    }
  }, [diagramRef, metadata]);
}

export function SchemaVisualizer(props) {
  const classes = useStyles();

  const schemaVisualizerState = useSchemaVisualizerState();

  const diagramRef = useDiagram();

  const dimensions = useWindowDimenstions();

  const {
    handleIncreaseZoom,
    handleDecreaseZoom,
    handleZoomToFit,
    handleResetZoom,
    handleFindNodeOnCanvas,
    updateCanvasSizing,
  } = useCanvasActions(diagramRef.current);

  const { isDrawerOpen, toggleDrawer } = useSidePanel(true, updateCanvasSizing);

  const { isSearchBoxOpen, openSearchBox, searchButtonRef, searchBoxRef } = useDiagramSearch();

  const loading = useInitializeSchemaVisualizer(
    props.metadata,
    diagramRef.current,
    props.updateDiagramInstance
  );

  const entitiesInCanvas = useMemo(
    () => schemaVisualizerState.nodeDataArray.filter((node) => node.category === 'entityTypeNode'),
    [schemaVisualizerState.nodeDataArray]
  );

  useDiagramEventListener(diagramRef, props.metadata);

  return (
    <>
      {loading && (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="column"
          alignItems="center"
          className={classes.loader}
        >
          <Grid item>
            <Drawing height={224} width={330} />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              Preparing visualization
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container direction="row" className={classes.wrapper}>
        <CustomFab
          size="small"
          color="primary"
          className={clsx(classes.floatingActionButton, {
            [classes.panelOpen]: isDrawerOpen,
          })}
          onClick={toggleDrawer}
        >
          <BsReverseLayoutSidebarInsetReverse size="18px" />
        </CustomFab>
        <NodesPanel
          metadata={props.metadata}
          diagram={diagramRef.current}
          entitiesInCanvas={entitiesInCanvas}
          isDrawerOpen={isDrawerOpen}
          windowHeight={dimensions.height}
        />

        <Grid
          item
          container
          className={clsx(classes.diagramWrapper, { [classes.contentExpandedLeft]: isDrawerOpen })}
        >
          <ReactDiagram
            ref={diagramRef}
            divClassName={classes.diagramContainer}
            initDiagram={initDiagram}
            nodeDataArray={schemaVisualizerState.nodeDataArray}
            linkDataArray={schemaVisualizerState.linkDataArray}
            modelData={schemaVisualizerState.modelData}
            onModelChange={handleModelChange}
            skipsDiagramUpdate={schemaVisualizerState.skipsDiagramUpdate}
          />
          <OverviewDialog
            diagram={diagramRef.current}
            handleIncreaseZoom={handleIncreaseZoom}
            handleDecreaseZoom={handleDecreaseZoom}
            handleZoomToFit={handleZoomToFit}
            handleResetZoom={handleResetZoom}
            height={dimensions.height}
            width={dimensions.width}
          />

          <Grid item className={classes.searchBar}>
            <Grid container alignItems="center">
              <CustomIconButton
                color="secondary"
                ref={searchButtonRef}
                disabled={!schemaVisualizerState.nodeDataArray.length}
                className={clsx(classes.searchButton, {
                  [classes.searchBarOpen]: isSearchBoxOpen,
                })}
                onClick={openSearchBox}
              >
                <MdSearch size="20px" />
              </CustomIconButton>
              <Autocomplete
                fullWidth
                size="small"
                autoHighlight
                autoComplete
                openOnFocus
                filterOptions={autoCompleteFilterOptions}
                className={clsx(classes.searchBox, {
                  [classes.showSearchBox]:
                    isSearchBoxOpen && schemaVisualizerState.nodeDataArray.length,
                })}
                options={entitiesInCanvas}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.key === value.key}
                onChange={handleFindNodeOnCanvas}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search a Node"
                    variant="outlined"
                    inputRef={searchBoxRef}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                renderOption={(option, { inputValue }) => (
                  <AutocompleteOption
                    label={option.label}
                    name={option.name}
                    inputValue={inputValue}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useNodePanelsStyles = makeStyles((theme) => ({
  drawerPaper: {
    overflow: 'hidden',
    position: 'fixed',
    height: `calc(100% - ${theme.spacing(11)}px)`,
    top: theme.spacing(11.5),
    width: theme.spacing(drawerWidth),
    padding: theme.spacing(2),
  },
  listSearchBox: {
    width: theme.spacing(drawerContentWidth),
    marginBottom: theme.spacing(1),
  },
  searchBoxIcons: {
    fontSize: theme.spacing(2.5),
    color: theme.palette.grey[500],
  },
  helperText: {
    fontSize: theme.spacing(1.8),
    color: theme.palette.grey[600],
  },
  helperTextError: {
    fontSize: theme.spacing(1.8),
    color: theme.palette.error.main,
  },
  toggleButtonGroup: {
    width: theme.spacing(drawerContentWidth),
    height: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  toggleButton: {
    width: '100%',
    fontSize: 10,
    fontWeight: 600,
    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const useNodesPanelSearch = (entityArray) => {
  const [searchedEntity, setSearchedEntity] = useState(() => ({
    searchedItem: '',
    visibleItems: [],
  }));

  const updateSearchedEntity = useCallback(
    (event) => {
      const value = event.target.value;

      setSearchedEntity({
        searchedItem: value,
        // move this logic to worker if any performance issue arises in future
        visibleItems: matchSorter(entityArray, value, { keys: ['name'] }),
      });
    },
    [setSearchedEntity, entityArray]
  );

  const resetSearchedEntity = useCallback(() => {
    setSearchedEntity({
      searchedItem: '',
      visibleItems: [],
    });
  }, [setSearchedEntity]);

  return {
    searchedEntity,
    searchInputHandlers: {
      onChange: updateSearchedEntity,
      value: searchedEntity.searchedItem,
    },
    resetSearchedEntity,
  };
};

const useFilter = (entityArray, searchedEntity) => {
  const [filter, setFilter] = useState(FILTER_TOGGLES.ALL);

  const nodes = useSchemaVisualizerState(selectors.nodes);

  const updateFilter = useCallback((event, filterOption) => {
    setFilter(filterOption);
  }, []);

  const filteredList = useMemo(() => {
    const items = searchedEntity.searchedItem ? searchedEntity.visibleItems : entityArray;

    if (filter === FILTER_TOGGLES.SELECTED) {
      return items.filter(({ entityType }) => inKeyMap(nodes, entityType));
    } else if (filter === FILTER_TOGGLES.UNSELECTED) {
      return items.filter(({ entityType }) => !inKeyMap(nodes, entityType));
    }

    return items;
  }, [entityArray, filter, nodes, searchedEntity]);

  return { filter, updateFilter, filteredList };
};

const NodesPanel = React.memo(
  ({ metadata, entitiesInCanvas, diagram, isDrawerOpen, windowHeight }) => {
    const classes = useNodePanelsStyles();

    const { nodeDataArray, nodes } = useSchemaVisualizerState(selectors.nodesMapAndArray);

    const { searchedEntity, searchInputHandlers, resetSearchedEntity } = useNodesPanelSearch(
      metadata.entitySet
    );

    const { filter, updateFilter, filteredList } = useFilter(metadata.entitySet, searchedEntity);

    // Function to conditionally render the node selection based data
    const helperText = () => {
      if (entitiesInCanvas.length >= CANVAS.MAX_NODE_COUNT) {
        return (
          <Typography className={classes.helperTextError}>Maximum node limit reached</Typography>
        );
      } else if (entitiesInCanvas.length > 1 && entitiesInCanvas.length < CANVAS.MAX_NODE_COUNT) {
        return (
          <Typography className={classes.helperText}>
            {entitiesInCanvas.length} nodes added
          </Typography>
        );
      } else if (entitiesInCanvas.length === 1) {
        return (
          <Typography className={classes.helperText}>
            {entitiesInCanvas.length} node added
          </Typography>
        );
      }
      return <Typography className={classes.helperText}>No nodes added</Typography>;
    };

    return (
      <Drawer
        anchor="left"
        variant="persistent"
        open={isDrawerOpen}
        elevation={0}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid container item>
          <Grid container item>
            <ToggleButtonGroup
              size="small"
              value={filter}
              exclusive
              onChange={updateFilter}
              aria-label="text alignment"
              className={classes.toggleButtonGroup}
            >
              <ToggleButton
                className={classes.toggleButton}
                value={FILTER_TOGGLES.ALL}
                aria-label="all nodes"
              >
                All
              </ToggleButton>
              <ToggleButton
                className={classes.toggleButton}
                value={FILTER_TOGGLES.SELECTED}
                aria-label="selected nodes"
              >
                Selected
              </ToggleButton>
              <ToggleButton
                className={classes.toggleButton}
                value={FILTER_TOGGLES.UNSELECTED}
                aria-label="unselected nodes"
              >
                Unselected
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search Entity"
              className={classes.listSearchBox}
              {...searchInputHandlers}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch className={classes.searchBoxIcons} />
                  </InputAdornment>
                ),
                endAdornment: searchedEntity.searchedItem && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={resetSearchedEntity}>
                      <MdClose className={classes.searchBoxIcons} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>{helperText()}</Grid>
            {Boolean(nodeDataArray.length) && (
              <Grid item>
                <Tooltip title="Reset Canvas">
                  <span>
                    <IconButton size="small" color="primary" onClick={resetSchemaVisualizer}>
                      <MdRefresh size="20px" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <FixedSizeList
              height={windowHeight - LIST_WINDOWING_CONFIG.HEIGHT_OFFSET}
              itemCount={filteredList.length}
              itemSize={LIST_WINDOWING_CONFIG.ITEM_SIZE}
              width={LIST_WINDOWING_CONFIG.WIDTH}
              itemData={filteredList}
            >
              {(listItemProps) => (
                <EntityListItem
                  {...listItemProps}
                  nodes={nodes}
                  diagram={diagram}
                  metadata={metadata}
                />
              )}
            </FixedSizeList>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
);
