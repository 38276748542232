/**
 * @file
 *
 * React hook to create a new export job
 */

import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Button } from '@mui/material';
import { useLocalStorage } from 'react-use';

import { useTenantState } from 'data/user';
import { useCreateJobMutation } from 'data/jobs';
import { SHEET_NAMING_CONVENTION } from 'components/ReportViewComponents/constants';
import { LOCAL_STORAGE_KEYS } from '../constants';

/**
 * Custom React Hook for creating an export job with specific configurations.
 *
 * @typedef {Object} HookReturnObject
 * @property {boolean} isCreatingJob - A boolean value indicating whether a job is currently being created.
 * @property {string} sheetNameAccessor - The sheet name preference of the users for the export job.
 * @property {boolean} showExternalCodes - A boolean value indicating whether the users want external codes in their exported file.
 * @property {function} handleCreateJob - A function to initiate the process of creating an export job with the specified configurations.
 * @property {function} handleSheetNameChange - A callback function to handle changes in the sheet name preference.
 * @property {function} handleShowExternalCodes - A callback function to handle changes in the preference for showing external codes.
 *
 * @param {function} closeDialog - A function to close the current dialog.
 * @param {function} openDialog - A function to open the job dialog
 * @param {function} openDrawer - A function that opens the report view drawer
 * @param {function} updateDrawerTabIndex - A function that updates the active tab in the drawer
 * @returns {HookReturnObject} - An object containing the state variables and callback functions for the export job.
 */
export function useCreateExportJob(
  openDialog,
  openDrawer,
  updateDrawerTabIndex,
  isRetriggerFlow = false,
  toggleJobType,
  showExpiredJobs
) {
  const [queryUrl, setQueryUrl] = useState('');
  const [isCreatingJob, setIsCreatingJob] = useState(false);
  const [sheetNameAccessor, setSheetNameAccessor] = useLocalStorage(
    LOCAL_STORAGE_KEYS.EXPORT_FLOW_PERSONALISATIONS.SHEET_NAMING_CONVENTION,
    SHEET_NAMING_CONVENTION.TECHNICAL
  ); // To set the sheet name preference of the users
  const [showPicklistValues, setShowPicklistValues] = useLocalStorage(
    LOCAL_STORAGE_KEYS.EXPORT_FLOW_PERSONALISATIONS.VIEW_PICKLIST_VALUES,
    true
  ); // To check if the users want external codes in their exported fle
  const [filePassword, setFilePassword] = useState(null);
  const [randomizationConfig, setRandomizationConfig] = useState([]);

  const { createJobMutation } = useCreateJobMutation();
  const tenant = useTenantState();
  const { connectionId } = useParams();

  const handleOpenExportJobDialog = useCallback(
    (url) => {
      setQueryUrl(url);
      openDialog();
    },
    [openDialog]
  );

  const handleSheetNameChange = useCallback(
    (event) => {
      setSheetNameAccessor(event.target.value);
    },
    [setSheetNameAccessor]
  );

  const handleShowPicklistValues = useCallback(
    (eventOrBoolean) => {
      if (eventOrBoolean.target) {
        setShowPicklistValues(eventOrBoolean.target.value === 'true');
        return;
      }

      setShowPicklistValues(eventOrBoolean);
    },
    [setShowPicklistValues]
  );

  // setJobInfoForRetrigger is used to re-trigger an expired job using exisiting job data
  const setJobInfoForRetrigger = useCallback(
    (queryUrl, showPicklistValues, sheetNameAccessor) => {
      setQueryUrl(queryUrl);
      setShowPicklistValues(showPicklistValues);
      setSheetNameAccessor(sheetNameAccessor);
    },
    [setSheetNameAccessor, setShowPicklistValues]
  );

  const setRandomizationConfiguration = useCallback((dataForRandomization) => {
    setRandomizationConfig(dataForRandomization);
  }, []);

  const handleCreateJob = useCallback(async () => {
    setIsCreatingJob(true);

    await createJobMutation
      .mutateAsync({
        formData: {
          job_type: 'DATA_EXPORT',
          additional_info: {
            query_string: queryUrl,
            show_picklist_value: showPicklistValues,
            sheet_name_accessor: sheetNameAccessor,
            file_password: filePassword,
            randomization_fields: randomizationConfig,
          },
        },
        tenant,
        connectionId,
      })
      .then(() => {
        if (isRetriggerFlow && showExpiredJobs) {
          // toggle job type only when it's re-trigger flow of an expired job
          toggleJobType();
        }
        toast.success(
          (t) => (
            <span>
              Successfully created a job
              <Button
                size="small"
                onClick={() => {
                  openDrawer();
                  updateDrawerTabIndex(null, 2);
                  toast.dismiss(t.id);
                }}
                sx={{ ml: 1, height: 30 }}
              >
                Show
              </Button>
            </span>
          ),
          {
            duration: 6000,
            position: 'top-center',
          }
        );
      })
      .catch((err) => toast.error('Failed to create an export job'))
      .finally(() => {
        setFilePassword(null);
        setIsCreatingJob(false);
      });
  }, [
    connectionId,
    createJobMutation,
    filePassword,
    isRetriggerFlow,
    openDrawer,
    queryUrl,
    randomizationConfig,
    sheetNameAccessor,
    showExpiredJobs,
    showPicklistValues,
    tenant,
    toggleJobType,
    updateDrawerTabIndex,
  ]);

  return {
    sheetNameAccessor,
    showPicklistValues,
    handleCreateJob,
    handleSheetNameChange,
    handleShowPicklistValues,
    isCreatingJob,
    handleOpenExportJobDialog,
    queryUrlToExport: queryUrl,
    setFilePassword,
    setJobInfoForRetrigger,
    setRandomizationConfiguration,
  };
}
