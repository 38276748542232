/**
 * @file
 *
 * A custom hook to copy to clipboard
 */
import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useCopyToClipboard } from 'react-use';

export function useCopy(copyText) {
  const [state, copy] = useCopyToClipboard();

  useEffect(() => {
    if (state.error) {
      toast.error('Copy to clipboard failed!', {
        id: 'copy-shareable-query-error',
        duration: 3000,
      });
    } else if (state.value) {
      toast.success('Copied to clipboard', {
        duration: 3000,
      });
    }
  }, [state]);

  const copyToClipboard = useCallback(
    (_copyText) => {
      copy(_copyText && typeof _copyText === 'string' ? _copyText : copyText);
    },
    [copyText, copy]
  );
  return copyToClipboard;
}
