import { throttle } from 'lodash-es';
import { set, get, del } from 'idb-keyval';

export function createIdbPersistor({
  key = `REACT_QUERY_OFFLINE_CACHE`,
  throttleTime = 1000,
} = {}) {
  // cleanup localStorage
  if (window.localStorage) {
    window.localStorage.removeItem(key);
  }

  return {
    persistClient: throttle((persistedClient) => {
      // We want to skip caching the /whoAmI call
      // Reason: When a signed-up user enables trial for Dataflow and navigates to Dataflow from Plaftorm
      // they would get an unauthorized error since Dataflow was accessing the /whoAmI call which was cached
      // and no the updated one. The difference between the cached and updated /whoAmI call is that, the cached
      // one doesn't contain permissions for dataflow.
      const queries = persistedClient?.clientState?.queries ?? [];

      const filteredQueries = queries.filter((query) => {
        // A query can be either a string or an array. And hence we are checking if the queryKey is a string
        // before checking if it starts with /whoAmI
        if (typeof query.queryKey === 'string') {
          return !query.queryKey.startsWith('/whoAmI');
        } else if (Array.isArray(query.queryKey)) {
          const endpoint = query.queryKey[0];

          if (
            endpoint.endsWith('/usage') ||
            endpoint.startsWith('/user-system') ||
            endpoint.startsWith('/connection')
          ) {
            return false;
          }

          return true;
        }
        return true;
      });

      if (persistedClient?.clientState?.queries) {
        persistedClient.clientState.queries = filteredQueries;
      }

      // We are persisting the queryClient inside idb. The /whoAmI calls don't get persisted
      set(key, JSON.stringify(persistedClient));
    }, throttleTime),
    restoreClient: async () => {
      const cacheString = await get(key);

      if (!cacheString) {
        return;
      }

      return JSON.parse(cacheString);
    },
    removeClient: () => {
      del(key);
    },
  };
}
