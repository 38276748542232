/**
 * @file
 *
 * This file contains various miscellaneous utils related to odata
 */
import { isPlainObject, entries, omit, cloneDeep, keyBy, set, uniqBy, isEmpty } from 'lodash-es';

import { getServiceInstance, dataflowApiBase } from '../service';

/**
 * Data Types supported by OData (v2 for now)
 */
export const ODATA_DATA_TYPES = {
  'Edm.Binary': 'Edm.Binary',
  'Edm.Boolean': 'Edm.Boolean',
  'Edm.Byte': 'Edm.Byte',
  'Edm.DateTime': 'Edm.DateTime',
  'Edm.Decimal': 'Edm.Decimal',
  'Edm.Double': 'Edm.Double',
  'Edm.Guid': 'Edm.Guid',
  'Edm.Int16': 'Edm.Int16',
  'Edm.Int32': 'Edm.Int32',
  'Edm.Int64': 'Edm.Int64',
  'Edm.SByte': 'Edm.SByte',
  'Edm.Single': 'Edm.Single',
  'Edm.String': 'Edm.String',
  'Edm.Time': 'Edm.Time',
  'Edm.DateTimeOffset': 'Edm.DateTimeOffset',
};

/**
 * enumerations for different OData query builder form
 */
export const ENUMS = {
  COLUMN_SELECT_FLAG: {
    INCLUDE: 'include',
    EXCLUDE: 'exclude',
  },
  ORDER_BY_FLAG: {
    ASC: 'asc',
    DESC: 'desc',
  },
  DELIMITERS: {
    EXPAND: '/',
  },
};

/**
 * @function
 *
 * function to recursively fetch all the data by traversing the __next link sent back from the odata service
 *
 * @param {object} responseData         the initial response from the odata endpoint
 * @param {object[]} records            the list of records from the initial request to concat to
 * @param {string} connectionId         id of the connection
 * @param {string} tenantId             tenant id
 *
 * @returns {Promise<object[]>}                  all collected records
 */
export async function traversePaginatedQueryRequests(
  responseData,
  records,
  connectionId,
  tenantId,
  { isWorker = false, authToken } = {}
) {
  const _records = [...records, ...responseData.results];

  if (!responseData.__next) {
    return _records;
  }

  const response = await getServiceInstance(tenantId, { isWorker, authToken }).post(
    `${dataflowApiBase}/connection/${connectionId}/proxy`,
    {
      url: `${responseData.__next}&format=json`,
    }
  );

  if (Array.isArray(response.d)) {
    return [..._records, ...response.d];
  }
  return traversePaginatedQueryRequests(response.d, _records, connectionId, tenantId, {
    isWorker,
    authToken,
  });
}

/**
 * @function
 *
 * function to flatten the response for complex types
 *
 * @param {object[]} rawRecords       the list of records from the OData service
 * @param {object} baseEntityType     the selected entity base type
 *
 * @returns {object[]}                the flattened list of records
 */
export function flattenRecords(rawRecords, baseEntityType) {
  if (rawRecords.length === 0) {
    return { records: [], metadata: [] };
  }

  const propertiesMap = keyBy(baseEntityType.property, ({ name }) => name);
  const navigationPropertiesMap = keyBy(baseEntityType.navigationProperty, ({ name }) => name);

  /**
   * @function
   *
   * function to flatten an object (created to be used to flatten nested complex types in odata response)
   * it mutates the rootObject to add the key-value pairs from the obj object with keys prefixed with keyPrefix value
   *
   * @param {object} obj               the record object
   * @param {object} rootObject        the root object to add the flattened record object properties to
   * @param {string} [keyPrefix='']    prefix to add to key on the rootObject
   *
   */
  function flattenRecord(obj, rootObject, keyPrefix = '') {
    let primaryProperties = {};

    // The below forEach loop goes through the record and extracts out the externalCodes from all the expanded
    // navigational properties. The below code also pushes all the navigational properties into rootObject.navProperties object
    entries(obj).forEach(([key, val]) => {
      if (isPlainObject(val)) {
        if (val.__metadata) {
          if (propertiesMap[key]) {
            flattenRecord(omit(val, '__metadata'), rootObject, `${keyPrefix}${key}/`);
          } else if (keyPrefix === '') {
            set(rootObject, ['navProperties', key], [val]);
          }
        } else if (keyPrefix === '' && val.results && Array.isArray(val.results)) {
          set(rootObject, ['navProperties', key], val.results);
        }
        return;
      }

      if (keyPrefix === '' && Array.isArray(val)) {
        set(rootObject, ['navProperties', key], val);
        return;
      }

      primaryProperties[key] = val;
    });

    entries(primaryProperties).forEach(([key, val]) => {
      rootObject[`${keyPrefix}${key}`] = val;

      entries(rootObject.navProperties).forEach(([navPropKey, navPropValue]) => {
        const isNavPropExpanded = Array.isArray(navPropValue);

        const propertyPicklist = propertiesMap[key]?.picklist;
        const navigationPropertyPicklist = navigationPropertiesMap[navPropKey]?.picklist;

        // Check if both the property and navigationalProperty have a picklist value in them
        const isPicklistOption = Boolean(propertyPicklist && navigationPropertyPicklist);
        // Check if the picklist value of the property and the navigational property are the same
        const isRelatedToPrimaryProperty =
          isPicklistOption && propertyPicklist === navigationPropertyPicklist;

        if (isNavPropExpanded && isRelatedToPrimaryProperty && val === navPropValue[0]?.id) {
          const extCode = navPropValue[0]?.externalCode ?? '';
          rootObject[`${keyPrefix}${key}`] = `${val} (${extCode})`;
        }
      });
    });

    // add unexpanded navProperties as well
    if (keyPrefix === '') {
      // eslint-disable-next-line no-unused-expressions
      baseEntityType.navigationProperty?.forEach((navProp) => {
        const key = navProp.name;

        if (!rootObject?.navProperties?.[key]) {
          set(rootObject, ['navProperties', key], {
            __deferred: {
              uri: `${rootObject.__metadata.uri}/${navProp.name}`,
            },
            ...(obj[key] === null && { error: 'Unable to fetch the records' }),
          });
        }
      });
    }
  }

  const records = [];
  rawRecords.forEach((record) => {
    const flattenedRecord = { __metadata: record.__metadata };
    flattenRecord(omit(record, '__metadata'), flattenedRecord);

    records.push(flattenedRecord);
  });

  return { records };
}

/**
 * @function
 *
 * function to flatten the properties in an entityType
 *
 * @param {object} entityType           entityTypes from the metadata
 * @param {object} complexTypesMap      hashmap of the complex types from the metadata
 *
 * @returns {object}                    entityType with complex data types flattened out
 */
export function flattenEntityType(entityType, complexTypesMap) {
  if (isEmpty(complexTypesMap)) {
    return entityType;
  }

  const flattenedProperty = [];

  function flattenProperty(type, prefix = '') {
    type.property.forEach((prop) => {
      if (ODATA_DATA_TYPES[prop.type]) {
        flattenedProperty.push({ ...prop, name: `${prefix}${prop.name}` });
      } else if (complexTypesMap[prop.type]) {
        flattenProperty(complexTypesMap[prop.type], `${prefix}${prop.name}/`);
      }
    });
  }

  flattenProperty(entityType);

  const flattenedEntityType = cloneDeep(entityType);

  flattenedEntityType.property = flattenedProperty;

  return flattenedEntityType;
}

/**
 * @function
 *
 * function to get the original entity type from the metadata from a decorated entity type
 *
 * @param {object} entity           decorated / flattend entity type
 * @param {object} entityTypesMap   hashmap of entity types from the metadata
 */
export const getOriginalEntityType = (entity, entityTypesMap) =>
  entityTypesMap[`${entity?.namespace}.${entity?.name}`];

/**
 * @function
 *
 * helper function to get the entity set name from the entity type name and namespace value
 *
 * @param {object} param
 * @param {object} param.schema             parsed metadata schema object
 * @param {object} param.entity             entity type value
 *
 * @returns {string}                   entity set name
 */
export const getEntitySetName = ({ schema, entity }) =>
  schema.entitySetMap[`${entity.namespace}.${entity.name}`]?.name;

/**
 * @function
 *
 * helper function to get the entity from the set name
 *
 * @param {string} entitySetName      entity set name
 * @param {object} schema             parsed metadata schema object
 *
 * @returns {object} entity type
 */
export const getEntityTypeFromSetName = (entitySetName, schema) => {
  const set = schema.entitySet.find((_set) => _set.name === entitySetName);

  if (!set) {
    return null;
  }

  return schema.entityTypesMap[set.entityType];
};

/**
 * @function
 *
 * helper function to form OData Query URL
 *
 * @param {string} baseURL            base URL of the endpoint
 * @param {string} baseParams         mandatory parameters to be passed
 * @param {string} queryString        the query string formed by the query builder
 *
 * @returns {string}                  final URL
 */
export const formQueryURL = (baseURL, baseParams, queryString) => {
  if (queryString) {
    return `${baseURL}/${queryString}${baseParams ? `&${baseParams}` : ''}`;
  }

  return `${baseURL}${baseParams ? `?${baseParams}` : ''}`;
};

/**@function
 *
 * helper function to check if relation toRole multiplicity is supportable
 *
 * @param {object} toRole               the toRole end of a relation
 *
 * @returns {boolean}
 */
export const checkIfMultiplicitySupportable = (toRole) =>
  toRole?.multiplicity === '0..1' || toRole?.multiplicity === '1';

/**
 * @function
 *
 * function to compute the available valid expand options
 *
 * @param {object[]} options          all the current expanded options
 *
 * @returns {object[]}                valid expand options derived from current state
 */
export const dedupeAndFilterExpandOptions = (options) => uniqBy(options, 'relationship');

/**
 * @function
 *
 * function to find the toRole entity type for a navigation property association
 *
 * NOTE: this utility function is co-located with this hook
 * because it is not a generic utility and can only be used with the
 * structure used in this hook
 *
 * @param {object} selectedExpandableOption         expandable column option selected
 * @param {object} schema                           parsed metadata schema
 *
 * @returns {object}                                entityType from the schema
 */
export const getTargetEntityTypeFromExpandOption = (selectedExpandableOption, schema) => {
  const targetRole = selectedExpandableOption.association.end.find(
    (end) => end.role === selectedExpandableOption.toRole
  );

  return schema.entityTypesMap[targetRole.type];
};
