/**
 * @file
 *
 * this file contains the data fetching code for billing usage
 */

import { createStore } from '@halka/state';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useUpdateEffect } from 'react-use';
import { useQuery } from 'react-query';

import { CONFIG } from '../config';
import { API_ERROR_TYPES } from '../constants';
import { useNotifyError } from '../hooks/useNotifyError';
import { billingApiBase, getServiceInstance } from '../service';
import { useTenantState } from './user';

export const useQuotaUsageState = createStore({
  isDialogOpen: false,
});

export const billingPlanDisplayActions = {
  openDialog: () => {
    useQuotaUsageState.set({ isDialogOpen: true });
  },
  closeDialog: () => {
    useQuotaUsageState.set({ isDialogOpen: false });
  },
};

const billingUsageFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId)
    .get(`${billingApiBase}${endpoint}`)
    .then((response) => {
      return response?.find((app) => app.application_code === CONFIG.APP_NAME);
    });
};

export function useBillingUsage(featureCode, bookmarkData = []) {
  const tenant = useTenantState();
  const history = useHistory();

  const { data, error, isFetching, isLoading } = useQuery({
    queryKey: [`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id],
    queryFn: billingUsageFetcher,
    enabled: Boolean(tenant),
  });

  useUpdateEffect(() => {
    if (!isFetching) {
      if (!data) {
        history.push('/error', {
          errorType: API_ERROR_TYPES.BILLING_PLAN,
        });
      }
    }
  }, [data, tenant, isFetching]);

  useNotifyError({ error });

  return {
    error,
    isLoading,
    data,
    isFeatureQuotaExhausted: checkIfFeatureUsageExhausted(data, featureCode, bookmarkData),
  };
}

export const FEATURE_CODES = {
  BOOKMARK_CREATE_COUNT: 'BOOKMARK_CREATE_COUNT',
  CONNECTION_CREATE_COUNT: 'CONNECTION_CREATE_COUNT',
  MAX_LOG_RETENTION: 'MAX_LOG_RETENTION',
  BULK_EXPORT_DATA: 'BULK_EXPORT_DATA',
  REPORT_VIEW: 'REPORT_VIEW',
};

export const checkIfFeatureUsageExhausted = (billingUsage, featureCode, bookmarkData) => {
  // If billing usage is empty, then return false. Because if there is no billing usage present, there is nothing
  // exhaust, right?
  if (isEmpty(billingUsage)) {
    return false;
  }

  // Grab the feature data from billingUsage using the featureCode
  const selectedFeature = billingUsage.features?.find(
    (feature) => feature.feature_code === featureCode
  );
  // Check if the billing plan for selected feature is active, if it is not then return false
  if (selectedFeature?.is_active) {
    // If the selected feature is connection creation, then we check if the active acount of the feature is more
    // than or equal to the assigned quota. If yes, then we have disabled the feature and hence we return true
    if (selectedFeature.feature_code === FEATURE_CODES.CONNECTION_CREATE_COUNT) {
      if (selectedFeature.usage_details?.active_count >= selectedFeature.quota) {
        return true;
      }

      return false;
    }
    // If the selected feature is bookmark creation, then we check if the total no. of bookmarks is more than or equal to
    // the reference_count. If yes, then we have disabled the feature and hence we return true
    else if (selectedFeature.feature_code === FEATURE_CODES.BOOKMARK_CREATE_COUNT) {
      if (bookmarkData.length >= selectedFeature.reference_count) {
        return true;
      }

      return false;
    }

    // For all other features, if their is_active is true then we return false, because they don't have any other check,
    // and hence their accessibility depends on if the feature is active or not
    return false;
  }

  // If the selected feature is not active then the feature should be disabled and hence we return true
  return true;
};
