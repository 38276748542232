import React from 'react';
import Lottie from 'react-lottie-player';

import successAnimationData from '../../lotties/success.json';
import failureAnimationData from '../../lotties/failure.json';

export const VARIANTS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export function FileExportStateAnimation({ variant = VARIANTS.SUCCESS }) {
  return variant === VARIANTS.SUCCESS ? (
    <Lottie
      loop
      play
      animationData={successAnimationData}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      style={{ width: 100, height: 100 }}
    />
  ) : (
    <Lottie
      loop
      play
      animationData={failureAnimationData}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      style={{ width: 100, height: 100 }}
    />
  );
}
