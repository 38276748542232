/**
 * @file
 *
 * This component contains the Effective Range filter options for SuccessFactor APIs
 */
import React, { useCallback, useState } from 'react';
import { Grid, Typography, makeStyles, Tooltip, IconButton } from '@material-ui/core';
import { GrPowerReset } from 'react-icons/gr';
import shallowEqual from 'shallowequal';

import { useQueryBuilderState, effectiveRangeActions } from '../state/queryBuilder';
import { hintTexts, relativeDateTimeOptions } from '../odata/queryBuilder';
import { tourSteps } from './Onboarding/Tour';
import { DatePicker } from './FormComponents/DatePicker';
import { checkIsVariableValid } from 'hooks/useDateTimePicker';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(2),
  },
}));

export const getMaxDate = (toDate) => {
  if (!toDate) {
    return;
  }

  if (checkIsVariableValid(toDate.key)) {
    return relativeDateTimeOptions[toDate.key].getDateObj().subtract(1, 'day').toDate();
  } else {
    return toDate.subtract(1, 'day').toDate();
  }
};

export const getMinDate = (fromDate) => {
  if (!fromDate) {
    return;
  }

  if (checkIsVariableValid(fromDate.key)) {
    return relativeDateTimeOptions[fromDate.key].getDateObj().add(1, 'day').toDate();
  } else {
    return fromDate.add(1, 'day').toDate();
  }
};

const effectiveRangeSelector = (state) => ({ ...state.effectiveRange, queryKey: state.queryKey });
export function EffectiveRangeOptions() {
  const classes = useStyles();

  // This state is used to track whether the reset handler has been triggered or not. If the handler is triggered then the counter is incremented
  // and the DatePicker subscribes to this state and sets its inner state (which controls the textfield) to an empty string everytime the state changes.
  const [resetCounter, updateResetCounter] = useState(0);

  const handleReset = () => {
    updateResetCounter((prevState) => prevState + 1);
    effectiveRangeActions.reset();
  };

  const { asOfDate, fromDate, toDate, queryKey } = useQueryBuilderState(
    effectiveRangeSelector,
    shallowEqual
  );

  const getOptionDisabled = useCallback(
    (field) => (option) => {
      if (option.key === relativeDateTimeOptions.CUSTOM.key) {
        return false;
      }

      if (field === 'fromDate') {
        const _fromDate = option.getDateObj();

        if (toDate) {
          // If the toDate is a dynamic varilable, we can access the getDateObj function to get the date object,
          if (checkIsVariableValid(toDate?.key)) {
            const _toDate = toDate.getDateObj();

            return _fromDate >= _toDate;
          } else {
            return _fromDate >= toDate;
          }
        }
      }

      if (field === 'toDate') {
        const _toDate = option.getDateObj();

        if (fromDate) {
          // If the fromDate is a dynamic varilable, we can access the getDateObj function to get the date object,
          if (checkIsVariableValid(fromDate?.key)) {
            const _fromDate = fromDate.getDateObj();

            return _toDate <= _fromDate;
          } else {
            return _toDate <= fromDate;
          }
        }
      }
    },
    [fromDate, toDate]
  );

  const disableAsOfDate = Boolean(toDate) || Boolean(fromDate);
  const disableFromToDate = Boolean(asOfDate);

  return (
    <Grid item container spacing={2} data-tour-step={tourSteps['effective-range'].id}>
      <Grid item container justifyContent="flex-end" alignItems="flex-start" xs={2}>
        <Grid item>
          <Typography color="textPrimary" className={classes.topMargin} variant="subtitle1">
            Effective Range
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={10} alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <DatePicker
            key={queryKey + disableAsOfDate.toString()}
            label="As of Date"
            clearable
            {...hintTexts.date}
            disabled={disableAsOfDate}
            value={asOfDate}
            onChange={effectiveRangeActions.onAsOfDateChange}
            resetCounter={resetCounter}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            key={queryKey + disableFromToDate.toString()}
            label="From Date"
            clearable
            {...hintTexts.date}
            disabled={disableFromToDate}
            maxDate={getMaxDate(toDate)}
            value={fromDate}
            onChange={effectiveRangeActions.onFromDateChange}
            resetCounter={resetCounter}
            getOptionDisabled={getOptionDisabled('fromDate')}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            key={queryKey + disableFromToDate.toString()}
            label="To Date"
            clearable
            {...hintTexts.date}
            disabled={disableFromToDate}
            minDate={getMinDate(fromDate)}
            value={toDate}
            onChange={effectiveRangeActions.onToDateChange}
            resetCounter={resetCounter}
            getOptionDisabled={getOptionDisabled('toDate')}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Clear all Effective Range parameters">
            <IconButton size="small" onClick={handleReset}>
              <GrPowerReset />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
