/**
 * @file
 *
 * This SVG file is for drawing loader
 */
import * as React from 'react';

export function Drawing(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={756.96}
      height={473.36}
      viewBox="0 0 756.96 473.36"
      {...props}
    >
      <path
        d="M654.23 315.877a10.056 10.056 0 014.9-14.62l-1.422-35.707 15.897 9.598-1.088 32.436a10.11 10.11 0 01-18.287 8.293z"
        fill="#a0616a"
      />
      <path
        d="M675.447 292.73l-14.132 2.257a4.5 4.5 0 01-5.17-3.85l-5.98-45.03-29.544-40.432a13.58 13.58 0 013.652-19.453 13.579 13.579 0 0118.753 4.13L673.2 237.57l6.006 50.181a4.554 4.554 0 01.013.965 4.486 4.486 0 01-3.772 4.013z"
        fill="#3f3d56"
      />
      <path fill="#a0616a" d="M626.725 459.863h-12.26l-5.832-47.288 18.094.001-.002 47.287z" />
      <path
        d="M605.708 456.36h23.644v14.887H590.82a14.887 14.887 0 0114.887-14.887z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M678.45 438.879l-9.16 8.148-34.145-33.667 11.876-9.813 31.429 35.332z"
      />
      <path
        d="M660.42 450.23l17.665-15.714 9.894 11.123-28.788 25.609a14.887 14.887 0 011.228-21.018z"
        fill="#2f2e41"
      />
      <path
        d="M503.523 189.457a10.056 10.056 0 0112.324 9.267l34.328 9.93-14.129 12.05-30.432-11.277a10.11 10.11 0 01-2.09-19.97z"
        fill="#a0616a"
      />
      <circle cx={610.547} cy={130.628} r={24.561} fill="#a0616a" />
      <path
        d="M628.23 451.009l-17.064-1.366a4.497 4.497 0 01-4.139-4.337l-4.762-143.833a4.49 4.49 0 013.876-4.605l34.02-4.747a4.5 4.5 0 015.057 5.214L632.2 373.584a3.514 3.514 0 00-.05.634l.939 72.246a4.501 4.501 0 01-4.503 4.56 4.34 4.34 0 01-.356-.015z"
        fill="#2f2e41"
      />
      <path
        d="M650.084 433.758l-58.62-65.239a4.504 4.504 0 01-1.127-3.484l7.404-69.405a4.493 4.493 0 014.773-4.013l35.976 2.398a4.5 4.5 0 013.964 5.931l-21.555 63.768a3.487 3.487 0 00.797 3.55l49.792 51.604a4.5 4.5 0 01-.949 6.999l-14.819 8.757a4.51 4.51 0 01-5.636-.866z"
        fill="#2f2e41"
      />
      <path
        d="M606.145 170.36s15-13 22-6c0 0 32.5 20.5 18.5 76.5 0 0 12 54 7 56s-55.5 9.5-59.5 3.5-6-98 12-130z"
        fill="#3f3d56"
      />
      <path
        d="M518.783 216.9l2.322-14.122a4.5 4.5 0 015.287-3.69l44.613 8.551 47.694-15.26a13.58 13.58 0 0117.303 9.61 13.579 13.579 0 01-9.842 16.488l-54.332 13.714-49.508-10.152a4.554 4.554 0 01-.92-.293 4.486 4.486 0 01-2.617-4.846z"
        fill="#3f3d56"
      />
      <path
        d="M621.086 150.55c2.245-5.194 4.144-12.112-.16-15.785-2.498-2.13-6.095-2.134-9.366-2.4-9.218-.746-18.706-4.987-23.211-13.063s-1.99-20.084 6.51-23.728c5.73-2.456 12.298-.783 18.295.918l18.006 5.106c5.35 1.517 10.967 3.19 14.818 7.201 6.143 6.398 5.684 17.212.805 24.618s-12.226 15.131-20.638 17.943z"
        fill="#2f2e41"
      />
      <path
        d="M755.96 473.36h-248a1 1 0 010-2h248a1 1 0 010 2zM494 0H7a7.008 7.008 0 00-7 7v360a7.008 7.008 0 007 7h487a7.008 7.008 0 007-7V7a7.008 7.008 0 00-7-7zm5 367a5.002 5.002 0 01-5 5H7a5.002 5.002 0 01-5-5V7a5.002 5.002 0 015-5h487a5.002 5.002 0 015 5z"
        fill="#3f3d56"
      />
      <path fill="#3f3d56" d="M1 28.04h499v2H1z" />
      <circle cx={18} cy={15} r={6} fill="#3f3d56" />
      <circle cx={35.25} cy={15} r={6} fill="#3f3d56" />
      <circle cx={52.5} cy={15} r={6} fill="#3f3d56" />
      <path
        d="M301.823 311.305a1 1 0 01-1-1v-41a1 1 0 012 0v41a1 1 0 01-1 1zM300.452 253.305a.995.995 0 01-.502-.136l-56.816-33.104a1 1 0 011.007-1.728l56.815 33.104a1 1 0 01-.504 1.864z"
        fill="#3f3d56"
      />
      <path
        d="M186.824 253.305a1 1 0 01-.505-1.864l56.815-33.104a1 1 0 011.007 1.728l-56.815 33.104a1 1 0 01-.502.136zM359.452 303.305a.995.995 0 01-.502-.136l-56.816-33.104a1 1 0 011.007-1.728l56.815 33.104a1 1 0 01-.504 1.864z"
        fill="#3f3d56"
      />
      <path
        d="M245.824 303.305a1 1 0 01-.505-1.864l56.815-33.104a1 1 0 011.007 1.728l-56.815 33.104a1 1 0 01-.502.136zM243.823 202.305a1 1 0 01-1-.998l-.093-36.387a1 1 0 01.998-1.002h.002a1 1 0 011 .997l.093 36.387a1 1 0 01-.998 1.003z"
        fill="#3f3d56"
      />
      <path
        d="M256.756 221.22h-26.052a4.505 4.505 0 01-4.5-4.5v-13.38a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.38a4.505 4.505 0 01-4.5 4.5z"
        fill="#ccc"
      />
      <path
        d="M213.638 153.2h-55.434a1 1 0 010-2h55.434a1 1 0 010 2zM243.73 138.918a1 1 0 01-1-1v-34.613a1 1 0 012 0v34.613a1 1 0 01-1 1zM136.637 153.2a.996.996 0 01-.509-.139l-53.815-31.896a1 1 0 011.02-1.72l53.815 31.895a1 1 0 01-.511 1.86z"
        fill="#3f3d56"
      />
      <path
        d="M82.824 185.097a1 1 0 01-.511-1.86l53.815-31.897a1 1 0 011.02 1.721l-53.815 31.896a.996.996 0 01-.51.14z"
        fill="#3f3d56"
      />
      <path
        d="M154.204 169.33h-13.381a4.505 4.505 0 01-4.5-4.5V138.78a4.505 4.505 0 014.5-4.5h13.381a4.505 4.505 0 014.5 4.5v26.052a4.505 4.505 0 01-4.5 4.5zM256.756 105.996h-26.052a4.505 4.505 0 01-4.5-4.5V88.114a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.382a4.505 4.505 0 01-4.5 4.5zM79.204 138.33H65.823a4.505 4.505 0 01-4.5-4.5V107.78a4.505 4.505 0 014.5-4.5h13.381a4.505 4.505 0 014.5 4.5v26.052a4.505 4.505 0 01-4.5 4.5zM79.204 201.33H65.823a4.505 4.505 0 01-4.5-4.5V170.78a4.505 4.505 0 014.5-4.5h13.381a4.505 4.505 0 014.5 4.5v26.052a4.505 4.505 0 01-4.5 4.5z"
        fill="#ccc"
      />
      <path
        d="M329.256 153.2h-55.433a1 1 0 010-2h55.433a1 1 0 010 2zM350.824 153.2a1 1 0 01-.51-1.86l53.814-31.896a1 1 0 111.02 1.721l-53.815 31.896a.995.995 0 01-.51.14z"
        fill="#3f3d56"
      />
      <path
        d="M404.637 185.097a.995.995 0 01-.509-.14l-53.815-31.896a1 1 0 111.02-1.72l53.815 31.895a1 1 0 01-.511 1.86z"
        fill="#3f3d56"
      />
      <path
        d="M346.638 169.33h-13.382a4.505 4.505 0 01-4.5-4.5V138.78a4.505 4.505 0 014.5-4.5h13.382a4.505 4.505 0 014.5 4.5v26.052a4.505 4.505 0 01-4.5 4.5zM421.638 138.33h-13.382a4.505 4.505 0 01-4.5-4.5V107.78a4.505 4.505 0 014.5-4.5h13.382a4.505 4.505 0 014.5 4.5v26.052a4.505 4.505 0 01-4.5 4.5zM421.638 201.33h-13.382a4.505 4.505 0 01-4.5-4.5V170.78a4.505 4.505 0 014.5-4.5h13.382a4.505 4.505 0 014.5 4.5v26.052a4.505 4.505 0 01-4.5 4.5zM198.756 272.22h-26.052a4.505 4.505 0 01-4.5-4.5v-13.38a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.38a4.505 4.505 0 01-4.5 4.5zM314.571 272.22H288.52a4.505 4.505 0 01-4.5-4.5v-13.38a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.38a4.505 4.505 0 01-4.5 4.5zM261.571 321.22H235.52a4.505 4.505 0 01-4.5-4.5v-13.38a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.38a4.505 4.505 0 01-4.5 4.5zM372.571 321.22H346.52a4.505 4.505 0 01-4.5-4.5v-13.38a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.38a4.505 4.505 0 01-4.5 4.5zM314.571 321.22H288.52a4.505 4.505 0 01-4.5-4.5v-13.38a4.505 4.505 0 014.5-4.5h26.052a4.505 4.505 0 014.5 4.5v13.38a4.505 4.505 0 01-4.5 4.5z"
        fill="#ccc"
      />
      <path
        d="M275.4 169.597H212.06a4.505 4.505 0 01-4.5-4.5v-25.792a4.505 4.505 0 014.5-4.5H275.4a4.505 4.505 0 014.5 4.5v25.792a4.505 4.505 0 01-4.5 4.5z"
        fill="#3f3d56"
      />
    </svg>
  );
}
