/**
 * @file
 *
 * this file contains the component that displays the file export progress
 * for bulk data export
 */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { motion } from 'framer-motion';
import { interpolate } from '@popmotion/popcorn';
import { MdCheckCircle } from 'react-icons/md';

import { FileIcon } from '../SVGComponents/FileIcon';
import { PencilIcon } from '../SVGComponents/PencilIcon';

const useStyles = makeStyles((theme) => ({
  batchContainer: {
    overflow: 'hidden',
  },
  batchBox: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: theme.spacing(0.5),
  },
  pencil: {
    position: 'relative',
    bottom: theme.spacing(11),
    height: '100%',
  },
  checkContainer: {
    position: 'relative',
    bottom: theme.spacing(5),
    left: theme.spacing(3),
  },
  checkIcon: {
    color: 'white',
  },
  exportingText: {
    color: theme.palette.grey[500],
    marginTop: theme.spacing(2),
  },
}));

const minimumBatchLimit = [0, 5];
export function DownloadProgress({
  totalNoOfBatches,
  activeBatch,
  recordsCount,
  selectedEntityLabel,
}) {
  const classes = useStyles();

  const effectiveActiveBatch =
    totalNoOfBatches > 5
      ? Math.floor(interpolate([0, totalNoOfBatches], minimumBatchLimit)(activeBatch))
      : activeBatch;
  const batchesToShow = Math.min(5, totalNoOfBatches);

  return (
    <Grid
      wrap="nowrap"
      item
      container
      alignItems="center"
      direction="column"
      className={classes.batchContainer}
    >
      <Grid item container justifyContent="center">
        {Array(batchesToShow)
          .fill(0)
          .map((_, index) => (
            <motion.div key={index}>
              <Grid item container className={classes.batchBox}>
                <FileIcon
                  width="100%"
                  height="100%"
                  {...(index < effectiveActiveBatch && { fill: '#1ebb96' })}
                />
                {index < effectiveActiveBatch && (
                  <Grid item className={classes.checkContainer}>
                    <MdCheckCircle className={classes.checkIcon} fontSize={24} />
                  </Grid>
                )}
                {index === effectiveActiveBatch && (
                  <Grid component={motion.div} item container className={classes.pencil}>
                    <motion.div
                      animate={{
                        width: [0, 15, 22, 32, 22, 15, 0],
                      }}
                      transition={{
                        time: [0.3, 0.3, 0.3, 0.1],
                        repeat: Infinity,
                        repeatDelay: 0.5,
                      }}
                      style={{
                        backgroundColor: '#767676',
                        height: 3,
                        position: 'absolute',
                        top: 60,
                        left: 20,
                      }}
                    />
                    <motion.div
                      animate={{
                        x: [15, 20, 30, 40, 50, 40, 30, 20, 15],
                      }}
                      transition={{
                        time: [0.3, 0.3, 0.3, 0.1],
                        repeat: Infinity,
                        repeatDelay: 0.5,
                      }}
                      style={{ position: 'absolute', top: 40 }}
                    >
                      <PencilIcon width={25} height={25} fill="#767676" />
                    </motion.div>
                  </Grid>
                )}
              </Grid>
            </motion.div>
          ))}
      </Grid>
      <Typography className={classes.exportingText}>
        Exporting <strong>{recordsCount} </strong>records of <strong>{selectedEntityLabel}</strong>{' '}
        entity...
      </Typography>
    </Grid>
  );
}
