import { useMutation, useQuery, useQueryClient } from 'react-query';

import { dataflowApiBase, getServiceInstance } from 'service';
import { useTenantState } from './user';
import { useNotifyError } from 'hooks/useNotifyError';
import produce from 'immer';

function runLogsFetcher({ queryKey }) {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${dataflowApiBase}${endpoint}`);
}

export function useEditRuns(connectionId, userSystemId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/edit/run/system/${userSystemId}`, tenant?.tenant_id],
    queryFn: runLogsFetcher,
    enabled: Boolean(tenant),
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

export function useFetchRunInfo(connectionId, runId, userSystemId) {
  const tenant = useTenantState();
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/edit/run/${runId}`, tenant?.tenant_id],
    queryFn: runLogsFetcher,
    enabled: Boolean(tenant && runId),
    onSuccess: (data) => {
      const allRuns = queryClient.getQueryData([
        `/connection/${connectionId}/edit/run/system/${userSystemId}`,
        tenant?.tenant_id,
      ]);

      const updatedRunIndex = allRuns.findIndex((run) => run.run_id === data.run_id);
      const updatedRuns = produce(allRuns, (draft) => {
        draft[updatedRunIndex].state = data.state;
      });
      queryClient.setQueryData(
        [`/connection/${connectionId}/edit/run/system/${userSystemId}`, tenant?.tenant_id],
        updatedRuns
      );
    },
  });

  useNotifyError({
    error: error,
    fallbackMessage: 'Error: Failed to fetch run log',
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

function createRun(tenant, connectionId, body) {
  return getServiceInstance(tenant?.tenant_id).post(
    `${dataflowApiBase}/connection/${connectionId}/edit/run`,
    body
  );
}
export function useCreateRunMutation() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, connectionId, body }) => createRun(tenant, connectionId, body),
    {
      onSuccess: (data, { connectionId, tenant, userSystemId }) => {
        const runInfo = queryClient.getQueryData([
          `/connection/${connectionId}/edit/run/system/${userSystemId}`,
          tenant?.tenant_id,
        ]);

        if (runInfo) {
          queryClient.setQueryData(
            [`/connection/${connectionId}/edit/run/system/${userSystemId}`, tenant?.tenant_id],
            [data, ...runInfo]
          );
          return;
        }

        queryClient.setQueryData(
          [`/connection/${connectionId}/edit/run/system/${userSystemId}`, tenant?.tenant_id],
          [data]
        );
      },
      onSettled: (data, error, { connectionId, tenant, userSystemId }) => {
        queryClient.invalidateQueries([
          `/connection/${connectionId}/edit/run/system/${userSystemId}`,
          tenant?.tenant_id,
        ]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update data',
  });

  return mutation;
}
