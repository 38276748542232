/**
 * @file
 * File contains the code that renders the Connection Activation Layer
 */

import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CgBolt } from 'react-icons/cg';
import { BiNavigation, BiSupport } from 'react-icons/bi';
import { trim } from 'lodash-es';

import { getMailToString, navigateToSystems } from 'utils';
import { useLoggedInUser, useTenantState } from 'data/user';
import { EMAIL_FORMATS, ERROR_CODES, SYSTEMS } from '../constants';
import { useConnectionHelper } from 'data/connectionsAndSystems';
import GenericErrorImage from './Illustrations/GenericErrorImage';
import DialogWrapper from './DialogWrapper';

export default function ConnectionActivationWrapper({ children, connection }) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const isSFSystem = connection?.user_system?.system?.startsWith(SYSTEMS.SF_EC.KEY) ?? true;

  const { activateConnection, isActivating, activationError } = useConnectionHelper(
    connection?.connection_id
  );

  useEffect(() => {
    if (!activationError) {
      // Fail-safe to set the error dialog state to false, if there is no error
      setIsErrorDialogOpen(false);
      return;
    }

    // If there is an error, open the error dialog
    setIsErrorDialogOpen(true);
  }, [activationError]);

  // If the connection is active OR if the connection is an OData Service Connection, we return the connection card without any wrapper
  if (connection.is_active || !isSFSystem) {
    return children;
  }

  // If the connection is inactive, we wrap the connection card with an activation wrapper
  return (
    <>
      <Stack>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(77, 121, 166, 0.3)',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              zIndex: 2,
              borderRadius: 1,
              p: 2,
              pb: 4,
            }}
          >
            <Button
              startIcon={isActivating ? <CircularProgress color="inherit" size={16} /> : <CgBolt />}
              size="small"
              variant="contained"
              disableElevation
              onClick={activateConnection}
            >
              Activate
            </Button>
          </Box>
          <Box sx={{ opacity: 0.6, cursor: 'not-allowed' }}>{children}</Box>
        </Box>
      </Stack>
      {isErrorDialogOpen && activationError && (
        <ErrorDialogRenderer
          error={activationError}
          connection={connection}
          isDialogOpen={isErrorDialogOpen}
          closeDialog={() => setIsErrorDialogOpen(false)}
        />
      )}
    </>
  );
}

function ErrorDialogRenderer({ error, connection, isDialogOpen, closeDialog }) {
  const { code, errorDetails, msg } = error;

  const tenant = useTenantState();
  const { user } = useLoggedInUser();

  // For system secrets and ping test dialogs, the action will be to navigate to the Systems app
  const navigateToSystemsApp = useCallback(() => {
    const { tenant_id, user_system_id } = connection;
    navigateToSystems(tenant_id, user_system_id);

    closeDialog();
  }, [closeDialog, connection]);

  // For billing dialog, the action will be to contact the support
  const triggerEmail = useCallback(() => {
    const emailHref = getMailToString({
      emailSubject: EMAIL_FORMATS.BILLING_PLAN.subject,
      emailBody: EMAIL_FORMATS.BILLING_PLAN.getBody(user, tenant),
    });

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = emailHref;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    closeDialog();
  }, [closeDialog, tenant, user]);

  // Billing Specific Error Dialog
  if (code === ERROR_CODES.FAILED__BILLING_ERROR) {
    return (
      <DialogWrapper
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        title="Connection Activation Failed"
        primaryBtnText="Contact Support"
        primaryBtnProps={{ startIcon: <BiSupport /> }}
        primaryBtnAction={triggerEmail}
      >
        <DialogBody msg={msg} desc={errorDetails} errorCode={code} />
      </DialogWrapper>
    );
  }

  // Ping Test Specific Error Dialog OR System Secrets Specific Error Dialog
  if (
    code === ERROR_CODES.FAILED__MISSING_SYSTEM_SECRETS ||
    code === ERROR_CODES.FAILED__PING_TEST ||
    code === ERROR_CODES.FAILED__MISSING_CONNECTION_TYPE
  ) {
    return (
      <DialogWrapper
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        title="Connection Activation Failed"
        primaryBtnText="Open Systems App"
        primaryBtnProps={{ startIcon: <BiNavigation /> }}
        primaryBtnAction={navigateToSystemsApp}
      >
        <DialogBody msg={msg} desc={errorDetails} errorCode={code} />
      </DialogWrapper>
    );
  }

  // Generic Error Dialog
  return (
    <DialogWrapper
      isOpen={isDialogOpen}
      closeDialog={closeDialog}
      title="Connection Activation Failed"
    >
      <DialogBody msg={msg} desc={errorDetails} errorCode={code} />
    </DialogWrapper>
  );
}

function DialogBody({ msg, desc = '', errorCode }) {
  let customDesc = desc;

  if (typeof customDesc === 'string') {
    const isMissingSecretError = errorCode === ERROR_CODES.FAILED__MISSING_SYSTEM_SECRETS;

    // We only want to do desc customization for missing secrets error
    if (isMissingSecretError) {
      customDesc = `Your SuccessFactors System is missing some secrets - ${customDesc}. Please maintain them to activate the connection`;
    }
  }

  // Ensuring that desc being passed to the Typography component is a string
  customDesc = JSON.stringify(customDesc);

  return (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
      <Box
        width="60%"
        bgcolor="rgba(255, 112, 112, 0.1)"
        borderRadius={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <GenericErrorImage width="200px" height="200px" />
      </Box>
      <Typography mt={2} variant="h6">
        {msg ?? 'Activation Failed! Something went wrong'}
      </Typography>
      <Typography width="90%" align="center" color="textSecondary" variant="body1">
        {trim(customDesc, '"') ??
          'Please check your system credentials and billing plan and try again.'}
      </Typography>
    </Stack>
  );
}
