/**
 * @file
 *
 * this file contains generic utils that are not any context specific
 */
import produce from 'immer';
import isUuid from 'is-uuid';
import { getPlatformURL } from 'service';
import dayjs from 'dayjs';

import { APPLICATIONS_LINKED_TO_SYSTEM, CANVAS, SYSTEMS, SYSTEM_CONFIG } from './constants';
import { CONFIG } from 'config';
import { JOB_STATE } from 'components/ReportViewComponents/constants';

/**
 * @function
 *
 * this function is to wrap error prone code
 * that will return a default value in case of an error
 *
 *
 * @param {any} defaultReturn       default value to be returned on error
 * @param {Function} callback       function to call wrapped in try
 * @param {Function} [onError]      a callback called in case of error
 *
 * @returns {any}
 */
export const returnDefaultIfThrows = (defaultReturn, callback, onError) => {
  let result = defaultReturn;
  try {
    result = callback();
  } catch (err) {
    if (onError) {
      onError(err);
    }
  } finally {
    return result;
  }
};

export const cast = {
  /**
   *
   * this function is to cast any value to a valid int
   *
   * @param {any} value
   *
   * @returns {number}
   */
  int: (value) => {
    try {
      const _value = parseInt(value, 10);

      if (!Number.isNaN(_value)) {
        return _value;
      }
    } finally {
    }
    return null;
  },
  /**
   *
   * this function is to cast any value to a valid float
   *
   * @param {any} value
   *
   * @returns {number | null}
   */
  float: (value) => {
    try {
      const _value = parseFloat(value, 10);

      if (!Number.isNaN(_value)) {
        return _value;
      }
    } finally {
    }
    return null;
  },
  /**
   * this function is to cast any value to a valid date and then return a dayjs value of it or null
   *
   * @param {any} value
   *
   * @returns {import('dayjs').Dayjs | null}
   */
  date: (value, utc) => {
    try {
      if (value) {
        const date = dayjs(value);
        return utc ? date.utc() : date;
      }
    } finally {
    }
    return null;
  },
  /**
   * this function is to cast any value to uuid
   *
   * @param {any} value
   *
   * @returns {string | null}
   */
  uuid: (value) => {
    if (typeof value !== 'string') {
      return null;
    }

    if (isUuid.anyNonNil(value)) {
      return value;
    }

    return null;
  },
  /**
   * this function is to cast any value to non empty string
   *
   * @param {any} value
   *
   * @returns {string | null}
   */
  string: (value) => (value === null || value === '' || value === undefined ? null : String(value)),
};

export const validate = {
  int(
    value,
    { min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, required = false } = {}
  ) {
    if (value === null || value === undefined || value === '') {
      return !required;
    }

    try {
      const num = Number(value);

      return Number.isFinite(num) && Number.isInteger(num) && num >= min && num <= max;
    } catch (err) {
      return false;
    }
  },
  float(
    value,
    { min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, required = false } = {}
  ) {
    if (value === null || value === undefined || value === '') {
      return !required;
    }

    try {
      const num = Number(value);

      return Number.isFinite(num) && num >= min && num <= max;
    } catch (err) {
      return false;
    }
  },
  string(value, { required = false } = {}) {
    if (value === null || value === undefined || value === '') {
      return !required;
    }

    return typeof value === 'string' && Boolean(value.length);
  },
  uuid(value, { required = false } = {}) {
    if (value === null || value === undefined || value === '') {
      return !required;
    }

    return typeof value !== 'string' && isUuid.anyNonNil(value);
  },
};

/**
 * @function
 *
 * this function is to iterate over an array and perform filter and map
 * both in one iteration
 *
 * @param {any[]} array             an array
 * @param {function} predicate      callback used to filter elements
 * @param {function} iteratee       callback used to transform elements
 *
 * @returns {any[]}
 */
export const filterAndMap = (array, predicate, iteratee) => {
  return array.reduce((finalArray, element) => {
    if (predicate(element)) {
      finalArray.push(iteratee(element));
    }

    return finalArray;
  }, []);
};

/**
 * @function
 *
 * utility to wrap state updater in immer
 *
 * @param {function} setter         the setter function from useState or any other compatible (like @halka/state setter)
 *
 * @returns {function}              the decorated setter function using immer
 */
export const wrapWithImmer = (setter) => (fn) => setter(produce(fn));

/**
 * @function
 *
 * shallow property reference based object equality check
 *
 * Note: doesn't check if number of properties on each object is equal or not
 *
 * @param {object} a              first object
 * @param {object} b              second object
 * @param {string[]} [propKeys]   property keys to consider (optional)
 *
 * @returns {boolean}
 */
export const isObjectPropertiesEqual = (a, b, propKeys = Object.keys(a)) =>
  propKeys.every((key) => a[key] === b[key]);

/**
 * @function
 *
 * This function will strip off the $format from the url
 *
 * @param {string} _url                     url that needs to be stripped
 *
 * @returns {string}
 */
export const strip$formatAnd$inlinecountFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    urlObj.searchParams.delete('$format');
    urlObj.searchParams.delete('$inlinecount');
    urlObj.searchParams.delete('paging');

    return urlObj.href;
  } catch {
    return url;
  }
};

/**
 * @function
 *
 * This function checks whether the selected query url is already bookmarked or not
 *
 * @param {Array} bookmarkData              array of all the bookmarks
 * @param {string} queryUrl                 selected query url
 *
 * @returns {boolean}
 */
export const checkBookmarkExists = (bookmarkData = [], queryUrl) => {
  const url = strip$formatAnd$inlinecountFromUrl(queryUrl);
  const bookmarksFromAllCollections = bookmarkData.flatMap((collection) => collection.bookmarks);
  const exists = bookmarksFromAllCollections.some(
    (bookmark) => strip$formatAnd$inlinecountFromUrl(bookmark?.query_string) === url
  );
  return exists;
};

/**
 * @function
 *
 * This function returns a placeholder for the connection details related fields
 *
 * @param {string} systemType              type of the system selected
 * @param {string} fieldName               name of the field for which you want the placeholder
 *
 * @returns {string}
 */
export const getConnectionFormPlaceholder = ({ systemType, fieldName }) => {
  switch (fieldName) {
    case 'name':
      return systemType === SYSTEMS.SF_EC.KEY ? 'Users' : 'Northwind v2';
    case 'description':
      return systemType === SYSTEMS.SF_EC.KEY
        ? 'This is a SuccessFactors System'
        : 'This is a public OData Service';
    default:
      return '';
  }
};

/**
 * @function
 *
 * This function returns a tooltip title for the features that are closely coupled with the usage plan
 *
 * @param {boolean} hasFeatureUsageLimitReached             whether the feature usage has reach the limit
 * @param {boolean} hasPlanExpired                          whether plan has expired or not
 * @param {string} defaultTitle                             the title your want to return by default.
 *
 * @returns {string}
 */
export const getTooltipTitleForPlanRelatedFeature = ({
  hasFeatureUsageLimitReached,
  hasPlanExpired,
  defaultTitle = '',
}) => {
  if (hasPlanExpired) {
    return 'Your plan has expired';
  } else if (hasFeatureUsageLimitReached) {
    return 'You have exhausted your usage quota';
  }

  return defaultTitle;
};

/**
 * @function
 *
 * This function extracts out the property name and returns it. Employee1/Address -> Address
 *
 * @param {object} property            the property for which you want the property name
 *
 * @returns {string}
 */
export const extractPropertyName = (property) => {
  return property.parent ? property.name.split('/')[1] : property.name;
};

/**
 * @function
 *
 * This function generates a random co-ordinate in the viewport of the canvas
 *
 * @param {object} diagram            diagram instance
 *
 * @returns {string}
 */
export const getRandomCoordinateInViewport = (diagram) => {
  const { left, right, top, bottom } = diagram.viewportBounds;

  const x = Math.floor(Math.random() * (right - left + 1 - CANVAS.SPAWN_OFFSET_X * 2)) + left;
  const y = Math.floor(Math.random() * (bottom - top + 1 - CANVAS.SPAWN_OFFSET_Y * 2)) + top;

  return `${x} ${y}`;
};

/**
 * This function generates the application url for a given application
 *
 * @param {string} appName Name of the application
 * @param {string} id Id of the project/template
 * @param {string} tenantId Id of the tenant
 *
 * @returns
 */
export const getSelectedProjectUrl = (appName, id, tenantId) => {
  if (!id) {
    return '';
  }

  const baseUrl = getPlatformURL();

  if (
    appName === APPLICATIONS_LINKED_TO_SYSTEM.mapperProjects.KEY ||
    appName === APPLICATIONS_LINKED_TO_SYSTEM.deploymentTargets.KEY
  ) {
    return `${baseUrl}/#/mapper/t-${tenantId}-t&/m/detail/${id}`;
  }

  if (appName === APPLICATIONS_LINKED_TO_SYSTEM.templates.KEY) {
    return `${baseUrl}/#/hrDataGeneratorV2/t-${tenantId}-t&/hrdgV2/replication/${id}`;
  }

  if (appName === APPLICATIONS_LINKED_TO_SYSTEM.mdgenTemplates.KEY) {
    return `${baseUrl}/#/mdgen/t-${tenantId}-t&/mdg/template/${id}`;
  }

  if (appName === APPLICATIONS_LINKED_TO_SYSTEM.dataflowConnections.KEY) {
    if (CONFIG.ENV === 'development') {
      return `http://localhost:3010/t/${tenantId}/connection/${id}`;
    } else {
      return `${baseUrl.replace('platform', 'dataflow')}/t/${tenantId}/connection/${id}`;
    }
  }

  return '';
};

/**
 * Function that returns the number of days left till expiry and expiry status
 *
 * @param {string} expiryDate
 * @returns
 */
export function getBillingExpiryStatus(expiryDate) {
  let noOfDaysLeft;
  if (expiryDate) {
    const currentDateObj = new Date();
    const expiryDateObj = new Date(expiryDate);

    noOfDaysLeft = Math.ceil((expiryDateObj - currentDateObj) / (1000 * 60 * 60 * 24));
  }

  return { noOfDaysLeft, hasPlanExpired: noOfDaysLeft < 0 };
}

/**
 * Function returns a mailto: string with the email-subject and email-body added
 *
 * @param {Object} emailInfo {emailSubject, emailBody}
 * @returns
 */
export function getMailToString({ emailSubject, emailBody }) {
  return `mailto:${CONFIG.SUPPORT_EMAIL}?subject=${emailSubject}&body=${emailBody}`;
}

/**
 * Function checks whether the browser is a chromium broswer
 * @returns
 */
export function checkIsChromiumBrowser() {
  const userAgent = navigator.userAgent;

  return userAgent.includes('Chrome');
}

/**
 * Function triggers a force redraw for the element passed
 *
 * @param {HTMLElement} element
 * @returns
 */
export function forceDrawElement(element) {
  if (!element instanceof HTMLElement) {
    return;
  }
  element.style.display = 'none';
  setTimeout(() => {
    element.style.display = 'block';
  });
}

/**
 * Function extracts the error details from the JSON string shared
 *
 * @param {string} errorDetailsString
 * @returns
 */
export function extractErrorDetails(errorDetailsString) {
  const msgRegex = /"msg":"(.*?)"/;
  const typeRegex = /"type":"(.*?)"/;
  const statusRegex = /"status":(\d+)/;

  const msgMatch = errorDetailsString.match(msgRegex);
  const typeMatch = errorDetailsString.match(typeRegex);
  const statusMatch = errorDetailsString.match(statusRegex);

  const msg = msgMatch ? msgMatch[1] : 'Looks like something went wrong!';
  const type = typeMatch ? typeMatch[1] : null;
  const status = statusMatch ? parseInt(statusMatch[1]) : null;

  return { msg, type, status };
}

/**
 * Function navigates the use to the system application
 *
 * @param {string} tenantId
 * @param {string} userSystemId
 */
export function navigateToSystems(tenantId, userSystemId) {
  window.open(`${getPlatformURL()}/#/systems/t-${tenantId}-t&/s/system/${userSystemId}`, '_blank');
}

/**
 * Function that extracts the latest metadata and picklist and returns it back
 *
 * @param {object} configurations
 * @returns
 */
export function getLastestSystemConfiguration(configurations) {
  let latestMetadata = null;
  let latestPicklist = null;

  configurations.forEach((config) => {
    const { system_config_type: configType } = config;

    if (configType === SYSTEM_CONFIG.METADATA) {
      if (!latestMetadata) {
        latestMetadata = config;
        return;
      }

      if (config.version > latestMetadata.version) {
        latestMetadata = config;
      }
    }

    if (configType === SYSTEM_CONFIG.PICKLIST) {
      if (!latestPicklist) {
        latestPicklist = config;
        return;
      }

      if (config.version > latestPicklist.version) {
        latestPicklist = config;
      }
    }
  });

  return { latestMetadata, latestPicklist };
}

/**
 * Function returns the required configuration from the system
 *
 * @param {object} userSystems all the caches user-systems
 * @param {string} systemId the id of the user-system
 *
 * @returns {object} the configuration
 */
export function getSystemConfigurationAndPreferences(userSystems, systemId) {
  // Find the system linked to the connection
  const linkedSystem = userSystems?.find(({ user_system_id }) => user_system_id === systemId);

  if (!linkedSystem) {
    return {};
  }

  const allPreferences = linkedSystem?.preferences?.dataflow;

  // Extract and return the metadata and picklist
  const systemConfigurations = linkedSystem.system_configurations?.reduce((acc, config) => {
    const { system_config_type, state } = config ?? {};

    if (system_config_type === SYSTEM_CONFIG.METADATA && state === 'complete') {
      acc.metadata = config;
    }

    if (system_config_type === SYSTEM_CONFIG.PICKLIST && state === 'complete') {
      acc.picklist = config;
    }

    return acc;
  }, {});

  return {
    ...systemConfigurations,
    allPreferences,
  };
}

/**
 * Function to parse JSON string
 *
 * @param {string|object} data data that needs to be parsed - can be a string or an object
 * @returns returns a JSON object if a valid JSON string was passed or else returns the passed data without parsing
 */
export function parseJSONString(data) {
  if (typeof data !== 'string') {
    return data;
  }

  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
}

/**
 * Constructs a URL object from a given URL string.
 *
 * @param {string} url - The URL string to construct a URL object from.
 * @param {string} whoTrigerredIt - A description of who triggered the URL construction.
 * @returns {URL|string} A URL object if the URL is valid, otherwise the original URL string.
 */
export const constructURL = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj;
  } catch (error) {
    return null;
  }
};

/**
 * Extracts the entity name from the provided URL object.
 * @param {URL} urlObj - The URL object from which to extract the entity name.
 * @returns {string|null} The extracted entity name or null if not found.
 */
export const extractEntityName = (urlObj) => {
  const entityRegex = /\/odata\/v2\/(.*)/;
  const pathname = urlObj?.pathname ?? '';
  const match = pathname.match(entityRegex);
  const entity = match?.[1];

  return entity;
};

/**
 * Filters an array of jobs to retrieve only the jobs that are currently in progress and not expired.
 *
 * @param {Array<Object>} jobs - An array of job objects.
 * @returns {Array<Object>} - An array containing only the in-progress and non-expired job objects.
 */
export const getJobsInProgress = (jobs) => {
  if (!jobs || !jobs.length) {
    return [];
  }

  return jobs.filter((job) => {
    // first filter out active jobs
    const currentDate = new Date();
    const expiredDate = new Date(job.expired_at);
    const isJobExpired = job.expired_at ? expiredDate < currentDate : false;

    // then filter out inprogress jobs
    const isJobInprogress =
      job.state === JOB_STATE.STARTED ||
      job.state === JOB_STATE.WAITING ||
      job.state === JOB_STATE.QUEUED;
    return !isJobExpired && isJobInprogress; // In-progress and active jobs
  });
};

/**
 *
 * This function removes the last segment from the current URL path and uses
 * the history object to navigate to that location, effectively simulating
 * a "go back" action.
 *
 * @param {object} history - The history object used for navigation. Provided by React Router
 * @param {object} location - The location object representing the current URL.
 *
 * @example
 * // Assuming the current URL is '/users/profile/edit'
 * goBackToPreviousPage(history, location);
 * // Navigates to '/users/profile'
 *
 */
export const goBackToPreviousPage = (history, location) => {
  const addressOfBackPage = location.pathname.replace(/\/[^/]+\/?$/, ''); // removes one '/xxx' at the end of url
  history.push(addressOfBackPage);
};
