/**
 * @file
 *
 * this file contains the component and hooks needsed for the "Order By" section of the query builder form
 */
import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Chip,
  makeStyles,
  IconButton,
  useTheme,
} from '@material-ui/core';
import { Autocomplete, ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { MdDelete, MdArrowDownward, MdArrowUpward, MdVpnKey } from 'react-icons/md';

import { ENUMS } from '../odata/utils';
import { commonTextFieldProps } from '../theme';
import { AutocompleteOption, autoCompleteFilterOptions } from './FormComponents/AutocompleteOption';
import { orderByActions, useQueryBuilderState } from '../state/queryBuilder';
import { tourSteps } from './Onboarding/Tour';
import { AutocompleteVirtualizedListbox } from './AutocompleteVirtualizedListbox';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(1),
  },
  toggleButtonGroup: {
    width: theme.spacing(10),
    height: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  toggleButton: {
    width: '100%',
    fontSize: 10,
    fontWeight: 600,

    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const orderByStateSelector = (state) => state.orderBy;
export function OrderOptions() {
  const classes = useStyles();
  const theme = useTheme();

  const { options, value, flags } = useQueryBuilderState(orderByStateSelector);

  return (
    <>
      <Grid item container spacing={2} data-tour-step={tourSteps['order-by'].id}>
        <Grid item container justifyContent="flex-end" alignItems="flex-start" xs={2}>
          <Grid item>
            <Typography color="textPrimary" className={classes.topMargin} variant="subtitle1">
              Order By
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={10} alignItems="center" spacing={1}>
          <Grid item xs={9}>
            <Autocomplete
              multiple
              size="small"
              limitTags={3}
              fullWidth
              autoHighlight
              autoComplete
              openOnFocus
              ListboxComponent={AutocompleteVirtualizedListbox}
              filterOptions={autoCompleteFilterOptions}
              options={options}
              getOptionLabel={(columnOption) => columnOption.name}
              value={value}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option.name}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Columns to order by"
                  {...commonTextFieldProps}
                />
              )}
              renderOption={(option, { inputValue }) => (
                <AutocompleteOption
                  label={option.label}
                  name={option.name}
                  inputValue={inputValue}
                  adornment={option.isKey && !option.name.includes('/') ? <MdVpnKey /> : null}
                />
              )}
              onChange={orderByActions.onOrderByChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={2} />
        <Grid item container xs={10} direction="column" spacing={1}>
          {value.map((selectedColumn, index) => (
            <Grid item container spacing={1} alignItems="center" key={selectedColumn.name}>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    orderByActions.onOrderByRemove(index);
                  }}
                >
                  <MdDelete color={theme.palette.error.light} />
                </IconButton>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  value={flags[selectedColumn.name]}
                  onChange={() => {
                    orderByActions.onToggleOrderByFlag(selectedColumn.name);
                  }}
                  exclusive
                  aria-label="column order by flag"
                  size="small"
                  className={classes.toggleButtonGroup}
                >
                  <ToggleButton
                    size="small"
                    value={ENUMS.ORDER_BY_FLAG.ASC}
                    aria-label="ascending"
                    className={classes.toggleButton}
                  >
                    <MdArrowDownward />
                    ASC
                  </ToggleButton>
                  <ToggleButton
                    size="small"
                    value={ENUMS.ORDER_BY_FLAG.DESC}
                    aria-label="descending"
                    className={classes.toggleButton}
                  >
                    <MdArrowUpward />
                    DESC
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <Typography>{selectedColumn.name}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
