/**
 * @file
 *
 * This file contains the edit bookmark dialog component
 */
import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { commonTextFieldProps } from '../theme';
import { useDisclosure } from '../hooks/useDisclosure';
import { useEditBookmarkMutaton } from '../data/queriesAndBookmarks';
import { useTenantState } from '../data/user';
import { strip$formatAnd$inlinecountFromUrl } from '../utils';

const validationSchema = yup.object({
  name: yup.string().required('Bookmark title is required'),
  description: yup.string(),
});

export const useEditBookmark = () => {
  const { isOpen, close, open } = useDisclosure();

  const [selectedBookmark, updateSelectedBookmark] = useState(null);

  const closeEditDialog = useCallback(() => {
    updateSelectedBookmark(null);

    close();
  }, [close]);

  const openEditDialog = useCallback(
    (bookmark) => {
      updateSelectedBookmark(bookmark);

      open();
    },
    [open]
  );

  return {
    isEditDialogOpen: isOpen,
    openEditDialog,
    closeEditDialog,
    selectedBookmark,
  };
};

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2, 3),
  },
}));

const dialogTitleId = 'update-bookmark-title';
const dialogDescriptionId = 'update-bookmark-description';
export function EditBookmarkDialog({ isOpen, handleClose, selectedBookmark }) {
  const classes = useStyles();

  const { editBookmarkMutation } = useEditBookmarkMutaton();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      url: strip$formatAnd$inlinecountFromUrl(selectedBookmark.query_string),
      name: selectedBookmark.name,
      description: selectedBookmark.description,
    },
  });

  const tenant = useTenantState();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await editBookmarkMutation.mutateAsync({
          formData: data,
          tenant,
          selectedBookmark,
        });

        handleClose();
      } finally {
      }
    },
    [selectedBookmark, handleClose, tenant, editBookmarkMutation]
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id={dialogTitleId}>Edit Bookmark</DialogTitle>
        <DialogContent id={dialogDescriptionId}>
          <TextField
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            label="Bookmark URL"
            name="url"
            helperText=" "
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
          />
          <TextField
            label="Bookmark Title"
            placeholder="Favourite Query"
            name="name"
            helperText={errors.name ? errors.name.message : ' '}
            error={Boolean(errors.name)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={editBookmarkMutation.isLoading}
          />
          <TextField
            label="Bookmark Description"
            placeholder="This is my favorite query"
            name="description"
            helperText={errors.description ? errors.description.message : ' '}
            error={Boolean(errors.description)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={editBookmarkMutation.isLoading}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={editBookmarkMutation.isLoading || !isValid}
            type="submit"
            startIcon={editBookmarkMutation.isLoading ? <CircularProgress size={15} /> : null}
          >
            {editBookmarkMutation.isLoading ? 'Updating' : 'Update'}
          </Button>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            disabled={editBookmarkMutation.isLoading}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
