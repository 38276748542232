/**
 * @file
 *
 * this file contains the component and hooks for the query builder form
 */
import React from 'react';
import {
  Grid,
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  withStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { MdFileDownload, MdRefresh } from 'react-icons/md';
import { BsFileEarmarkArrowDown } from 'react-icons/bs';
import { Autocomplete } from '@material-ui/lab';

import { WhereFilterOptions } from './WhereFilterOptions';
import { ColumnSelectionOptions } from './ColumnSelectOptions';
import { OrderOptions } from './OrderOptions';
import { ExpandOptions } from './ExpandOptions';
import { EffectiveRangeOptions } from './EffectiveRangeOptions';
import { SYSTEMS } from '../constants';
import {
  resetQueryBuilder,
  useQueryBuilderState,
  useTagSelectActions,
} from '../state/queryBuilder';
import { EntityAndPaginationOptions } from './EntityAndPaginationOptions';
import { tourSteps } from './Onboarding/Tour';
import {
  BulkExportDialog,
  BULK_EXPORT_STAGES,
  useBulkExportState,
  useTriggerExport,
} from './BulkExportDialog';
import { commonTextFieldProps } from '../theme';
import { FEATURE_CODES, useBillingUsage } from '../data/billingUsage';

const TagSelectionTextField = withStyles((theme) => ({
  root: {
    '& .MuiFilledInput-root': {
      paddingRight: 45,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 2,
    },
    minWidth: theme.spacing(40),
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  resetBtn: {
    margin: theme.spacing(0, 2, 0),
  },
  hideVisible: {
    visibility: 'hidden',
  },
}));

const selectedEntitySelector = (state) => state.entity.value;
const tagStateSelector = (state) => state.tag;
function QueryBuilderMenu({
  baseEndpoint,
  baseParams,
  systemType,
  schema,
  fetching,
  fetchQueriedData,
}) {
  const classes = useStyles();

  const selectedEntity = useQueryBuilderState(selectedEntitySelector);
  const { triggerExport, fetchCountAndOpenDialog } = useTriggerExport({
    baseEndpoint,
    baseParams,
    systemType,
    schema,
  });

  const { exportStage } = useBulkExportState();

  const { isFeatureQuotaExhausted } = useBillingUsage(FEATURE_CODES.BULK_EXPORT_DATA);

  const selectedTag = useQueryBuilderState(tagStateSelector);
  const { onTagSelection } = useTagSelectActions(schema);

  const isSfSystem = systemType === SYSTEMS.SF_EC.KEY || systemType === SYSTEMS.SF_EC_SHARED.KEY;

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={1}
        component="form"
        onSubmit={fetchQueriedData}
        onReset={resetQueryBuilder}
      >
        {[SYSTEMS.SF_EC.KEY, SYSTEMS.SF_EC_SHARED.KEY].includes(systemType) && (
          <Grid item container spacing={3}>
            <Grid item container alignItems="flex-start" xs={2}>
              <Grid item></Grid>
            </Grid>
            <Grid item container xs={10} alignItems="center" spacing={1}>
              <Grid item container alignItems="center" justifyContent="flex-end" xs={9} spacing={1}>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Filter Entity by Tag
                  </Typography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    autoHighlight
                    autoComplete
                    openOnFocus
                    disableListWrap
                    options={schema.tagCollectionSet}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TagSelectionTextField
                        fullWidth
                        {...params}
                        variant="filled"
                        {...commonTextFieldProps}
                      />
                    )}
                    value={selectedTag}
                    noOptionsText="No Tags"
                    onChange={onTagSelection}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <EntityAndPaginationOptions schema={schema} isSfSystem={isSfSystem} />
        {selectedEntity && isSfSystem && <EffectiveRangeOptions />}
        {selectedEntity && <WhereFilterOptions isSfSystem={isSfSystem} />}
        {selectedEntity && <ExpandOptions schema={schema} />}
        {selectedEntity && <ColumnSelectionOptions />}

        {selectedEntity && <OrderOptions />}

        <Grid item container spacing={3}>
          <Grid item container alignItems="flex-start" xs={2}>
            <Grid item></Grid>
          </Grid>
          <Grid item container xs={10} alignItems="center" spacing={1}>
            <Grid item container justifyContent="space-between" xs={9}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                  disabled={!selectedEntity || fetching}
                  data-tour-step={tourSteps['get-data'].id}
                  startIcon={<MdFileDownload />}
                >
                  {fetching ? 'Fetching...' : 'Get Data'}
                </Button>
                <Button className={classes.resetBtn} startIcon={<MdRefresh />} type="reset">
                  Reset
                </Button>
              </Grid>
              {exportStage && exportStage !== BULK_EXPORT_STAGES.FETCHING_RECORDS ? null : (
                <Tooltip
                  title={isFeatureQuotaExhausted ? 'You cannot access bulk data export' : ''}
                >
                  <span>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={fetchCountAndOpenDialog}
                        {...(!selectedEntity && { className: classes.hideVisible })}
                        startIcon={
                          exportStage ? (
                            <CircularProgress color="primary" size={17} />
                          ) : (
                            <BsFileEarmarkArrowDown />
                          )
                        }
                        disabled={Boolean(exportStage) || isFeatureQuotaExhausted}
                      >
                        Bulk Data Export
                      </Button>
                    </Grid>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BulkExportDialog selectedEntity={selectedEntity} triggerExport={triggerExport} />
    </>
  );
}

export const QueryBuilder = React.memo(QueryBuilderMenu);
