/**
 * @file
 *
 * this file renders the loading animation used in bulk export dialog
 */

import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie-player';

import animationData from '../../lotties/aeroplane.json';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.spacing(2),
    color: theme.palette.grey[400],
    position: 'relative',
    bottom: theme.spacing(6),
  },
  container: {
    overflow: 'hidden',
  },
}));

export function Loading() {
  const classes = useStyles();

  return (
    <Grid item container alignItems="center" className={classes.container} direction="column">
      <Lottie
        loop
        animationData={animationData}
        play
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{ width: 200, height: 200 }}
      />
      <Typography className={classes.text}>Please wait...</Typography>
    </Grid>
  );
}
