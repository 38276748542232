/**
 * @file
 *
 * This file contains the data fetching code for each connection
 */
import { useMemo, useLayoutEffect, useState, useEffect } from 'react';
import { keyBy, pullAll } from 'lodash-es';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useErrorHandler } from 'react-error-boundary';
import produce from 'immer';

import { useConnectionsAndSystems } from './connectionsAndSystems';
import { CONNECTION_TYPES, PING_TEST, SECRET_KEYS, SYSTEMS } from '../constants';
import { getServiceInstance, systemApiBase, dataflowApiBase } from '../service';
import { useNotifyError } from '../hooks/useNotifyError';
import { useTenantState } from './user';
import { FEATURE_CODES } from './billingUsage';

// eslint-disable-next-line import/no-webpack-loader-syntax
import MetadataParserWorker from 'comlink-loader!../odata/parser';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MetadataTransformerWorker from 'comlink-loader!../odata/transformer';
import { usePingTest } from 'hooks/usePingTest';

const metadataParser = new MetadataParserWorker();
const metadataTransformer = new MetadataTransformerWorker();

export const CONNECTION_ERROR_TYPE = {
  FETCH: 'fetch',
  PARSE: 'parse',
  RUNTIME: 'runtime',
  EXPIRED: 'billing_expired',
};

const systemSecretsFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${systemApiBase}${endpoint}`);
};

const metadataFetcher = ({ queryKey }) => {
  const [url, connectionId, tenantId] = queryKey;

  return getServiceInstance(tenantId).post(`${dataflowApiBase}/connection/${connectionId}/proxy`, {
    url,
    type: 'xml',
  });
};

/**
 * custom data fetch hook to get selected connection details and parsed metadata
 *
 * @param {string} connectionId
 */
export function useODataConnection(connection) {
  const { isConnectionsLoading, error: connectionsError } = useConnectionsAndSystems();

  const queryClient = useQueryClient();

  const tenant = useTenantState();
  const connectionId = connection.connection_id;

  const {
    runPingTest,
    isPingingSystem,
    errorMessage: pingTestErrorMessage,
  } = usePingTest(connection);
  const [finalPingStatus, setFinalPingStatus] = useState(PING_TEST.NEUTRAL);

  // A connection is created automatically when a system is created. The created connection is named after
  // the system. So, we should use system name in other parts of connection page ui components. This only
  // applies to connections that were created before we introduced the new connection creation flow.
  const connectionName = connection?.user_system?.name;

  const userSystemId = connection?.user_system_id;
  const isODataServiceSystem = connection?.user_system?.system === SYSTEMS.ODATA_SERVICE.KEY;
  const isSFSystem = connection?.user_system?.system === SYSTEMS.SF_EC.KEY;
  const isOAuth = connection?.user_system.connection_type === CONNECTION_TYPES.OAUTH.KEY;

  const {
    data: systemSecretsData,
    error: systemSecretsError,
    isFetching: isSystemSecretsFetching,
  } = useQuery({
    queryKey: [`/user-system/${userSystemId}/system-secret`, tenant?.tenant_id],
    queryFn: systemSecretsFetcher,
    enabled: Boolean(userSystemId && tenant),
  });

  const endpointAccessor = isOAuth
    ? SECRET_KEYS.OAUTH_ENDPOINT.KEY
    : SECRET_KEYS.SERVICE_ENDPOINT.KEY;

  let metadataEndpoint = useMemo(
    () => systemSecretsData?.find(({ key }) => key === endpointAccessor)?.value,
    [endpointAccessor, systemSecretsData]
  );

  if (
    !isSystemSecretsFetching &&
    !metadataEndpoint?.includes('/odata/v2/$metadata') &&
    isSFSystem
  ) {
    metadataEndpoint = `${metadataEndpoint}/odata/v2/$metadata`;
  }

  const missingValues = useMemo(() => {
    const allSecrets = Object.keys(SECRET_KEYS);

    // Certain secrets are not required for configuring a system and hence should not be considered as missing values
    const secretsToBePulled = [SECRET_KEYS.ODATA_VERSION.KEY, SECRET_KEYS.CONNECTION_TYPE.KEY];

    // The credentials required by an OData Service system are Service Endpoint, Username and Password. Rest all the credentials
    // should be pulled out and not be considered as missing values in case the selected system is an OData Service system
    if (isODataServiceSystem) {
      secretsToBePulled.push(SECRET_KEYS.API_KEY.KEY);
      secretsToBePulled.push(SECRET_KEYS.OAUTH_CERTIFICATE.KEY);
      secretsToBePulled.push(SECRET_KEYS.OAUTH_ENDPOINT.KEY);
      secretsToBePulled.push(SECRET_KEYS.SF_EC_COMPANY_ID.KEY);
      secretsToBePulled.push(SECRET_KEYS.OAUTH_SF_EC_COMPANY_ID.KEY);
    }

    if (isSFSystem) {
      if (isOAuth) {
        secretsToBePulled.push(SECRET_KEYS.SERVICE_ENDPOINT.KEY);
        secretsToBePulled.push(SECRET_KEYS.SF_EC_COMPANY_ID.KEY);
        secretsToBePulled.push(SECRET_KEYS.USERNAME.KEY);
        secretsToBePulled.push(SECRET_KEYS.PASSWORD.KEY);
      } else {
        secretsToBePulled.push(SECRET_KEYS.OAUTH_ENDPOINT.KEY);
        secretsToBePulled.push(SECRET_KEYS.OAUTH_SF_EC_COMPANY_ID.KEY);
        secretsToBePulled.push(SECRET_KEYS.API_KEY.KEY);
        secretsToBePulled.push(SECRET_KEYS.OAUTH_CERTIFICATE.KEY);
        secretsToBePulled.push(SECRET_KEYS.OAUTH_EMAIL_KEY.KEY);
        secretsToBePulled.push(SECRET_KEYS.OAUTH_TECHNICAL_USER_ID.KEY);
      }
    }

    pullAll(allSecrets, secretsToBePulled);

    const keySet = new Set(allSecrets);
    // eslint-disable-next-line no-unused-expressions
    systemSecretsData?.forEach((secret) => {
      if (keySet.has(secret.key)) {
        keySet.delete(secret.key);
      }
    });

    return [...keySet].map((key) => SECRET_KEYS[key].LABEL);
  }, [isOAuth, isODataServiceSystem, isSFSystem, systemSecretsData]);

  const handleError = useErrorHandler();

  const [baseEndpoint, baseParams] = useMemo(() => {
    let url;
    try {
      url = new URL(metadataEndpoint);
    } catch (err) {
      return ['', ''];
    }

    const baseEndpoint = `${url.origin}${url.pathname.replace('/$metadata', '')}`;
    const baseParams = url.searchParams.toString();

    return [baseEndpoint, baseParams];
  }, [metadataEndpoint]);

  useEffect(() => {
    if (!baseEndpoint) {
      return;
    }
    runPingTest(baseEndpoint, setFinalPingStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseEndpoint]);

  const {
    data: metadataData,
    error: metadataError,
    isLoading: isMetadataLoading,
    isIdle: isMetadataFetcherIdle,
  } = useQuery({
    queryKey: [metadataEndpoint, connectionId, tenant?.tenant_id],
    queryFn: metadataFetcher,
    enabled: Boolean(metadataEndpoint && tenant && finalPingStatus === PING_TEST.SUCCESS),
  });

  // sometimes we get a message instead of actual metadata. if it's not valid metadata we
  // we catch the message and show it in the fetch fallback.
  const diffMetadataError = metadataData && !metadataData.includes('<?xml');

  // Dedicated state for showing connection loader. We stop the loader
  // only after the last operation (transform logic) is complete.
  const [showLoader, setShowLoader] = useState(true);

  const [metadata, setMetadata] = useState(null);
  const [isMetadataParsing, setMetadataParsing] = useState(false);

  useEffect(() => {
    if (
      diffMetadataError ||
      metadataError ||
      systemSecretsError ||
      (!isSystemSecretsFetching && systemSecretsData && !metadataEndpoint)
    ) {
      handleError({
        missingValues,
        connectionName,
        type: CONNECTION_ERROR_TYPE.FETCH,
        error: metadataError || systemSecretsError,
        additionalInfo: metadataData,
      });
      return;
    }

    if (!metadataData) {
      return;
    }

    setMetadataParsing(true);

    setTimeout(async () => {
      if (metadataData) {
        try {
          const parsedMetadata = await metadataParser.parseMetadata(metadataData);

          if (!parsedMetadata) {
            throw new Error('Failed to parse metadata!');
          }

          const metadata = await metadataTransformer.transformMetadata(parsedMetadata);
          setMetadata(metadata);
        } catch (error) {
          handleError({
            connectionName,
            type: CONNECTION_ERROR_TYPE.PARSE,
            error,
          });
        } finally {
          setShowLoader(false);
        }
      }

      setMetadataParsing(false);
    });
  }, [
    metadataError,
    metadataData,
    systemSecretsError,
    isSystemSecretsFetching,
    systemSecretsData,
    metadataEndpoint,
    handleError,
    missingValues,
    connectionName,
    diffMetadataError,
  ]);

  const error =
    connectionsError ||
    (!isConnectionsLoading && !userSystemId) ||
    systemSecretsError ||
    (systemSecretsData && !metadataEndpoint) ||
    metadataError;

  useNotifyError({ error });

  useLayoutEffect(() => {
    if (error) {
      queryClient.removeQueries([`/user-system/${userSystemId}/system-secret`, tenant?.tenantId], {
        exact: true,
      });
      queryClient.removeQueries([metadataEndpoint, connectionId, tenant?.tenant_id], {
        exact: true,
      });
    }
  }, [error, userSystemId, metadataEndpoint, connectionId, tenant, queryClient]);

  return {
    error,
    isMetadataLoading: isMetadataLoading || (!error && !metadataData),
    isMetadataParsing,
    metadata,
    missingValues,
    baseEndpoint,
    baseParams,
    connectionName,
    finalPingStatus,
    isPingingSystem,
    isMetadataFetcherIdle,
    pingTestErrorMessage,
    isSystemSecretsFetching,
    showLoader,
  };
}

export const useSystemSecrets = (userSystemId, fetchSecrets) => {
  const tenant = useTenantState();

  const { data, error, isFetching } = useQuery({
    queryKey: [`/user-system/${userSystemId}/system-secret`, tenant?.tenant_id],
    queryFn: systemSecretsFetcher,
    enabled: Boolean(tenant && fetchSecrets),
  });

  useNotifyError({ error, mustRedirect: true });

  const secretsByKey = useMemo(() => keyBy(data ?? [], ({ key }) => key), [data]);

  return {
    isFetching,
    error,
    secretsByKey,
    secrets: data ?? [],
  };
};

const QUOTA_COUNT_ACTIONS = {
  INCREMENT: 'increment',
  DECREMENT: 'decrement',
};

const getActiveCount = (incrementOrDecrement, featureData) => {
  if (incrementOrDecrement === QUOTA_COUNT_ACTIONS.INCREMENT) {
    return featureData.usage_details.active_count ?? 0 + 1;
  } else {
    if (featureData.usage_details.active_count > 0) {
      return featureData.usage_details.active_count - 1;
    }
  }
};

const getUpdatedBillingUsageForConnections = (billingUsage, incrementOrDecrement) => {
  const featureIndex = billingUsage.features?.findIndex(
    (feature) => feature.feature_code === FEATURE_CODES.CONNECTION_CREATE_COUNT
  );

  const updatedBillingUsage = produce(billingUsage, (draft) => {
    if (draft.features[featureIndex].usage_details) {
      draft.features[featureIndex].usage_details.active_count = getActiveCount(
        incrementOrDecrement,
        draft.features[featureIndex]
      );
    } else {
      draft.features[featureIndex].usage_details = {
        active_count: 1,
      };
    }
  });

  return updatedBillingUsage;
};

const createSystem = (tenant, formData) =>
  getServiceInstance(tenant?.tenant_id).post(`${systemApiBase}/user-system`, formData);
export const useCreateUserSystemMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ tenant, formData }) => createSystem(tenant, formData), {
    onSuccess: (data, { tenant }) => {
      const userSystems = queryClient.getQueryData([
        `/user-system?systemCode=${data.system}`,
        tenant?.tenant_id,
      ]);

      queryClient.setQueryData(
        [`/user-system?systemCode=${data.system}`, tenant?.tenant_id],
        [...userSystems, data]
      );
    },
    onSettled: (data, error, { tenant, formData }) => {
      queryClient.invalidateQueries([
        `/user-system?systemCode=${formData.system}`,
        tenant?.tenant_id,
      ]);
    },
  });

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a new user system',
  });

  return { createUserSystemMutation: mutation };
};

const createConnection = (tenant, formData) =>
  getServiceInstance(tenant?.tenant_id).post(`${dataflowApiBase}/connection`, formData);
export const useCreateConnectionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ tenant, formData }) => createConnection(tenant, formData), {
    onMutate: ({ tenant }) => {
      const featureQuota = queryClient.getQueryData([
        `/tenant/${tenant?.tenant_id}/usage`,
        tenant?.tenant_id,
      ]);

      const updatedBillingUsage = getUpdatedBillingUsageForConnections(
        featureQuota,
        QUOTA_COUNT_ACTIONS.INCREMENT
      );

      queryClient.setQueryData(
        [`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id],
        updatedBillingUsage
      );

      return { featureQuota };
    },
    onSuccess: (data, { tenant, userSystem }) => {
      const connections = queryClient.getQueryData([
        '/connection?populateUserSystem=true',
        tenant?.tenant_id,
      ]);

      const newConnectionWithUserSystemPopulated = {
        ...data,
        user_system: { ...userSystem },
      };

      queryClient.setQueryData(
        ['/connection?populateUserSystem=true', tenant?.tenant_id],
        [newConnectionWithUserSystemPopulated, ...connections]
      );
    },
    onError: (error, { tenant }, context) => {
      queryClient.setQueryData(
        [`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id],
        context.featureQuota
      );
    },
    onSettled: (data, error, { tenant }) => {
      queryClient.invalidateQueries(['/connection?populateUserSystem=true', tenant?.tenant_id]);
      queryClient.invalidateQueries([`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id]);
    },
  });

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a new connection',
  });

  return { createConnectionMutation: mutation };
};

const deleteConnection = (tenant, connection, isSystemDeletable) => {
  return getServiceInstance(tenant?.tenant_id).delete(
    `${dataflowApiBase}/connection/${connection.connection_id}`,
    {
      data: {
        deleteSystem: isSystemDeletable,
      },
    }
  );
};
export const useDeleteConnectionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, connection, isSystemDeletable }) =>
      deleteConnection(tenant, connection, isSystemDeletable),
    {
      onMutate: async ({ tenant, connection }) => {
        await queryClient.cancelQueries(['/connection?populateUserSystem=true', tenant?.tenant_id]);

        const connections = queryClient.getQueryData([
          '/connection?populateUserSystem=true',
          tenant?.tenant_id,
        ]);

        const featureQuota = queryClient.getQueryData([
          `/tenant/${tenant?.tenant_id}/usage`,
          tenant?.tenant_id,
        ]);

        const updatedBillingUsage = getUpdatedBillingUsageForConnections(
          featureQuota,
          QUOTA_COUNT_ACTIONS.DECREMENT
        );

        queryClient.setQueryData(
          ['/connection?populateUserSystem=true', tenant?.tenant_id],
          connections.filter((conn) => conn.connection_id !== connection.connection_id)
        );

        queryClient.setQueryData(
          [`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id],
          updatedBillingUsage
        );

        return { connections, featureQuota };
      },
      onSuccess: (data, { tenant, connection }) => {
        const odataSystems = queryClient.getQueryData([
          `/user-system?systemCode=${SYSTEMS.ODATA_SERVICE.KEY}`,
          tenant?.tenant_id,
        ]);

        const sfSystems = queryClient.getQueryData([
          `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
          tenant?.tenant_id,
        ]);

        if (!connection.is_dummy) {
          if (connection.user_system.system === SYSTEMS.ODATA_SERVICE.KEY) {
            queryClient.setQueryData(
              [`/user-system?systemCode=${SYSTEMS.ODATA_SERVICE.KEY}`, tenant?.tenant_id],
              odataSystems.filter((system) => system.user_system_id !== connection.user_system_id)
            );
          } else {
            queryClient.setQueryData(
              [`/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`, tenant?.tenant_id],
              sfSystems.filter((system) => system.user_system_id !== connection.user_system_id)
            );

            queryClient.setQueryData(
              [`/user-system/${connection.user_system_id}/system-secret`, tenant?.tenant_id],
              []
            );
          }
        }
      },
      onError: (error, { tenant }, context) => {
        queryClient.setQueryData(
          ['/connection?populateUserSystem=true', tenant?.tenant_id],
          context.connections
        );
        queryClient.setQueryData(
          [`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id],
          context.featureQuota
        );
      },
      onSettled: (data, error, { tenant, connection }) => {
        queryClient.invalidateQueries(['/connection?populateUserSystem=true', tenant?.tenant_id]);
        queryClient.invalidateQueries([`/tenant/${tenant?.tenant_id}/usage`, tenant?.tenant_id]);

        if (!connection.is_dummy) {
          if (connection.user_system.system === SYSTEMS.ODATA_SERVICE.KEY) {
            queryClient.invalidateQueries([
              `/user-system?systemCode=${SYSTEMS.ODATA_SERVICE.KEY}`,
              tenant?.tenant_id,
            ]);
          } else {
            queryClient.invalidateQueries([
              `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
              tenant?.tenant_id,
            ]);
            queryClient.invalidateQueries([
              `/user-system/${connection.user_system_id}/system-secret`,
              tenant?.tenant_id,
            ]);
          }
        }
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to delete connection',
  });

  return { deleteConnectionMutation: mutation };
};

const editConnection = (tenant, connectionId, formData) =>
  getServiceInstance(tenant?.tenant_id).put(
    `${dataflowApiBase}/connection/${connectionId}`,
    formData
  );
export const useEditConnectionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, selectedConnection, formData }) =>
      editConnection(tenant, selectedConnection.connection_id, formData),
    {
      onSuccess: (data, { tenant, selectedConnection, formData }) => {
        const connections = queryClient.getQueryData([
          '/connection?populateUserSystem=true',
          tenant?.tenant_id,
        ]);

        const updatedConnectionIndex = connections.findIndex(
          (connection) => connection.connection_id === selectedConnection.connection_id
        );

        const updatedConnections = produce(connections, (draft) => {
          draft[updatedConnectionIndex].name = formData.name;
          draft[updatedConnectionIndex].description = formData.description;
        });

        queryClient.setQueryData(
          ['/connection?populateUserSystem=true', tenant?.tenant_id],
          updatedConnections
        );
      },
      onSettled: (data, error, { tenant }) => {
        queryClient.invalidateQueries(['/connection?populateUserSystem=true', tenant?.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update the connection',
  });

  return {
    editConnectionMutation: mutation,
  };
};

const saveSecrets = (tenant, updatedConnection, formData) =>
  getServiceInstance(tenant?.tenant_id).put(
    `${systemApiBase}/user-system/${updatedConnection.user_system_id}/system-secret`,
    { ...formData, [SECRET_KEYS.ODATA_VERSION.KEY]: 2 }
  );
export const useSaveSecretsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, updatedConnection, formData }) => saveSecrets(tenant, updatedConnection, formData),
    {
      onSuccess: (data, { formData, updatedConnection, tenant }) => {
        const isOAuth = formData[SECRET_KEYS.CONNECTION_TYPE.KEY] === CONNECTION_TYPES.OAUTH.KEY;

        const metadataEndpoint = isOAuth
          ? `${formData[SECRET_KEYS.OAUTH_ENDPOINT.KEY]}/odata/v2/$metadata`
          : formData[SECRET_KEYS.SERVICE_ENDPOINT.KEY];

        queryClient.removeQueries(
          [metadataEndpoint, updatedConnection.connection_id, tenant?.tenant_id],
          { exact: true }
        );
      },
      onSettled: (data, error, { formData, updatedConnection, tenant, systemType }) => {
        queryClient.removeQueries(
          [`/user-system/${updatedConnection.user_system_id}/system-secret`, tenant?.tenant_id],
          { exact: true }
        );
        queryClient.invalidateQueries([`/user-system?systemCode=${systemType}`, tenant?.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update the secrets',
  });

  return { saveSecretsMutation: mutation };
};

const updatedConnectionType = (tenant, updatedConnection, formData) =>
  getServiceInstance(tenant?.tenant_id).put(
    `${systemApiBase}/user-system/${updatedConnection.user_system_id}`,
    formData
  );
export const useUpdateConnectionTypeMutation = () => {
  const mutation = useMutation(({ tenant, updatedConnection, formData }) =>
    updatedConnectionType(tenant, updatedConnection, formData)
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update connection type',
  });

  return { updateConnectionTypeMutation: mutation };
};
