/**
 * @file
 *
 * This component is responsible for handling the expand section of the query builder form
 */
import React from 'react';
import { Grid, Typography, Chip, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { commonTextFieldProps } from '../theme';
import { AutocompleteOption, autoCompleteFilterOptions } from './FormComponents/AutocompleteOption';
import { useExpandActions, useQueryBuilderState } from '../state/queryBuilder';
import { tourSteps } from './Onboarding/Tour';
import { AutocompleteVirtualizedListbox } from './AutocompleteVirtualizedListbox';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(1),
  },
}));

const expandStateSelector = (state) => state.expand;
export function ExpandOptions({ schema }) {
  const classes = useStyles();

  const { options, value } = useQueryBuilderState(expandStateSelector);

  const { onSelectedExpandChange } = useExpandActions(schema);

  return (
    <Grid item container spacing={2} data-tour-step={tourSteps['expand-columns'].id}>
      <Grid item container justifyContent="flex-end" alignItems="flex-start" xs={2}>
        <Grid item>
          <Typography color="textPrimary" className={classes.topMargin} variant="subtitle1">
            Expand
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={10} alignItems="center" spacing={1}>
        <Grid item xs={9}>
          <Autocomplete
            multiple
            size="small"
            limitTags={3}
            fullWidth
            autoHighlight
            autoComplete
            openOnFocus
            disableListWrap
            ListboxComponent={AutocompleteVirtualizedListbox}
            filterOptions={autoCompleteFilterOptions}
            options={options}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(columnOption) => columnOption.name}
            value={value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option.name}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label="Columns to expand" {...commonTextFieldProps} />
            )}
            renderOption={(option, { inputValue }) => (
              <AutocompleteOption label={option.label} name={option.name} inputValue={inputValue} />
            )}
            onChange={onSelectedExpandChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
