import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';

import { useTenantState } from './user';
import { getAPIBaseURL, getServiceInstance } from 'service';
import { useNotifyError } from 'hooks/useNotifyError';
import { dataflowApiBase } from 'service';
import { createStore } from '@halka/state';

const jobsFetcher = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  // Create a new CancelToken source for this request
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const promise = getServiceInstance(tenantId).get(`${dataflowApiBase}${endpoint}`, {
    cancelToken: source.token,
  });

  // Cancel the request if React Query calls the `promise.cancel` method
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export function useQueryAllJobs(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch, isFetched } = useQuery({
    queryKey: [`/connection/${connectionId}/job`, tenant?.tenant_id],
    queryFn: jobsFetcher,
    enabled: Boolean(tenant),
    refetchOnMount: tenant && connectionId ? 'always' : false,
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
    isFetched,
  };
}

const createJob = (formData, tenant, connectionId) =>
  getServiceInstance(tenant?.tenant_id).post(
    `${dataflowApiBase}/connection/${connectionId}/job`,
    formData
  );

export const useCreateJobMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ formData, tenant, connectionId }) => createJob(formData, tenant, connectionId),
    {
      onSuccess: (data, { connectionId, tenant }) => {
        queryClient.setQueryData([`/connection/${connectionId}/job`, tenant?.tenant_id], [data]);

        const jobId = data.job_id;

        const eventSource = initializeJobEventSource({
          queryClient,
          connectionId,
          jobId,
          tenantId: tenant?.tenant_id,
        });

        jobEventSourceActions.setEventSource(jobId, eventSource);
      },
      onSettled: (data, error, { connectionId, tenant }) => {
        queryClient.invalidateQueries([`/connection/${connectionId}/job`, tenant?.tenant_id]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a job',
  });

  return {
    createJobMutation: mutation,
  };
};

/**
 * Reinitializes the EventSource connection for receiving real-time updates.
 *
 * @param {Object} queryClient - The query client object used for managing API queries.
 * @param {string} connectionId - The unique identifier for the connection.
 * @param {string} job_id - The identifier for the job.
 * @param {string} tenant_id - The identifier for the tenant.
 *
 * @returns {void}
 */
export const initializeJobEventSource = ({ queryClient, connectionId, jobId, tenantId }) => {
  const jobStatusEndpoint = `${getAPIBaseURL()}/api${dataflowApiBase}/connection/${connectionId}/job/${jobId}/status?tenantId=${tenantId}`;
  const source = new EventSource(jobStatusEndpoint, { withCredentials: true });

  const jobStatus = {};

  const updateJobCache = () => {
    queryClient.setQueryData([`/connection/${connectionId}/job`, tenantId], (oldJobs) => {
      // Find the current job index
      const jobIndex = oldJobs.findIndex((job) => job.job_id === jobId);

      // Only update the array if the job is found
      if (jobIndex > -1) {
        return [
          ...oldJobs.slice(0, jobIndex),
          {
            ...oldJobs[jobIndex],
            ...jobStatus,
          },
          ...oldJobs.slice(jobIndex + 1),
        ];
      }

      return oldJobs;
    });
  };

  const updateStatus = (event) => {
    const data = JSON.parse(event.data);
    jobStatus.state = data.status;
  };

  const updateProgress = (event) => {
    const data = JSON.parse(event.data);
    jobStatus.progress = Math.round(data.progress);
    updateJobCache();
  };

  const connectionListener = (event) => {
    if (event.data === 'close') {
      queryClient.invalidateQueries([`/connection/${connectionId}/job`, tenantId]);

      source.removeEventListener('status', updateStatus);
      source.removeEventListener('progress', updateProgress);
      source.removeEventListener('connection', connectionListener);

      // Remove the EventSource connection from the map
      jobEventSourceActions.removeEventSource(jobId);

      // Close the connection
      source.close();
    }
  };

  source.addEventListener('status', updateStatus);
  source.addEventListener('progress', updateProgress);

  // When the connection is closed, re-validate the all jobs cache
  source.addEventListener('connection', connectionListener);

  return source;
};

const jobEventSourceState = createStore(new Map());
export const jobEventSourceActions = {
  getEventSourceMap: () => {
    return jobEventSourceState.get();
  },
  getEventSource: (jobId) => {
    const eventSourceMap = jobEventSourceState.get();
    return eventSourceMap.get(jobId);
  },
  checkEventSource: (jobId) => {
    const eventSourceMap = jobEventSourceState.get();
    return eventSourceMap.has(jobId);
  },
  setEventSource: (jobId, eventSource) => {
    const eventSourceMap = jobEventSourceState.get();
    eventSourceMap.set(jobId, eventSource);
    jobEventSourceState.set(eventSourceMap);
  },
  removeEventSource: (jobId) => {
    const eventSourceMap = jobEventSourceState.get();
    const eventSource = eventSourceMap.get(jobId);

    if (eventSource) {
      eventSourceMap.delete(jobId);
      jobEventSourceState.set(eventSourceMap);
    }
  },
};
