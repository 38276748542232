/**
 * @file
 *
 * this file contains the hook used by the datetime field
 */
import { castToDayjsDate, checkIsDateValid } from 'date';
import { dateTimeParamOptions, relativeDateTimeOptions } from 'odata/queryBuilder';
import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';

const checkIfValueIsVariable = (value) =>
  typeof value === 'string' && value.match('^[$][A-Z]+[$]$') ? true : false;

const checkIsVariableSupported = (value) => dateTimeParamOptions.some(({ key }) => key === value);

export const checkIsVariableValid = (value) => {
  if (checkIfValueIsVariable(value)) {
    return checkIsVariableSupported(value);
  }

  return false;
};

export const useDateTimePicker = ({ dateFormat, value, onChange, clearable, resetCounter }) => {
  // Picker open/close handler
  const [isPickerOpen, setIsPickerOpen] = useState({
    datetime: false,
  });

  const openDateTimePicker = useCallback(() => {
    setIsPickerOpen((state) => ({ ...state, datetime: true }));
  }, []);
  const closeDateTimePicker = useCallback(() => {
    setIsPickerOpen((state) => ({ ...state, datetime: false }));
  }, []);

  // Handle field inputs
  const [inputValue, updateInputValue] = useState(() => {
    if (checkIsVariableValid(value?.key)) {
      return value.key;
    } else {
      const date = castToDayjsDate(value);

      if (checkIsDateValid(date)) {
        return date.format(dateFormat);
      }
    }

    return '';
  });

  const [isInputValid, updateIsInputValid] = useState(() => {
    if (value) {
      if (typeof value?.key === 'string' && value.key.startsWith('$')) {
        return checkIsVariableValid(value.key);
      } else {
        return checkIsDateValid(value);
      }
    }

    return clearable;
  });

  // The resetCounter state gets updated whenever the effective-range fields are reseted, we are listening
  // to the changes in resetCounter to reset the inputValue and the valid status of the input.
  useUpdateEffect(() => {
    updateInputValue('');
    updateIsInputValid(true);
  }, [resetCounter]);

  // onChange handler
  const handleChange = useCallback(
    (input) => {
      // There are 2 types of inputs this function will handle:
      // 1) Input from the textfield (custom date/time - typed by the user)
      // 2) Input from the Picker (custom data/time - picked from the picker UI)

      if (input?.target) {
        // The input passed by a textfield will be an event. We check if the input has a target
        // property, if it does then we know that the input is a user-typed input

        const value = input.target.value;

        // There can be 2 types of user inputs
        // 1) A valid date: 2021-10-13
        // 2) A variable: $TODAY$

        // If the value matches the regex, it means that the value is a variable. So, we
        // check if we support the variable, if we do, we push it to the query builder state
        if (typeof value === 'string' && value.startsWith('$')) {
          const isCurrentValueValid = checkIsVariableValid(value);

          updateIsInputValid(isCurrentValueValid);

          updateInputValue((previousValue) => {
            const isPreviousValueValid = previousValue.startsWith('$')
              ? checkIfValueIsVariable(previousValue)
              : checkIsDateValid(previousValue);

            // If the previous value is valid and if the current value is not valid
            // then return null
            const isVariableSupported = checkIsVariableValid(value);
            if (isVariableSupported) {
              onChange(relativeDateTimeOptions[value]);
            }

            if (isPreviousValueValid && !isVariableSupported) {
              onChange(null);
            }

            return value;
          });
        } else {
          const date = castToDayjsDate(value, dateFormat);
          const isCurrentValueValid = checkIsDateValid(date);

          updateIsInputValid(isCurrentValueValid);

          updateInputValue((previousValue) => {
            const isPreviousValueValid = previousValue.startsWith('$')
              ? checkIsVariableValid(previousValue)
              : checkIsDateValid(previousValue);

            setTimeout(() => {
              if (isCurrentValueValid) {
                onChange(date);
              } else if (isPreviousValueValid && !isCurrentValueValid) {
                onChange(null);
              }
            });

            return value;
          });
        }
      }
      // If event.target doesn't exists, it means that the date was selected from the date picker.
      // The arguement we recieve in the handleChange function in this case is a date object and we have to convert that date to the valid format before updating
      // the "inputDate" state as we are using the state to track the controlled TextField and we want to show the value of the Textfield in our desired format
      else {
        const date = input?.format(dateFormat);
        // If date is selected from the DatePicker, we are already moulding it in the required format in the above line of code
        // so we can directly set the isDateValid to true
        updateIsInputValid(true);
        updateInputValue(date ?? '');
        onChange(input);
      }
    },
    [dateFormat, onChange]
  );

  const [autocompleteValue, updateAutocompleteValue] = useState(() => {
    if (checkIsVariableValid(value?.key)) {
      return value.key;
    } else if (!isNaN(new Date(value))) {
      return relativeDateTimeOptions.CUSTOM.key;
    }

    return null;
  });
  const handleAutocompleteChange = useCallback(
    (event, value, reason) => {
      if (reason === 'clear') {
        updateInputValue('');
        updateAutocompleteValue(null);
        onChange(null);
      } else if (reason === 'select-option') {
        if (value) {
          if (value.key === relativeDateTimeOptions.CUSTOM.key) {
            openDateTimePicker();
          } else {
            updateIsInputValid(true);
            updateInputValue(value.key);
            onChange(value);
          }
          updateAutocompleteValue(value.key);
        }
      }
    },
    [openDateTimePicker, onChange]
  );

  const isError = clearable
    ? (!inputValue && typeof inputValue !== 'number' ? null : inputValue) !== null && !isInputValid
    : !isInputValid;

  return {
    dateTimePicker: {
      open: isPickerOpen.datetime,
      onOpen: openDateTimePicker,
      onClose: closeDateTimePicker,
      value: inputValue,
      onChange: handleChange,
    },
    textfield: {
      error: isError,
      onChange: handleChange,
    },
    autocomplete: {
      // inputValue is the value inside the textfield of the autocomplete
      inputValue,
      // value is the option selected in the autocomplete
      value: autocompleteValue,
      onChange: handleAutocompleteChange,
    },
  };
};
