/**
 * @file
 *
 * This file contains the error / not found page that displays a title and body based on the redirect state
 */
import React, { useMemo } from 'react';
import { Grid, Button, Typography, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Nav } from '../components/Nav';
import { getPlatformURL } from '../service';
import { API_ERROR_TYPES, EMAIL_FORMATS } from '../constants';
import { AccessDeniedImage } from '../components/Illustrations/AccessDenied';
import { NotFoundImage } from '../components/Illustrations/NotFound';
import { useLoggedInUser, useTenantState } from '../data/user';
import { CONFIG } from '../config';
import { EmptyImage } from '../components/Illustrations/Empty';
import { ErrorImage } from '../components/Illustrations/Error';
import { useInitialLoadError } from '../hooks/useInitialLoadChecks';
import { getMailToString } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: `calc(100vh - ${theme.spacing(2)}px)`,
  },
  card: {
    maxWidth: theme.spacing(80),
    minWidth: theme.spacing(60),
  },
  actionButton: {
    marginTop: theme.spacing(2),
  },
  errorTitle: {
    margin: theme.spacing(2),
  },
}));

const ErrorAction = ({ isFatal, onboardedDirectly, supportEmail, ...rest }) => {
  const tenant = useTenantState();

  if (isFatal) {
    return onboardedDirectly ? (
      <Typography
        variant="h6"
        component="p"
        align="center"
        color="textSecondary"
        gutterBottom
        {...rest}
      >
        {supportEmail}
      </Typography>
    ) : (
      <Button
        variant="contained"
        color="primary"
        disableElevation
        {...rest}
        href={getPlatformURL()}
      >
        Go back to Platform Home
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      component={RouterLink}
      to={tenant ? `/t/${tenant.tenant_id}` : '/'}
      {...rest}
    >
      Go back to Dataflow Home
    </Button>
  );
};

export function Error() {
  const classes = useStyles();

  const { state } = useLocation();

  useInitialLoadError();

  const { user } = useLoggedInUser();
  const tenant = useTenantState();

  const derivedState = useMemo(() => {
    const errorType = state?.errorType;

    switch (errorType) {
      case API_ERROR_TYPES.UNAUTHORIZED:
        return {
          image: <AccessDeniedImage height={224} width={330} />,
          title: 'You are not authorized!',
          body: 'You may have selected the wrong tenant or are not authorized to use this app or project. Try changing your tenant from the tenant selection menu at the top.',
          action: (
            <ErrorAction
              isFatal={state.isFatal}
              onboardedDirectly={!user.email || user.has_onboarded_directly}
              supportEmail={
                <>
                  Stuck? Reach out to us at{' '}
                  <Link
                    href={getMailToString({
                      emailSubject: EMAIL_FORMATS.ERROR.subject,
                      emailBody: EMAIL_FORMATS.ERROR.getBody(user, tenant),
                    })}
                  >
                    {CONFIG.SUPPORT_EMAIL}
                  </Link>
                </>
              }
              className={classes.actionButton}
            />
          ),
        };
      case API_ERROR_TYPES.BILLING_PLAN:
        return {
          image: <EmptyImage height={224} width={330} />,
          title: 'This tenant is not assigned with any usage quota',
          body: 'You are not assigned any plan as of now and this may prevent you from using the application.',
          action: (
            <Typography
              variant="h6"
              component="p"
              align="center"
              color="textSecondary"
              gutterBottom
              className={classes.actionButton}
            >
              Please reach out to us at{' '}
              <Link
                href={getMailToString({
                  emailSubject: EMAIL_FORMATS.INQUIRY.subject,
                  emailBody: EMAIL_FORMATS.INQUIRY.getBody(user, tenant),
                })}
              >
                <strong>{CONFIG.SUPPORT_EMAIL}</strong>
              </Link>
              ​ to get a plan assigned
            </Typography>
          ),
        };
      case API_ERROR_TYPES.INCORRECT_PAYLOAD:
        return {
          image: <ErrorImage height={224} width={330} />,
          title: 'Oops! Something unexpected happened!',
          body:
            state?.errorDetails ??
            'Our team has been notified of the issue and it will be resolved soon!',
          action: (
            <Typography
              variant="h6"
              component="p"
              align="center"
              color="textSecondary"
              gutterBottom
              className={classes.actionButton}
            >
              Please reach out to us at{' '}
              <Link
                href={getMailToString({
                  emailSubject: EMAIL_FORMATS.INQUIRY.subject,
                  emailBody: EMAIL_FORMATS.INQUIRY.getBody(user, tenant),
                })}
              >
                <strong>{CONFIG.SUPPORT_EMAIL}</strong>
              </Link>
              ​ to get a plan assigned
            </Typography>
          ),
        };
      default:
        return user?.email
          ? {
              image: <NotFoundImage height={224} width={330} />,
              title: 'Could not find what you are looking for!',
              body:
                state?.errorDetails ??
                'Go back to the Dataflow home screen to find all the connections',
              action: (
                <ErrorAction
                  isFatal={state?.isFatal}
                  onboardedDirectly={!user.email || user.has_onboarded_directly}
                  supportEmail={
                    <>
                      Reach out to us at{' '}
                      <Link
                        href={getMailToString({
                          emailSubject: EMAIL_FORMATS.ERROR.subject,
                          emailBody: EMAIL_FORMATS.ERROR.getBody(user, tenant),
                        })}
                      >
                        {CONFIG.SUPPORT_EMAIL}
                      </Link>
                    </>
                  }
                  className={classes.actionButton}
                />
              ),
            }
          : {
              image: <NotFoundImage height={224} width={330} />,
              title: 'Oops! Something unexpected happened!',
              body:
                state?.errorDetails ??
                'Our team has been notified of the issue and they are working on resolving it!',
              action: (
                <ErrorAction
                  isFatal={state?.isFatal}
                  onboardedDirectly={!user.email || user.has_onboarded_directly}
                  supportEmail={
                    <>
                      Reach out to us at{' '}
                      <Link
                        href={getMailToString({
                          emailSubject: EMAIL_FORMATS.ERROR.subject,
                          emailBody: EMAIL_FORMATS.ERROR.getBody(user, tenant),
                        })}
                      >
                        {CONFIG.SUPPORT_EMAIL}
                      </Link>{' '}
                      for more info
                    </>
                  }
                  className={classes.actionButton}
                />
              ),
            };
    }
  }, [state, tenant, user, classes]);

  return (
    <>
      <Nav title={derivedState?.title ? 'Error' : 'Not Found'} />
      <Grid
        className={classes.container}
        direction="column"
        container
        justifyContent="center"
        alignItems="center"
      >
        {derivedState.image}
        <ErrorTitle derivedState={derivedState} />
        <ErrorBody derivedState={derivedState} />
        {derivedState.action}
      </Grid>
    </>
  );
}

const ErrorTitle = ({ derivedState }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.errorTitle}
      variant="h5"
      component="p"
      align="center"
      color="error"
      gutterBottom
    >
      {derivedState?.title}
    </Typography>
  );
};

const ErrorBody = ({ derivedState }) => {
  return (
    <Typography variant="h6" component="p" align="center" color="textSecondary" gutterBottom>
      {derivedState?.body}
    </Typography>
  );
};
