import React, { Children, useContext } from 'react';
import { Grid, Button, useTheme, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MdClose, MdSkipNext, MdSkipPrevious } from 'react-icons/md';

import {
  tourStateActions,
  tourSteps,
  TourStepContext,
  useTourState,
  updateAppOnboardedStatus,
} from './Tour';

export function StepLayout({
  children,
  showNextStepButton,
  showPrevStepButton,
  isFinalStep,
  references,
}) {
  const theme = useTheme();

  const { closeTooltip, currentStepId } = useContext(TourStepContext);
  const { onboardedOnce } = useTourState();

  const handleSkipTutorial = () => {
    tourStateActions.reset();
    if (!onboardedOnce) {
      updateAppOnboardedStatus();
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={2}
      style={{ padding: theme.spacing(2) }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          <Button variant="outlined" color="primary" size="small" onClick={handleSkipTutorial}>
            Skip Tutorial
          </Button>
        </Grid>
        <Grid item>
          <IconButton size="small" onClick={closeTooltip}>
            <MdClose />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        {Children.map(children, (child) => (
          <Grid item>{child}</Grid>
        ))}
      </Grid>
      {references && (
        <Grid
          item
          component={Alert}
          severity="info"
          role="contentinfo"
          style={{ marginTop: theme.spacing(2) }}
        >
          {references}
        </Grid>
      )}
      {(showNextStepButton || showPrevStepButton) && (
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: theme.spacing(2) }}
        >
          <Grid item>
            {showPrevStepButton && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disableElevation
                onClick={tourSteps[currentStepId]?.prev}
                startIcon={<MdSkipPrevious />}
              >
                Previous
              </Button>
            )}
          </Grid>
          <Grid item>
            {showNextStepButton && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={tourSteps[currentStepId]?.next}
                endIcon={!isFinalStep && <MdSkipNext />}
              >
                {isFinalStep ? 'Finish Tour' : 'Next'}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
