import { useEffect } from 'react';
import { createStore } from '@halka/state';
import { useHistory } from 'react-router-dom';

const useInitialLoad = createStore(true);

export function useInitialLoadNormal() {
  useEffect(() => {
    useInitialLoad.set(false);
  }, []);
}

export function useInitialLoadError() {
  const history = useHistory();

  useEffect(() => {
    const isInitialLoad = useInitialLoad.get();

    if (isInitialLoad) {
      history.push('/');
    }

    useInitialLoad.set(false);
  }, [history]);
}
