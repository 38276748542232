import React from 'react';

function GenericSuccessIllustration(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={693}
      height={576.898}
      data-name="Layer 1"
      viewBox="0 0 722 568.282"
      {...props}
    >
      <path
        fill="#354a5f"
        d="M172.93 443.972h497.38a21.689 21.689 0 0 0 21.69-21.69v-287.62a21.35 21.35 0 0 0-.76-5.69 21.683 21.683 0 0 0-20.93-16H476.04a60.38 60.38 0 0 0-8.18 25.01c-2.41 25.69 12.32 49.44 25.5 71.63 13.18 22.19 25.75 47.99 18.77 72.84-4.72 16.76-17.51 28.89-33.38 36.16a93.665 93.665 0 0 1-48.59 7.4 152.118 152.118 0 0 1-29.26-6.68c-12.47-4.05-24.65-9.3-36.81-14.61-.01 0-.01 0-.02-.01-.21-.09-.43-.19-.64-.28-9.41-4.11-18.81-8.23-28.35-11.86a173.14 173.14 0 0 0-34.62-9.54c-17.99-2.82-36.13-1.8-52.18 6.36-26.16 13.29-40.18 42.01-51.15 69.23-11.08 27.52-21.01 55.96-24.2 85.35ZM1 333.942v88.34a21.689 21.689 0 0 0 21.69 21.69h82.68c1.43-13.33.23-26.61-5.02-39-11.44-27.02-39.12-43-65.51-55.83-11.09-5.39-22.37-10.59-33.84-15.2Z"
      />
      <path
        fill="#354a5f"
        d="M87.647 423.572a21.69 21.69 0 0 0 21.69 21.69h82.68c1.43-13.33.23-26.61-5.02-39-2.69-6.352-8.972-13.39-17.159-20.498-32.118-27.888-82.191-4.728-82.191 37.808Z"
      />
      <path
        fill="#3f3d56"
        d="M0 422.282v-287.62a22.716 22.716 0 0 1 22.69-22.69h647.62a22.716 22.716 0 0 1 22.69 22.69v287.62a22.716 22.716 0 0 1-22.69 22.69H22.69A22.716 22.716 0 0 1 0 422.282Zm22.69-308.31A20.713 20.713 0 0 0 2 134.662v287.62a20.713 20.713 0 0 0 20.69 20.69h647.62a20.713 20.713 0 0 0 20.69-20.69v-287.62a20.713 20.713 0 0 0-20.69-20.69Z"
      />
      <path
        fill="#3f3d56"
        d="M214.31 443.262h29.41c5.03-16.936 12.29-34.889 18.92-51.249 12.72-31.368 28.97-64.465 59.3-79.782 12.95-6.54 27.07-9.094 41.49-8.955 19.92.189 40.4 5.536 59.15 12.623 7.67 2.892 15.27 6.073 22.84 9.323 28.52 12.255 56.73 25.603 87.4 29.211 38.81 4.562 84.44-12.802 95.04-50.203 8.09-28.635-6.48-58.363-21.76-83.936-15.28-25.574-32.36-52.937-29.57-82.546.01-.099.02-.208.04-.308 1.05-10.635 7.4-18.278 16.27-23.178h-38.62c-.51 27.621 15.47 52.002 29.85 76.075 15.28 25.564 29.85 55.302 21.76 83.936-10.6 37.392-98.373-6.439-137.183-11.01-10.96-1.283 6.45 3.28-4 0-16.39-5.14-18.207 41.991-34.157 35.073-9.93-4.313-19.87-8.597-29.94-12.404-4.17-1.58-8.43-3.061-12.74-4.433-.01 0-.01 0-.02-.01-28.94-9.164-60.59-12.732-87.33.517-.18.09-.37.18-.55.269-30.33 15.306-46.58 48.404-59.3 79.772-10.51 25.931-21.12 53.863-26.3 81.215ZM214.63 237.11H23.338a5.91 5.91 0 1 0 0 11.82H214.63a5.91 5.91 0 1 0 0-11.82ZM82.781 203.972h-59.31a5.91 5.91 0 1 0 0 11.82h59.31a5.91 5.91 0 0 0 0-11.82Z"
      />
      <path fill="#ffb6b6" d="m311.498 549.925-12.124-.102-5.366-47.851 17.892.151-.402 47.802z" />
      <path
        fill="#2f2e41"
        d="M272.665 569.203a5.427 5.427 0 0 0 5.374 5.468l24.104.2 4.22-8.552 1.55 8.596 9.094.083-2.297-30.662-3.164-.21-12.906-.89-4.164-.279-.073 8.675-19.362 13.133a5.418 5.418 0 0 0-2.376 4.438Z"
      />
      <path fill="#ffb6b6" d="m434.498 549.925-12.124-.102-5.366-47.851 17.892.151-.402 47.802z" />
      <path
        fill="#2f2e41"
        d="M395.665 569.203a5.427 5.427 0 0 0 5.374 5.468l24.104.2 4.22-8.552 1.55 8.596 9.094.083-2.297-30.662-3.164-.21-12.906-.89-4.164-.279-.073 8.675-19.362 13.133a5.418 5.418 0 0 0-2.376 4.438Z"
      />
      <path
        fill="#ffb6b6"
        d="m296.542 166.72 54.563-41.99a11.113 11.113 0 0 0 2.09-15.452 11.113 11.113 0 0 0-16.02-1.863l-55.34 45.356-34.789 26.143a9.698 9.698 0 1 0 4.238 11.702Z"
      />
      <path
        fill="#a0616a"
        d="m341.013 176.374-2.405 53.714 81.686 9.884c-6.428-21.583-16.968-51.562-12.138-66.583Z"
      />
      <path
        fill="#e6e6e6"
        d="M387.446 77.9h-25.162l-10.806 18.491-5.149-.284c-10.035 3.865-14.035 7.865-19.573 18.522l-19.462 16.343 15 18c10.36-1.697 14.096-9.74 10.806-24.51 0 0-11.346 19.773 4.998 29.501 0 0-2.915 18.328 0 18.131s9.63-3.927 2.915-.197-5.564 3.891-5.345 5.1c3.626 19.975 6.626 34.975 6.626 34.975l71 7s-4.091-42.938-3.869-45.38-1.27 3.528.223-2.44 3.73-3.73 1.492-5.969 15.385-4.165 8.507-14.92l23-6-12.856-30.556c0-10.317-16.57-18.68-26.887-18.68h-1.321Z"
      />
      <path
        fill="#2f2e41"
        d="M414.223 210.859c-27.777 9.554-52.784 7.456-73.929-10.887 0 0-5.95 22-11.975 19s-3.047 5.395-4.702 8.472c-1.757 3.268.283 8.78-5.52 10.654s-3.623 7.382-5.502 11.435c-13.146 28.364-28.159 68.514-38.3 119.44-10.423 52.328 15 140 15 140l24-2 6-114 27.442-48.187s13.481-7.85 5.02-11.832-2.794-8.917 3.372-7.95 13.165-31.032 13.165-31.032c-8.79 22.092 46 217 46 217l23-2-13-272ZM402.38 31.478c2.727 6.794 10.422 20.153 11.464 27.44l-.469-.46a32.901 32.901 0 0 1-3.04 4.347 21.133 21.133 0 0 0-7.177-4.934 25.297 25.297 0 0 1 2.52 9.706c-5.01 4.363-17.804 1.73-24.196 4.056-10.287 3.732-21.208 5.06-31.991 6.164-3.827.394-8.112.633-10.794-1.629s-1.085-6.45-2.528-9.617c-10.279-22.565-.793-30.706 7.267-40.938 1.773-2.251 1.766-5.611 3.924-7.76 2.994-2.977 7.584-3.893 11.816-4.627a6.413 6.413 0 0 1-5.633-2.788 5.785 5.785 0 0 1 .076-6.038c1.514-2.51 4.659-3.968 7.693-4.333a29.21 29.21 0 0 1 8.903.765 15.93 15.93 0 0 1 5.39 1.724 4.412 4.412 0 0 1 2.298 4.471 5.715 5.715 0 0 1-3.38 3.643 14.96 14.96 0 0 1-3.82 1.046 29.972 29.972 0 0 1 17.58 3.077c6.783 3.618 11.36 9.896 14.096 16.685Z"
      />
      <circle cx={365.655} cy={45.32} r={24.244} fill="#ffb6b6" />
      <path
        fill="#2f2e41"
        d="M388.984 32.32c-1.406 4.25-5.423 5.618-9.136 6.472-1.17.273-2.349.527-3.537.753a23.642 23.642 0 0 0-1.393-1.593 21.133 21.133 0 0 0-7.176-4.935 24.905 24.905 0 0 1 2.306 7.612q-3.367.488-6.771.798a7.655 7.655 0 0 1-8.991-5.516c-2.066 3.378-4.509 6.56-7.85 7.902a9.5 9.5 0 0 1-10.806-3.233c-2.001-2.44-3.115-5.72-3.917-8.884-.948-3.762-1.474-8.096.387-10.483 1.491-1.932 4.063-1.967 6.437-1.795a76.606 76.606 0 0 1 15.468 2.804l.427.96c-.867-5.21 1.014-10.222 4.495-12.005 2.347-1.192 5.172-.992 7.916-.75a36.078 36.078 0 0 1 10.338 1.999 19.857 19.857 0 0 1 9.369 7.483c2.436 3.699 3.646 8.743 2.434 12.412Z"
      />
      <path
        fill="#2f2e41"
        d="M354.422 9.66c.239-.07-.381.021-.166.027a1.975 1.975 0 0 1-.317-.045c-.018-.003-.26-.08-.1-.024a1.946 1.946 0 0 1-.924-.603 8.063 8.063 0 0 1-1.321-2.352 8.447 8.447 0 0 0-1.818-3.03 2.079 2.079 0 0 0-3.317.506c-.472 1.044-.04 2.331.296 3.357a10.423 10.423 0 0 0 1.532 2.97 9.532 9.532 0 0 0 4.38 3.214 9.97 9.97 0 0 0 6.267.179 1.514 1.514 0 0 0 .06-2.771l-3.425-1.402a1.5 1.5 0 1 0-1.208 2.746l3.425 1.402.06-2.771a5.966 5.966 0 0 1-1.31.224 5.023 5.023 0 0 1-1.42-.06 6.227 6.227 0 0 1-1.315-.32 8.568 8.568 0 0 1-.8-.34q-.142-.068-.281-.143c.006.004-.392-.228-.217-.12a8.459 8.459 0 0 1-1.242-.966 3.415 3.415 0 0 1-.372-.415 7.94 7.94 0 0 1-.542-.751 7.792 7.792 0 0 1-.796-1.763 7.899 7.899 0 0 1-.22-.792 3.25 3.25 0 0 0-.08-.435c-.127-.342.025.199-.122.347l-.63.465a.824.824 0 0 1-.707-.113q-.112-.158.05.052a4.896 4.896 0 0 1 .342.518 14.16 14.16 0 0 1 .614 1.3 14.26 14.26 0 0 0 1.42 2.683 4.83 4.83 0 0 0 5.29 2.023 1.512 1.512 0 0 0 .855-1.942 1.53 1.53 0 0 0-1.941-.854Z"
      />
      <circle cx={316.609} cy={201.382} r={71.685} fill="#fff" />
      <path
        fill="#3f3d56"
        d="M316.609 274.067a72.685 72.685 0 1 1 72.685-72.686 72.768 72.768 0 0 1-72.685 72.686Zm0-143.37a70.685 70.685 0 1 0 70.685 70.684 70.765 70.765 0 0 0-70.685-70.684Z"
      />
      <path
        fill="#354a5f"
        d="M306.83 238.69a7.972 7.972 0 0 1-6.379-3.19l-19.557-26.076a7.974 7.974 0 1 1 12.759-9.568l12.794 17.058 32.862-49.292a7.974 7.974 0 1 1 13.27 8.847l-39.114 58.67a7.977 7.977 0 0 1-6.413 3.549 12.73 12.73 0 0 1-.222.002Z"
      />
      <path
        fill="#ccc"
        d="M237.648 575.708a1.186 1.186 0 0 0 1.19 1.19h253.29a1.19 1.19 0 1 0 0-2.38h-253.29a1.187 1.187 0 0 0-1.19 1.19Z"
      />
      <path
        fill="#ffb6b6"
        d="m461.911 203.833-23.433-64.74a11.113 11.113 0 0 0-14.086-6.683 11.113 11.113 0 0 0-6.642 14.697l26.403 66.502 14.34 41.085a9.698 9.698 0 1 0 12.437-.483Z"
      />
      <path
        fill="#e6e6e6"
        d="m426.647 110.262 19 34s-43.787 14.992-43.894 12.496 2.176-37.134 2.176-37.134Z"
      />
    </svg>
  );
}

export default GenericSuccessIllustration;
