/**
 * @file
 *
 * this file contains a custom datetime picker toolbar extended from mui pickers to support for date and time selection in a single component
 */
import React from 'react';

import { Button, Grid, makeStyles, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { MdAccessTime, MdEvent } from 'react-icons/md';
import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { getPaddedDateOrTimeString } from '../../date';

dayjs.extend(customParseFormat);

const useStyles = makeStyles((theme) => ({
  toolbarBackgroud: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(10),
    width: theme.spacing(41),
    padding: theme.spacing(3),
  },
  tabGroup: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  year: {
    color: theme.palette.common.white,
    fontSize: theme.spacing(1.72),
    opacity: theme.spacing(0.05),
    fontWeight: theme.typography.fontWeightBold,
  },
  dateAndTime: {
    fontSize: theme.spacing(3.7),
    color: theme.palette.common.white,
    opacity: theme.spacing(0.05),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
  },
  active: {
    opacity: theme.spacing(1),
  },
}));

const DateTimeButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    minWidth: theme.spacing(0.6),
    lineHeight: theme.spacing(0.17),
  },
}))(Button);

function a11yProps(index) {
  return {
    id: `date-or-time-tabs-${index}`,
    'aria-controls': `date-or-time-tabpanel-${index}`,
  };
}

export const VIEWS = {
  DATE: 'date',
  YEAR: 'year',
  MONTH: 'month',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const timeFields = [VIEWS.HOURS, VIEWS.MINUTES, VIEWS.SECONDS];

export function DateTimePickerToolbar({ date, setOpenView, openView }) {
  const classes = useStyles();

  // This function updates the view of the DateTimePicker according to the button that is selected. If the 'hours' button is selected, the this
  // function handles the task of setting the view of the DateTimePicker to hours.
  const updateTabView = (event) => {
    if (event.currentTarget.dataset.view) {
      setOpenView(event.currentTarget.dataset.view);
    }
  };

  const day = getPaddedDateOrTimeString(date.$d.getDate());
  const year = getPaddedDateOrTimeString(date.$d.getFullYear());
  const month = date.$d.getMonth();
  const hours = getPaddedDateOrTimeString(date.$d.getHours());
  const minutes = getPaddedDateOrTimeString(date.$d.getMinutes());
  const seconds = getPaddedDateOrTimeString(date.$d.getSeconds());

  return (
    <>
      <Grid item container className={classes.toolbarBackgroud}>
        <Grid item style={{ width: '100%' }}>
          <Typography
            component={DateTimeButton}
            size="small"
            data-view={VIEWS.YEAR}
            onClick={updateTabView}
            className={clsx(classes.year, {
              [classes.active]: openView === VIEWS.YEAR,
            })}
          >
            {year}
          </Typography>
        </Grid>
        <Grid item container>
          <Grid item style={{ width: '40%' }}>
            <Typography
              component={DateTimeButton}
              disableRipple
              data-view={VIEWS.DATE}
              onClick={updateTabView}
              className={clsx(classes.dateAndTime, {
                [classes.active]: openView === VIEWS.DATE || openView === VIEWS.MONTH,
              })}
            >
              {months[month]} {day}
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: '60%' }}
          >
            <Typography
              className={clsx(classes.dateAndTime, {
                [classes.active]: openView === VIEWS.HOURS,
              })}
              component={DateTimeButton}
              disableRipple
              data-view={VIEWS.HOURS}
              onClick={updateTabView}
            >
              {hours}
            </Typography>
            <span className={classes.dateAndTime}>:</span>
            <Typography
              className={clsx(classes.dateAndTime, {
                [classes.active]: openView === VIEWS.MINUTES,
              })}
              component={DateTimeButton}
              data-view={VIEWS.MINUTES}
              onClick={updateTabView}
            >
              {minutes}
            </Typography>
            <span className={classes.dateAndTime}>:</span>
            <Typography
              className={clsx(classes.dateAndTime, {
                [classes.active]: openView === VIEWS.SECONDS,
              })}
              component={DateTimeButton}
              data-view={VIEWS.SECONDS}
              onClick={updateTabView}
            >
              {seconds}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Tabs
        value={timeFields.includes(openView) ? 1 : 0}
        onChange={updateTabView}
        aria-label="date or time tabs"
        className={classes.tabGroup}
      >
        <Tab
          label={<MdEvent data-view={VIEWS.DATE} fontSize="23px" />}
          data-view={VIEWS.DATE}
          {...a11yProps(0)}
        />
        <Tab
          label={<MdAccessTime data-view={VIEWS.HOURS} fontSize="23px" />}
          data-view={VIEWS.HOURS}
          {...a11yProps(1)}
        />
      </Tabs>
    </>
  );
}
