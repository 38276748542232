/**
 * @file
 *
 * The global error boundary fallback component
 */
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { CONFIG } from '../config';

export const SENTRY_ERROR_TYPES = {
  APP_CRASH: 'app_crash',
  API_ERROR: 'api_error',
};

export function GlobalErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    if (CONFIG.ENV === 'production') {
      const scope = new Sentry.Scope();
      scope.setLevel(Sentry.Severity.Fatal);
      scope.setTag('type', SENTRY_ERROR_TYPES.APP_CRASH);
      Sentry.captureException(error, scope);
    }

    return resetErrorBoundary;
  }, [error, resetErrorBoundary]);

  return (
    <Redirect
      to={{
        pathname: '/error',
        state: {
          title: 'Something unexpected happened',
          body: 'Our team has been reported of the issue automatically and a fix will be dispatched soon',
          isFatal: true,
        },
      }}
    />
  );
}
