import React from 'react';
import { Card, makeStyles, Grid, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    width: theme.spacing(40),
    height: theme.spacing(28),
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export function CreateConnectionLoader() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      component={Card}
      variant="outlined"
      className={classes.card}
      spacing={2}
    >
      <Skeleton
        variant="circle"
        style={{ marginTop: theme.spacing(2) }}
        height={theme.spacing(10)}
        width={theme.spacing(10)}
      />

      <Skeleton
        variant="rect"
        style={{ marginTop: theme.spacing(2) }}
        height={theme.spacing(4)}
        width={theme.spacing(32)}
      />
    </Grid>
  );
}

export function ConnectionCardLoader() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      component={Card}
      variant="outlined"
      className={classes.card}
      spacing={2}
    >
      <Skeleton
        variant="text"
        style={{ marginTop: theme.spacing(0.5) }}
        height={theme.spacing(2)}
        width={theme.spacing(25)}
      />

      <Skeleton
        variant="rect"
        style={{ marginTop: theme.spacing(0.6) }}
        height={theme.spacing(4)}
        width={theme.spacing(32)}
      />
      <Skeleton
        variant="text"
        style={{ marginTop: theme.spacing(1) }}
        height={theme.spacing(4)}
        width={theme.spacing(32)}
      />
      <Skeleton
        variant="text"
        style={{ marginTop: theme.spacing(-1) }}
        height={theme.spacing(4)}
        width={theme.spacing(32)}
      />

      <Skeleton
        variant="text"
        style={{ marginTop: theme.spacing(1) }}
        height={theme.spacing(4)}
        width={theme.spacing(36)}
      />

      <Skeleton
        variant="circle"
        style={{
          marginTop: theme.spacing(1.5),
          position: 'absolute',
          bottom: theme.spacing(1.5),
          left: theme.spacing(1.5),
        }}
        height={theme.spacing(4)}
        width={theme.spacing(4)}
      />
    </Grid>
  );
}
