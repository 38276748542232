import { nanoid } from 'nanoid';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { purgeIDB } from '../reportView/worker';

const TabIndexedStorageContext = createContext(null);
const localStorageKey = '__integrtr_dataflow_tab_id';

/**
 * The tab context provider provides a unique ID for the current tab for the lifetime of the
 * app. This is useful for separating the contexts of Indexed Storage that is useful in Report
 * View (otherwise using same storage between tabs can cause data inconsistency).
 */
export default function TabIndexedStorageContextProvider({ children }) {
  const [tabId] = useState(() => nanoid());

  useTrackCurrentUsedTab({ tabId });

  return (
    <TabIndexedStorageContext.Provider value={tabId}>{children}</TabIndexedStorageContext.Provider>
  );
}

const durationToStale = 600000; // 10 minutes.
const durationToCheckTab = 60000; // 1 minute.

/**
 * Tracks the currently used tabs in a browser. It does this by storing the timestamp for
 * the current tab when it was last active. It does it every 60 seconds. Why 60 seconds?
 * Because it provides enough time for enough tabs to not conflict during `setItem` because
 * every tab will write to the same localStorage key.
 *
 * This is done to remove any indexed storages that correspond to a stale tab.
 */
function useTrackCurrentUsedTab({ tabId }) {
  useEffect(() => {
    const interval = setInterval(async () => {
      const tabIds = JSON.parse(window.localStorage.getItem(localStorageKey)) || {};

      // Remove all inactive tab's indexed storage.
      const now = Date.now();
      await Promise.all(
        Object.keys(tabIds).map(async (id) => {
          const time = tabIds[id];

          // If the tab has not been active for over 10 minutes, it's time to remove.
          if (now - time > durationToStale) {
            await purgeIDB({ tabId: id });
          }
        })
      );

      // Filter out the stale tab ids.
      Object.keys(tabIds).forEach((id) => {
        const time = tabIds[id];

        if (now - time > durationToStale) {
          delete tabIds[id];
        }
      });

      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...tabIds,
          [tabId]: Date.now(),
        })
      );
    }, durationToCheckTab);

    return () => {
      clearInterval(interval);
    };
  }, [tabId]);
}

/**
 * Hook to get the current tab ID. This ID is unique for a tab for the lifetime of the app.
 */
export function useCurrentTabId() {
  return useContext(TabIndexedStorageContext);
}
