/**
 * @file
 *
 * React hook to display error toast on any type of error from service APIs
 */
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Box, IconButton, Typography } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { API_ERROR_TYPES } from '../constants';
import { redirectToLoginPage } from '../service';
import { CONFIG } from '../config';
import { SENTRY_ERROR_TYPES } from '../components/GlobalErrorFallback';

export const wrapErrorMessageWithFallback = (error, fallbackMessage) => {
  if (CONFIG.ENV === 'production') {
    const scope = new Sentry.Scope();
    scope.setLevel(Sentry.Severity.Error);
    scope.setTag('type', SENTRY_ERROR_TYPES.API_ERROR);
    Sentry.captureException(error, scope);
  }

  const message = error?.errorDetail ?? error?.errorDetails ?? error?.msg ?? error?.message;

  if (!message || typeof message !== 'string') {
    return fallbackMessage;
  }

  if (message.length > 100) {
    return fallbackMessage;
  }

  return message;
};

const errorKeyCache = new Set();

export function useNotifyError({ error, mustRedirect = false, fallbackMessage }) {
  const history = useHistory();

  useEffect(() => {
    if (error && !errorKeyCache.has(error.key)) {
      errorKeyCache.add(error.key);
      toast.error(
        (currentToast) => (
          <Box display="flex" alignItems="center">
            <Typography>
              {wrapErrorMessageWithFallback(
                error,
                fallbackMessage ?? 'Error: Something went wrong!'
              )}
            </Typography>
            <Box ml={2}>
              <IconButton
                size="small"
                onClick={() => {
                  toast.dismiss(currentToast.id);
                }}
              >
                <MdClose />
              </IconButton>
            </Box>
          </Box>
        ),
        {
          duration: 3000,
        }
      );

      if (error.type === API_ERROR_TYPES.JWT) {
        if (CONFIG.ENV === 'production') {
          redirectToLoginPage(window.location.href);
        }
      } else if (error.type === API_ERROR_TYPES.UNAUTHORIZED && mustRedirect) {
        history.push('/error', {
          errorType: error.type,
        });
      } else if (error.type === API_ERROR_TYPES.VALIDATION_ERROR) {
        history.push('/error', {
          errorType: error.type,
          errorDetails: error.msg,
        });
      } else if (mustRedirect) {
        history.push('/error', {
          isFatal: true,
          errorDetails: error.msg,
          errorType: error.type,
        });
      }
    }
  }, [error, mustRedirect, fallbackMessage, history]);
}
