/**
 * @file
 *
 * this file contains a custom Helper Text tooltip
 */
import React from 'react';
import {
  Tooltip,
  IconButton,
  InputAdornment,
  withStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { InputAdornment as Mui5InputAdornment, IconButton as Mui5IconButton } from '@mui/material';
import { MdErrorOutline, MdHelpOutline } from 'react-icons/md';

const HelperTextTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[700],
    textAlign: 'center',
    marginTop: theme.spacing(1),
    fontSize: theme.typography.body2,
  },
  arrow: {
    color: theme.palette.grey[700],
  },
}))(Tooltip);

const useErrorTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.error.main,
  },
  tooltip: {
    backgroundColor: theme.palette.error.main,
  },
}));

export function HelperTextAdornment(props) {
  const errorTooltipClasses = useErrorTooltipStyles();
  const theme = useTheme();

  return (
    <InputAdornment position="start">
      <HelperTextTooltip
        title={props.text ?? ''}
        classes={props.error ? errorTooltipClasses : undefined}
        arrow
      >
        <IconButton size="small" disableRipple>
          {props.error ? <MdErrorOutline color={theme.palette.error.main} /> : <MdHelpOutline />}
        </IconButton>
      </HelperTextTooltip>
    </InputAdornment>
  );
}

export function MUI5HelperTextAdornment(props) {
  const errorTooltipClasses = useErrorTooltipStyles();

  return (
    <Mui5InputAdornment position="start">
      <HelperTextTooltip
        title={props.text ?? ''}
        classes={props.error ? errorTooltipClasses : undefined}
        arrow
      >
        <Mui5IconButton size="small" disableRipple>
          {props.error ? <MdErrorOutline color="red" /> : <MdHelpOutline />}
        </Mui5IconButton>
      </HelperTextTooltip>
    </Mui5InputAdornment>
  );
}
