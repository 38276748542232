import React, { useCallback, useMemo, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
  Box,
  Stack,
  Button,
  CircularProgress,
  DialogActions,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Divider,
  Collapse,
} from '@mui/material';
import { FiMoreVertical } from 'react-icons/fi';
import {
  MdChevronRight,
  MdContentCopy,
  MdDelete,
  MdEdit,
  MdExpandMore,
  MdMoveToInbox,
  MdShare,
} from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash-es';

import { constructURL, extractEntityName, getBillingExpiryStatus } from 'utils';
import { useBillingUsage } from 'data/billingUsage';
import { getBookmarkErrorText, useDeleteBookmarkDialog } from 'components/BookmarkPanel';
import { ErrorImage } from 'components/Illustrations/Error';
import { NoBookmarksImage } from 'components/Illustrations/NoBookmarks';
import { hydrateReportViewState } from 'state/reportView';
import {
  useCreateCollectionMutations,
  useDeleteCollectionMutations,
  useEditBookmarkMutaton,
  useEditCollectionMutaton,
  useMoveBookmarkMutation,
} from 'data/queriesAndBookmarks';
import { validationSchema } from 'components/CreateBookmarkDialog';
import { useTenantState } from 'data/user';
import { commonTextFieldProps } from 'mui5Theme';
import { useEditBookmark } from 'components/EditBookmarkDialog';
import { useCopy } from 'hooks/useCopy';
import { useShareableUrl } from 'state/queryBuilder';
import { toast } from 'react-hot-toast';
import { initialQueryDataState } from 'components/ReportView';
import { BOOKMARK_TYPE, DOM_IDS } from './constants';
import { useDisclosure } from 'hooks/useDisclosure';
import DialogWrapper from 'components/DialogWrapper';

export default function BookmarkPanel({
  data,
  isLoading,
  error,
  schema,
  baseEndpoint,
  systemType,
  setQueryData,
  connection,
}) {
  const { data: billingUsage } = useBillingUsage();
  const tenant = useTenantState();
  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);
  const { createCollectionMutation } = useCreateCollectionMutations();

  const { isEditDialogOpen, openEditDialog, closeEditDialog, selectedBookmark } = useEditBookmark();

  const [newCollectionInfo, setNewCollectionInfo] = useState({});

  const {
    isOpen: isCreateCollectionDlgOpen,
    open: openCreateCollectionDlg,
    close: closeCreateCollectionDlg,
  } = useDisclosure();

  const handleCreateCollectionClick = useCallback(() => {
    openCreateCollectionDlg();
  }, [openCreateCollectionDlg]);

  const createNewCollection = useCallback(async () => {
    const connectionId = connection.connection_id;
    const collectionInfo = {
      name: newCollectionInfo.collectionName,
      description: newCollectionInfo.collectionDescription,
      view: BOOKMARK_TYPE.REPORT_VIEW,
    };

    await createCollectionMutation.mutateAsync({ collectionInfo, tenant, connectionId });

    setNewCollectionInfo({});
    closeCreateCollectionDlg();
  }, [
    closeCreateCollectionDlg,
    connection.connection_id,
    createCollectionMutation,
    newCollectionInfo,
    tenant,
  ]);

  const handleCloseCreateCollectionDlg = useCallback(() => {
    closeCreateCollectionDlg();
    setNewCollectionInfo({});
  }, [closeCreateCollectionDlg]);

  if (isLoading && !hasPlanExpired) {
    return (
      <Stack spacing={1} p={1}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height={80}
              sx={{ borderRadius: 1 }}
            />
          ))}
      </Stack>
    );
  }

  // if error exists then display error, if error doesn't exists then check if the plan has expired. If the plan has expired,
  // then show the plan expiry message.
  if (error || hasPlanExpired) {
    return (
      <Stack height="100%" alignItems="center" mt={10}>
        <ErrorImage width="180px" height="200px" />
        <Typography color="error">{getBookmarkErrorText(error, hasPlanExpired)}</Typography>
      </Stack>
    );
  }

  if (!data) {
    return (
      <Stack height="100%" alignItems="center" mt={10}>
        <NoBookmarksImage width="180px" height="200px" />
        <Typography color="textSecondary">No Bookmarks Available</Typography>
      </Stack>
    );
  }

  return (
    <Stack direction={'column'}>
      <Stack
        alignContent="center"
        justifyContent={'space-between'}
        borderBottom="1px solid lightgray"
        direction={'row'}
        sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 10 }}
      >
        <Typography
          justifyContent={'center'}
          alignContent={'center'}
          sx={{ fontWeight: 500 }}
          ml={2}
        >
          Collections ({data.length})
        </Typography>
        <Button
          size="small"
          variant="text"
          sx={{ margin: 1, textTransform: 'none' }}
          onClick={handleCreateCollectionClick}
        >
          + Create collection
        </Button>
      </Stack>
      {data.map((collection) => (
        <EachCollection
          baseEndpoint={baseEndpoint}
          systemType={systemType}
          schema={schema}
          key={collection.id}
          collection={collection}
          openEditDialog={openEditDialog}
          setQueryData={setQueryData}
          tenant={tenant}
          connectionId={connection.connection_id}
          collectionsData={data}
        />
      ))}

      {selectedBookmark && (
        <EditBookmarkDialog
          selectedBookmark={selectedBookmark}
          isOpen={isEditDialogOpen}
          handleClose={closeEditDialog}
        />
      )}
      {isCreateCollectionDlgOpen && (
        <DialogWrapper
          isOpen={isCreateCollectionDlgOpen}
          closeDialog={handleCloseCreateCollectionDlg}
          title="Create new collection"
          primaryBtnText="Create"
          primaryBtnAction={createNewCollection}
          secondaryBtnAction={handleCloseCreateCollectionDlg}
          primaryBtnProps={{ disabled: isEmpty(newCollectionInfo) }}
        >
          <CreateCollectionFields setNewCollectionInfo={setNewCollectionInfo} />
        </DialogWrapper>
      )}
    </Stack>
  );
}

export function EachCollection({
  baseEndpoint,
  systemType,
  schema,
  collection,
  openEditDialog,
  setQueryData,
  connectionId,
  tenant,
  collectionsData,
}) {
  const collectionId = collection.id;
  const isDefaultCollection = collection.is_default;
  const { id: defaultCollectionId } = collectionsData.find((collection) => collection.is_default);
  const { deleteCollectionMutation } = useDeleteCollectionMutations();
  const { editCollectionMutation } = useEditCollectionMutaton();
  const [isCollectionExpanded, setIsCollectionExpanded] = useState(false);
  const [editedCollectionInfo, setEditedCollectionInfo] = useState({});

  // To control collection options popper.
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleCollectionClick = useCallback(() => {
    setIsCollectionExpanded((prevState) => !prevState);
  }, []);

  const bookmarks = collection?.bookmarks ?? [];
  const bookmarksLength = bookmarks?.length;
  const isLastBookmark = bookmarks?.length - 1;

  const {
    isOpen: isEditCollectionDlgOpen,
    open: openEditCollectionDlg,
    close: closeEditCollectionDlg,
  } = useDisclosure();

  const {
    isOpen: isDeleteCollectionDlgOpen,
    open: openDeleteCollectionDlg,
    close: closeDeleteCollectionDlg,
  } = useDisclosure();

  const handleDeleteCollection = useCallback(async () => {
    await deleteCollectionMutation.mutateAsync({
      tenant,
      connectionId,
      collectionId,
      view: BOOKMARK_TYPE.REPORT_VIEW,
    });
    closeDeleteCollectionDlg();
  }, [closeDeleteCollectionDlg, collectionId, connectionId, deleteCollectionMutation, tenant]);

  const editCollection = useCallback(async () => {
    const collectionInfo = {
      name: editedCollectionInfo.collectionName,
      description: editedCollectionInfo.collectionDescription,
      view: BOOKMARK_TYPE.REPORT_VIEW,
    };
    await editCollectionMutation.mutateAsync({
      selectedCollection: collection,
      collectionInfo,
      tenant,
    });
    closeEditCollectionDlg();
  }, [closeEditCollectionDlg, collection, editCollectionMutation, editedCollectionInfo, tenant]);

  const handleCloseEditCollectionDlg = useCallback(() => {
    setEditedCollectionInfo({});
    closeEditCollectionDlg();
  }, [closeEditCollectionDlg]);

  return (
    <>
      <Stack
        direction="row"
        alignContent={'center'}
        spacing={1}
        py={1.5}
        px={1}
        onClick={handleCollectionClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Stack justifyContent={'space-between'} direction="row" alignContent="center" width="100%">
          <Stack direction="row" alignItems="center" width="90%">
            {isCollectionExpanded ? (
              <MdExpandMore fontSize={18} />
            ) : (
              <MdChevronRight fontSize={18} />
            )}

            <Tooltip title={collection.description}>
              <Typography ml={1} noWrap width="90%">
                {collection?.name}
              </Typography>
            </Tooltip>
          </Stack>

          <Stack>
            {!isDefaultCollection && (
              <IconButton size="small" disableRipple onClick={handleMenuOpen} p={0}>
                <FiMoreVertical />
              </IconButton>
            )}

            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => openEditCollectionDlg()}>
                <ListItemIcon>
                  <MdEdit />
                </ListItemIcon>
                Edit Collection
              </MenuItem>

              <Tooltip
                title={
                  bookmarksLength !== 0 && 'Move or delete bookmarks before deleting the collection'
                }
              >
                <span>
                  <MenuItem
                    sx={{ color: 'red' }}
                    onClick={() => {
                      openDeleteCollectionDlg();
                      handleMenuClose();
                    }}
                    disabled={bookmarks?.length !== 0}
                  >
                    <ListItemIcon>
                      <MdDelete color="red" />
                    </ListItemIcon>
                    Delete Collection
                  </MenuItem>
                </span>
              </Tooltip>
            </Menu>
          </Stack>
        </Stack>
      </Stack>
      <Collapse in={isCollectionExpanded} style={{ width: '100%' }}>
        {bookmarksLength > 0 ? (
          bookmarks.map((bookmark, currIndex) => (
            <Stack direction="row" key={bookmark.query_bookmark_id}>
              <Bookmark
                key={bookmark.query_bookmark_id}
                baseEndpoint={baseEndpoint}
                systemType={systemType}
                schema={schema}
                bookmark={bookmark}
                openEditDialog={openEditDialog}
                setQueryData={setQueryData}
                isLastBookmark={isLastBookmark}
                currIndex={currIndex}
                tenant={tenant}
                collectionsData={collectionsData}
                defaultCollectionId={defaultCollectionId}
              />
            </Stack>
          ))
        ) : (
          <Stack height="100%" alignItems="center" mb={2}>
            <NoBookmarksImage width="180px" height="200px" />
            <Typography color="textSecondary">No Bookmarks in this collection</Typography>
          </Stack>
        )}
      </Collapse>
      <Divider variant="fullWidth" />
      {isEditCollectionDlgOpen && (
        <DialogWrapper
          isOpen={isEditCollectionDlgOpen}
          closeDialog={handleCloseEditCollectionDlg}
          title="Edit collection"
          primaryBtnText="Done"
          primaryBtnAction={editCollection}
          secondaryBtnAction={handleCloseEditCollectionDlg}
          primaryBtnProps={{ disabled: isEmpty(editedCollectionInfo) }}
        >
          <CreateCollectionFields
            setNewCollectionInfo={setEditedCollectionInfo}
            collection={collection}
          />
        </DialogWrapper>
      )}
      {isDeleteCollectionDlgOpen && (
        <DialogWrapper
          isOpen={isDeleteCollectionDlgOpen}
          closeDialog={closeDeleteCollectionDlg}
          title="Delete Collection"
          secondaryBtnAction={closeDeleteCollectionDlg}
          primaryBtnAction={handleDeleteCollection}
          primaryBtnText="Delete"
          size="xs"
        >
          <Typography>
            Are you sure you want to delete this collection? This action is irreversible!
          </Typography>
        </DialogWrapper>
      )}
    </>
  );
}

export function Bookmark({
  bookmark,
  baseEndpoint,
  systemType,
  schema,
  openEditDialog,
  setQueryData,
  isLastBookmark,
  currIndex,
  tenant,
  collectionsData,
  defaultCollectionId,
}) {
  const { moveBookmarkMutation } = useMoveBookmarkMutation();
  const [moveToCollectionId, setMoveToCollectionId] = useState({
    collectionId: defaultCollectionId,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const deleteBookmark = useDeleteBookmarkDialog();

  const copyToClipboard = useCopy();
  const { getShareableUrl } = useShareableUrl();

  const bookmarkedEntity = useMemo(() => {
    const urlObj = constructURL(bookmark.query_string);
    if (!urlObj) {
      return '';
    }
    const bookmarkedEntity = extractEntityName(urlObj);

    return bookmarkedEntity;
  }, [bookmark]);

  const handleCopyShareableURL = useCallback(() => {
    try {
      const url = new URL(bookmark.query_string);
      url.searchParams.delete('$format');
      url.searchParams.delete('$inlinecount');
      url.searchParams.delete('$orderby');

      const shareableURL = getShareableUrl(url.toString());
      const shareableUrlObj = new URL(shareableURL);

      const odataQuery = shareableUrlObj.searchParams.get('odataQuery');
      const decodedODataQuery = decodeURIComponent(odataQuery);
      const strippedODataQuery = decodedODataQuery.replace(`${baseEndpoint}/`, '');

      shareableUrlObj.searchParams.set('odataQuery', strippedODataQuery);

      copyToClipboard(shareableUrlObj.href);
    } catch (error) {
      toast.error('Copy to clipboard failed!', {
        id: 'copy-shareable-query-error',
        duration: 3000,
      });
    } finally {
      handleMenuClose();
    }
  }, [baseEndpoint, bookmark.query_string, copyToClipboard, getShareableUrl, handleMenuClose]);

  const {
    isOpen: isMoveBookmarkDlgOpen,
    open: openMoveBookmarkDlg,
    close: closeMoveBookmarkDlg,
  } = useDisclosure();

  const handleMoveBookmark = useCallback(async () => {
    try {
      await moveBookmarkMutation.mutateAsync({
        collectionId: moveToCollectionId,
        selectedBookmark: bookmark,
        tenant,
        view: BOOKMARK_TYPE.REPORT_VIEW,
      });
      toast.success('successfully moved bookmark');
    } catch (e) {
      toast.error('Failed to move bookmark');
    } finally {
      closeMoveBookmarkDlg();
    }
  }, [bookmark, closeMoveBookmarkDlg, moveBookmarkMutation, moveToCollectionId, tenant]);

  return (
    <Stack
      borderBottom={currIndex === isLastBookmark ? 'none' : '1px solid lightgray'}
      spacing={1}
      p={1.5}
      width="100%"
    >
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Tooltip title={bookmark.name}>
            <Typography
              onClick={() => {
                hydrateReportViewState(bookmark.query_string, baseEndpoint, schema, systemType);
                setQueryData(initialQueryDataState);
              }}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="body1"
              fontWeight={500}
              noWrap
            >
              {bookmark.name}
            </Typography>
          </Tooltip>
          <IconButton size="small" disableRipple onClick={handleMenuOpen}>
            <FiMoreVertical />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                openEditDialog(bookmark);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <MdEdit />
              </ListItemIcon>
              Edit Bookmark
            </MenuItem>
            <MenuItem
              onClick={() => {
                copyToClipboard(bookmark.query_string);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <MdContentCopy fontSize="18px" />
              </ListItemIcon>
              Copy Query URL
            </MenuItem>
            <MenuItem onClick={handleCopyShareableURL}>
              <ListItemIcon>
                <MdShare />
              </ListItemIcon>
              Copy Shareable URL
            </MenuItem>
            <MenuItem onClick={openMoveBookmarkDlg}>
              <ListItemIcon>
                <MdMoveToInbox style={{ fontSize: 16 }} />
              </ListItemIcon>
              Move Bookmark
            </MenuItem>
            <MenuItem
              sx={{ color: 'red' }}
              onClick={() => {
                deleteBookmark(bookmark.query_bookmark_id);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <MdDelete color="red" />
              </ListItemIcon>
              Delete Bookmark
            </MenuItem>
          </Menu>
        </Stack>
        <Tooltip title={bookmark?.description ?? ''} color="textSecondary">
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {bookmark?.description ?? ''}
          </Typography>
        </Tooltip>
        <Box
          borderRadius={1}
          p={1}
          mt={1}
          mb={0.5}
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            '& > *': {
              marginRight: '1rem', // Adjust this value to control the spacing between items
            },
            backgroundColor: '#ededed',
            overflowX: 'auto',
            overflowY: 'hidden',
            '&::-webkit-scrollbar': {
              width: 5,
              height: 5,
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: 20,
              border: '3px solid #929394',
            },
          }}
        >
          <Typography variant="body2" fontFamily="monospace">
            {decodeURIComponent(bookmark.query_string)}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="body2">Entity:</Typography>
          <Typography variant="body2" fontWeight="500">
            {bookmarkedEntity ?? 'N/A'}
          </Typography>
        </Stack>
        <Typography color="textSecondary" variant="body2">
          + customizations
        </Typography>
      </Stack>
      {isMoveBookmarkDlgOpen && (
        <DialogWrapper
          isOpen={isMoveBookmarkDlgOpen}
          closeDialog={closeMoveBookmarkDlg}
          title="Move Bookmark"
          secondaryBtnAction={closeMoveBookmarkDlg}
          primaryBtnAction={handleMoveBookmark}
          primaryBtnText="Done"
          children={
            <MoveBookmarkField
              setMoveToCollectionId={setMoveToCollectionId}
              collectionsData={collectionsData}
              defaultCollectionId={defaultCollectionId}
            />
          }
          size="xs"
        />
      )}
    </Stack>
  );
}

export function EditBookmarkDialog({ isOpen, handleClose, selectedBookmark }) {
  const { editBookmarkMutation } = useEditBookmarkMutaton();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: selectedBookmark.name,
      description: selectedBookmark.description,
    },
  });

  const tenant = useTenantState();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await editBookmarkMutation.mutateAsync({
          formData: data,
          tenant,
          selectedBookmark,
        });

        handleClose();
      } finally {
      }
    },
    [selectedBookmark, handleClose, tenant, editBookmarkMutation]
  );

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Bookmark</DialogTitle>
        <DialogContent>
          <TextField
            label="Bookmark Title"
            placeholder="Favourite Query"
            name="name"
            helperText={errors.name ? errors.name.message : ' '}
            error={Boolean(errors.name)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={editBookmarkMutation.isLoading}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            label="Bookmark Description"
            placeholder="This is my favorite query"
            name="description"
            helperText={errors.description ? errors.description.message : ' '}
            error={Boolean(errors.description)}
            fullWidth
            {...commonTextFieldProps}
            inputRef={register}
            disabled={editBookmarkMutation.isLoading}
            inputProps={{ maxLength: 255 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={editBookmarkMutation.isLoading || !isValid}
            type="submit"
            startIcon={editBookmarkMutation.isLoading ? <CircularProgress size={15} /> : null}
          >
            {editBookmarkMutation.isLoading ? 'Updating' : 'Update'}
          </Button>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            disabled={editBookmarkMutation.isLoading}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function CreateCollectionFields({ setNewCollectionInfo, collection }) {
  const [collectionName, setCollectionName] = useState(collection?.name ?? '');
  const [collectionDescription, setCollectionDescription] = useState(collection?.description ?? '');

  const handleCollectionInfoChange = useCallback(
    (event) => {
      if (event.target.id === DOM_IDS.COLLECTION_FIELDS.NAME) {
        setCollectionName(event.target.value);
        setNewCollectionInfo({
          collectionName: event.target.value,
          collectionDescription: collectionDescription,
        });
        return;
      } else if (event.target.id === DOM_IDS.COLLECTION_FIELDS.DESCRIPTION) {
        setCollectionDescription(event.target.value);
        setNewCollectionInfo({
          collectionName: collectionName,
          collectionDescription: event.target.value,
        });
        return;
      }
    },
    [collectionDescription, collectionName, setNewCollectionInfo]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Typography>Create a collection to group your bookmarks</Typography>
      <TextField
        {...commonTextFieldProps}
        label="Collection name"
        placeholder="EmpJob queries"
        size="small"
        value={collectionName}
        onChange={handleCollectionInfoChange}
        id={DOM_IDS.COLLECTION_FIELDS.NAME}
        inputProps={{ maxLength: 255 }}
      />
      <TextField
        value={collectionDescription}
        {...commonTextFieldProps}
        label="Description"
        placeholder="Add a description for this collection"
        size="small"
        onChange={handleCollectionInfoChange}
        id={DOM_IDS.COLLECTION_FIELDS.DESCRIPTION}
        inputProps={{ maxLength: 255 }}
      />
    </Stack>
  );
}

export function MoveBookmarkField({
  setMoveToCollectionId,
  collectionsData,
  isCreateFlow = false,
  register,
  name = 'collectionId',
  setValue,
  defaultCollectionId,
}) {
  const [selectedCollectionId, setSelectedCollectionId] = useState(defaultCollectionId);

  const handleCollectionChoiceChange = useCallback(
    (event) => {
      setSelectedCollectionId(event.target.value);
      isCreateFlow
        ? setValue('collectionId', event.target.value)
        : setMoveToCollectionId({ collectionId: event.target.value });
    },
    [isCreateFlow, setMoveToCollectionId, setValue]
  );

  return (
    <TextField
      value={selectedCollectionId}
      inputRef={register}
      onChange={handleCollectionChoiceChange}
      fullWidth
      name={name}
      select
      {...commonTextFieldProps}
      label="Choose collection"
      SelectProps={{
        MenuProps: {
          style: {
            zIndex: 10010,
          },
        },
      }}
    >
      {collectionsData.map((collection) => (
        <MenuItem value={collection.id} key={collection.id}>
          {collection.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
