/**
 * @file
 *
 * this file contains the base component for Connections Listing for a tenant
 */
import React, { useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  Button,
  ScopedCssBaseline,
  Stack,
  ThemeProvider,
  Typography as Mui5Typography,
} from '@mui/material';
import { BiLinkExternal } from 'react-icons/bi';

import { Nav } from '../components/Nav';
import { useConnectionsAndSystems } from '../data/connectionsAndSystems';
import { ConnectionCardLoader } from '../components/Loaders/HomePageLoaders';
import { useTenantState } from '../data/user';
import { tourStateActions, useTourState } from '../components/Onboarding/Tour';
import { ExpiryBanner } from '../components/ExpiryBanner';
import { useInitialLoadNormal } from '../hooks/useInitialLoadChecks';
import { mui5Theme } from 'mui5Theme';
import { EmptyImage } from 'components/Illustrations/Empty';
import { getPlatformURL } from '../service';
import ConnectionActivationWrapper from '../components/ConnectionActivationWrapper';
import ConnectionCard from '../components/ConnectionCard';
import ArchiveConfirmationDialog, {
  useArchiveConnectionConfirmation,
} from '../components/ArchiveConnectionDialog';
import { PERMISSION_CODES } from '../constants';
import { NotFoundImage } from 'components/Illustrations/NotFound';

const useStyles = makeStyles((theme) => ({
  titleBar: {
    height: theme.spacing(6),
    top: theme.spacing(5.5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    padding: theme.spacing(0.5, 6, 0),
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    borderBottom: theme.borders[0],
  },
  container: {
    height: '100%',
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    margin: theme.spacing(10, 0, 0),
    padding: theme.spacing(5, 4, 0),
  },
  card: {
    position: 'relative',
    '&:hover [data-icon="edit"]': {
      opacity: 1,
      visibility: 'visible',
    },
    padding: theme.spacing(2, 4, 0),
  },
  createCard: {
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.light,
    borderWidth: theme.spacing(0.3),
    padding: theme.spacing(1),
  },
  disableCreateCard: {
    borderColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
  buttonContainer: {
    width: '100%',
    height: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.light,
  },
  button: {
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(10),
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  editFab: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  system: {
    marginTop: -theme.spacing(2),
    fontSize: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  details: {
    width: '100%',
    height: theme.spacing(12),
    margin: theme.spacing(1, 0),
  },
  description: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'normal',
    width: theme.spacing(32),
    maxHeight: theme.spacing(5.5),
    overflow: 'hidden',
  },
  connectionName: {
    width: theme.spacing(32),
    height: theme.spacing(8),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  odata: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  sf: {
    height: theme.spacing(4.375),
    width: theme.spacing(31.25),
  },
  deleteOption: {
    color: theme.palette.error.main,
  },
  menuItemIcon: {
    minWidth: theme.spacing(3),
  },
  connectionTypeChip: {
    position: 'absolute',
    right: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
  },
  createConnectionTooltip: {
    top: theme.spacing(-2),
  },
}));

export function ConnectionsListing() {
  const classes = useStyles();
  const tenant = useTenantState();

  const { connections, isConnectionsLoading } = useConnectionsAndSystems();
  const isSystemAdmin = tenant?.permissions.some(
    ({ permission_code }) => permission_code === PERMISSION_CODES.MANAGE_SYSTEM
  );

  const showLoader = isConnectionsLoading || !tenant;

  const {
    connectionToBeArchived,
    openArchiveConfirmationDialog,
    closeArchiveConfirmationDialog,
    isArchiveConfirmationDialogOpen,
  } = useArchiveConnectionConfirmation();

  useInitialLoadNormal();

  useEffect(() => {
    const { isConnectionLive } = useTourState.get();

    if (isConnectionLive) {
      tourStateActions.reset();
    }
  }, []);

  return (
    <>
      <Nav />
      <Grid container justifyContent="center" alignItems="center" className={classes.titleBar}>
        <Grid item container justifyContent="center">
          <Grid component={Typography} variant="h6" align="center">
            Dataflow
          </Grid>
        </Grid>
      </Grid>
      <ExpiryBanner />
      <Grid
        className={classes.container}
        container
        direction="row"
        alignContent="flex-start"
        justifyContent="flex-start"
        spacing={2}
      >
        {showLoader && (
          <>
            <ConnectionCardLoader />
            <ConnectionCardLoader />
            <ConnectionCardLoader />
            <ConnectionCardLoader />
            <ConnectionCardLoader />
          </>
        )}
        <ThemeProvider theme={mui5Theme}>
          <ScopedCssBaseline
            {...(connections.length === 0 && {
              sx: (theme) => ({
                height: `calc(100vh - ${theme.spacing(30)})`,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }),
            })}
          >
            {!showLoader && connections.length === 0 && (
              <Stack
                width="100%"
                height="100%"
                alignItems="center"
                spacing={1}
                justifyContent="center"
              >
                {isSystemAdmin ? (
                  <EmptyImage height={224} width={330} />
                ) : (
                  <NotFoundImage height={224} width={330} />
                )}
                <Stack alignItems="center">
                  <Mui5Typography
                    mt={2}
                    variant="h5"
                    component="p"
                    align="center"
                    color="primary"
                    fontWeight={500}
                    gutterBottom
                  >
                    {isSystemAdmin ? 'Create your first connection' : 'No Connection Assigned'}
                  </Mui5Typography>
                  <Mui5Typography
                    variant="h6"
                    component="p"
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    width="70%"
                  >
                    {isSystemAdmin
                      ? 'Get started by setting up a SuccessFactors System in your Systems App to create your first Connection'
                      : 'Kindly request your tenant admin to assign you to a SuccessFactors System to access the linked Connection'}
                  </Mui5Typography>
                </Stack>
                {isSystemAdmin && (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    startIcon={<BiLinkExternal />}
                    onClick={() =>
                      window.open(
                        `${getPlatformURL()}/#/systems/t-${tenant?.tenant_id}-t&/s/system/`
                      )
                    }
                  >
                    Go to Systems
                  </Button>
                )}
              </Stack>
            )}
            {!showLoader && (
              <Stack
                direction="row"
                alignContent="flex-start"
                spacing={2}
                useFlexGap
                flexWrap="wrap"
                pb={5}
              >
                {connections?.map((connection) => (
                  <ConnectionActivationWrapper
                    key={connection.connection_id}
                    connection={connection}
                  >
                    <ConnectionCard
                      connection={connection}
                      openArchiveConfirmationDialog={openArchiveConfirmationDialog}
                    />
                  </ConnectionActivationWrapper>
                ))}
              </Stack>
            )}
            {isArchiveConfirmationDialogOpen && (
              <ArchiveConfirmationDialog
                isDialogOpen={isArchiveConfirmationDialogOpen}
                closeDialog={closeArchiveConfirmationDialog}
                connectionId={connectionToBeArchived}
              />
            )}
          </ScopedCssBaseline>
        </ThemeProvider>
      </Grid>
    </>
  );
}
