import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import React, { useState, useCallback } from 'react';

import { commonTextFieldProps } from 'theme';

export function TextEditor({ row, column, onRowChange, onClose }) {
  return (
    <TextField
      autoFocus
      variant="outlined"
      value={row[column.key]}
      onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
      onBlur={() => onClose(true)}
      sx={{
        outline: 'none !important',
        borderWidth: 0,
        width: '100%',
      }}
    />
  );
}

export function DropDownEditor({ row, column, onRowChange, picklistId, options, picklistFetcher }) {
  const [isPicklistDataLoading, setIsPicklistDataLoading] = useState(false);

  const handleOpen = useCallback(async () => {
    if (options && options.length) {
      setIsPicklistDataLoading(false);
      return;
    }

    setIsPicklistDataLoading(true);
    await picklistFetcher(picklistId);
    setIsPicklistDataLoading(false);
  }, [options, picklistFetcher, picklistId]);

  const handleChange = useCallback(
    (event, newValue) => {
      onRowChange({ ...row, [column.key]: newValue.value }, true);
    },
    [column.key, onRowChange, row]
  );

  return (
    <Autocomplete
      disabled={isPicklistDataLoading}
      fullWidth
      size="small"
      getOptionLabel={(option) => option.value}
      loading={isPicklistDataLoading}
      loadingText="Loading Picklist"
      options={options}
      onChange={handleChange}
      onOpen={handleOpen}
      noOptionsText={'No results found'}
      sx={{
        outline: 'none !important',
        borderWidth: 0,
        height: 40,
        '& .MuiFormControl-root': {
          margin: 0,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {isPicklistDataLoading ? (
                  <CircularProgress color="inherit" size={15} sx={{ ml: 1 }} />
                ) : null}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          {...commonTextFieldProps}
          sx={{
            '& .MuiFilledInput-root': {
              padding: 0,
              height: 40,
            },
          }}
        />
      )}
    />
  );
}
