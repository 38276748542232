import { Button, Collapse, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export default function ExpandableTypography({
  children,
  expandText = 'more',
  collapseText = 'less',
  textFieldProps,
}) {
  const [enableExpand, setEnableExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const textRef = useRef();

  useEffect(() => {
    const textHeight = textRef.current.clientHeight;
    const lineHeight = parseFloat(window.getComputedStyle(textRef.current)['line-height']);
    const lines = textHeight / lineHeight;
    setEnableExpand(lines > 2);
  }, [children]);

  return (
    <Stack direction="row" alignItems="flex-end" position="relative">
      <Stack alignItems="flex-start">
        <Collapse in={isExpanded}>
          <Typography fontFamily="monospace" {...textFieldProps} ref={textRef}>
            {children}
          </Typography>
        </Collapse>
        {!isExpanded && (
          <Typography
            fontFamily="monospace"
            {...textFieldProps}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {children}{' '}
          </Typography>
        )}
      </Stack>
      {enableExpand && (
        <Button
          sx={{
            textTransform: 'none',
            p: 0,
            textAlign: 'right',
            minWidth: 40,
            position: 'absolute',
            right: 0,
            bottom: 0,
            ':hover': { backgroundColor: 'transparent' },
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? collapseText : expandText}
        </Button>
      )}
    </Stack>
  );
}
