/* eslint-disable import/no-webpack-loader-syntax */
import IndexedDBWorker from 'comlink-loader!./worker';
import { useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useRaf } from 'rooks';
import { useParams } from 'react-router-dom';

import { getAuthToken } from '../service';
import { useReportViewState } from '../state/reportView';
import { generateDatabaseNamePrefix, initialWorkerStateMachine } from './utils';
import { useTenantState } from 'data/user';
import { useCurrentTabId } from 'components/TabIndexedStorageContext';
import { FETCH_CANCELLATION } from 'components/ReportViewComponents/constants';
import { CONFIG } from '../config';

const worker = IndexedDBWorker();

let recordsCount = null;

export async function cancelAllPendingBatchedRequests(reason = FETCH_CANCELLATION.BY_DATAFLOW) {
  await worker.cancelPendingRequests(reason);
}

export function useIntializeIndexedDB(schema, showPicklistValues) {
  // This state ensures that the fetch limit alert is shown when the data-count is more than 10K
  const [showFetchLimitAlert, setShowFetchLimitAlert] = useState(false);

  const tenant = useTenantState();
  const params = useParams();
  const tabId = useCurrentTabId();

  const { tenant_id: tenantId } = tenant ?? {};
  const { connectionId } = params;

  const initializeDatabase = async (currentState, queryUrl) => {
    setShowFetchLimitAlert(false);

    const authToken = getAuthToken();

    await cancelAllPendingBatchedRequests();

    recordsCount = await worker.fetchRecordsCount({
      connectionId,
      tenantId,
      queryUrl,
      authToken,
    });

    if (recordsCount?.message === FETCH_CANCELLATION.CANCEL_QUERY_TRIGGER) {
      throw new Error(recordsCount.message);
    }

    if (recordsCount > CONFIG.DATA_FETCH_LIMIT) {
      setShowFetchLimitAlert(true);
    }

    const dbName = `${generateDatabaseNamePrefix({ tabId })}${currentState.entity.value.name}`;

    return await worker.createDBInstance({
      dbName,
      entity: currentState.entity.value,
      expandedNavigationProps: currentState.expand.value,
      expandOptionsMap: currentState.expand.optionsMap,
      schema,
      connectionId,
      tenantId,
      authToken,
      queryUrl,
      recordsCount,
      tabId,
      showPicklistValues,
    });
  };

  return { initializeDatabase, showFetchLimitAlert, setShowFetchLimitAlert };
}

export async function fetchPaginatedRecords({ limit, skip }) {
  const { current } = useReportViewState.get();

  if (!current.entity.value) {
    return;
  }
  const paginatedData = await worker.runPagination(limit, skip, JSON.stringify(current));

  return paginatedData;
}

export function useWorkerStateMachine() {
  const [batchState, setBatchState] = useState(initialWorkerStateMachine);

  const previousChangeId = useRef(batchState.changeId);

  useRaf(async () => {
    const workerBatchState = await worker.getBatchState();

    if (workerBatchState.changeId !== previousChangeId.current) {
      previousChangeId.current = workerBatchState.changeId;
      flushSync(() => {
        setBatchState(workerBatchState);
      });
    }
  }, true);

  return batchState;
}
