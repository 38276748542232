import React from 'react';
import { TextField, Grid, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import shallowEqual from 'shallowequal';
import { InputAdornment } from '@mui/material';

import { AutocompleteOption, autoCompleteFilterOptions } from './FormComponents/AutocompleteOption';
import {
  useEntitySelectActions,
  useQueryBuilderState,
  PAGINATION,
  paginationActions,
} from '../state/queryBuilder';
import { commonTextFieldProps } from '../theme';
import { cast, isObjectPropertiesEqual, validate } from '../utils';
import { DebouncedTextField } from './FormComponents/DebouncedTextField';
import { tourSteps } from './Onboarding/Tour';
import { AutocompleteVirtualizedListbox } from './AutocompleteVirtualizedListbox';
import { reportViewStateHandlers, useReportViewState } from 'state/reportView';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(1),
  },
}));

const entityAndPaginationStateSelector = (state) => ({
  entityOptions: state.entity.options,
  selectedEntity: state.entity.value,
});

export function EntityAndPaginationOptions({ schema, isSfSystem }) {
  const classes = useStyles();

  const { entityOptions, selectedEntity } = useQueryBuilderState(
    entityAndPaginationStateSelector,
    isObjectPropertiesEqual
  );

  const { onEntityChange } = useEntitySelectActions(schema, isSfSystem);

  return (
    <Grid item container spacing={2}>
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="flex-start"
        xs={2}
        className={classes.topMargin}
      >
        <Grid item>
          <Typography color="textPrimary" variant="subtitle1">
            Select
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={10} spacing={1}>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            size="small"
            autoHighlight
            autoComplete
            openOnFocus
            disableListWrap
            ListboxComponent={AutocompleteVirtualizedListbox}
            options={entityOptions}
            filterOptions={autoCompleteFilterOptions}
            getOptionSelected={(option, value) =>
              option.name === value.name && option.namespace === value.namespace
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Select Entity" {...commonTextFieldProps} />
            )}
            renderOption={(option, { inputValue }) => (
              <AutocompleteOption label={option.label} name={option.name} inputValue={inputValue} />
            )}
            value={selectedEntity}
            onChange={onEntityChange}
            data-tour-step={tourSteps['select-entity'].id}
          />
        </Grid>

        <TopParam />

        <SkipParam />
      </Grid>
    </Grid>
  );
}

const topSliceSelector = ({ top, queryKey }) => ({ top, queryKey });
const validateTopValue = (value) =>
  validate.int(value, { min: PAGINATION.TOP.MIN, max: PAGINATION.TOP.MAX, required: true });
export function TopParam({ isReportView, disabled }) {
  const queryBuilderState = useQueryBuilderState(topSliceSelector, shallowEqual);

  const { top, queryKey, entity } = isReportView
    ? useReportViewState.get().fork
    : { top: queryBuilderState.top, queryKey: queryBuilderState.queryKey, entity: null };

  const isDisabled = !entity?.value || disabled;

  return isReportView ? (
    <DebouncedTextField
      key={queryKey}
      value={top}
      onChange={reportViewStateHandlers.handleUpdateTopValue}
      validate={validateTopValue}
      cast={cast.int}
      type="number"
      placeholder="1 - 1000"
      isMUI5={true}
      helperText="Must be an integer between 1 and 1000"
      min={PAGINATION.TOP.MIN}
      max={PAGINATION.TOP.MAX}
      forceDefaultValueOnBlur
      defaultValue={10}
      size="small"
      sx={{
        '& .MuiOutlinedInput-root': {
          '& input': {
            textAlign: 'right',
            height: '10px',
          },
          '& fieldset': {
            borderColor: 'rgba(53, 74, 95, 0.5)',
          },
          '&.Mui-disabled fieldset': {
            borderColor: 'grey.300',
          },
        },
        borderRadius: 1,
        marginLeft: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ color: isDisabled ? 'grey.400' : '#354A5F' }}>
            <p style={{ fontFamily: 'inherit', fontWeight: '500', fontSize: '0.7rem' }}>TOP</p>
          </InputAdornment>
        ),
        sx: { fontSize: '12px' },
      }}
      inputProps={{ min: 0 }}
      disabled={isDisabled}
    />
  ) : (
    <Grid item xs={3}>
      <DebouncedTextField
        fullWidth
        key={queryKey}
        label="Rows to fetch"
        data-tour-step={tourSteps['rows-to-fetch'].id}
        {...commonTextFieldProps}
        value={top}
        onChange={paginationActions.onTopChange}
        validate={validateTopValue}
        cast={cast.int}
        type="number"
        placeholder="10"
        helperText="Must be an integer between 1 and 1000"
        min={PAGINATION.TOP.MIN}
        max={PAGINATION.TOP.MAX}
        forceDefaultValueOnBlur
        defaultValue={100}
      />
    </Grid>
  );
}

const skipSliceSelector = ({ skip, queryKey }) => ({ skip, queryKey });
const validateSkipValue = (value) =>
  validate.int(value, { min: PAGINATION.SKIP.MIN, max: PAGINATION.SKIP.MAX, required: true });
export function SkipParam({ isReportView, disabled }) {
  const queryBuilderState = useQueryBuilderState(skipSliceSelector, shallowEqual);

  const { skip, queryKey, entity, isCustomPagination } = isReportView
    ? useReportViewState.get().fork
    : {
        skip: queryBuilderState.skip,
        queryKey: queryBuilderState.queryKey,
        entity: null,
        isCustomPagination: null,
      };

  const isDisabled = !entity?.value || disabled;

  return isReportView ? (
    <DebouncedTextField
      key={queryKey}
      value={isCustomPagination ? skip : 0}
      onChange={reportViewStateHandlers.handleUpdateSkipValue}
      validate={validateSkipValue}
      cast={cast.int}
      isMUI5={true}
      type="number"
      placeholder="0"
      helperText="Must be a positive integer i.e. >= 0"
      min={PAGINATION.SKIP.MIN}
      max={PAGINATION.SKIP.MAX}
      forceDefaultValueOnBlur
      defaultValue={0}
      size="small"
      sx={{
        '& .MuiOutlinedInput-root': {
          '& input': {
            textAlign: 'right',
            height: '10px',
          },
          '& fieldset': {
            borderColor: 'rgba(53, 74, 95, 0.5)',
          },
          '&.Mui-disabled fieldset': {
            borderColor: 'grey.300',
          },
        },
        borderRadius: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ color: isDisabled ? 'grey.400' : '#354A5F' }}>
            <p style={{ fontFamily: 'inherit', fontWeight: '500', fontSize: '0.7rem' }}>SKIP</p>
          </InputAdornment>
        ),
        sx: { fontSize: '12px' },
      }}
      inputProps={{ min: 0 }}
      disabled={isDisabled}
    />
  ) : (
    <Grid item xs={3}>
      <DebouncedTextField
        fullWidth
        key={queryKey}
        label="Rows to Skip"
        data-tour-step={tourSteps['rows-to-skip'].id}
        {...commonTextFieldProps}
        value={skip}
        onChange={paginationActions.onSkipChange}
        validate={validateSkipValue}
        cast={cast.int}
        type="number"
        placeholder="10"
        helperText="Must be a positive integer i.e. >= 0"
        min={PAGINATION.SKIP.MIN}
        max={PAGINATION.SKIP.MAX}
        forceDefaultValueOnBlur
        defaultValue={0}
      />
    </Grid>
  );
}
