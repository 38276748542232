import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { MdContentCopy } from 'react-icons/md';
import { useCopyToClipboard } from 'react-use';

export const CopyButton = ({ title, textToCopy, tooltipZIndex, ...props }) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Tooltip title={`'Copy ${title}`}>
      <IconButton
        {...props}
        onClick={() => {
          copy(textToCopy);
        }}
      >
        <MdContentCopy />
      </IconButton>
    </Tooltip>
  );
};
