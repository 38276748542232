/**
 * @file
 *
 * This file contains the component for logs and bookmarks drawer
 */
import { Box, LinearProgress, Drawer, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import React, { useCallback, useState, useMemo } from 'react';

import {
  useQueryBookmarks,
  useQueryLogs,
  usePredefinedBookmarks,
} from '../data/queriesAndBookmarks';
import { tourSteps, useTourState } from './Onboarding/Tour';
import { BookmarkPanel } from './BookmarkPanel';
import { LogPanel } from './LogPanel';
import { BOOKMARK_TYPE } from './ReportViewComponents/constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const useTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const updateTabIndex = useCallback(
    (event, newValue) => {
      setTabIndex(newValue);
    },
    [setTabIndex]
  );

  return { tabIndex, updateTabIndex };
};

const SIDEPANEL_TABS = {
  LOGS: 'Logs',
  BOOKMARKS: 'Bookmarks',
};

export const drawerWidth = 38;
const useSidePanelStyles = makeStyles((theme) => ({
  drawerPaper: {
    overflow: 'hidden',
    position: 'fixed',
    height: `calc(100% - ${theme.spacing(11)}px)`,
    top: theme.spacing(11.5),
    width: theme.spacing(drawerWidth),
  },
  tabContainer: {
    height: '100%',
  },
  tabs: {
    width: '100%',
  },
  tab: {
    position: 'relative',
    minWidth: '50%',
    width: '50%',
  },
  tabPanel: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    overflowY: 'auto',
    width: '100%',
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(0),
    width: '100%',
  },
}));

export function SidePanel(props) {
  const classes = useSidePanelStyles();

  const params = useParams();

  const { tabIndex, updateTabIndex } = useTabs();

  const {
    isRefetching: isLogRefetching,
    isLoading: isLogLoading,
    data: logData,
    error: logError,
  } = useQueryLogs(params.connectionId);

  const {
    isRefetching: isBookmarkRefetching,
    isLoading: isBookmarkLoading,
    data: bookmarkData,
    error: bookmarkError,
  } = useQueryBookmarks(params.connectionId);

  const queryViewBookmarks = useMemo(
    () => bookmarkData?.filter(({ type }) => type === BOOKMARK_TYPE.QUERY_VIEW),
    [bookmarkData]
  );

  const {
    isRefetching: isPredefinedBookmarkRefetching,
    isLoading: isPredefinedBookmarkLoading,
    data: predefinedBookmarkData,
    error: predefinedBookmarkError,
  } = usePredefinedBookmarks(params.connectionId);

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={props.isDrawerOpen}
      elevation={0}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid container direction="column" className={classes.tabContainer}>
        <Grid item className={classes.tabs}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={updateTabIndex}
            aria-label="simple tabs example"
          >
            <Tab
              className={classes.tab}
              onClick={tourSteps['side-panel-btn'].next}
              label={
                <Grid container justifyContent="center">
                  {isLogRefetching && (
                    <LinearProgress
                      className={classes.loader}
                      color="primary"
                      variant="indeterminate"
                    />
                  )}
                  {SIDEPANEL_TABS.LOGS}
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tab}
              data-tour-step={tourSteps['bookmark-panel-btn'].id}
              onClick={() => {
                const { currentStepId } = useTourState.get();
                if (currentStepId === tourSteps['bookmark-panel-btn'].id) {
                  tourSteps['bookmark-panel-btn'].next();
                }
              }}
              label={
                <Grid container justifyContent="center">
                  {(isBookmarkRefetching || isPredefinedBookmarkRefetching) && (
                    <LinearProgress
                      className={classes.loader}
                      color="primary"
                      variant="indeterminate"
                    />
                  )}
                  {SIDEPANEL_TABS.BOOKMARKS}
                </Grid>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
        <Grid item className={classes.tabPanel}>
          <TabPanel value={tabIndex} index={0}>
            <LogPanel
              logData={logData}
              isLogLoading={isLogLoading}
              logError={logError}
              isBookmarkLoading={isBookmarkLoading}
              bookmarkError={bookmarkError}
              bookmarkData={queryViewBookmarks}
              populateQueryBuilder={props.populateQueryBuilder}
              openCreateBookmarkDialog={props.openCreateBookmarkDialog}
              baseEndpoint={props.baseEndpoint}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} data-tour-step={tourSteps['bookmark-panel'].id}>
            <BookmarkPanel
              bookmarkData={queryViewBookmarks}
              predefinedBookmarkData={predefinedBookmarkData}
              populateQueryBuilder={props.populateQueryBuilder}
              isBookmarkLoading={isBookmarkLoading}
              isPredefinedBookmarkLoading={isPredefinedBookmarkLoading}
              bookmarkError={bookmarkError}
              predefinedBookmarkError={predefinedBookmarkError}
              baseEndpoint={props.baseEndpoint}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Drawer>
  );
}
