import React, { useCallback, useRef, useState } from 'react';
import { GrDrag } from 'react-icons/gr';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { List as VirtualizedList } from 'react-virtualized';
import { get } from 'lodash-es';
import { MdVpnKey } from 'react-icons/md';
import { TiPin } from 'react-icons/ti';

import { updateReportViewState, useReportViewState } from '../../state/reportView';
import { useDisclosure } from '../../hooks/useDisclosure';
import { ROOT_LEVEL_GROUP_NAME } from './constants';
import PopoverMenu from './PopoverMenu';
import { Badge, Box, Button, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { borders } from 'mui5Theme';

const ROW_HEIGHT = 40;

const entityValueSelector = (state) => state.fork.entity.value;
const visibleFieldsSliceSelector = (state) => ({
  visibleFieldsValues: state.fork.visibleFields.value,
  visibleFieldsOptionsMap: state.fork.visibleFields.optionsMap,
});
export function ArrangeFieldsOption({ disabled }) {
  const ref = useRef();

  const entityValue = useReportViewState(entityValueSelector);
  const { visibleFieldsValues, visibleFieldsOptionsMap } = useReportViewState(
    visibleFieldsSliceSelector
  );

  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <Badge>
        <Button
          disabled={!entityValue || visibleFieldsValues.length === 0 || disabled}
          ref={ref}
          onClick={open}
          size="small"
          variant="outlined"
          color="primary"
        >
          Arrange Fields
        </Button>
      </Badge>
      {isOpen && (
        <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current} sx={{ pr: 0 }}>
          <ArrangeFieldsPopperContent
            visibleFieldsValues={visibleFieldsValues}
            visibleFieldsOptionsMap={visibleFieldsOptionsMap}
          />
        </PopoverMenu>
      )}
    </>
  );
}

const useDragAndDrop = (visibleFieldsValues, visibleFieldsOptionsMap) => {
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { over } = event;

      const activeField = get(visibleFieldsOptionsMap, activeId);
      const overField = get(visibleFieldsOptionsMap, over.id);

      const isDragDisabled =
        (activeField.isKey && !activeField.parent) ||
        (overField.isKey && !overField.parent) ||
        activeField.isPinned ||
        overField.isPinned;

      if (!isDragDisabled) {
        const activeIndex = activeId ? visibleFieldsValues.indexOf(activeId) : -1;

        if (over) {
          const overIndex = visibleFieldsValues.indexOf(over.id);

          if (activeIndex !== overIndex) {
            const reOrderedArray = arrayMove(visibleFieldsValues, activeIndex, overIndex);
            updateReportViewState((state) => {
              state.fork.visibleFields.value = reOrderedArray;
            });
          }
        }
      }

      setActiveId(null);
    },
    [activeId, visibleFieldsValues, visibleFieldsOptionsMap]
  );

  return { sensors, handleDragEnd, setActiveId, activeId };
};

function ArrangeFieldsPopperContent({ visibleFieldsValues, visibleFieldsOptionsMap }) {
  const { sensors, handleDragEnd, setActiveId, activeId } = useDragAndDrop(
    visibleFieldsValues,
    visibleFieldsOptionsMap
  );

  return (
    <Box minWidth={400} maxWidth={680} height={300}>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        collisionDetection={closestCenter}
        onDragStart={({ active }) => {
          const fieldData = get(visibleFieldsOptionsMap, active.id);
          if (!(fieldData.isKey && !fieldData.parent) || !fieldData.isPinned) {
            setActiveId(active.id);
          }
        }}
        onDragCancel={() => setActiveId(null)}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={visibleFieldsValues} strategy={verticalListSortingStrategy}>
          <VirtualizedList
            width={400}
            height={300}
            style={{
              py: 16,
              '&>div': {
                height: `${visibleFieldsValues?.length ?? 0 * ROW_HEIGHT + 10}px !important`,
                overflow: 'unset !important',
              },
            }}
            rowCount={visibleFieldsValues.length}
            rowHeight={ROW_HEIGHT}
            rowRenderer={({ style, index }) => {
              const fieldName = visibleFieldsValues[index];
              const fieldData = get(visibleFieldsOptionsMap, fieldName);

              return (
                <Draggable
                  key={fieldName}
                  id={fieldName}
                  style={style}
                  disableDrag={(fieldData.isKey && !fieldData.parent) || fieldData.isPinned}
                >
                  <SortableItem
                    fieldName={fieldName}
                    isKey={fieldData.isKey}
                    isPinned={fieldData.isPinned}
                    disableDrag={(fieldData.isKey && !fieldData.parent) || fieldData.isPinned}
                  />
                </Draggable>
              );
            }}
          />
        </SortableContext>
        <DragOverlay
          dropAnimation={{
            duration: 500,
          }}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          {activeId ? (
            <Box component={ListItem} alignItems="center" px={1.25} py={0.7}>
              <SortableItem fieldName={activeId} isDragOverlay />
            </Box>
          ) : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
}

function Draggable({ id, children, style, disableDrag }) {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  return (
    <Box
      component={ListItem}
      alignItems="center"
      ref={setNodeRef}
      {...(!disableDrag && { ...listeners })}
      {...(!disableDrag && { ...attributes })}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition,
        px: 1,
        py: 0.7,
        zIndex: isDragging ? 10 : 0,
        opacity: isDragging ? 0.5 : 1,
        cursor: disableDrag ? 'not-allowed' : 'grab',
      }}
      style={style}
    >
      {children}
    </Box>
  );
}

export function SortableItem({
  fieldName,
  isDragOverlay = false,
  isKey = false,
  disableDrag = false,
  isPinned = false,
}) {
  const [parentName, propertyName] = fieldName.split('.');

  const displayName =
    parentName === ROOT_LEVEL_GROUP_NAME ? propertyName : `${parentName}/${propertyName}`;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      p={0.8}
      position="relative"
      border={borders[0]}
      borderRadius={1}
      sx={(theme) => ({
        cursor: isDragOverlay ? 'grabbing' : 'grab',
        backgroundColor: disableDrag ? theme.palette.grey[300] : theme.palette.common.white,
      })}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <GrDrag fontSize={16} />
        <Tooltip title={displayName} arrow>
          <Box>
            <Typography noWrap>{displayName}</Typography>
          </Box>
        </Tooltip>
      </Stack>
      <Box pr={1}>
        {isKey && <MdVpnKey fontSize={16} />}
        {isPinned && <TiPin fontSize={16} />}
      </Box>
    </Stack>
  );
}
